<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="FighterData"
      @on-cell-click="onCellClick"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: 'ParticipantsTableComponent',
    props: ["FighterData"],
    data(){
      return {
          columns: [
              {
                  label: 'Vārds, Uzvārds',
                  field: 'NameSurname',
              },
              {
                  label: 'Komanda',
                  field: 'Team',
                  thClass: 'text-center',
                  tdClass: 'text-center',
              },
              {
                  label: 'Vecums',
                  field: 'Age',
                  thClass: 'text-center',
                  tdClass: 'text-center',
              },
              {
                  label: 'Svars',
                  field: 'Weight',
                  thClass: 'text-center',
                  tdClass: 'text-center',
              },
              {
                  label: 'Treneris',
                  field: 'Coach',
                  thClass: 'text-center',
                  tdClass: 'text-center',
              },
          ],
      }
    },
    methods: {

    },
    mounted() {
    },
    components: {

    },
  };
</script>