var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-good-table" }, [
    _c(
      "table",
      {
        staticClass: "table table-bordered",
        staticStyle: { "font-size": "16px" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.ParticipantsLozesanaiData, function(data) {
            return _c(
              "tr",
              {
                class: { "young-mark": data.Young },
                on: {
                  drag: function($event) {
                    _vm.dragFighter()
                  }
                }
              },
              [
                _c("td", [
                  _vm._v(
                    _vm._s(data.NameSurname) +
                      " (" +
                      _vm._s(data.CmUserId) +
                      ")"
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.Team))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.Age))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.Weight))]),
                _vm._v(" "),
                !(
                  data.CmUserId === _vm.Selected.Id &&
                  _vm.Selected.Row === "CmUserNr"
                )
                  ? _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            _vm.showEdit(data, "CmUserNr")
                          }
                        }
                      },
                      [_vm._v(_vm._s(data.CmUserNr))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                data.CmUserId === _vm.Selected.Id &&
                _vm.Selected.Row === "CmUserNr"
                  ? _c("td", [
                      _c("label", [_vm._v("Mainit: ")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Selected.Value,
                            expression: "Selected.Value"
                          }
                        ],
                        domProps: { value: _vm.Selected.Value },
                        on: {
                          focus: function($event) {
                            $event.target.select()
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.updateData(_vm.Selected)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.Selected, "Value", $event.target.value)
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          _vm.ShowChangeCategoryModal(data)
                        }
                      }
                    },
                    [_vm._v("Parvietot")]
                  )
                ])
              ]
            )
          })
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "btn btn-success",
        staticStyle: { width: "25rem", float: "right" },
        on: {
          click: function($event) {
            _vm.tossup()
          }
        }
      },
      [_vm._v("Automātiska lozēšana")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "btn btn-primary",
        staticStyle: { width: "25rem", float: "right" },
        on: {
          click: function($event) {
            _vm.generateBattles()
          }
        }
      },
      [_vm._v("Rediģet ciņas")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Vards, Uzvārds")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", [_vm._v("Vecums")]),
        _vm._v(" "),
        _c("th", [_vm._v("Svars")]),
        _vm._v(" "),
        _c("th", [_vm._v("Lozes numurs")]),
        _vm._v(" "),
        _c("th", [_vm._v("Darbibas")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-278af804", { render: render, staticRenderFns: staticRenderFns })
  }
}