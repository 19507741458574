var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page__content__data" }, [
    _c("div", { staticClass: "page__content__data-container" }, [
      _c("div", { staticClass: "page__content__data-container__sub-content" }, [
        _c("div", { staticClass: "list-grid" }, [
          _c("div", { staticClass: "list-grid--header" }, [
            _vm._v("\n          Saglabatie pulti\n        ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-grid__item" }, [
            _c(
              "div",
              { staticClass: "list-grid__item__table" },
              [
                _vm._l(_vm.RemoteControls, function(RemoteControl) {
                  return _vm.RemoteControls.length !== 0
                    ? _c(
                        "div",
                        { staticClass: "list-grid__item__table__cell" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list-grid__item__table__cell--text",
                              staticStyle: { "font-size": "1.5rem !important" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(RemoteControl.RemoteAddress) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-grid__item__table__cell__btn-container"
                            },
                            [
                              !RemoteControl.Active
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--accept-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.activateRemoteControl(
                                            RemoteControl.Id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Aktivet\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              RemoteControl.Active
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--reject-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.deactivateRemoteControl(
                                            RemoteControl.Id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Deaktivet\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.RemoteControls.length === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "list-grid__item__table__empty-table-msg"
                      },
                      [
                        _c("div", {}, [
                          _vm._v("\n                Pultu nav\n              ")
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "list-grid" }, [
          _c("div", { staticClass: "list-grid--header" }, [
            _vm._v("\n          Ienakošie dati\n        ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-grid__item" }, [
            _c(
              "div",
              { staticClass: "list-grid__item__table" },
              [
                _vm._l(_vm.RemotesData, function(Package) {
                  return _vm.RemotesData.length !== 0
                    ? _c(
                        "div",
                        { staticClass: "list-grid__item__table__cell" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list-grid__item__table__cell--text",
                              staticStyle: {
                                "font-size": "1.5rem !important",
                                padding: "0.5rem 3rem 0"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Remote: " +
                                  _vm._s(Package.RemoteAddress) +
                                  "\n                "
                              ),
                              _c("br"),
                              _vm._v(
                                " Color: " +
                                  _vm._s(Package.Color) +
                                  "\n                "
                              ),
                              _c("br"),
                              _vm._v(
                                " TimeStamp: " +
                                  _vm._s(Package.TimeStamp) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-grid__item__table__cell__btn-container"
                            },
                            [
                              Package.Valid
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--accept-btn"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Valid\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !Package.Valid
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--reject-btn"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Invalid\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.RemotesData.length === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "list-grid__item__table__empty-table-msg"
                      },
                      [
                        _c("div", {}, [
                          _vm._v("\n                Datu nav\n              ")
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-53751f27", { render: render, staticRenderFns: staticRenderFns })
  }
}