<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Vecuma kategorijas pievienosana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
       <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input" v-bind:class="{slide : show}">
        <h1 class="page__content__input--title">Sacensiba: {{CompetitionTitle}}</h1>
        <br>
        <div class="page__content__input--btn">
          <router-link class="btn btn-info"  :to="{ name: 'createAgeCategoryPage'}">Izveidot vecuma categoriju +</router-link>
        </div>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Discipline</label>
            <multiselect v-model="Discipline" :options="Disciplines" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="Select discipline" track-by="Id" :custom-label="DisciplineLabel"></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input-form__item--label control-label col-lg-2 col-sm-4">Vecuma kategorija</label>
            <multiselect v-model="AgeCategoryIds" :options="Categories" :multiple="true" track-by="Id" :custom-label="AgeCategoryLabel"></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <button class="page__content__input__form__item--btn btn btn-primary" v-on:click="AddCategory">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import MenuComponent from "../../components/common/MenuComponent";


  export default {
    name: "addAgeCategoryPage",
    data(){
      return {
        show: false,
        Disciplines: [],
        Categories: [],
        AgeCategories: [],
        AgeCategoriesInCompetition: [],
        AgeCategoryIds:"",
        CompetitionTitle: "",
        Discipline: "",
        Data: {
          CompetitionId: "",
          Discipline: "",
          AgeCategoryIds: "",
        },

      }
    },
    methods: {
      AddCategory(){
        this.Data.CompetitionId=this.$route.params.cmId
        this.Data.Discipline=this.Discipline.Id;
        this.Data.AgeCategoryIds=this.AgeCategoryIds
        console.log(this.Data.AgeCategoryIds)
        console.log(this.AgeCategoryIds)
        console.log(this.Data)
          this.$socket.emit('add-age-categories', this.Data);
      /*  this.$axios.post( '/api/competitions/category/age/add', this.Data)
          .then( response => {
          })
          .catch( (error) => {
            this.errors.push(e)
          });*/
      },
      getData(){
        this.$axios.get(`/api/competitions/category/age/data`,{
          params: {
            cmId: this.$route.params.cmId,
          }
        })
        .then(response => {
          this.CompetitionTitle = response.data.CompetitionTitle;
          this.Disciplines = response.data.Disciplines;
          this.AgeCategories = response.data.AgeCategories;
          this.AgeCategoriesInCompetition = response.data.AgeCategoriesInCompetition;
          console.log(this.Disciplines)
          console.log(this.AgeCategories)
          console.log(this.AgeCategoriesInCompetition )

        })
        .catch(e => {
          this.errors.push(e)
        })
      },
      DisciplineLabel (option) {
        return `${option.Title}`
      },
      AgeCategoryLabel (option) {
        if ((typeof option.Title=='undefined') && (typeof option.AgeMin=='undefined') && (typeof option.AgeMax=='undefined')){return `-`}
        return `${option.Title} age: ${option.AgeMin}-${option.AgeMax} ${option.Rounds}x${option.RoundTime} min`
      },
    },
    mounted() {
      this.getData();
        this.$socket.on('success', response => {
            if (response===true){
                console.log(this.Data.CompetitionId)
                console.log(this.$route.params.cmId)
                this.$router.push({ name: 'categoriesPage', params: { cmId: this.Data.CompetitionId }})
            }else{
              alert(response)
              /*alert("Check all fields on filled right")*/
            }
        });
      this.$socket.on('refresh-agecategories-data', response => {
        this.getData();
      })
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-agecategories-data');
    },
    watch: {
      Discipline: function (value) {
        this.Categories = this.AgeCategories[this.Discipline.IdForAgeCateg]
        this.AgeCategoryIds = this.AgeCategoriesInCompetition[this.Discipline.Id]
        console.log(this.AgeCategoryIds)
      },
    },
    components: {
        MenuComponent,
      Multiselect
    }
  }
</script>

<style lang="scss" scoped>
  .page__content{
    &__input{
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #eeecf6;
      z-index: 0;
      padding: 0 1rem;
      &--title{
        padding-left: 4rem;
      }
      &--btn{
        padding: 0 6rem 3rem;
      }
      &__form{
        display: flex;
        flex-direction: column;
        align-items: center;
          &__item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 2rem;
          width: 55rem;
          &--label{
            text-align: left;
          }
          &__input{
          }
          &--btn{
            text-align: center;
            padding-right: 3rem;
            width: 15rem;
          }
        }
      }
    }
  }

  #coachs{}

  #app {
    margin-top: 20px;
  }

  pre {
    margin-top: 20px;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
