var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input" }, [
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item ",
              staticStyle: { "z-index": "2" }
            },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label "
                },
                [_vm._v("Atrast dalibnieku")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Users,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.NameSurnameLabel
                },
                on: {
                  input: function($event) {
                    _vm.setCategories()
                  }
                },
                model: {
                  value: _vm.ApplyData.User,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData, "User", $$v)
                  },
                  expression: "ApplyData.User"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.User.Name,
                  expression: "ApplyData.User.Name"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.User.Name },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData.User, "Name", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.User.Surname,
                  expression: "ApplyData.User.Surname"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.ApplyData.User.Surname },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData.User, "Surname", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__input__form__item--input",
                staticStyle: { "z-index": "1" }
              },
              [
                _c("DatePicker", {
                  attrs: {
                    "calendar-class": "calendar",
                    "monday-first": true,
                    placeholder: _vm.ApplyData.User.DateOfBirth,
                    "bootstrap-styling": true
                  },
                  on: {
                    closed: function($event) {
                      _vm.setCategories()
                    }
                  },
                  model: {
                    value: _vm.ApplyData.User.DateOfBirth,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData.User, "DateOfBirth", $$v)
                    },
                    expression: "ApplyData.User.DateOfBirth"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Genders,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.GenderLabel
                },
                on: {
                  input: function($event) {
                    _vm.setCategories()
                  }
                },
                model: {
                  value: _vm.ApplyData.User.Gender,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData.User, "Gender", $$v)
                  },
                  expression: "ApplyData.User.Gender"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.Weight,
                  expression: "ApplyData.Weight"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.Weight },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData, "Weight", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(6),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Categories,
                  "close-on-select": true,
                  multiple: true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.CategoryLabel
                },
                model: {
                  value: _vm.ApplyData.Categories,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData, "Categories", $$v)
                  },
                  expression: "ApplyData.Categories"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(7),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.Fights,
                  expression: "ApplyData.Fights"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.Fights },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData, "Fights", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(8),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.Victory,
                  expression: "ApplyData.Victory"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.Victory },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData, "Victory", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(9),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.Knockouts,
                  expression: "ApplyData.Knockouts"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.Knockouts },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData, "Knockouts", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(10),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Teams,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.TeamLabel
                },
                model: {
                  value: _vm.ApplyData.Team,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData, "Team", $$v)
                  },
                  expression: "ApplyData.Team"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.$store.state.authUser.type == 1
            ? _c("div", { staticClass: "page__content__input__form__item" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "page__content__input__form__item--label control-label ",
                    staticStyle: { left: "-2rem" },
                    attrs: { type: "checkbox" }
                  },
                  [_vm._v("Samaksāts")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ApplyData.Payed,
                      expression: "ApplyData.Payed"
                    }
                  ],
                  staticClass:
                    "page__content__input__form__item--input form-control",
                  staticStyle: { width: "5rem", left: "-2rem" },
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.ApplyData.Payed)
                      ? _vm._i(_vm.ApplyData.Payed, null) > -1
                      : _vm.ApplyData.Payed
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.ApplyData.Payed,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.ApplyData, "Payed", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.ApplyData,
                              "Payed",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.ApplyData, "Payed", $$c)
                      }
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser.type == 1
            ? _c("div", { staticClass: "page__content__input__form__item" }, [
                _vm._m(11),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ApplyData.PaymentComments,
                      expression: "ApplyData.PaymentComments"
                    }
                  ],
                  staticClass:
                    "page__content__input__form__item--input form-control",
                  attrs: { rows: "5" },
                  domProps: { value: _vm.ApplyData.PaymentComments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ApplyData,
                        "PaymentComments",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__btn-item" }, [
            _c(
              "button",
              {
                staticClass:
                  "page__content__input__form__btn-item--btn btn btn-primary",
                on: {
                  click: function($event) {
                    _vm.applyUser(_vm.ApplyData)
                  }
                }
              },
              [_vm._v("Saglabat")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Pieteikt dalibnieku")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Vards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Uzvards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { left: "-2rem", "z-index": "1" }
      },
      [_vm._v("Dzimsanas Datums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Gender"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Weight"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { "z-index": "0" }
      },
      [_vm._v("Kategorijas"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Cinas"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Uzvaras"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Nokdauni"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Team"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Maksājuma komentārs"), _c("sup", [_vm._v("*")])]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1b7300a0", { render: render, staticRenderFns: staticRenderFns })
  }
}