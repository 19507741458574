<template>
  <div class="page score-registration col-xs-12 clear-pads">

    <div class="col-xs-6 clear-pads red-corner" :class="[redKickActive == true ? 'red-corner-darker' : 'red-corner-bg']"></div>

    <div class="col-xs-6 clear-pads blue-corner" :class="[blueKickActive == true ? 'blue-corner-darker' : 'blue-corner-bg']"></div>

 <!--   <div class="container timer-block">
      <div class="clear-pads col-md-12 col-sm-12 col-xs-12 text-center">
        <timer v-if="fight.roundDetails" :data="fight.roundDetails" :lvl="fight.lvl"></timer>
      </div>
    </div>-->

    <a class="red-corner trigger" @click="kick(1, fight.red_user_id)" @mousedown="animateRed( true )" @mouseup="animateRed( false )"><div class="col-xs-6 clear-pads"></div></a>

    <a class="blue-corner trigger" @click="kick(2, fight.blue_user_id)" @mousedown="animateBlue( true )" @mouseup="animateBlue( false )"><div class="col-xs-6 clear-pads"></div></a>

    <modal name="confirm-ready-status" :height="'auto'">
      <confirm-ready-status></confirm-ready-status>
    </modal>
  </div>
</template>
<script>
  import timer from '../../components/JudgingComponents/FightInfoComponents/Timer.vue';
  import ConfirmReadyStatus from '../../components/JudgingComponents/ReadyCheckComponents/ConfirmReadyStatus.vue';
  export default {
    name: 'JudgeFightPage',
    data () {
      return {
        redKickActive: null,
        blueKickActive: null,
        fight: {}
      }
    },
    methods: {
      animateRed( bool ){
        this.redKickActive = bool;
      },
      animateBlue( bool ){
        this.blueKickActive = bool;
      },
      validateKick(){
        let validity = this.fight.roundDetails.round_status === 1 && this.fight.roundDetails.pause === 0;
        return + validity ;
      },
      kick(color, userId) {
        let data = {
          judge_id: this.$store.state.authUser ? this.$store.state.authUser.id : null,
          battle_id: this.fight.id,
          remote_id: 1,
          color_id: color,
          user_id: userId,
          time: this.$moment( new Date() ).format('HH:mm:ss'),
          valid: this.validateKick(),
          round:  this.fight.roundDetails.round_nr,
          room: this.$route.params.fightId
        };
        this.$socket.emit('add-point', data )
      }
    },
    mounted () {
      this.$bus.$emit("header-off");
      this.$axios.get(`/api/judge/fight-info`, {
        params: {
          fightId: this.$route.params.fightId,
          judgeId: this.$store.state.authUser ? this.$store.state.authUser.id : null
        }
      })
      .then(response => {
        this.fight = response.data;

        if( !this.fight.judgeStatus.status ){
          //this.$modal.show('confirm-ready-status');
        }
      })
      .catch(e => {
        this.errors.push(e)
      });

      this.$socket.emit('room-join', this.$route.params.fightId);

      this.$socket.on( 'round-status', data => {
        this.fight.roundDetails = data.response;
        if(data.emit){
          this.$bus.$emit(data.emit, data.response);
        }
      });

      this.$socket.on('request-judges-statuses', data => {
        this.$modal.show('confirm-ready-status');
      });
    },
    beforeDestroy () {
      this.$socket.emit('room-leave', this.$route.params.fightId);
    /*  this.$socket.removeAllListeners('fight-active');*/
/*      this.$socket.removeAllListeners('request-judges-statuses');*/
    },
    components: {
      timer,
      ConfirmReadyStatus
    }
  }

</script>

<style lang="scss" scoped>
  @keyframes redDarker {
    0% { background: rgba(255, 77, 74, 0.5);}
    100% { background: rgba(255, 77, 74, 0.7);}
  }
  @keyframes blueDarker {
    0% { background: rgba(102, 94, 255, 0.5);}
    100% { background: rgba(102, 94, 255, 0.7);}
  }
  .timer-block{
    height: 100%;
    display: flex;
    align-items: center;
  }
  .score-registration{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
    .red-corner, .blue-corner{
      position: absolute;
      top: 0px;
      bottom: 0px;
    }
    .trigger{
      z-index: 1;
    }
    .red-corner{
      left: 0px;
      right: 50%;
      &-bg{
         background: rgba(255, 77, 74, 0.5);
       }
      &-darker{
         animation: redDarker .3s forwards;
       }
    }
    .blue-corner{
      left: 50%;
      right: 0px;
      &-bg{
         background: rgba(102, 94, 255, 0.5);
       }
      &-darker{
         animation: blueDarker .3s forwards;
       }
    }
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
  }
</style>
