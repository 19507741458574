<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{RingTitle}}</h1>
      </div>
    </div>
    <div class="page__full-view-page-content">
      <div class="page__full-view-page-content__data">
        <public-ring-battles :BattlesData="RingBattles" :Url="'http://localhost:8000'+$route.path+'/'+RingId"></public-ring-battles>
      </div>
    </div>
  </div>
</template>

<script>

  import PublicRingBattlesComponent from '../../components/public/RingsInCompetitionComponents/FullViewComponent.vue';

  export default {
    name: "FullViewPage",
    data () {
      return {
        show: false,
        CompetitionTitle: null,
        RingBattles: [],
        RingTitle: "",
        RingId: "",
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/competition/ring/battles`, {
          params: {
            cmId: this.$route.params.cmId,
            ringId: this.$route.params.ringId,
          }
        })
          .then(response => {
            this.RingTitle = response.data.RingTitle;
            this.RingBattles = response.data.Battles;
            this.RingId=this.RingBattles[0].RingId;
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-battles-list', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-battles-list');

    },
    components: {
      'public-ring-battles': PublicRingBattlesComponent
    }
  }
</script>

<style  lang="scss" scoped>

 /* .ring-data-container {
    padding: 1rem 7rem;
    &__timer-set{

      &--label{

      }
      &--input{

      }
    }
  }*/
  .full-view-page-content {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    &__data {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
</style>