<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{ CompetitionInfo.Title }}</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
         <!-- <img src="/static/menu_icon.jpg" width="20rem">-->
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data" >
        <div class="page__content__data__primary-info">
          <div class="page__content__data__primary-info__item">
            <h2 class="page__content__data__primary-info__item--label">Datums</h2>
            <h3 class="page__content__data__primary-info__item--content">{{ this.$moment(CompetitionInfo.Date).format('YYYY-MM-DD') }}</h3>
          </div>
          <div class="page__content__data__primary-info__item">
            <h2 class="page__content__data__primary-info__item--label">Pieteikšanās līdz</h2>
            <h3 class="page__content__data__primary-info__item--content">{{ this.$moment(CompetitionInfo.AppUntil).format('YYYY-MM-DD') }}</h3>
          </div>
          <div class="page__content__data__primary-info__item">
            <h2 class="page__content__data__primary-info__item--label">Galvenais tiesnesis</h2>
            <h3 class="page__content__data__primary-info__item--content">{{CompetitionInfo.JudgeName +" "+ CompetitionInfo.JudgeSurname}}</h3>
          </div>
        </div>
          <div class="page__content__data__invite">
            <div class="page__content__data__invite--content notice" v-html="CompetitionInfo.InviteMessage"></div>
            <div class="page__content__data__invite--apply">
              <router-link :to="{ name: 'applyTeamPage', params: {cmId: $route.params.cmId }}" style="cursor: pointer;">Pieteikties sacensībām</router-link>
            </div>
          </div>
        <div class="page__content__data__info">
          <div class="page__content__data__info__item" style="width: 100%;">
              <h2 class="page__content__data__info__item--label">Programma</h2>
              <div class="page__content__data__info__item--content">
                <h3 v-html="CompetitionInfo.Program"></h3>
              </div>
            </div>
            <div class="page__content__data__info__item" style="width: 100%">
              <h2 class="page__content__data__info__item--label">Noteikumi</h2>
              <h3 class="page__content__data__info__item--content" v-html="CompetitionInfo.Rules">
              </h3>
            </div>
            <div class="page__content__data__info__item">
              <h2 class="page__content__data__info__item--label">Apbalvošana</h2>
              <h3 class="page__content__data__info__item--content" v-html="CompetitionInfo.Prizes">
              </h3>
            </div>
            <div class="page__content__data__info__item">
              <h2 class="page__content__data__info__item--label">Ambulance</h2>
              <h3 class="page__content__data__info__item--content" v-html="CompetitionInfo.Ambulance">
              </h3>
            </div>
            <div class="page__content__data__info__item">
              <h2 class="page__content__data__info__item--label">Sadarbiba</h2>
              <h3 class="page__content__data__info__item--content" v-html="CompetitionInfo.Cooperation">
              </h3>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import MenuComponent from '../../components/common/MenuComponent.vue';

  export default {
    name: "competitionPage",
    data() {
      return {
        show: false,
        CompetitionInfo: {
          Title: "",
          Date: "",
          AppUntil: "",
          MainJudge: "",
          InviteMessage: "",
          Program: "",
          Rules: "",
          Prizes: "",
          Ambulance: "",
          Cooperation: ""
        },
      }
    },
    methods:{
    },
    mounted() {
      this.$axios.get(`/api/competition`, {
        params: {
          cmId: this.$route.params.cmId,
        }
      })
        .then(response => {
          this.CompetitionInfo = response.data.CompetitionInfo;
          console.log(this.CompetitionInfo);
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    beforeDestroy () {
    },
    components: {
        MenuComponent
    }
  }
</script>

<style lang="scss" scoped>

  .notice {
    background-color: black;
  }

 /* .notice img{
    width: 75rem;
    height: auto;
  }
*/
 /* @media (max-width: 991px){
    .page__content__data__invite--content notice img{
      width:100%;
      height: auto;
    }
  }*/

</style>