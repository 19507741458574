<template>
	<div class="page">
		<div class="page__section">
			<div class="page__section__name-container">
				<h1 class="page__section__name-container--title">{{CompetitionTitle}}<br><span class="page__section__name-container--subtitle">{{Subtitle}}</span></h1>
			</div>
			<div class="page__section__sort">
				<select class="page__section__sort--select" v-model="SelectedTeam" v-on:change="SelectTeam">
					<option v-for="(team, key) in Teams" :value="team.Id" selected="selected">{{ team.Title }}</option>
				</select>
			</div>
		</div>
		<div class="page__content">
			<div class="page__content__navbar-container" v-bind:class="{slide : show}">
				<MenuComponent></MenuComponent>
				<div @click="show = !show" class="page__content__navbar-container--open-btn">
					<span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
				</div>
			</div>
			<div class="page__content__data">
				<ResultsTableComponent :ResultsData="Results"></ResultsTableComponent>
				<!--<div class="emptycategory" v-if="JSON.stringify(BattlesInWeightCategory)=='{}'">
					<h1 >Šeit pagaidam nav ne viena ciņa</h1>
				</div>
				<div v-for="(AgeCategory, AgeCategoryTitle) in Fighters">
					<div v-for="(WeightCategory, index, key) in AgeCategory">

						<a v-if="key==0" class="page__content__data&#45;&#45;header" :href="'/competition/category/age/'+$route.params.cmId+'/'+SelectedTeam+'/'+WeightCategory[0].CmAgeCategoryId">{{ WeightCategory[0].AgeCategoryTitle }}</a><br>
						<a class="page__content__data&#45;&#45;header" :href="'/competition/category/weight/'+$route.params.cmId+'/'+SelectedTeam+'/'+WeightCategory[0].CmAgeCategoryId+'/'+WeightCategory[0].CmWeightCategoryId">{{ WeightCategory[0].WeightCategoryTitle }} Kg</a>

						<AdminNavigationInCategoryComponent v-if="$store.state.authUser && $store.state.authUser.type === 1" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]" :ParticipantsLozesanai="FightersLozesanai[AgeCategoryTitle][index]"></AdminNavigationInCategoryComponent>
						<PublicNavigationInCategoryComponent v-if="!$store.state.authUser" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
						<PublicNavigationInCategoryComponent v-if="$store.state.authUser && !($store.state.authUser.type === 1)" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
					</div>
				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
	import MenuComponent from "../../components/common/MenuComponent";
	import ResultsTableComponent from "../../components/common/Results/TeamResultsTableComponent";

  export default {
  name: 'teamReportResultsPage',
  data () {
    return {
      	show: false,
				TeamPlace: 'nav',
				TeamTitle: null,
				Teams: {},
      	SelectedTeam: this.$route.params.teamId,
				Subtitle: ''
		}
  },
    methods: {
      SelectTeam: function(){
		if(this.$route.params.ringId) {
			this.$router.replace({
				name: 'teamReportResultsInRingPage',
				params: {cmId: this.$route.params.cmId, teamId: this.SelectedTeam, ringId: this.$route.params.ringId}
			})
		} else if(this.$route.params.discipId && !this.$route.params.ageCategId && !this.$route.params.weightCategId) {
          this.$router.replace({
            name: 'teamReportResultsInDisciplinePage',
            params: {cmId: this.$route.params.cmId, teamId: this.SelectedTeam, discipId: this.$route.params.discipId}
          })
        } else if(this.$route.params.ageCategId && !this.$route.params.weightCategId) {
          this.$router.replace({
            name: 'teamReportResultsInAgeCategoryPage',
            params: {cmId: this.$route.params.cmId, teamId: this.SelectedTeam, ageCategId: this.$route.params.ageCategId}
          })
        } else if(this.$route.params.weightCategId){
          this.$router.replace({ name: 'teamReportResultsInWeightCategoryPage',
            params: {cmId: this.$route.params.cmId, teamId: this.SelectedTeam, weightCategId: this.$route.params.weightCategId}
          })
        } else {
          this.$router.replace({ name: 'teamReportResultsPage',
            params: {cmId: this.$route.params.cmId, teamId: this.SelectedTeam}
          })
        }
		this.$axios.get(`/api/competition/team-report-results`, {
			params: {
				cmId: this.$route.params.cmId,
				teamId: this.SelectedTeam,
				discipId: this.$route.params.discipId,
				ageCategId: this.$route.params.ageCategId,
				weightCategId: this.$route.params.weightCategId,
				ringId: this.$route.params.ringId,
			}
		})
			.then(response => {
			  this.CompetitionTitle = response.data.CompetitionTitle;
			  this.Results = response.data.Results;
			  this.TeamPlace = response.data.TeamPlace;
			  this.Teams = response.data.TeamsInCompetition;
						this.Subtitle = response.data.Subtitle;
			  if(this.TeamPlace!=0){
				this.TeamPlace = 'nav'
			  }
			})
			.catch(e => {
			  this.errors.push(e)
			})
		},

		getData: function(){
				this.$axios.get(`/api/competition/team-report-results`, {
					params: {
						cmId: this.$route.params.cmId,
						teamId: this.$route.params.teamId,
						discipId: this.$route.params.discipId,
						ageCategId: this.$route.params.ageCategId,
						weightCategId: this.$route.params.weightCategId,
						ringId: this.$route.params.ringId,
					}
				})
						.then(response => {
							this.CompetitionTitle = response.data.CompetitionTitle;
							this.Results = response.data.Results;
							this.TeamPlace = response.data.TeamPlace;
							this.Teams = response.data.TeamsInCompetition;
							this.Subtitle = response.data.Subtitle;
							if(this.TeamPlace!=0){
								this.TeamPlace = 'nav'
							}
						})
						.catch(e => {
							this.errors.push(e)
						})
      /*  this.$axios.get(`/api/team-report-results`, {
          params: {
            cmId: this.$route.params.cmId,
            TeamId: this.$route.params.teamId
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.Fighters = response.data.Fighters;
            this.Teams=response.data.Teams;
            this.SelectedTeam=response.data.SelectedDiscipId;
            this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
            this.BattlesForGraph=response.data.BattlesForGraph;
            this.FightersLozesanai = response.data.FightersLozesanai;
            console.log(response.data);
            /!*console.log(this.CompetitionTitle)*!/

          })
          .catch(e => {
            this.errors.push(e)
          })
*/
			},
		/*	TeamLabel (option) {
				if ((typeof option.Title=='undefined') || (typeof option.Id=='undefined')){return '-'}
				return `${option.Title}`
			},*/
    },
	mounted () {
		this.getData();
	/*	this.$socket.on('refresh-users-data', response => {
			this.SelectTeam();
		});
		this.$socket.on('refresh-cmusers-data', response => {
			this.SelectTeam();
		});
		this.$socket.on('refresh-battles-list', response => {
			this.SelectTeam();
		});
		this.$socket.on('refresh-tossup-data', response => {
			this.SelectTeam();
		});
    this.$bus.$on("CmUserData", (data)=>{
      this.ParticipantData=data;
      console.log("geted data: ",data)
    });*/
	},
	beforeDestroy () {
/*		this.$socket.removeListener('refresh-users-data');
		this.$socket.removeListener('refresh-cmusers-data');
		this.$socket.removeListener('refresh-battles-list');
		this.$socket.removeListener('refresh-tossup-data');*/
	},
  components: {
    MenuComponent,
		ResultsTableComponent
  }
}
</script>

<style  lang="scss" scoped>
	.emptycategory{
		display: flex;
		justify-content: center;
		align-items: center;
		height:100%;
	}

</style>