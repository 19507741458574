<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Ringu saraksts</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__table">
        <div style="padding: 1rem 4rem;">
          <a v-if="CreationForm" class="btn btn-info" v-on:click="CreationForm = !CreationForm">Izveidot ringu +</a>
          <a v-if="!CreationForm" class="btn btn-info" v-on:click="CreationForm = !CreationForm">Aizvert</a>
        </div>
        <div v-if="!CreationForm" style="padding: 1rem 4rem; background-color: #dadada">
          <label class="">Nosaukums </label>
          <input value="Ring" v-model="RingTitle" v-on:keyup.enter="createRing">
          <button class="btn btn-info" v-on:click="createRing()">Izveidot ringu</button>
        </div>
        <rings-grid :RingsData="Rings"></rings-grid>
      </div>
    </div>
  </div>
</template>

<script>
  import RingsGridComponent from "../../components/admin/CompetitionDataTablesComponents/RingsGridComponent.vue";
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "ringsPage",
    data() {
      return{
        show: false,
        Rings: [{Id: 1, RingTitle: "Title" }],
        CreationForm: true,
        RingTitle: "",
      }
    },
    methods: {
      createRing(){
          this.$socket.emit('create-ring', {Title: this.RingTitle} );
      },
      getData(){
          this.$axios.get(`/api/rings`, {
              params: {
                  cmId: this.$route.params.cmId,
              }
          })
              .then(response => {
                  this.Rings = response.data.Rings;
                  console.log(this.Rings);
              })
              .catch(e => {
                  this.errors.push(e)
              })
      }
    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-rings-data', response => {
          this.getData();
      });
      this.$socket.on('success', response => {
        console.log(response);
        if (response == true){
          alert(`New ring created!`)
        }else{
          alert(response);
        }
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-rings-data');
      this.$socket.removeListener('success');
    },
    components:{
        MenuComponent,
      "rings-grid": RingsGridComponent
    },
  }
</script>

<style scoped>

</style>