<template>
  <div class="page" style="padding: 0;">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Izveleties uzvaretaju</h1>
    </div>
    <div class="page__content" style="padding: 0;">
      <div class="page__content__input" style="padding-top: 3rem;">
        <div class="page__content__input__form" >
          <div class="page__content__input__form__item" style="padding:0;">
          </div>
          <div class="page__content__input__form__btn-item" >
            <table class="table table-bordered table-hover text-center table-battle-results" align="center">
              <thead>
              <tr class="atcive">
                <th colspan="6" style="text-align:center;">Battle Results</th><!-- ({{BattleData}})</th>-->
              </tr>
              </thead>
              <tbody>
              <tr>
                <th colspan="2"></th>
                <th colspan="2">Red: {{BattleData.RedNameSurname}}</th>
                <th colspan="2">Blue: {{BattleData.BlueNameSurname}}</th>
              </tr>
              <tr>
                <th colspan="2">Scores</th>
                <th colspan="2"><input v-model="RedScore"/></th>
                <th colspan="2"><input v-model="BlueScore"/></th>
              </tr>
              <tr>
                <th colspan="2">Result</th>
                <th colspan="2"><p>{{RedScore}}</p></th>
                <th colspan="2"><p>{{BlueScore}}</p></th>
              </tr>
              <tr>
                <th colspan="2">Winner</th>
                <th colspan="2" class="red-radio-label">
                  <label class="radio-container">
                    <input type="radio" v-model="winner" value="red">Red
                    <span class="red-checkmark"></span>
                  </label>
                </th>

                <th colspan="2" class="blue-radio-label">
                  <label class="radio-container">
                    <input type="radio" v-model="winner" value="blue">Blue
                    <span class="blue-checkmark"></span>
                  </label>
                </th>
              </tr>
              </tbody>
            </table>
            <br>
            <p style="width: 100%; font-size: 2rem; display: inline-block;" v-if="BattleData.WinnerId">
              Winner is: {{BattleData.WinnerId === BattleData.RedCmUserId ? BattleData.RedNameSurname : BattleData.BlueNameSurname}}
            </p>
            <br>
            <div style="margin: 1rem;">
              <button class="btn btn-primary" @click="SendResult(winner, RedScore, BlueScore)">
                Saglabat
              </button>
            </div>
            <div style="margin: 1rem;">
              <button class="page__content__input__form__btn-item--btn" @click="$modal.hide('SelectWinnerModal')">
                ❌ Aizvert
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "SelectWinnerComponent",
    props: ["BattleData"],
    data(){
      return {
        Team: null,
        AllTeams: [],
        winner: null,
        RedScore: 0,
        BlueScore: 0,
      }
    },
    methods: {
      SendResult(winner, RedScore, BlueScore,){
        console.log('sended')
        if(this.winner) {
          if(this.winner=='red') {
            console.log('red')
            this.BattleData.WinnerId=this.BattleData.RedCmUserId
            this.$socket.emit('insert-battle-results', {WinnerId: this.BattleData.RedCmUserId, RedScore: RedScore, BlueScore: BlueScore, BattleId: this.BattleData.Id});
          }else if(this.winner=='blue') {
            console.log('blue')
            this.BattleData.WinnerId=this.BattleData.BlueCmUserId
            this.$socket.emit('insert-battle-results', {WinnerId: this.BattleData.BlueCmUserId, RedScore: RedScore, BlueScore: BlueScore, BattleId: this.BattleData.Id});
          } else {
            alert('Resultatu saglabašanas kļuda!')
          }
        }
      }
    },
    mounted() {
      let Result = this.BattleData.Result;
      Result = Result.split(' ');
      console.log('Result: ',Result);
      Result.forEach((item, index) => {
        if (item === "") {
          Result.splice(index, 1);
        }
      });
      this.RedScore = Result[0];
      this.BlueScore = Result[1];
      this.getData()
    },
    components: {
      "multiselect": Multiselect
    },
  };
</script>

<style lang="scss">
  .change-category-modal{
    padding: 5rem 10rem 10rem 10rem;
    &__participant-data{
      padding-bottom: 2rem;
      &--name-surname{
        text-align:center;
      }
    }
    &__set-category-container{
      &--select{
        padding-bottom: 4rem;
      }
      &--save-btn{
        float: right;
        width: 14rem;
        height: 5rem;
      }
    }
  }
</style>

<style lang="scss">


  .red-radio-label{
    color:#EA0101;
  }
  .blue-radio-label{
    color:#020EEB;
  }

  .table-battle-results{
    text-align:center;
    width: 1000px;
    height:120px;
    font-size:18px;
  }

  .table-decision{
    text-align:center;
    width: 1000px;
    height:200px;
    font-size:18px;
  }

  .table-remarks{
    text-align:center;
    width:1000px;
    font-size:18px;
  }

  .table-won-by{
    text-align:center;
    width: 1000px;
    height:150px;
    font-size:18px;
  }

  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .red-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .radio-container:hover input ~ .red-checkmark {
    background-color: #ccc;
  }

  .radio-container input:checked ~ .red-checkmark {
    background-color: red;
  }

  .red-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-container input:checked ~ .red-checkmark:after {
    display: block;
  }

  .radio-container .red-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .blue-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .radio-container:hover input ~ .blue-checkmark {
    background-color: #ccc;
  }

  .radio-container input:checked ~ .blue-checkmark {
    background-color: blue;
  }

  .blue-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-container input:checked ~ .blue-checkmark:after {
    display: block;
  }

  .radio-container .blue-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* REMARKS */
  .remark-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .remark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .red-remark-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  .remark-container:hover input ~ .red-remark-checkmark {
    background-color: #ccc;
  }


  .remark-container input:checked ~ .red-remark-checkmark {
    background-color: red;
  }

  .red-remark-checkmark:after {
    position: absolute;
    display: none;
  }

  .remark-container input:checked ~ .red-remark-checkmark:after {
    display: block;
  }

  .remark-container .red-remark-checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .blue-remark-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  .remark-container:hover input ~ .blue-remark-checkmark {
    background-color: #ccc;
  }

  .remark-container input:checked ~ .blue-remark-checkmark {
    background-color: blue;
  }

  .blue-remark-checkmark:after {
    position: absolute;
    display: none;
  }

  .remark-container input:checked ~ .blue-remark-checkmark:after {
    display: block;
  }

  .remark-container .blue-remark-checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
  }

  .table-select{
    width: 10rem;
  &__options{
     width: 12rem;
   }
  }

  @media (max-width: 1720px) {

    .t_totalmin {
      display: none;
    }

    .t_totalmax {
      display: table-row;
    }
  }


</style>
