<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Komandas</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__table">
        <div style="padding: 1rem 4rem;">
          <router-link :to="{ name: 'createTeamPage'}" class="btn btn-info" >Izveidot komandu +</router-link>
        </div>
        <TeamsTableComponent :TeamsData="Teams"></TeamsTableComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import TeamsTableComponent from '../../components/admin/CompetitionDataTablesComponents/TeamsTableComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "teamsPage",
    data(){
      return {
        show: false,
        Teams: [],
      }
    },
    methods:{
      getData(){
        if (this.$store.state.authUser.type==4) {
          console.log('User Data: ',this.$store.state.authUser)
          let Data = {
            userId: this.$store.state.authUser.id
          }
          this.$axios.get(`/api/coach/teams`,{params:{
              userId: this.$store.state.authUser.id
            }
          })
            .then(response => {
              this.Teams = response.data.TeamsData;
              console.log(this.Teams)
            })
            .catch(e => {
              this.errors.push(e)
            })
        } else {
          this.$axios.get(`/api/teams`)
            .then(response => {
              this.Teams = response.data.TeamsData;
              console.log(this.Teams)
            })
            .catch(e => {
              this.errors.push(e)
            })
        }
      }
    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-teams-data', response => {
          this.getData();
      });
    },
    beforeDestroy () {
        this.$socket.removeListener('refresh-teams-data');
    },
    components:{
        MenuComponent,
      TeamsTableComponent
    }
  }
</script>

<style scoped lang="scss">

</style>