<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Pievienot ringu</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <h1 class="page__content__input--title">Sacensiba: {{CompetitionTitle}}</h1>
        <br>
        <div class="page__content__input__form">
        <div style="padding: 0 4rem 3rem;">
          <router-link class="btn btn-info"  :to="{ name: 'ringsPage'}">Izveidot ringu +</router-link>
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Ring</label>
          <multiselect v-model="Ring" :options="Rings" :close-on-select="true"
                        :clear-on-select="false" :open-direction="'below'"
                       placeholder="Select ring" track-by="Id" :custom-label="RingLabel"></multiselect>
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Ringa numurs<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control"  v-model="Data.RingNr">
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Kategorijas</label>

          <multiselect v-model="Data.CategoriesIds" :options="Categories" :multiple="true" placeholder="Select categories" track-by="Id" :custom-label="CategoryLabel"></multiselect>

        </div>
         <div class="page__content__input__form__item">
           <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Galvenais Tiesnesis</label>
           <multiselect v-model="MainJudge" :options="MainJudges" :close-on-select="true"
                         :clear-on-select="false" :open-direction="'below'"
                        placeholder="Select judge" track-by="Id" :custom-label="JudgeLabel"></multiselect>
         </div>
         <div class="page__content__input__form__item">
           <label class="page__content__input__form__item--label control-label col-lg-2 col-sm-4">Tiesnesu sastavs</label>
           <multiselect v-model="Data.JudgesIds" :options="Judges" :multiple="true" placeholder="Select judge" track-by="Id" :custom-label="JudgeLabel"></multiselect>
         </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="AddRing">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "addRingPage",
    data(){
      return {
        show: false,
        CompetitionTitle: "CompetitionTitle",
        Ring: "",
        MainJudge: "",
        Data: {
          CompetitionId: "",
          Ring: "",
          RingNr: "",
          MainJudge: "",
          JudgesIds: [],
          CategoriesIds: [],
        },
        Rings:[],
        Categories: [],
        Judges: [],
        MainJudges: [],

      }
    },
    methods: {
      AddRing(){
        this.Data.CompetitionId=this.$route.params.cmId
        this.Data.Ring=this.Ring.Id;
        this.Data.MainJudge=this.MainJudge.Id;
        this.$socket.emit('create-competition-ring', this.Data );
       /* this.$axios.post( '/api/competition/rings/ring/add', this.Data)
          .then( response => {
          })
          .catch( (error) => {
            this.errors.push(e)
          });*/
      },
      getData(){
        this.$axios.get(`/api/competition/rings/ring/add/getringdata`,{
            params: {
                cmId: this.$route.params.cmId,
            }
        })
            .then(response => {
                this.CompetitionTitle=response.data.CompetitionTitle;
                this.Rings = response.data.Rings;
                this.Categories = response.data.Categories;
                this.Judges = response.data.Judges;
                this.MainJudges = response.data.MainJudges;
                console.log(this.Rings)
                console.log(this.Categories)
                console.log(this.Judges)
                console.log(this.MainJudges )

            })
            .catch(e => {
                this.errors.push(e)
            })
      },
      CategoryLabel (option) {
        return `${option.DisciplineTitle} ${option.Title} age: ${option.AgeMin}-${option.AgeMax}`
      },
      RingLabel (option) {
        return `${option.Title}`
      },
      JudgeLabel (option) {
        return `${option.Name} ${option.Surname}`
      },
    },
    mounted() {
        this.getData()
        this.$socket.on('refresh-rings-data', response => {
            this.getData();
        });
        this.$socket.on('success', response => {
            if (response===true){
                this.$router.push({ name: 'ringsInCompetitionPage', params: { cmId: this.$route.params.cmId }})
            }else{alert("Check all fields on filled right")}
        });
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
        this.$socket.removeListener('success');
        this.$socket.removeListener('refresh-rings-data');
        this.$socket.removeListener('refresh-categories-data');
    },
    /*  watch: {
        Discipline: function (value) {
          this.AgeCategoryIds = this.AgeCategoriesInCompetition[this.Discipline.Id]
          console.log(this.AgeCategoryIds)
        },
      },*/
    components: {
        MenuComponent,
      Multiselect
    }
  }
</script>

<style lang="scss" scoped>
/*
  .page__content{
    &__input__form{
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: 0.5s;
      transform: translateX(-5%);
      background-color: #eeecf6;
      z-index: 0;
      padding: 7rem 20rem;
      &__item{
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;
        &--label{
          text-align: right;
        }
        &__input{
        }
        &--btn{
          text-align: left;
          padding-right: 3rem;
          width: 15rem;
        }
      }
    }
  }
  #coachs{}
  .slide{
    transform: translateX(0%);
    transition: 0.5s;
    z-index: 0;
  }
*/

  #app {
    margin-top: 20px;
  }

  pre {
    margin-top: 20px;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
