var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", staticStyle: { padding: "0" } }, [
    _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v(_vm._s(_vm.TeamData.Title))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__team-apply-form" }, [
      _c("div", { staticClass: "page__team-apply-form__list-block" }, [
        _c("h4", [_vm._v("Komandas dalibnieki")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "page__team-apply-form__list-block__list--item btn btn-primary",
            on: {
              click: function($event) {
                _vm.getApplyData(0)
              }
            }
          },
          [_vm._v("Pievienot")]
        ),
        _vm._v(" "),
        _vm.SelectType
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ApplyId,
                    expression: "ApplyId"
                  }
                ],
                staticClass: "page__team-apply-form__list-block__list",
                attrs: { size: "7" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.ApplyId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      _vm.getApplyData(_vm.ApplyId)
                    }
                  ]
                }
              },
              _vm._l(_vm.TeamParticipants, function(Participant) {
                return _c(
                  "option",
                  {
                    staticClass:
                      "page__team-apply-form__list-block__list--item",
                    domProps: { value: Participant.UserId }
                  },
                  [
                    _vm._v(
                      _vm._s(Participant.Name) +
                        " " +
                        _vm._s(Participant.Surname)
                    )
                  ]
                )
              })
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.SelectType
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ApplyId,
                    expression: "ApplyId"
                  }
                ],
                staticClass: "page__team-apply-form__list-block__list",
                attrs: { size: "7" },
                on: {
                  click: function($event) {
                    _vm.deleteUserClaim(_vm.ApplyId)
                  },
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.ApplyId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.TeamParticipants, function(Participant) {
                return _c(
                  "option",
                  {
                    staticClass:
                      "page__team-apply-form__list-block__list--delete-item",
                    domProps: { value: Participant.UserId }
                  },
                  [
                    _vm._v(
                      _vm._s(Participant.Name) +
                        " " +
                        _vm._s(Participant.Surname)
                    )
                  ]
                )
              })
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "page__team-apply-form__list-block__list--delete-item btn btn-danger",
            on: {
              click: function($event) {
                _vm.SelectType = false
              }
            }
          },
          [_vm._v("Dzēst")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__team-apply-form__input" }, [
        _c("ul", { staticClass: "nav nav-tabs", attrs: { role: "tablist" } }, [
          _c("li", { staticClass: "active" }, [
            _c(
              "a",
              {
                attrs: {
                  id: "personal-data",
                  "data-toggle": "tab",
                  href: "#personal-data-" + _vm.ApplyNr,
                  role: "tab"
                }
              },
              [_vm._v("\n            Personiskie dati")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  id: "specifications",
                  "data-toggle": "tab",
                  href: "#specifications-" + _vm.ApplyNr,
                  role: "tab"
                }
              },
              [_vm._v("\n            Cīnītāja īpašības")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  id: "category",
                  "data-toggle": "tab",
                  href: "#category-" + _vm.ApplyNr,
                  role: "tab"
                }
              },
              [_vm._v("\n            Kategorija")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-content page__team-apply-form__input__data-tabs"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "tab-pane active tab-content page__team-apply-form__input__data-tabs__item",
                attrs: { id: "personal-data-" + _vm.ApplyNr, role: "tabpanel" }
              },
              [
                _c("h4", [_vm._v("Atrast dalibnieku: ")]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: {
                    options: _vm.Users,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "open-direction": "below",
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.NameSurnameLabel
                  },
                  on: {
                    input: function($event) {
                      _vm.setCategories(_vm.index)
                    }
                  },
                  model: {
                    value: _vm.ApplyData.User,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData, "User", $$v)
                    },
                    expression: "ApplyData.User"
                  }
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Vards*: ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ApplyData.User.Name,
                      expression: "ApplyData.User.Name"
                    }
                  ],
                  staticClass:
                    "page__content__team-apply-form__item--input form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.ApplyData.User.Name },
                  on: {
                    change: function($event) {
                      _vm.setCategories()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.ApplyData.User, "Name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Uzvards*: ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ApplyData.User.Surname,
                      expression: "ApplyData.User.Surname"
                    }
                  ],
                  staticClass:
                    "page__content__team-apply-form__item--input form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.ApplyData.User.Surname },
                  on: {
                    change: function($event) {
                      _vm.setCategories()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ApplyData.User,
                        "Surname",
                        $event.target.value
                      )
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "tab-pane tab-content page__team-apply-form__input__data-tabs__item",
                attrs: { id: "specifications-" + _vm.ApplyNr, role: "tabpanel" }
              },
              [
                _c("h4", [_vm._v("Dzimsanas Datums*: ")]),
                _vm._v(" "),
                _c("DatePicker", {
                  staticStyle: { "padding-bottom": "10rem" },
                  attrs: {
                    "calendar-class": "calendar",
                    "monday-first": true,
                    "bootstrap-styling": true
                  },
                  on: {
                    closed: function($event) {
                      _vm.setCategories()
                    }
                  },
                  model: {
                    value: _vm.ApplyData.User.DateOfBirth,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData.User, "DateOfBirth", $$v)
                    },
                    expression: "ApplyData.User.DateOfBirth"
                  }
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Gender*:")]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: {
                    options: _vm.Genders,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "open-direction": "below",
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.GenderLabel
                  },
                  on: {
                    input: function($event) {
                      _vm.setCategories()
                    }
                  },
                  model: {
                    value: _vm.ApplyData.User.Gender,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData.User, "Gender", $$v)
                    },
                    expression: "ApplyData.User.Gender"
                  }
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Svars*:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ApplyData.Weight,
                      expression: "ApplyData.Weight"
                    }
                  ],
                  staticClass:
                    "page__content__team-apply-form__item--input form-control",
                  domProps: { value: _vm.ApplyData.Weight },
                  on: {
                    change: function($event) {
                      _vm.setCategories()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.ApplyData, "Weight", $event.target.value)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "tab-pane tab-content page__team-apply-form__input__data-tabs__item",
                attrs: { id: "category-" + _vm.ApplyNr, role: "tabpanel" }
              },
              [
                _c("h4", [_vm._v("Kategorija*:")]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: {
                    options: _vm.Categories,
                    "close-on-select": true,
                    "clear-on-select": false,
                    multiple: true,
                    "open-direction": "below",
                    limit: 4,
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.CategoryLabel
                  },
                  model: {
                    value: _vm.ApplyData.Categories,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData, "Categories", $$v)
                    },
                    expression: "ApplyData.Categories"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "page__team-apply-form__btn-item" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "page__team-apply-form__btn-item--btn btn btn-primary",
                      on: {
                        click: function($event) {
                          _vm.applyUser(_vm.ApplyData)
                        }
                      }
                    },
                    [_vm._v("Saglabāt")]
                  ),
                  _vm._v(" "),
                  _vm.ApplySaved
                    ? _c("img", {
                        attrs: {
                          src: "/static/green-tick.png",
                          alt: "",
                          width: "40",
                          height: "30"
                        }
                      })
                    : _vm._e()
                ])
              ],
              1
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticStyle: { margin: "1rem", width: "17rem" },
        on: {
          click: function($event) {
            _vm.$modal.hide("ApplyTeamModal")
          }
        }
      },
      [_vm._v("\n    ❌ Aizvert\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ef020c0", { render: render, staticRenderFns: staticRenderFns })
  }
}