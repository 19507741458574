<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Ringu saraksts</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
      <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
    <div class="page__content__data">
      <div v-if="$store.state.authUser && $store.state.authUser.type === 1" style="padding: 1rem 4rem;">
        <router-link :to="{name: 'addRingPage', params:{ cmId:  $route.params.cmId}}" class="btn btn-info" >Pievienot ringu +</router-link>
      </div>
      <admin-rings-grid v-if="$store.state.authUser && $store.state.authUser.type === 1" :RingsData="Rings"></admin-rings-grid>
      <public-rings-grid v-if="!$store.state.authUser" :RingsData="Rings"></public-rings-grid>
      <public-rings-grid v-if="$store.state.authUser && !($store.state.authUser.type === 1)" :RingsData="Rings"></public-rings-grid>
    </div>
    </div>
  </div>
</template>

<script>

  import AdminRingsGridInCompetitionComponent from "../../components/admin/RingsInCompetitionComponents/RingsGridInCompetitionComponent.vue";

  import PublicRingsGridInCompetitionComponent from "../../components/public/RingsInCompetitionComponents/RingsGridInCompetitionComponent.vue";
  import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "ringsInCompetitionPage",
    data() {
      return{
        show: false,
        Rings: [],
        CompetitionTitle: "",
      }
    },
    methods:{
        getData(){ this.$axios.get(`/api/competition/rings`, {
            params: {
                cmId: this.$route.params.cmId,
            }
        })
            .then(response => {
                this.Rings = response.data.Rings;
                this.CompetitionTitle = response.data.CompetitionTitle;
                console.log(this.Rings);
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
    },
    mounted() {
        this.getData()
        this.$socket.on('refresh-competition-rings-data', response => {
            this.getData();
        });
        this.$socket.on('refresh-categories-data', response => {
          this.getData();
        });
    },
    beforeDestroy () {
        this.$socket.removeListener('refresh-competition-rings-data');
      this.$socket.removeListener('refresh-categories-data');
    },
    components:{
        MenuComponent,
      "admin-rings-grid": AdminRingsGridInCompetitionComponent, "public-rings-grid": PublicRingsGridInCompetitionComponent
    },
  }
</script>

<style scoped>

</style>