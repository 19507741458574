<template>
  <div class="vue-good-table">
    <table class="table table-bordered" style="font-size: 16px;">
      <thead>
      <tr>
        <th>Nr</th>
        <th>Limenis</th>
        <th>Komanda</th>
        <th>Sarkanais sturis</th>
        <th>Resultats</th>
        <th>Zils Sturis</th>
        <th>Komanda</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="data in BattlesListData">
        <td>{{data.Nr}}</td>
        <td>{{data.lvl}}</td>
        <td @click="showEdit(data, 'RedTeam')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='RedTeam'))" style="cursor: pointer;">
          {{data.RedTeam}} &emsp; <span class="glyphicon glyphicon-pencil"></span>
        </td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='RedTeam') ">
          <div v-if="!updatable">
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel"></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
        <td @click="showEdit(data, 'RedNameSurname')"
            v-if="!((data.Id===Selected.Id) && (Selected.Row==='RedNameSurname'))" style="cursor: pointer;">{{data.RedNameSurname}} ({{data.RedCmUserId}}) &emsp; <span class="glyphicon glyphicon-pencil"></span>
        </td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='RedNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td>{{data.Result}}</td>
        <td @click="showEdit(data, 'BlueNameSurname')"
            v-if="!((data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname'))" style="cursor: pointer;">{{data.BlueNameSurname}} ({{data.BlueCmUserId}}) &emsp; <span class="glyphicon glyphicon-pencil"></span>
        </td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td @click="showEdit(data, 'BlueTeam')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='BlueTeam'))" style="cursor: pointer;">
          {{data.BlueTeam}} &emsp; <span class="glyphicon glyphicon-pencil"></span>
        </td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='BlueTeam') ">
          <div v-if="!updatable">
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel"></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePickerComponent from 'vuejs-datepicker';

  export default {
    name: 'BattlesListTableComponent',
    props: ["BattlesListData"],
    data() {
      return {
        Selected: {
          Id: "",
          Row: "",
          Value: "",
          type: ""
        },
        updatable: false,
        Teams: [],
      };
    },
    methods: {
      showEdit(data, row) {
        console.log(row)
        if (row == 'RedTeam') {
            this.$axios.get(`/api/get-teams/short-data`, {})
                .then(response => {
                    this.Teams = response.data.Teams;
                })
                .catch(e => {
                    this.errors.push(e)
                })
          this.Selected = {
            Id: data.Id,
            Row: row,
            UserId: data.RedCmUserId,
            Value: {
              Id: data.RedTeamId,
              Title: data[row]
            },
          }
        } else if (row == 'BlueTeam') {
            this.$axios.get(`/api/get-teams/short-data`, {})
                .then(response => {
                    this.Teams = response.data.Teams;
                })
                .catch(e => {
                    this.errors.push(e)
                })
          this.Selected = {
            Id: data.Id,
            Row: row,
            UserId: data.BlueCmUserId,
            Value: {
              Id: data.BlueTeamId,
              Title: data[row]
            },
          }
        } else if (row == 'RedNameSurname') {
          console.log("red")
          this.Selected = {
            Id: data.Id,
            Row: row,
            Value: {
              UserId: data.RedId,
              Field: data[row]
            },
          }
        } else if (row == 'BlueNameSurname') {
          this.Selected = {
            Id: data.Id,
            Row: row,
            Value: {
              UserId: data.BlueId,
              Field: data[row]
            },
          }
        }
        console.log(data.ID)
      },
      editData(rowData) {
        this.Selected = {}
        console.log("Will edit: ", rowData)
        if ((rowData.Row == 'RedTeam') || (rowData.Row == 'BlueTeam')) {
          rowData.type = 'edit'
          this.$socket.emit('edit-team-title', rowData);
        }
      },
      updateData(rowData) {
        this.Selected = {}
        console.log("Will update: ", rowData)
        if ((rowData.Row == 'RedNameSurname') || (rowData.Row == 'BlueNameSurname')) {
          console.log('should work')
          this.$socket.emit('edit-name-surname', rowData);
        }
        if ((rowData.Row == 'RedTeam') || (rowData.Row == 'BlueTeam')) {
          rowData.type = 'update'
          this.$socket.emit('edit-team-title', rowData);
        }
      },
      TeamLabel(option) {
        if ((typeof option.Title == 'undefined')) {
          return '-'
        }
        return `${option.Title}`
      },
    },
    mounted() {

    },
    components: {
      DatePickerComponent, "multiselect": Multiselect
    },
  };
</script>

<style>
  th {
    text-align: center;
  }

  thead {
    padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;
  }

  tbody {
    text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>