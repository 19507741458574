var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.RingTitle))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__ring-data-container" }, [
      _c("div", { staticClass: "page__ring-data-container__timer-set" }, [
        _c(
          "h2",
          { staticClass: "page__ring-data-container__timer-set--label" },
          [
            _vm._v("Viena ringa radišanas laiks:\n        "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.timer.time,
                  expression: "timer.time"
                }
              ],
              staticClass: "page__ring-data-container__timer-set--input",
              attrs: { type: "number", min: "10", max: "60" },
              domProps: { value: _vm.timer.time },
              on: {
                change: function($event) {
                  _vm.displayRings()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.timer, "time", $event.target.value)
                }
              }
            }),
            _vm._v(" sekundes\n      ")
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__full-view-content" }, [
      _c(
        "div",
        { staticClass: "page__full-view-content__data" },
        [
          _c("public-ring-battles", {
            attrs: {
              BattlesData: _vm.RingBattles,
              Url: "http://cm.wakolat.lv" + _vm.$route.path + "/" + _vm.RingId
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-43062248", { render: render, staticRenderFns: staticRenderFns })
  }
}