<template>
  <div>
    <div class="page__section" style="padding: 0;">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Ievadiet komandas dati</h1>
      </div>
    </div>
    <div class="page__content" style="padding: 0;">
      <div class="page__content__input" style="padding-top: 1rem;">
        <div class="page__content__input__form">
          <div class="page__content__input__form__item" v-if="$store.state.authUser && !($store.state.authUser.type==4)">
            <label class="page__content__input__form__item--label control-label ">Coach<sup>*</sup></label>
            <multiselect v-model="Coach" :options="Coaches" :close-on-select="true"
                         :clear-on-select="false" :open-direction="'below'"
                         placeholder="Select coach" track-by="Id" :custom-label="CoachLabel">
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="TeamData.Title">
          </div>
          <div class="page__content__input__form__item" v-if="$store.state.authUser && ($store.state.authUser.type==1 || $store.state.authUser.type==4)">
            <label class="page__content__input__form__item--label control-label">Saīsināts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Short">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label">Adrese<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Address">
          </div>
          <div class="page__content__input__form__item" v-if="!$store.state.authUser || ($store.state.authUser.type!=1 && $store.state.authUser.type!=4 && $store.state.authUser.type!=5)">
            <label class="page__content__input__form__item--label control-label">Trenera vards, uzvards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Coach">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label">E-pasts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Email">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label">Telefons<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Phone">
          </div>
          <div class="page__content__input__form__item" v-if="$store.state.authUser && ($store.state.authUser.type==1 || $store.state.authUser.type==4)">
            <label class="page__content__input__form__item--label control-label">Saits<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Site">
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="createTeam">Saglabat</button>
            <button class="page__content__input__form__btn-item--btn" @click="$modal.hide('TeamCreateModal')">
              ❌ Aizvert
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "CreateTeamModalComponent",
    data(){
      return {
        TeamData: {},
        Coaches: [],
        Coach: {}
      }
    },
    methods: {
      getData(){
        this.$axios.get(`/api/teams/new-team/data`,{
        })
          .then(response => {
            this.Coaches = response.data.CoachsData;
            console.log(this.Coaches)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      createTeam(){
        if(this.$store.state.authUser){
          if((this.$store.state.authUser.type==1) || (this.$store.state.authUser.type==5)){
            this.TeamData.CoachId = this.Coach.Id;
            console.log('create team from secretary or doc: ',this.TeamData)
            this.$socket.emit('create-team',  this.TeamData);
          } else if (this.$store.state.authUser.type==4) {
            this.TeamData.CoachId = this.$store.state.authUser.id;
            console.log('create team from coach: ',this.TeamData)
            this.$socket.emit('create-team',  this.TeamData);
          } else {
            console.log('create team from anon or simple user: ',this.TeamData)
            this.$socket.emit('create-unknown-users-team',this.TeamData)
            /*this.$bus.$emit('NonexistentTeamData',  this.TeamData);*/
          }
        } else {
          console.log('create team from anon or simple user: ',this.TeamData)
          this.$socket.emit('create-unknown-users-team',this.TeamData)
         /* this.$bus.$emit('NonexistentTeamData',  this.TeamData);*/
        }

      },
      CoachLabel (option) {
        if ((option.Name) && (option.Surname)){
          return `${option.Name} ${option.Surname}`
        }
      },
     /* ShowApplyTeamModal(){
        console.log('Team: ',this.Team)
       /!*   this.$modal.show('ApplyTeamModal');*!/
        if(this.Team){
          this.$modal.hide('TeamSelectModal');
          this.$bus.$emit("SelectTeamData", this.Team);
        }else{
          this.$modal.hide('TeamSelectModal');
        }
      },*/
/*      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },*/
    },
    mounted() {
      this.getData()
      this.$socket.on('team-created', response => {
        if (response.status){
          console.log('TeamData: ',this.TeamData)
          if(this.TeamData){
            this.TeamData.Id=response.TeamId
            console.log('created team data: ', this.TeamData)
            this.$modal.hide('TeamCreateModal');
            this.$bus.$emit("SelectTeamData", this.TeamData);
          }else{
            console.log('Team data is empty: ', this.TeamData)
            this.$modal.hide('TeamCreateModal');
          }
        }else{
          alert("Check all fields on filled right")
        }
      });
      this.$socket.on('created-unknown-users-team', response => {
        if(typeof response.status!='undefined') {
          if (response.status === true) {
            this.TeamData.Id = response.UnkUsersTeamId
            console.log('created team data: ', this.TeamData)
            this.$modal.hide('TeamCreateModal');
            this.$bus.$emit('NonexistentTeamData', this.TeamData);
          } else {
            alert('Check all fields on filled right!')
          }
        }else{
          alert('Check all fields on filled right!')
        }
      });
    /*console.log('Team: ',this.Team)
    if(this.Team){
      this.$modal.hide('TeamSelectModal');
      this.$bus.$emit("SelectTeamData", this.Team);
    }else{
      this.$modal.hide('TeamSelectModal');
    }*/
    },
    beforeDestroy() {
      this.$socket.removeListener('created-unknown-users-team');
    },
    components: {
      "multiselect": Multiselect
    },
  };
</script>

<style lang="scss">
  /*.change-category-modal{
    padding: 5rem 10rem 10rem 10rem;
    &__participant-data{
      padding-bottom: 2rem;
      &--name-surname{
        text-align:center;
      }
    }
    &__set-category-container{
      &--select{
        padding-bottom: 4rem;
      }
      &--save-btn{
        float: right;
        width: 14rem;
        height: 5rem;
      }
    }
  }*/
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>