var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", [_vm._v("Streams")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-group competition-list" },
        _vm._l(_vm.Competitions, function(competition, key, index) {
          return _c(
            "div",
            {
              staticClass:
                "list-group-item list-group-item-action competition-list__item"
            },
            [
              _c("div", { staticClass: "competition-list__item--title" }, [
                _c("h2", [_vm._v(" " + _vm._s(competition.Title))])
              ]),
              _vm._v(" "),
              _vm._l(competition.Rings, function(Ring) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "list-group-item list-group-item-action ring-list-item",
                    on: {
                      click: function($event) {
                        _vm.showRingFight(Ring.Id)
                      }
                    }
                  },
                  [
                    _c("h4", [
                      _vm._v(
                        " " + _vm._s(Ring.Title) + " Nr. " + _vm._s(Ring.Nr)
                      )
                    ])
                  ]
                )
              })
            ],
            2
          )
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-19008a02", { render: render, staticRenderFns: staticRenderFns })
  }
}