var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__table" },
          [
            _c("div", { staticClass: "page__content__table__actions" }, [
              _c(
                "div",
                { staticClass: "page__content__table__actions--button" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn circle-btn",
                      staticStyle: { "padding-left": "1rem" },
                      on: {
                        click: function($event) {
                          _vm.$modal.show("addRemoteModal")
                        }
                      }
                    },
                    [_vm._v("+")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn square-btn",
                      on: {
                        click: function($event) {
                          _vm.$modal.show("addRemoteModal")
                        }
                      }
                    },
                    [_vm._v("Pieslegt pultu")]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("remoteControlsComponent", {
              attrs: {
                RemoteControls: _vm.RemoteControls,
                RemotesData: _vm.RemotesData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.$store.state.authUser.type === 1 ||
      _vm.$store.state.authUser.type === 6
        ? _c(
            "modal",
            {
              attrs: {
                name: "addRemoteModal",
                clickToClose: false,
                scrollable: true,
                height: "auto"
              }
            },
            [
              _c("addRemoteModalComponent", {
                attrs: { RemotesAddresses: _vm.RemotesAddresses }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Tiesneša pulti")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-171b6004", { render: render, staticRenderFns: staticRenderFns })
  }
}