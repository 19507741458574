<template>
  <div class="page" style="padding: 0;">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Izveleties komandu</h1>
    </div>
    <div class="page__content" style="padding: 0;">
      <div class="page__content__input" style="padding-top: 3rem;">
        <div class="page__content__input__form" >
          <div class="page__content__input__form__item" style="padding:0;">
            <multiselect style="padding-bottom: 4rem;" v-model="Team" :options="AllTeams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'" :options-direction="maxHeight"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
          </div>
          <div class="page__content__input__form__item--link">
            <a style="cursor: pointer;" @click="ShowCreateTeamModal">Izveidot komandu</a>
          </div>
          <div class="page__content__input__form__btn-item" >
            <button class="btn btn-primary page__content__input__form__btn-item--btn" v-on:click="ShowApplyTeamModal">Tālāk</button>
            <button class="page__content__input__form__btn-item--btn" @click="$modal.hide('TeamSelectModal')">
              ❌ Aizvert
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "SelectTeamModalComponent",
    data(){
      return {
        Team: null,
        AllTeams: []
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/get-teams/full-data`, {
     /*     params: {
            userId: this.$store.state.authUser.id,
            userType: this.$store.state.authUser.type
          }*/
        })
            .then(response => {
              this.AllTeams = response.data.Teams;
              console.log(`geted teams: `,this.AllTeams)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      ShowCreateTeamModal(){
        console.log('Team create open: ')
        this.$modal.hide('TeamSelectModal');
        this.$bus.$emit("OpenCreateTeam", {});
      },
      ShowApplyTeamModal(){
        console.log('Team: ',this.Team)
        if(this.Team){
          let TeamData = {}
          if(!this.$store.state.authUser){
            TeamData = {
              Id: this.Team.Id,
              Title: this.Team.Title,
              Address: this.Team.Address,
              Coach: `${this.Team.CoachName} ${this.Team.CoachSurname}`,
              Phone: this.Team.Phone,
              Email: this.Team.Email,
            }
            this.$socket.emit('create-unknown-users-team',TeamData)
          } else if (this.$store.state.authUser){
            if((this.$store.state.authUser.type!=1) && (this.$store.state.authUser.type!=4) && (this.$store.state.authUser.type!=5)){
              TeamData = {
                Id: this.Team.Id,
                Title: this.Team.Title,
                Address: this.Team.Address,
                Coach: `${this.Team.CoachName} ${this.Team.CoachSurname}`,
                Phone: this.Team.Phone,
                Email: this.Team.Email,
              }
              this.$socket.emit('create-unknown-users-team',TeamData)
            } else {
              TeamData = Object.assign({}, this.Team)
              this.$modal.hide('TeamSelectModal');
              this.$bus.$emit("SelectTeamData", TeamData);
            }
          } else {
            TeamData = Object.assign({}, this.Team)
            this.$modal.hide('TeamSelectModal');
            this.$bus.$emit("SelectTeamData", TeamData);
          }
        }else{
          this.$modal.hide('TeamSelectModal');
          alert('Komanda nav izvēlēta!')
        }
      },
      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },
    },
    mounted() {
      this.getData()
      this.$socket.on('created-unknown-users-team', response => {
        if(typeof response.status!='undefined') {
          if (response.status === true) {
            let TeamData = Object.assign({}, this.Team)
            TeamData.Id = response.UnkUsersTeamId
            console.log('created team data: ', TeamData)
            this.$modal.hide('TeamSelectModal');
            this.$bus.$emit('NonexistentTeamData', TeamData);
          } else {
            alert('Komanda nav izvēlēta!')
          }
        }else{
          alert('Komanda nav izvēlēta!')
        }
      });
    },
    beforeDestroy() {
      this.$socket.removeListener('created-unknown-users-team');
    },
    components: {
      "multiselect": Multiselect
    },
  };
</script>

<style lang="scss">
  .change-category-modal{
    padding: 5rem 10rem 10rem 10rem;
    &__participant-data{
      padding-bottom: 2rem;
      &--name-surname{
        text-align:center;
      }
    }
    &__set-category-container{
      &--select{
        padding-bottom: 4rem;
      }
      &--save-btn{
        float: right;
        width: 14rem;
        height: 5rem;
      }
    }
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>