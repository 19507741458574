var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-md-12 clear-pads text-center confirm-status-modal bold"
    },
    [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button" },
          on: { click: _vm.hideModal }
        },
        [_c("span", [_vm._v("×")])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 question" }, [
        _vm._v("\n    Are you ready?\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 text-center" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              on: {
                click: function($event) {
                  _vm.confirmStatus(1)
                }
              }
            },
            [_vm._v("Yes")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger",
              on: {
                click: function($event) {
                  _vm.confirmStatus(0)
                }
              }
            },
            [_vm._v("No")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c292d2de", { render: render, staticRenderFns: staticRenderFns })
  }
}