<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="RingsData"
      @on-cell-click="onCellClick"
      :search-options="{
            enabled: false,
          }"
      :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
    </template>
    </vue-good-table>
  </div>
</template>

<script>
  export default {
    name: "RingsGridInCompetitionComponent",
    props: ["RingsData"],
    data(){
      return {
        columns: [
          {
            label: 'Nr',
            field: 'Nr',
          },
          {
            label: 'Nosaukums',
            field: 'RingTitle',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
              label: 'Kategorijas',
              field: 'Categories',
              thClass: 'text-center',
              tdClass: 'text-center',
          },
          {
            label: 'Galvenais tiesnesis',
            field: 'MainJudge',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Tiesneši ringā',
            field: 'Judges',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        CmId: null,
        RingId: null,
      }
    },
    methods: {
      onCellClick(params){
          this.CmId = this.$route.params.cmId;
          this.ringId = params.row.Id;
          console.log(this.ringId)
          this.$router.push('/competition/ring/' + this.CmId + '/' + this.ringId)
      },
    },
    components: {

    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>