<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{RingTitle}}</h1>
      </div>
      <button v-if="$store.state.authUser && $store.state.authUser.type === 1 && !EditMode" @click="EditMode=!EditMode" class="btn btn-primary" style="
          margin-left: 5rem;
          margin-bottom: 3rem;
      ">Ieslēgt labošanu</button>
      <button v-if="$store.state.authUser && $store.state.authUser.type === 1 && EditMode" @click="EditMode=!EditMode" class="btn btn-danger" style="
          margin-left: 5rem;
          margin-bottom: 3rem;
      ">Izslēgt labošanu</button>
      <button v-if="$store.state.authUser && $store.state.authUser.type === 1" @click="rewriteBattleNrs()" class="btn btn-warning" style="
          margin-left: 5rem;
          margin-bottom: 3rem;
      ">Pārrakstīt numurus</button>
      <!--<button type="button" onclick="printJS({printable: 'page-content', type: 'html', ignoreElements: ['search-box', 'left-time', 'actions']})">-->
      <button type="button" v-on:click="printBattles" class="btn" style="
          margin-left: 5rem;
          margin-bottom: 3rem;
      ">
        Izdrukāt sarakstu
      </button>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data" id="page-content">
        <admin-ring-battles v-if="$store.state.authUser && $store.state.authUser.type === 1 && EditMode" :BattlesData="Battles"></admin-ring-battles>
        <public-ring-battles v-if="(!$store.state.authUser) || $store.state.authUser && !($store.state.authUser.type === 1) || !EditMode" :BattlesData="Battles"></public-ring-battles>
<!--        <public-ring-battles v-if="$store.state.authUser && !($store.state.authUser.type === 1)" :BattlesData="Battles"></public-ring-battles>-->

        <modal v-if="$store.state.authUser && $store.state.authUser.type === 1" name="SelectWinnerModal" :clickToClose="false" :scrollable="true" height="auto" >
          <WinnerSelectComponent :BattleData="BattleData"></WinnerSelectComponent>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>

    import AdminRingBattlesComponent from '../../components/admin/RingsInCompetitionComponents/BattlesOnRingComponent.vue';

    import PublicRingBattlesComponent from '../../components/public/RingsInCompetitionComponents/BattlesOnRingComponent.vue';

    import WinnerSelectComponent from '../../components/admin/CompetitionDataTablesComponents/SelectWinnerComponent.vue';

    import MenuComponent from "../../components/common/MenuComponent";

    import print from "print-js";

  export default {
    name: 'ringBattlePage',
    data () {
      return {
        show: false,
        CompetitionTitle: null,
        Battles: [],
        RingTitle: '',
        BattleData: [],
        EditMode: false,
        printProperties: {
          printable: [],
          type: 'json',
          properties: [
            {
              field: 'Nr',
              displayName: 'Nr'
            },
            {
              field: `CategoryTitle`,
              displayName: 'Kategorija'
            },
            {
              field: `CompetitionPart`,
              displayName: 'Limenis'
            },
            {
              field: `RedTeam`,
              displayName: 'Komanda'
            },
            {
              field: `RedNameSurname`,
              displayName: 'Sarkanais stūris'
            },
            {
              field: 'Actions',
              displayName: 'Rezultats'
            },
            {
              field: `BlueNameSurname`,
              displayName: 'Zilais stūris'
            },
            {
              field: `BlueTeam`,
              displayName: 'Komanda'
            },
            {
              field: 'Actions',
              displayName: 'Uzvarētājs'
            },
          ],
          gridHeaderStyle: 'color: blue;  border: 2px solid #3971A5;',
          gridStyle: 'border: 2px solid #3971A5; align: center; text-align: center',
          header: ''
        }
      }

    },
    methods: {
      printBattles() {
        console.log('this.printProperties: ',this.printProperties)
        print(this.printProperties);
      },
      rewriteBattleNrs(){
        this.$axios.get(`/api/competition/ring/battles/rewrite-numbers`, {
          params: {
            cmId: this.$route.params.cmId,
            ringId: this.$route.params.ringId,
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.RingTitle = response.data.RingTitle;
            this.Battles = response.data.Battles;

            console.log(this.Battles);
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getData(){
        this.$axios.get(`/api/competition/ring/battles`, {
          params: {
            cmId: this.$route.params.cmId,
            ringId: this.$route.params.ringId,
          }
        })
            .then(response => {
              this.CompetitionTitle = response.data.CompetitionTitle;
              this.RingTitle = response.data.RingTitle;
              this.Battles = response.data.Battles;
              this.printProperties.printable = response.data.Battles
              this.printProperties.header = response.data.RingTitle

              console.log(this.Battles);
            })
            .catch(e => {
              this.errors.push(e)
            })
      }
    },
    mounted() {
      console.log('type print: ',typeof print)
      console.log('print: ',print)

      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-battles-list', response => {
        this.getData();
      });
      this.$bus.$on("SelectWinnerData", (BattleData)=>{
        this.BattleData=BattleData;
        this.$modal.show('SelectWinnerModal');
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-battles-list');
    },
    components: {
        MenuComponent,
      'admin-ring-battles': AdminRingBattlesComponent, 'public-ring-battles': PublicRingBattlesComponent,
      WinnerSelectComponent
    }
  }
</script>

<style  lang="scss" scoped>

</style>