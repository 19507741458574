<template>
  <div class="graph">
    <div class="competition">
      <div class="competition__part" v-for="(CompetitionPart, CompIndex) in BattlesGraphData">
        <div class="competition__part__battle-block" v-for="(Battle, BattleIndex) in CompetitionPart">
          <div class="competition__part__battle-block__connect--first" v-if="!(CompIndex===0)">
            <div class="competition__part__battle-block__connect">
              <div class="competition__part__battle-block__connect--middle_connection" ></div>
            </div>
          </div>
          <div class="competition__part__battle-block__fighters">
            <div class="competition__part__battle-block__fighters_red">
              <div class="competition__part__battle-block__fighters_red--name">
                {{ Battle.RedNameSurname }} ({{ Battle.RedCmNr }})
              </div>
              <div class="competition__part__battle-block__fighters_red__result">
                <div v-if="(Battle.CompetitionPart==1) && (Battle.WinnerId==Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--first">
                  {{Battle.RedResult}}
                </div>
                <div v-else-if="(Battle.CompetitionPart==1) && (Battle.WinnerId!=Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--second">
                  {{Battle.RedResult}}
                </div>
                <div v-else-if="(Battle.CompetitionPart!=1) && (Battle.WinnerId==Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--winner">
                  {{Battle.RedResult}}
                </div>
                <!--<div v-else-if="(Battle.CompetitionPart==2) && (BattleIndex==1) && (Battle.WinnerId!=Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result&#45;&#45;third">
                  {{Battle.RedResult}}
                </div>-->
                <div v-else class="competition__part__battle-block__fighters_red__result--dif">
                  {{Battle.RedResult}}
                </div>
              </div>
            </div>
            <div class="competition__part__battle-block__fighters_blue">
              <div class="competition__part__battle-block__fighters_blue--name">
                {{ Battle.BlueNameSurname }} ({{ Battle.BlueCmNr }})
              </div>
              <div class="competition__part__battle-block__fighters_blue__result">
                <div v-if="(Battle.CompetitionPart==1) && (Battle.WinnerId==Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--first">
                  {{Battle.BlueResult}}
                </div>
                <div v-else-if="(Battle.CompetitionPart==1) && (Battle.WinnerId!=Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--second">
                  {{Battle.BlueResult}}
                </div>
                <div v-else-if="(Battle.CompetitionPart!=1) && (Battle.WinnerId==Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--winner">
                  {{Battle.BlueResult}}
                </div>
                <!--<div v-else-if="(Battle.CompetitionPart==2) && (BattleIndex==1) && (Battle.WinnerId!=Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result&#45;&#45;third">
                  {{Battle.BlueResult}}
                </div>-->
                <div v-else class="competition__part__battle-block__fighters_red__result--dif">
                  {{Battle.BlueResult}}
                </div>
              </div>
            </div>
          </div>
          <div class="competition__part__battle-block__connect" v-if="!(CompetitionPart.length===1)">
            <div class="competition__part__battle-block__connect--end" v-if="!(BattleIndex & 1)">
              <div class="competition__part__battle-block__connect--top" v-bind:class="{ 'top-padding' : (BattlesGraphData.length>3) &&((Battle.CompetitionPart==2) || (Battle.CompetitionPart==4))}"></div>
            </div>
            <div class="competition__part__battle-block_connect" v-if="BattleIndex & 1">
              <div class="competition__part__battle-block__connect--bottom" v-bind:class="{ 'bottom-padding' : (BattlesGraphData.length>3) &&((Battle.CompetitionPart==2) || (Battle.CompetitionPart==4))}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BattlesGraphComponent",
    props: ["BattlesGraphData"],
    data(){
      return {
        More: true,
        BattlesLength: null,
      }
    },
    methods: {
    },
    mounted() {
    },
  }
</script>

<style lang="scss" scoped>
  .graph{
    overflow: auto;
  }
  .competition{
    display:flex;
    flex-direction: row;
    width:100rem;
    margin: 3rem 3rem;
    &__part{
      display:flex;
      flex-direction: column;
      justify-content: center;
      &__battle-block {
        display:flex;
        flex-direction: row;
        &__fighters {
          width: 20rem;
          padding-top: 3rem;
          &_red {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: #ff848a;
            color: red;
            height: 4rem;
            &--name{
              margin: 1rem 1rem;
            }
            &__result{
              background-color:white;
              &--first{
                background-color: goldenrod;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--second{
                background-color: grey;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--third{
                background-color: chocolate;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--winner{
                background-color: #b5ff45;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--dif{
                background-color:white;
                padding: 1rem 1rem;
              }
            }
          }
          &_blue {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: #989dff;
            color: blue;
            height: 4rem;
            &--name{
              margin: 1rem 1rem;
            }
            &__result{
              background-color:white;
              &--first{
                background-color: goldenrod;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--second{
                background-color: grey;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--third{
                background-color: chocolate;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--winner{
                background-color: #b5ff45;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--dif{
                background-color:white;
                padding: 1rem 1rem;
              }
            }
          }
        }
        &__connect{
          &--end{
            margin-top: 6.9rem;
          }
          &--first {
            margin-top: 6.9rem;
          }
          &--top{
            width: 5rem;
            height: 7.5rem;
            border-top: 0.3rem solid greenyellow;
            border-right: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              height: 7.5rem;
              border-top: 0.3rem solid greenyellow;
              border-left: 0.3rem solid greenyellow;
            }
          }
          &--bottom{
            width: 5rem;
            height: 7.5rem;
            border-bottom: 0.3rem solid greenyellow;
            border-right: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              height: 7.5rem;
              border-bottom: 0.3rem solid greenyellow;
              border-left: 0.3rem solid greenyellow;
            }
          }
          &--middle{
            width: 5rem;
            border-bottom: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              border-bottom: 0.3rem solid greenyellow;
            }
          }
        }
      }
    }
  }

  .top-padding{
    padding-top: 20rem;
  }
  .bottom-padding{
    padding-bottom: 10rem;
  }

  .connection{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .connect{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
  }
</style>