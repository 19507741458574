var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__table" },
        [
          _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
            ? _c("div", { staticClass: "page__content__table__actions" }, [
                _c(
                  "div",
                  { staticClass: "page__content__table__actions--button" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "circle-btn",
                        attrs: { to: { name: "createCompetitionPage" } }
                      },
                      [_vm._v("+")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "square-btn",
                        attrs: { to: { name: "createCompetitionPage" } }
                      },
                      [_vm._v("Pievienot sacinsību")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
            ? _c("admin-competitions-list", {
                staticClass: "page__content__table--list",
                attrs: {
                  CmNtfcId: _vm.CmNtfcId,
                  CompetitionsData: _vm.Competitions
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.state.authUser
            ? _c("public-competitions-list", {
                staticClass: "page__content__table--list",
                attrs: { CompetitionsData: _vm.Competitions }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && !(_vm.$store.state.authUser.type === 1)
            ? _c("public-competitions-list", {
                staticClass: "page__content__table--list",
                attrs: {
                  CmNtfcId: _vm.CmNtfcId,
                  CompetitionsData: _vm.Competitions
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.Competitions.length === 0
            ? _c("div", { staticClass: "info-text" }, [
                _c("h2", [_vm._v("Pagaidam nav ne vienas sacensības")])
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section--cover-img " }, [
        _c("img", { attrs: { src: "/static/thumb.jpg", height: "100%" } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Sacensību saraksts")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-54181228", { render: render, staticRenderFns: staticRenderFns })
  }
}