var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page__content__data-container__sub-content__options" },
    [
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
        ? _c(
            "div",
            {
              staticClass:
                "page__content__data-container__sub-content__options__container"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container--header "
                },
                [_vm._v(" Opcijas")]
              ),
              _vm._v(" "),
              _c("router-link", { attrs: { to: { name: "dashboardPage" } } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__options__container__item"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__options__container__item--label"
                      },
                      [_vm._v(" Vadibas panelis ")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "editProfilePage" } } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "page__content__data-container__sub-content__options__container__item--label"
                        },
                        [_vm._v(" Redaktet profili ")]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 3
        ? _c(
            "div",
            {
              staticClass:
                "page__content__data-container__sub-content__options__container"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container--header "
                },
                [_vm._v(" Opcijas")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "editProfilePage" } } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "page__content__data-container__sub-content__options__container__item--label"
                        },
                        [_vm._v(" Redaktet profili ")]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 4
        ? _c(
            "div",
            {
              staticClass:
                "page__content__data-container__sub-content__options__container"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container--header "
                },
                [_vm._v(" Opcijas")]
              ),
              _vm._v(" "),
              _c("router-link", { attrs: { to: { name: "createTeamPage" } } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__options__container__item"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__options__container__item--label"
                      },
                      [_vm._v(" Izveidot komandu ")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "editProfilePage" } } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "page__content__data-container__sub-content__options__container__item--label"
                        },
                        [_vm._v(" Redaktet profili ")]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container__input-item"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__input-item--label"
                    },
                    [_vm._v(" Iesildīšana iepriekš ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.WarmUp,
                        expression: "WarmUp"
                      }
                    ],
                    staticClass:
                      "page__content__data-container__sub-content__options__container__input-item--input",
                    attrs: { type: "number", min: "0", max: "9" },
                    domProps: { value: _vm.WarmUp },
                    on: {
                      change: function($event) {
                        _vm.setWarmUp()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.WarmUp = $event.target.value
                      }
                    }
                  }),
                  _vm._v("  \n      "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__input-item--label"
                    },
                    [_vm._v(" cīņam ")]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 5
        ? _c(
            "div",
            {
              staticClass:
                "page__content__data-container__sub-content__options__container"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container--header "
                },
                [_vm._v(" Opcijas")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "editProfilePage" } } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "page__content__data-container__sub-content__options__container__item--label"
                        },
                        [_vm._v(" Redaktet profili ")]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container__input-item"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__input-item--label"
                    },
                    [_vm._v(" Iesildīšana iepriekš ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.WarmUp,
                        expression: "WarmUp"
                      }
                    ],
                    staticClass:
                      "page__content__data-container__sub-content__options__container__input-item--input",
                    attrs: { type: "number", min: "0", max: "9" },
                    domProps: { value: _vm.WarmUp },
                    on: {
                      change: function($event) {
                        _vm.setWarmUp()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.WarmUp = $event.target.value
                      }
                    }
                  }),
                  _vm._v("  \n      "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__input-item--label"
                    },
                    [_vm._v(" cīņam ")]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 6
        ? _c(
            "div",
            {
              staticClass:
                "page__content__data-container__sub-content__options__container"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__data-container__sub-content__options__container--header "
                },
                [_vm._v(" Opcijas")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "editProfilePage" } } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page__content__data-container__sub-content__options__container__item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "page__content__data-container__sub-content__options__container__item--label"
                        },
                        [_vm._v(" Redaktet profili ")]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1818a7db", { render: render, staticRenderFns: staticRenderFns })
  }
}