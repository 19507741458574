<template>
  <div class="timer" v-if="data">
    <div class="circle-container" :class="[ data.round_status === 1 ? 'fight-going' : '' ]">
      <div class="outer-ring"></div>
      <div class="ring">
        <div class="front">
          <div class="timeout">
            {{ message }}
            <div class="timeout-time">{{ timeout }}</div>
          </div>

        </div>
        <div class="back">
          <div>{{ data.round_nr ? data.round_nr : '' }}</div>
        </div>
      </div>
    </div>
    <div class="text-center">
    <span> {{ time }} </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'timer',
    data () {
      return {
        time: '00:00',
        timeout: '',
        end: null,
        now: null,
        interval: null,
        warning: null,
        warningPlaying: false,
        playbackPromise: null,
        upcomingWarning: null,
        message: null,
        timeoutInterval: null,
        timeoutWarningInterval: null
      }
    },
    props: [ 'data', 'lvl' ],
    methods: {

      getSecondsCount( time ){
        return time * 60
      },
      getRestTime(){
        return this.getSecondsCount( this.data.rest_time );
      },
      getRoundTime(){
        if( this.lvl > 2 ){
          return this.data.preliminary_time ? this.data.preliminary_time : this.data.time;
        }
        return this.data.time;
      },
      showRounds(){
        this.message = this.data.round_nr + '/' + this.data.rounds;
      },
      showTimeout(){
        this.message = 'Timeout';
      },
      showFightEnd(){
        this.message = 'Fight over';
      },
      showTimeoutTimer(){
        this.timeoutInterval = setInterval(() => {

          let end = new Date( this.data.end_time ).getTime() + this.getRestTime() * 1000;

          let now = new Date().getTime();
          let distance = end - now;

          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if( minutes < 10 ){
            minutes = '0' + minutes;
          }
          if( seconds < 10 ){
            seconds = '0' + seconds;
          }
          this.timeout = minutes + ":" + seconds;

          if ( Math.floor( distance ) < 0) {
            clearInterval( this.timeoutInterval );
            this.timeout = "";
          }

        }, 1000);
      },
      calculateTime(){

        let now = new Date().getTime();
        let distance = this.end - now;

        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if( minutes < 10 ){
          minutes = '0' + minutes;
        }
        if( seconds < 10 ){
          seconds = '0' + seconds;
        }

        this.time = minutes + ":" + seconds;

        if ( distance > 0 && distance < 11000 && this.warningPlaying === false && this.data.pause == 0 ){
          this.roundEndingWarning();
        }

        if ( Math.floor( distance ) < 0) {
          clearInterval( this.interval );
          this.time = "00:00";
          if( this.data.round_nr === this.data.rounds ){
            this.fightEnded();
          }else{
            this.roundEnded();
          }
        }
      },
      startTimer(){
        //this.playAudio( require('./../assets/mp3/round1.mp3'), false, 2000 );

        this.interval = setInterval(() => {
          this.calculateTime();
        }, 1000);
      },
      setTimer(){
        if( this.data.round_status === 1 ){
          //Round is started

          if( this.data.pause == 0 ){
            //A battle is underway

            if( this.data.time_left ){
              //Continue button was pressed
              this.end = new Date().getTime() + 1000 * this.data.time_left - ( new Date().getTime() - new Date( this.data.continue_time ).getTime() );

            }else{
            //Start button was pressed
              this.end = new Date().getTime() + ( 1000 * ( this.getSecondsCount( this.getRoundTime() ) ) - ( new Date().getTime() - new Date( this.data.start_time ).getTime() ));
            }
          }else{
            //Judge is smoking, Stop button was pressed
            this.end = new Date().getTime() + ( 1000 * ( this.data.time_left ? this.data.time_left : this.getSecondsCount( this.getRoundTime() ) ));
          }
        }

        clearInterval( this.interval );
        clearTimeout( this.upcomingWarning );

        if( this.data.round_status === 1 ){
          //Round is started

          this.calculateTime();
          if( this.data.pause == 0 ){
            this.stopWarning();

            this.startTimer();
          }
        }else if( this.data.round_status === 2 && this.data.end_time ){
        //Round ended, awaiting next round
        //Check when next round should start and trigger alarm timeout

          this.showTimeout();
          this.showTimeoutTimer();
          var secondsSinceBreak = Math.floor( ( new Date().getTime() - new Date( this.data.end_time ).getTime()) / 1000 );

          if( secondsSinceBreak < ( this.getRestTime() - 10 ) ){
            //Still timeout
            this.upcomingWarning = setTimeout(() => {
                this.nextRoundUpcoming( 5 );
            }, ( this.getRestTime() - 10 - secondsSinceBreak ) * 1000 );

          }else{
            //Timeout finished
            this.nextRoundUpcoming( 5 );
          }
        }else if( this.data.battle_status == 2 ){
          this.showFightEnd();
        }else{
          this.showRounds();
        }
      },
      stopWarning(){
        if ( this.playbackPromise ) {
            this.playbackPromise.then(() => {
            this.warning.pause();
            this.warningPlaying = false;
            this.warning.loop = false;
          });
        }
      },
      playAudio( audio, loop, stopTimeout ){
        this.warning = new Audio( audio );
        this.warning.currentTime = 0;
        this.warning.volume = 0.2;
        this.warning.loop = loop;
        this.playbackPromise = this.warning.play();
        this.warningPlaying = true;

//        setTimeout(() => {
//          this.stopWarning();
//        }, stopTimeout );
      },
      roundEndingWarning(){
        this.playAudio( require('../../../assets/mp3/fight.mp3'), false, 10000 );
      },
      nextRoundUpcoming( stopTimeout ){
        this.playAudio( require('../../../assets/mp3/bell.mp3'), false, stopTimeout );
        this.timeoutWarningInterval = setInterval(() => {
          this.playAudio( require('../../../assets/mp3/bell.mp3'), false, stopTimeout );
        }, 10000 );
      },
      roundEnded(){
        this.showTimeout();
        this.showTimeoutTimer();
        this.stopWarning();

        this.upcomingWarning = setTimeout(() => {
          this.nextRoundUpcoming( 5 );
        }, ( this.getRestTime() - 10 ) * 1000 );
        let data = this.data;
        data.room = this.data.battle_id;
        data.battleId = this.data.battle_id;
        data.req_type = 'endRound';
        this.$socket.emit( "round-manage", this.data );
      },
      fightEnded(){
        this.showFightEnd();
        let data = this.data;
        this.stopWarning();
        data.room = this.data.battle_id;
        data.battleId = this.data.battle_id;
        data.req_type = 'endFight';
        this.$socket.emit( "round-manage", data );
      }
    },
    mounted () {
      this.setTimer();
      this.$bus.$on('fight-active', data => {
        clearInterval( this.timeoutWarningInterval );
        this.data = data;
        this.setTimer();
      });

      this.$bus.$on('fight-inactive', data => {
        this.data = data;
        this.stopWarning();
        clearInterval( this.interval );
      });
    },
    components: {},
    beforeDestroy () {
      this.stopWarning();
      this.$bus.$off('fight-active');
      this.$bus.$off('fight-inactive');

    },
  }
</script>

<style lang="scss" scoped>
  .circle-container {
    position: relative;
    perspective: 1000;
    margin: 0 auto;
  }

  .circle-container.fight-going .ring {
    -webkit-transform: rotate3d(45, 45, 0, 180deg);
    -moz-transform: rotate3d(45, 45, 0, 180deg);
    -ms-transform: rotate3d(45, 45, 0, 180deg);
    -o-transform: rotate3d(45, 45, 0, 180deg);
    transform: rotate3d(45, 45, 0, 180deg);
  }

  .circle-container.fight-going .outer-ring {
    -webkit-transform: rotate3d(45, 0, 0, 180deg);
    -moz-transform: rotate3d(45, 0, 0, 180deg);
    -ms-transform: rotate3d(45, 0, 0, 180deg);
    -o-transform: rotate3d(45, 0, 0, 180deg);
    transform: rotate3d(45, 0, 0, 180deg);
  }

  .circle-container, .front, .back {
    width: 17.5rem;
    height: 17.5rem;
    background-color: rgba(0,0,0,0);
  }

  .ring, .outer-ring,{
    transition: 0.5s;
    transform-style: preserve-3d;
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
  }

  .ring {
    position: relative;
    width: 17.5rem;
    height: 17.5rem;
  }

  .front, .back {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-align: center;
    div{
      width: 100%;
      font-family: cabin, sans-serif;
      font-weight: 700;

      text-align: center;
      color: #c02227;
    }
    div.timeout{
      font-size: 3.2rem;
    }
  }
  .back {
    div{
      font-size: 8rem;
    }
  }

  .front {
    background-color: #fff;
  }

  .back {
    transform: rotate3d(45,45,0,180deg);
    background-color: #fff;
  }

  .outer-ring {
    position: absolute;
    border: 1px solid #fff;
    top: -5px;
    left: -5px;
    width: 18.5rem;
    height: 18.5rem;
    background-color: red;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
  }
  .timeout-time{
    font-size: 3.2rem;
  }
</style>
