<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Iesniegumi sacensibai</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <H1 class="page__content__data--header" style="color:black;">{{CompetitionTitle}}</H1>
        <ul class="nav nav-tabs" role="tablist">
          <li class="active">
            <a  data-toggle="tab" href="#confirmedparticipants" role="tab">
              Apstiprinatie dalibnieki
            </a>
          </li>
          <li>
            <a  data-toggle="tab" href="#unconfirmedparticipants" role="tab">
              Neapstiprinatie dalibnieki
            </a>
          </li>
          <li>
            <a  data-toggle="tab" href="#externalclaims" role="tab">
              Pieteikumi pārskatā
            </a>
          </li>
          <li v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 4 || $store.state.authUser.type === 6)">
            <router-link  :to="{ name: 'applyParticipantPage'}" >
              Pieteikt dalibnieku +
            </router-link>
          </li>
          <li v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 4 || $store.state.authUser.type === 6)">
            <router-link  :to="{ name: 'applyTeamPage'}" >
              Pieteikt komandu +
            </router-link>
          </li>
          <li v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 6)">
            <a href="#" v-on:click="ShowWeighingModal">
              Sveršana
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="confirmedparticipants" role="tabpanel">
            <admin-participants-table v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 6)" :ParticipantsData="Participants.Approved"></admin-participants-table>
            <public-participants-table v-else :ParticipantsData="Participants.Approved"></public-participants-table>
          </div>
          <div class="tab-pane" id="unconfirmedparticipants" role="tabpanel">
            <admin-participants-table v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 6)" :ParticipantsData="Participants.DisApproved"></admin-participants-table>
            <public-participants-table v-else :ParticipantsData="Participants.DisApproved"></public-participants-table>
          </div>
          <div class="tab-pane" id="externalclaims" role="tabpanel">
            <admin-external-claims-table v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 6)" :ParticipantsData="Participants.External"></admin-external-claims-table>
            <public-external-claims-table v-else :ParticipantsData="Participants.External"></public-external-claims-table>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="$store.state.authUser && ($store.state.authUser.type === 1  || $store.state.authUser.type === 4 || $store.state.authUser.type === 6)" name="TeamSelectModal" :height="400" :clickToClose="false" >
      <SelectTeamModalComponent></SelectTeamModalComponent>
    </modal>
    <modal v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 4 || $store.state.authUser.type === 6)" name="ApplyTeamModal" :clickToClose="false" :scrollable="true" height="auto" >
      <ApplyTeamModalComponent :TeamData="TeamData"></ApplyTeamModalComponent>
    </modal>
    <modal v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 6)" name="WeighingModal" :clickToClose="false" height="auto" >
      <WeighingModalComponent></WeighingModalComponent>
    </modal>
  </div>
</template>

<script>
  import AdminClaimedParticipantsTableComponent from '../../components/admin/ParticipantTableComponents/ClaimedParticipantsTableComponent.vue'
  import AdminExternalClaimsTableComponent from '../../components/admin/ParticipantTableComponents/ExternalClaimsTableComponent.vue'
  import PublicExternalClaimsTableComponent from '../../components/public/ParticipantTableComponents/ExternalClaimsTableComponent.vue'
  import PublicClaimedParticipantsTableComponent from '../../components/public/ParticipantTableComponents/ClaimedParticipantsTableComponent.vue'
  import MenuComponent from "../../components/common/MenuComponent";
  import ApplyTeamModalComponent from '../../components/admin/ParticipantTableComponents/ApplyTeamModalComponent.vue';
  import SelectTeamModalComponent from '../../components/admin/ParticipantTableComponents/SelectTeamModalComponent.vue';
  import WeighingModalComponent from '../../components/admin/ParticipantTableComponents/WeighingQueueModalComponent.vue';


  export default {
    name: "competitionClaimsPage",
    data() {
      return {
        show: false,
        CompetitionTitle: null,
        Participants: {
          Approved: [],
          DisApproved: [],
          External: []
        },
        TeamData: {},
        WeighingNr: 0
      }
    },
    methods: {
      getInternalCmClaims: function() {
        this.$axios.get(`/api/claimed-participants`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.WeighingNr = response.data.WeighingNr;
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.Participants = {
              Approved: response.data.Participants.Approved,
              DisApproved: response.data.Participants.DisApproved,
              External: []
            };
            console.log(this.Participants)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getExternalCmClaims: function() {
        this.$axios.get(`/api/participant/external-applications`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.Participants.External = response.data.ExternalClaims
            console.log('ExternalClaims: ',response.data)
            console.log('this.Participants.External: ',this.Participants.External)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getData: function() {
        this.getInternalCmClaims()
        this.getExternalCmClaims()
      },
      ShowApplyTeamSelect(){
        this.$modal.show('TeamSelectModal');
      },
      ShowWeighingModal(){
        this.$modal.show('WeighingModal');
        console.log("vizvano")
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-unknown-users-claims', response => {
        this.getExternalCmClaims()
      });
      this.$bus.$on("SelectTeamData", (Team)=>{
        this.TeamData=Team;
        this.$modal.show('ApplyTeamModal');
        console.log("geted data: ",Team)
      });
      this.$socket.on('next-on-weighing',response=>{
        this.getData();
      });

    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('next-on-weighing');
    },
    components:{
      MenuComponent,
      "admin-participants-table": AdminClaimedParticipantsTableComponent,
      "public-participants-table": PublicClaimedParticipantsTableComponent,
      "admin-external-claims-table": AdminExternalClaimsTableComponent,
      "public-external-claims-table": PublicExternalClaimsTableComponent,
      ApplyTeamModalComponent,
      SelectTeamModalComponent,
      WeighingModalComponent
    },

  }
</script>

<style lang="scss" scoped>
  .my-modal{
    width:900px;
    margin:auto;
  }
</style>