<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{ CompetitionTitle }}<br><span class="page__section__name-container--subtitle">{{Subtitle}}</span></h1>
      </div>
      <div class="page__section__sort">
        <span class="page__section__sort--item" >
          Dalibnieku skaits: {{ ParticipantsCount }}
        </span>
      </div>
      <div class="page__section__sort">
        <span class="page__section__sort--item" >
          Komandu skaits: {{ TeamsCount }}
        </span>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <PublicResultsTableComponent v-if="!$store.state.authUser" :ResultsData="Results" :DataForRouter="DataForRouter"></PublicResultsTableComponent>
        <PublicResultsTableComponent v-if="$store.state.authUser && $store.state.authUser.type != 1" :ResultsData="Results" :DataForRouter="DataForRouter"></PublicResultsTableComponent>
        <AdminResultsTableComponent v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 2)" :ResultsData="Results" :DataForRouter="DataForRouter"></AdminResultsTableComponent>
      </div>
    </div>
  </div>
</template>

<script>

    import MenuComponent from "../../components/common/MenuComponent";
    import AdminResultsTableComponent from "../../components/admin/Results/ResultsTableComponent";
    import PublicResultsTableComponent from "../../components/public/Results/ResultsTableComponent";

  export default {
    name:  'ResultsPage',
    data () {
      return {
        show: false,
        CompetitionTitle: null,
        Results: [],
        ParticipantsCount: 0,
        TeamsCount: 0,
        DataForRouter: {
          cmId: this.$route.params.cmId,
          discipId: this.$route.params.discipId,
          ageCategId: this.$route.params.ageCategId,
          weightCategId: this.$route.params.weightCategId,
          ringId: this.$route.params.ringId
        },
        Subtitle: ''
      }
    },
    methods: {
      getData(){
        this.$axios.get(`/api/competition/results`, {
          params: {
            cmId: this.$route.params.cmId,
            discipId: this.$route.params.discipId,
            ageCategId: this.$route.params.ageCategId,
            weightCategId: this.$route.params.weightCategId,
            ringId: this.$route.params.ringId
          }
        })
            .then(response => {
              this.CompetitionTitle = response.data.CompetitionTitle;
              this.Results = response.data.Results;
              this.ParticipantsCount = response.data.ParticipantsCount;
              this.TeamsCount = response.data.TeamsCount;
              this.Subtitle = response.data.Subtitle;
              console.log(this.Results);
            })
            .catch(e => {
              this.errors.push(e)
            })
      }
    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
    },
    components: {
        MenuComponent,AdminResultsTableComponent,PublicResultsTableComponent
    }
  }
</script>

<style  lang="scss" scoped>

</style>