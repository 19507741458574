<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Svara kategorijas saraksts</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__table">
                <div style="padding: 1rem 4rem;">
                    <a v-if="CreationForm" class="btn btn-info" v-on:click="CreationForm = !CreationForm">Izveidot svaras kategoriju +</a>
                    <a v-if="!CreationForm" class="btn btn-info" v-on:click="CreationForm = !CreationForm">Aizvert</a>
                </div>
                <div v-if="!CreationForm" class="display: flex; flex-direction: row; flex-wrap: wrap;">
                        <label style="text-align: left; padding-left: 1.5rem;">Maksimalais svars </label>
                        <input class=" control-label" style="text-align: left; padding-left: 1.5rem;" v-model="WeightCategoryData.MaxWeight">
                        <label style="text-align: left; padding-left: 1.5rem;">More </label>
                        <input style="text-align: left; margin-left: 1.5rem; width: 2rem; height: 2rem;" type="checkbox" v-model="WeightCategoryData.More">
                        <button class="btn btn-primary" v-on:click="createWeightCategory()">Izveidot svaras kategoriju</button>
                </div>
                <weight-categories-grid :WeightCategoriesData="WeightCategories" ></weight-categories-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import WeightCategoriesGridComponent from "../../components/admin/CompetitionDataTablesComponents/WeightCategoriesGridComponent.vue";
    import MenuComponent from "../../components/common/MenuComponent";
    export default {
        name: "weightCategoriesPage",
        data() {
            return{
                show: false,
                WeightCategories: [],
                CreationForm: true,
                WeightCategoryData: {
                    MaxWeight: "",
                    More: ""
                },
            }
        },
        methods: {
            createWeightCategory(){
                this.$socket.emit('create-weight-category', this.WeightCategoryData);
            },
            getData: function(){
                this.$axios.get(`/api/categories/weight`, {})
                    .then(response => {
                        this.WeightCategories=response.data.WeightCategories;
                        console.log(this.WeightCategories)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }
        },
        created() {
            this.getData();
            this.$socket.on('refresh-weightcategories-data', response => {
                this.getData();
            });
            this.$socket.on('success', response => {
                console.log(response);
                if (response == true){
                    alert(`New weight category created!`)
                }else{
                    alert(response);
                }
            });
        },
        beforeDestroy () {
            this.$socket.removeListener('refresh-weightcategories-data');
            this.$socket.removeListener('success');
        },
        components:{
            MenuComponent,
            "weight-categories-grid": WeightCategoriesGridComponent
        },
    }
</script>

<style scoped>

</style>