var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Pult address: " + _vm._s(_vm.RemoteAddress))
        ]),
        _vm._v(" "),
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Pult key: " + _vm._s(_vm.RemoteKey))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "remote-buttons" }, [
        _c("div", { staticClass: "remote-buttons__top" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-warning remote-buttons__top--btn",
              on: {
                click: function($event) {
                  _vm.addRemoteRequest()
                }
              }
            },
            [_vm._v("Add remote")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "remote-buttons__bottom" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger remote-buttons__bottom--btn",
              on: {
                click: function($event) {
                  _vm.addRedPoint()
                }
              }
            },
            [_vm._v("Add red point")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary remote-buttons__bottom--btn",
              on: {
                click: function($event) {
                  _vm.addBluePoint()
                }
              }
            },
            [_vm._v("Add blue point")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f362f7f", { render: render, staticRenderFns: staticRenderFns })
  }
}