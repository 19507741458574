<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Tiesneša pulti</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__table">
                <div class="page__content__table__actions">
                    <div class="page__content__table__actions--button">
                        <button class="btn circle-btn" style="padding-left:1rem;" @click="$modal.show('addRemoteModal')">+</button>
                        <button class="btn square-btn" @click="$modal.show('addRemoteModal')">Pieslegt pultu</button>
                    </div>
                </div>
                <remoteControlsComponent :RemoteControls="RemoteControls" :RemotesData="RemotesData" ></remoteControlsComponent>
            </div>
        </div>
        <modal v-if="$store.state.authUser.type === 1 || $store.state.authUser.type === 6" name="addRemoteModal" :clickToClose="false" :scrollable="true" height="auto" >
            <addRemoteModalComponent :RemotesAddresses="RemotesAddresses"></addRemoteModalComponent>
        </modal>
    </div>
</template>

<script>
    import remoteControlsComponent from "../../components/admin/RemoteControlsComponents/RemoteControlsComponent.vue";
    import MenuComponent from "../../components/common/MenuComponent";
    import addRemoteModalComponent from "../../components/admin/RemoteControlsComponents/addRemoteModalComponent.vue"

    export default {
        name: "remoteControlsPage",
        data() {
            return{
                show: false,
                RemoteControls: [],
                RemotesData: [],
                RemotesAddresses: [],
            }
        },
        methods: {
            getRemoteControls: function(){
                this.$axios.get(`/api/remote-controls-list`, {})

                    .then(response => {
                        this.RemoteControls=response.data.RemoteControls;
                        this.RemotesAddresses = response.data.RemotesAddresses;
                        console.log(response.data)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },

            getRemoteData: function(){
                this.$axios.get(`/api/remote-controls-data`, {})

                    .then(response => {
                        this.RemotesData=response.data.RemotesData;
                        console.log(response.data)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
            getData: function (){
                this.getRemoteControls()
                this.getRemoteData()
            },

        },
        mounted() {
            this.getData()

            this.$socket.on('refresh-remotes-list',response => {
                this.getRemoteControls()
            });

            this.$socket.on('refresh-remotes-input-data',response => {
                this.getRemoteData()
            })
        },
        beforeDestroy () {
          this.$socket.removeListener('refresh-remotes-list');
          this.$socket.removeListener('refresh-remotes-input-data');
        },
        components:{
            MenuComponent, remoteControlsComponent, addRemoteModalComponent
        },
    }
</script>

<style lang="scss" scoped>

    body {
        font-size: 14px;
    }

    * {
        box-sizing: border-box;
    }


</style>