var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.ParticipantsData,
          "pagination-options": {
            enabled: true,
            perPage: 10
          },
          styleClass: "vgt-table striped bordered"
        },
        on: { "on-cell-click": _vm.onCellClick },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.field == "Actions"
                  ? _c("div", [
                      props.row.Approved == 1
                        ? _c("div", [
                            _c("div", { staticClass: "btn-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.unconfirmFighter(props.row.Id)
                                    }
                                  }
                                },
                                [_vm._v("Atcelt apstiprinajumu")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.editUserClaim(props.row)
                                    }
                                  }
                                },
                                [_vm._v("Labot")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.deleteUserClaim(props.row)
                                    }
                                  }
                                },
                                [_vm._v("Dzest")]
                              )
                            ])
                          ])
                        : props.row.Approved == 0
                        ? _c("div", [
                            _c("div", { staticClass: "btn-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.confirmFighter(props.row.Id)
                                    }
                                  }
                                },
                                [_vm._v("Apstiprinat")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.editUserClaim(props.row)
                                    }
                                  }
                                },
                                [_vm._v("Labot")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm",
                                  on: {
                                    click: function($event) {
                                      _vm.deleteUserClaim(props.row)
                                    }
                                  }
                                },
                                [_vm._v("Dzest")]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : props.column.field == "Payment"
                  ? _c("div", [
                      props.row.Payment == "0"
                        ? _c("div", [_vm._v("\n          Nav\n        ")])
                        : _c("div", [_vm._v("\n          Ir\n        ")])
                    ])
                  : props.column.field == "WeighingNr"
                  ? _c("div", [
                      props.row.WeighingNr == "0"
                        ? _c("div", [_vm._v("\n          -\n        ")])
                        : _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.formattedRow[props.column.field]) +
                                "\n        "
                            )
                          ])
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n      "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-08e279a2", { render: render, staticRenderFns: staticRenderFns })
  }
}