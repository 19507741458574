var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page__section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__sort" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedDiscipline,
                  expression: "SelectedDiscipline"
                }
              ],
              staticClass: "page__section__sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedDiscipline = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.Disciplines, function(discipline, key) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: discipline }
                },
                [_vm._v(_vm._s(key))]
              )
            })
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__team-sort" }, [
          _c(
            "a",
            {
              staticStyle: {
                "padding-left": "1rem",
                "padding-right": "1rem",
                cursor: "pointer"
              },
              on: { click: _vm.clearTeamSelect }
            },
            [_vm._v("Komanda:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedTeam,
                  expression: "SelectedTeam"
                }
              ],
              staticClass: "page__section__team-sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedTeam = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.Teams, function(team) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: team.Id }
                },
                [_vm._v(_vm._s(team.Title))]
              )
            })
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__data" },
          [
            JSON.stringify(_vm.BattlesInWeightCategory) == "{}"
              ? _c("div", { staticClass: "emptycategory" }, [
                  _c("h1", [_vm._v("Šeit pagaidam nav ne viena ciņa")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.Fighters, function(AgeCategory, AgeCategoryTitle) {
              return _c(
                "div",
                _vm._l(AgeCategory, function(WeightCategory, index, key) {
                  return _c(
                    "div",
                    [
                      key == 0
                        ? _c(
                            "a",
                            {
                              staticClass: "page__content__data--header",
                              attrs: {
                                href:
                                  "/competition/category/age/" +
                                  _vm.$route.params.cmId +
                                  "/" +
                                  _vm.SelectedDiscipline +
                                  "/" +
                                  WeightCategory[0].CmAgeCategoryId
                              }
                            },
                            [_vm._v(_vm._s(WeightCategory[0].AgeCategoryTitle))]
                          )
                        : _vm._e(),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "page__content__data--header",
                          attrs: {
                            href:
                              "/competition/category/weight/" +
                              _vm.$route.params.cmId +
                              "/" +
                              _vm.SelectedDiscipline +
                              "/" +
                              WeightCategory[0].CmAgeCategoryId +
                              "/" +
                              WeightCategory[0].CmWeightCategoryId
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(WeightCategory[0].WeightCategoryTitle) +
                              " Kg"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$store.state.authUser &&
                      _vm.$store.state.authUser.type === 1
                        ? _c("AdminNavigationInCategoryComponent", {
                            attrs: {
                              FightersData: WeightCategory,
                              BattlesData: _vm.BattlesInWeightCategory[index],
                              BattlesGraph: _vm.BattlesForGraph[index],
                              ParticipantsLozesanai:
                                _vm.FightersLozesanai[AgeCategoryTitle][index]
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.authUser
                        ? _c("PublicNavigationInCategoryComponent", {
                            attrs: {
                              FightersData: WeightCategory,
                              BattlesData: _vm.BattlesInWeightCategory[index],
                              BattlesGraph: _vm.BattlesForGraph[index]
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.state.authUser &&
                      !(_vm.$store.state.authUser.type === 1)
                        ? _c("PublicNavigationInCategoryComponent", {
                            attrs: {
                              FightersData: WeightCategory,
                              BattlesData: _vm.BattlesInWeightCategory[index],
                              BattlesGraph: _vm.BattlesForGraph[index]
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                })
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "ParticipantCategoryModal", width: 900, height: 400 }
        },
        [
          _c("ParticipantCategoryModalComponent", {
            attrs: { ParticipantData: _vm.ParticipantData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "AddCategoriesModal",
            scrollable: true,
            width: "100%",
            height: "auto"
          }
        },
        [_c("AddCategoryModalComponent")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Disciplines")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60e1bc84", { render: render, staticRenderFns: staticRenderFns })
  }
}