<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{RingTitle}}</h1>
      </div>
    </div>
    <div class="page__ring-data-container">
      <div class="page__ring-data-container__timer-set">
        <h2 class="page__ring-data-container__timer-set--label">Viena ringa radišanas laiks:
          <input class="page__ring-data-container__timer-set--input" type="number" min="10" max="60" v-model="timer.time" @change="displayRings()"/> sekundes
        </h2>
      </div>
    </div>
    <div class="page__full-view-content">
      <div class="page__full-view-content__data">
        <public-ring-battles :BattlesData="RingBattles" :Url="'http://cm.wakolat.lv'+$route.path+'/'+RingId"></public-ring-battles>
      </div>
    </div>
  </div>
</template>

<script>

  import PublicRingBattlesComponent from '../../components/public/RingsInCompetitionComponents/FullViewComponent.vue';

  export default {
    name: "FullViewPage",
    data () {
      return {
        show: false,
        CompetitionTitle: null,
        AgeCategoryFighters: null,
        Disceplines: null,
        SelectedDiscepline: null,
        Battles: {},
        RingBattles: [],
        RingTitle: "",
        RingId: "",
        timer: {time: 5},
        BattlesKeys: [],
        index: 0,
        Interval: null,
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/fullview`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.Battles = response.data.Battles;
            this.BattlesKeys = Object.keys(this.Battles)
            this.RingTitle=this.BattlesKeys[this.index];
            this.RingBattles=this.Battles[this.BattlesKeys[this.index]];
            this.RingId=this.Battles[this.BattlesKeys[this.index]][0].RingId;
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      displayRings(){
        clearInterval(this.Interval)
        if(this.timer.time<10){this.timer.time=10}
        if(this.timer.time>60){this.timer.time=60}
        this.Interval = setInterval(()=>{
          if (this.index >= this.BattlesKeys.length){this.index=0;}
          this.RingTitle=this.BattlesKeys[this.index];
          this.RingId=this.Battles[this.BattlesKeys[this.index]][0].RingId;
          this.RingBattles=this.Battles[this.BattlesKeys[this.index]];
          this.index+=1;
        },this.timer.time*1000)
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        console.log('geted refresh-users-data')
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        console.log('geted refresh-cmusers-data')
        this.getData();
      });
      this.$socket.on('refresh-battles-list', response => {
        console.log('geted refresh-battles-list')
        this.getData();
      });
      this.displayRings()
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-battles-list');

    },
    components: {
      'public-ring-battles': PublicRingBattlesComponent
    }
  }
</script>

<style  lang="scss" scoped>
/*
  .ring-data-container {
    padding: 1rem 7rem;
    &__timer-set{

      &--label{

      }
      &--input{

      }
    }
  }
  .full-view-page-content {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    &__data {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }*/
</style>