<template>
    <div class="page__content__navbar-container__navbar">
        <div v-if="$route.params.cmId===undefined && $store.state.authUser && $store.state.authUser.type === 1">
       <!--     <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>-->
          <router-link :to="{ name: 'stylesPage'}" >
            <div class="page__content__navbar-container__navbar--item">
              <span class="glyphicon glyphicon-leaf"></span> &emsp; Cīņu stili
            </div>
          </router-link>
            <router-link :to="{ name: 'disciplinesPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-copy"></span> &emsp; Disciplinas
                </div>
            </router-link>
            <router-link :to="{ name: 'ageCategoriesPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Vecuma kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'weightCategoriesPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-sort-by-attributes"></span> &emsp; Svaras kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-th-large"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'usersPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-user"></span> &emsp; Lietotai
                </div>
            </router-link>
            <router-link :to="{ name: 'createUserPage'}" >
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Izveidot lietotaju
                </div>
            </router-link>
            <router-link :to="{ name: 'teamsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-flag"></span> &emsp; Komandas
                </div>
            </router-link>
            <router-link :to="{ name: 'createTeamPage'}" >
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Izveidot komandu
                </div>
            </router-link>
            <router-link :to="{ name: 'remoteControlsPage'}" >
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-phone"></span> &emsp; Tiesneša pulti
              </div>
            </router-link>
          <!--  <router-link :to="{ name: 'Competitions'}" >
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>-->
        </div>

        <div v-if="!($route.params.cmId===undefined) && $store.state.authUser && $store.state.authUser.type === 1">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'competitionClaimsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikumi
                </div>
            </router-link>
            <router-link :to="{ name: 'applyTeamPage', params: {cmId: $route.params.cmId }}" >
              <div class="page__content__navbar-container__navbar--bottom-item">
                Pieteikt komandu
              </div>
            </router-link>
            <router-link :to="{ name: 'lozesanasPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Lozešana
                </div>
            </router-link>
            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'editCategoriesPage', params: {cmId: $route.params.cmId}}">
              <div class="page__content__navbar-container__navbar--bottom-item">
                Kategorijas saraksta labošana
              </div>
            </router-link>
            <router-link :to="{ name: 'addAgeCategoryPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pievienot vecuma kategoriju
                </div>
            </router-link>
            <router-link :to="{ name: 'addWeightCategoryPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pievienot svara kategoriju
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'addRingPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pievienot ringu
                </div>
            </router-link>
            <router-link :to="{ name: 'competitionRemotesPage', params: {cmId: $route.params.cmId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-phone"></span> &emsp; Tiesneša pulti
              </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link v-if="$route.params.ringId" :to="{ name: 'ResultsInRingPage', params: {cmId: $route.params.cmId, ringId: $route.params.ringId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
              </div>
            </router-link>
            <router-link v-else-if="$route.params.discipId && !$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInDisciplinePage', params: {cmId: $route.params.cmId, discipId: $route.params.discipId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
              </div>
            </router-link>
            <router-link v-else-if="$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, ageCategId: $route.params.ageCategId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
              </div>
            </router-link>
            <router-link v-else-if="$route.params.weightCategId" :to="{ name: 'ResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, weightCategId: $route.params.weightCategId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
              </div>
            </router-link>
            <router-link v-else :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
              </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>

        <div v-if="$route.params.cmId===undefined && !$store.state.authUser">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>

        <div v-if="!($route.params.cmId===undefined) && !$store.state.authUser">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'competitionClaimsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikumi
                </div>
            </router-link>
            <router-link :to="{ name: 'applyTeamPage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikt komandu
                </div>
            </router-link>

            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link v-if="$route.params.ringId" :to="{ name: 'ResultsInRingPage', params: {cmId: $route.params.cmId, ringId: $route.params.ringId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.discipId && !$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInDisciplinePage', params: {cmId: $route.params.cmId, discipId: $route.params.discipId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, ageCategId: $route.params.ageCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.weightCategId" :to="{ name: 'ResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, weightCategId: $route.params.weightCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>

        <div v-if="!($route.params.cmId===undefined) && $store.state.authUser && !($store.state.authUser.type === 1) && !($store.state.authUser.type === 4) && !($store.state.authUser.type === 6)">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'competitionClaimsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikumi
                </div>
            </router-link>
            <router-link :to="{ name: 'applyTeamPage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikt komandu
                </div>
            </router-link>
            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'TeamFightsPage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-briefcase"></span> &emsp; Komandas cīņas
                </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link v-if="$route.params.ringId" :to="{ name: 'ResultsInRingPage', params: {cmId: $route.params.cmId, ringId: $route.params.ringId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.discipId && !$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInDisciplinePage', params: {cmId: $route.params.cmId, discipId: $route.params.discipId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, ageCategId: $route.params.ageCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.weightCategId" :to="{ name: 'ResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, weightCategId: $route.params.weightCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>

        <div v-if="$route.params.cmId===undefined && $store.state.authUser && ($store.state.authUser.type === 4)">
          <router-link :to="{ name: 'teamsPage'}" >
            <div class="page__content__navbar-container__navbar--item">
              <span class="glyphicon glyphicon-flag"></span> &emsp; Komandas
            </div>
          </router-link>
          <router-link :to="{ name: 'createTeamPage'}" >
            <div class="page__content__navbar-container__navbar--bottom-item">
              Izveidot komandu
            </div>
          </router-link>
        </div>

        <div v-if="$route.params.cmId===undefined && $store.state.authUser">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>

        <div v-if="!($route.params.cmId===undefined) && $store.state.authUser && ($store.state.authUser.type === 4)">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
          <router-link :to="{ name: 'competitionClaimsPage', params: {cmId: $route.params.cmId}}">
            <div class="page__content__navbar-container__navbar--bottom-item">
              Pieteikumi
            </div>
          </router-link>
         <!--   <router-link :to="{ name: 'applyParticipantPage', params: {cmId: $route.params.cmId }}" >
              <div class="page__content__navbar-container__navbar&#45;&#45;bottom-item">
                 Pieteikt dalibnieku
              </div>
            </router-link>-->
            <router-link :to="{ name: 'applyTeamPage', params: {cmId: $route.params.cmId }}" >
              <div class="page__content__navbar-container__navbar--bottom-item">
                Pieteikt komandu
              </div>
            </router-link>
            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'TeamFightsPage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-briefcase"></span> &emsp; Komandas cīņas
                </div>
            </router-link>
            <router-link :to="{ name: 'teamsPage'}" >
              <div class="page__content__navbar-container__navbar--item">
                <span class="glyphicon glyphicon-flag"></span> &emsp; Komandas
              </div>
            </router-link>
            <router-link :to="{ name: 'createTeamPage'}" >
              <div class="page__content__navbar-container__navbar--bottom-item">
                Izveidot komandu
              </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link v-if="$route.params.ringId" :to="{ name: 'ResultsInRingPage', params: {cmId: $route.params.cmId, ringId: $route.params.ringId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.discipId && !$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInDisciplinePage', params: {cmId: $route.params.cmId, discipId: $route.params.discipId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, ageCategId: $route.params.ageCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.weightCategId" :to="{ name: 'ResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, weightCategId: $route.params.weightCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>
       <!-- <div v-if="!($route.params.cmId===undefined) && $store.state.authUser && ($store.state.authUser.type === 5)">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'TeamFightsPage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-briefcase"></span> &emsp; Komandas cīņas
                </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar&#45;&#45;item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>-->

        <div v-if="!($route.params.cmId===undefined) && $store.state.authUser && ($store.state.authUser.type === 6)">
            <router-link :to="{ name: 'competitionsPage'}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-home"></span> &emsp; Sacensības
                </div>
            </router-link>
            <router-link :to="{ name: 'competitionClaimsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--bottom-item">
                    Pieteikumi
                </div>
            </router-link>
            <router-link :to="{ name: 'categoriesPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-chevron-left"></span> &emsp; Kategorijas
                </div>
            </router-link>
            <router-link :to="{ name: 'ringsInCompetitionPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-folder-close"></span> &emsp; Ringi
                </div>
            </router-link>
            <router-link :to="{ name: 'FullViewPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list"></span> &emsp; Pilnais skats
                </div>
            </router-link>
            <router-link v-if="$route.params.ringId" :to="{ name: 'ResultsInRingPage', params: {cmId: $route.params.cmId, ringId: $route.params.ringId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.discipId && !$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInDisciplinePage', params: {cmId: $route.params.cmId, discipId: $route.params.discipId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.ageCategId && !$route.params.weightCategId" :to="{ name: 'ResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, ageCategId: $route.params.ageCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else-if="$route.params.weightCategId" :to="{ name: 'ResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, weightCategId: $route.params.weightCategId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link v-else :to="{ name: 'ResultsPage', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-star"></span> &emsp; Rezultāts
                </div>
            </router-link>
            <router-link :to="{ name: 'WeighingQueuePage', params: {cmId: $route.params.cmId }}" >
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-list-alt"></span> &emsp; Svēršanas rinda
                </div>
            </router-link>
            <router-link :to="{ name: 'Competitions', params: {cmId: $route.params.cmId}}">
                <div class="page__content__navbar-container__navbar--item">
                    <span class="glyphicon glyphicon-facetime-video"></span> &emsp; Streams
                </div>
            </router-link>
        </div>
        </div>
</template>

<script>
    export default {
        name: "MenuComponent",
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

</style>