<template>
  <div class="ring-battles">
    <div class="ring-battles__data-container">
      <div class="ring-battles__data-container--table">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Nr</th>
            <th>Vecuma kategorija</th>
            <th>Svara kategorija</th>
            <th>Limenis</th>
            <th>Komanda</th>
            <th class="side-color--red">Sarkanais stūris</th>
            <th class="side-color--red"></th>
            <th>Resultats</th>
            <th class="side-color--blue"></th>
            <th class="side-color--blue">Zilais stūris</th>
            <th>Komanda</th>
            <th>Winner</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="data in BattlesData" v-bind:class="data.Color">
            <td>{{data.Nr}}</td>
            <td>{{data.AgeCategory}}</td>
            <td>{{data.WeightCategory}}</td>
            <td><span v-if="data.CompetitionPart>1">1/</span>{{data.CompetitionPart}}</td>
            <td>{{data.RedTeam}}</td>
            <td>{{ data.RedNameSurname ? data.RedNameSurname : data.RedComesFrom }}</td>
            <td class="side-color--red"></td>
            <td class="results-color">{{data.Result}}</td>
            <td class="side-color--blue"></td>
            <td>{{ data.BlueNameSurname ? data.BlueNameSurname : data.BlueComesFrom }}</td>
            <td>{{data.BlueTeam}}</td>
            <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
            <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
            <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="ring-battles__data-container--qrcode">
        <qrcode-vue :value="Url" :size="200" level="H"></qrcode-vue>
      </div>
    </div>
  </div>
</template>

<script>

  import QrcodeVue from 'qrcode.vue';

  export default {
    name: 'FullViewComponent',
    props: ["BattlesData","Url"],
    data(){
      return {
        Selected: {
          Id: 0,
          Row: "",
          UserId: 0,
          Value: {
            Id:  0,
            Title: '',
            Field: ''
          },
          type: ""
        },
        updatable: false,
        Teams: [],
        Color: '',
        string: "",
        Search: false,
        Fights: [],
      };
    },
    methods: {

    },
    mounted() {
    },
    components: {
      QrcodeVue
    },
  };
</script>

<style lang="scss">
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;}
/*  .red-background {
    height: 9rem;
    background-color: #FB8585;

  }
  .green-background {
    height: 9rem;
    background-color: #88ff7b;
  }*/


</style>