<template>
  <div class="page">
    <div class="page__section">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Pieteikt dalibnieku</h1>
    </div>
  </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item "  style="z-index:2;">
            <label class="page__content__input__form__item--label control-label " >Atrast dalibnieku</label>
            <multiselect v-model="ApplyData.User" :options="Users" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="NameSurnameLabel" @input="setCategories()"></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Vards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.User.Name" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="ApplyData.User.Surname" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item" >
            <label class="page__content__input__form__item--label control-label " style="left:-2rem; z-index: 1;">Dzimsanas Datums<sup>*</sup></label>
            <div class="page__content__input__form__item--input" style="z-index: 1;">
              <DatePicker calendar-class="calendar" :monday-first="true" v-model="ApplyData.User.DateOfBirth" :placeholder="ApplyData.User.DateOfBirth" :bootstrap-styling="true" @closed="setCategories()"></DatePicker>
            </div>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Gender<sup>*</sup></label>
            <multiselect v-model="ApplyData.User.Gender" :options="Genders" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" @input="setCategories()">
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Weight<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Weight" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index: 0;">Kategorijas<sup>*</sup></label>
            <multiselect v-model="ApplyData.Categories" :options="Categories" :close-on-select="true"
                         :multiple="true" :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="CategoryLabel"></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Cinas<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Fights" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvaras<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Victory" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nokdauni<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Knockouts" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Team<sup>*</sup></label>
            <multiselect v-model="ApplyData.Team" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
          </div>
          <div class="page__content__input__form__item" v-if="$store.state.authUser.type==1">
            <label class="page__content__input__form__item--label control-label " type="checkbox" style="left:-2rem;">Samaksāts</label>
            <input class="page__content__input__form__item--input form-control" style="width:5rem; left:-2rem;" type="checkbox" v-model="ApplyData.Payed">
          </div>
          <div class="page__content__input__form__item" v-if="$store.state.authUser.type==1">
            <label class="page__content__input__form__item--label control-label ">Maksājuma komentārs<sup>*</sup></label>
            <textarea class="page__content__input__form__item--input form-control" rows="5" v-model="ApplyData.PaymentComments" ></textarea>
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="applyUser(ApplyData)">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePicker from 'vuejs-datepicker';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "applyParticipantPage",
    data(){
      return {
        show: false,
        Users: [],
        ApplyData: {
          User: {Gender:{}, DateOfBirth: new Date()},
          Categories: [],
          Weight: 0,
          Fights: 0,
          Victory: 0,
          Knockouts: 0,
          Team: this.TeamData,
          Payed: false,
          PaymentComments: '',
          CmId: this.$route.params.cmId ,
        },
        Genders: [{Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        Categories: [],
        Teams: [],
        AllCategories: [],
      }
    },
    methods: {
      setCategories(){
        console.log("this.ApplyData.User: "+this.ApplyData.User)
        if (!this.ApplyData.User){
          this.ApplyData.User={Gender:{}}
        }
        let now = new Date;
        let DateOfBirth = new Date(this.ApplyData.User.DateOfBirth);
        let Age=((now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        console.log("DateOfBirth: ",this.ApplyData.User.DateOfBirth)
        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          let CheckWeight=true;
          if(item.CategorySide==1){
            CheckWeight = (item.WeightMax<=this.ApplyData.Weight);
          }else{
            CheckWeight = (item.WeightMax>=this.ApplyData.Weight);
          }
          if (CheckWeight && (item.AgeMin<=Age) && (item.AgeMax>=Age) && (item.Gender==this.ApplyData.User.Gender.Id)) {
            this.Categories.push(item);
          }
        })
      },
      clearUser(){
        this.ApplyData.User={Gender:{}}
        this.ApplyData.Weight=0;
        this.ApplyData.Category={};
        this.setCategories()
      },
      applyUser(Data) {
        console.log("Data: ",Data)
        let DataApply = Object.assign({}, Data);
        let Now = new Date
        let DateOfBirth = new Date(Data.User.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        if ((typeof Data.User.Name!='undefined') && (typeof Data.User.Surname!='undefined')){
          if (Data.User.Name.length < 3) {
            alert("Vārds ievadīts ne pareizi!")
            return;
          } else if (Data.User.Surname.length < 2) {
            alert("Uzvārds ievadīts ne pareizi!")
            return;
          }
        }
        if ((typeof Data.User.Name=='undefined') || (typeof Data.User.Surname=='undefined')){
          alert("Dalibnieka dati neievadīti!")
          return;
        }
        if ((Age<=5) || (!DateOfBirth)) {
          alert("Dzimšanas datums neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (!Data.User.Gender.Id) {
          alert("Dzimums nav ievadīts!")
          return;
        }
        if ((Data.Weight.length<2) || (!Data.Weight)){
          alert("Svars neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (this.ApplyData.Categories.length==0) {
          alert("Kategorija neievadīta!")
          return;
        }
        if (!Data.Team) {
          alert("Komanda nav ievadīta!")
          return;
        }

        console.log('this.ApplyData: ',this.ApplyData)
        console.log('DataApply: ',DataApply)
        console.log('Data: ',Data)

        DataApply.Categories=[]
        console.log('after')
        console.log('this.ApplyData: ',this.ApplyData)
        console.log('DataApply: ',DataApply)
        console.log('Data: ',Data)

        Data.Categories.forEach((ApplyCategory)=>{
          console.log(`Category: `,ApplyCategory)
          DataApply.Categories.push(ApplyCategory.Id)
        })

        console.log(`Categories: `,DataApply.Categories)

        DataApply.User.DateOfBirth = DateOfBirth.getFullYear() +
            "-" +
            parseInt(DateOfBirth.getMonth() + 1) +
            "-" +
            DateOfBirth.getDate();
        console.log(DataApply.User.DateOfBirth)
        this.$socket.emit('apply-cmuser', DataApply);
      },
      NameSurnameLabel (option) {
        let now = new Date;
        let UserBirthDate=option.DateOfBirth;
        UserBirthDate = new Date(UserBirthDate);
        let Age=((now.getTime() - UserBirthDate) / (24 * 3600 * 365.25 * 1000) | 0);
        if ((typeof option.Name=='undefined')&&(typeof option.Surname=='undefined')){return '-'}
        return `${option.Name} - ${option.Surname} (Age: ${Age})`
      },
      CategoryLabel (option) {
        if ((typeof option.AgeCategory=='undefined')&&(typeof option.WeightMax=='undefined')){return '-'}
          let Side = '';

          if(option.CategorySide==1){
           Side = ' + ';
          }else{
           Side = ' - ';
          }
        return `${option.DisciplineTitle} ${option.AgeCategory} ${Side}${option.WeightMax} (Age: ${option.AgeMin} - ${option.AgeMax})`
      },
      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      getData() {
        this.$axios.get(`/api/participant/applydata`, {
          params: {
            userType: this.$store.state.authUser.type,
            userId: this.$store.state.authUser.id,
            cmId: this.$route.params.cmId,
          }
        })
        .then(response => {
          this.Users = response.data.Users;
          this.Categories = response.data.Categories;
          this.AllCategories = response.data.Categories;
          this.Teams = response.data.Teams;
          console.log(`ApplyData: ${this.ApplyData}`)
          console.log(this.Categories)
          console.log(this.Users)
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
    },
    computed: {
      styleObject: function () {
        return {
          background: 'red'
        }
      }
    },
    mounted() {
      this.getData();
      this.$socket.on('success', response => {
        console.log(response)
        if (response.status===true){
          if (this.$store.state.authUser.type==4){
            this.$router.push({name: 'competitionsPage'})
          }else {
            this.$router.push({name: 'competitionClaimsPage', params: {cmId: this.$route.params.cmId}})
          }
        }else{
          if (response.message) {
            alert(response.message)
          } else {
            alert("Something goes wrong! Check all fields on filled right")
          }
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-categories-data');
    },
    components: {
        MenuComponent,
      DatePicker, "multiselect": Multiselect
    },
  }
</script>


<style lang="scss" scoped>
 /* @media (max-width:400px){
    .vdp-datepicker__calendar{
      width: 100% !important;
    }
  }*/

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>