<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="StylesData"
      @on-cell-click="onCellClick"
      :search-options="{
          enabled: true,
          trigger: 'enter'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 30,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <button class="btn btn-primary" v-on:click="editStyle(props.row.Id)">Labot</button>
          <button class="btn btn-danger" v-on:click="deleteStyle(props.row.Id)">Delete</button>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "StylesTableComponent",
    props: ["StylesData"],
    data(){
      return {
        columns: [
          {
            label: 'Nosaukums',
            field: 'Title',
          },
          {
            label: 'Apraksts',
            field: 'Description',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
              label: 'Darbibas',
              field: 'Actions',
              thClass: 'text-center',
              tdClass: 'text-center',
          },
        ],
        CmId: null,
      }
    },
    methods: {
        editStyle(q) {
            this.$router.push('/styles/style/edit/' + q)
        },
        deleteStyle(q) {
            this.$socket.emit('delete-style', {styleId: q})

        },
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }

</style>