var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fight
    ? _c("div", { staticClass: "fight-info" }, [
        _c(
          "div",
          { staticClass: "fight-info__fighter fight-info__fighter--red" },
          [
            _c("h1", { staticClass: "fight-info__fighter__full-name" }, [
              _vm._v(
                _vm._s(_vm.fight.red_name) + " " + _vm._s(_vm.fight.red_surname)
              )
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "fight-info__fighter__club" }, [
              _vm._v("Club / Nationality: " + _vm._s(_vm.fight.red_club))
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "fight-info__fighter__glove",
              attrs: { src: "/static/red.png", align: "right", height: "200px" }
            })
          ]
        ),
        _vm._v(" "),
        _vm.fight
          ? _c("div", { staticClass: "fight-info__fight-description" }, [
              _c(
                "div",
                {
                  staticClass: "fight-info__fight-description__fight-main-info"
                },
                [
                  _c("h4", [
                    _vm._v(
                      "Weight Category: " +
                        _vm._s(_vm.fight.weight_category) +
                        " KG"
                    )
                  ]),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Ring: " + _vm._s(_vm.fight.ring))]),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Ring Nr: " + _vm._s(_vm.fight.ring_nr))])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "fight-info__fight-description__timer" },
                [
                  _vm.fight.roundDetails
                    ? _c("timer", {
                        attrs: {
                          data: _vm.fight.roundDetails,
                          lvl: _vm.fight.lvl
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.fight
          ? _c(
              "div",
              { staticClass: "fight-info__fighter fight-info__fighter--blue" },
              [
                _c("h1", { staticClass: "fight-info__fighter__full-name" }, [
                  _vm._v(
                    _vm._s(_vm.fight.blue_name) +
                      " " +
                      _vm._s(_vm.fight.blue_surname)
                  )
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "fight-info__fighter__club" }, [
                  _vm._v("Club / Nationality: " + _vm._s(_vm.fight.blue_club))
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "fight-info__fighter__glove",
                  attrs: {
                    src: "/static/blue.png",
                    align: "left",
                    height: "200px"
                  }
                })
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5019876b", { render: render, staticRenderFns: staticRenderFns })
  }
}