var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__data" },
        [
          _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
            ? _c(
                "div",
                { staticStyle: { padding: "1rem 4rem" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-info",
                      attrs: {
                        to: {
                          name: "addRingPage",
                          params: { cmId: _vm.$route.params.cmId }
                        }
                      }
                    },
                    [_vm._v("Pievienot ringu +")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
            ? _c("admin-rings-grid", { attrs: { RingsData: _vm.Rings } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.state.authUser
            ? _c("public-rings-grid", { attrs: { RingsData: _vm.Rings } })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && !(_vm.$store.state.authUser.type === 1)
            ? _c("public-rings-grid", { attrs: { RingsData: _vm.Rings } })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Ringu saraksts")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c20df1ce", { render: render, staticRenderFns: staticRenderFns })
  }
}