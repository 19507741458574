var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input" }, [
        _c("h1", { staticClass: "page__content__input--title" }, [
          _vm._v("Sacensiba: " + _vm._s(_vm.CompetitionTitle))
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 4rem 3rem" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-info",
                  attrs: { to: { name: "ringsPage" } }
                },
                [_vm._v("Izveidot ringu +")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Ring")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Rings,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "Select ring",
                  "track-by": "Id",
                  "custom-label": _vm.RingLabel
                },
                model: {
                  value: _vm.Data.Ring,
                  callback: function($$v) {
                    _vm.$set(_vm.Data, "Ring", $$v)
                  },
                  expression: "Data.Ring"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.Data.RingNr,
                  expression: "Data.RingNr"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.Data.RingNr },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.Data, "RingNr", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Kategorijas")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Categories,
                  multiple: true,
                  placeholder: "Select categories",
                  "track-by": "Id",
                  "custom-label": _vm.CategoryLabel
                },
                model: {
                  value: _vm.Data.CategoriesIds,
                  callback: function($$v) {
                    _vm.$set(_vm.Data, "CategoriesIds", $$v)
                  },
                  expression: "Data.CategoriesIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Galvenais Tiesnesis")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.MainJudges,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "Select judge",
                  "track-by": "Id",
                  "custom-label": _vm.JudgeLabel
                },
                model: {
                  value: _vm.Data.MainJudge,
                  callback: function($$v) {
                    _vm.$set(_vm.Data, "MainJudge", $$v)
                  },
                  expression: "Data.MainJudge"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Tiesnesu sastavs")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Judges,
                  multiple: true,
                  placeholder: "Select judge",
                  "track-by": "Id",
                  "custom-label": _vm.JudgeLabel
                },
                model: {
                  value: _vm.Data.JudgesIds,
                  callback: function($$v) {
                    _vm.$set(_vm.Data, "JudgesIds", $$v)
                  },
                  expression: "Data.JudgesIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__btn-item" }, [
            _c(
              "button",
              {
                staticClass:
                  "page__content__input__form__btn-item--btn btn btn-primary",
                on: { click: _vm.EditRing }
              },
              [_vm._v("Saglabat")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Labot ringu")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass:
          "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
      },
      [_vm._v("Ringa numurs"), _c("sup", [_vm._v("*")])]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-379acd5c", { render: render, staticRenderFns: staticRenderFns })
  }
}