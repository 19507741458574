import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

module.exports = new Vuex.Store({
  state: {
    authUser: null,
    locale: 'en'
  },
  getters: {},
  mutations: {
    SET_LOCALE (state, locale) {
      // mutate state
      state.locale = locale
    },
    SET_USER (state, user) {
      // mutate state
      state.authUser = user
    }
  },
  actions: {}
});