<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Vecuma kategorijas saraksts</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__table">
                <div style="padding: 1rem 4rem;">
                    <router-link class="btn btn-info"  :to="{ name: 'createAgeCategoryPage'}">Izveidot vecuma categoriju +</router-link>
                </div>
                <age-categories-grid :AgeCategoriesData="AgeCategories" ></age-categories-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import AgeCategoriesGridComponent from "../../components/admin/CompetitionDataTablesComponents/AgeCategoriesGridComponent.vue";
    import MenuComponent from "../../components/common/MenuComponent";
    export default {
        name: "ageCategoriesPage",
        data() {
            return{
                show: false,
                AgeCategories: [],
                CreationForm: true,
            }
        },
        methods: {
            getData: function(){
                this.$axios.get(`/api/categories/age`, {})

                    .then(response => {
                        this.AgeCategories=response.data.AgeCategories;
                        console.log(this.AgeCategories)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }

        },
        mounted() {
            this.getData()
            this.$socket.on('refresh-agecategories-data', response => {
                this.getData();
            });
        },
        beforeDestroy () {
            this.$socket.removeListener('refresh-agecategories-data');
        },
        components:{
            MenuComponent,
            "age-categories-grid": AgeCategoriesGridComponent
        },
    }
</script>

<style scoped>

</style>