var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.competitions, function(competition, index) {
      return _c(
        "router-link",
        {
          key: competition.id,
          staticClass: "list-group-item list-group-item-action",
          attrs: { to: { name: "Competition", params: { id: competition.id } } }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(index + 1) +
              ")  " +
              _vm._s(competition.title) +
              "\n\n    "
          ),
          _c("span", [
            _vm._v("Date: " + _vm._s(_vm._f("date")(competition.date)))
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5f078a40", { render: render, staticRenderFns: staticRenderFns })
  }
}