import Vue from 'vue'
import Router from 'vue-router'
import Competitions from '../pages/OldVersionsPages/Competitions.vue'
/*import Competition from '../pages/Competition.vue'*/


import remoteControlsPage from '../pages/AdminPages/remoteControlsPage.vue'
import competitionRemotesPage from '../pages/RemoteControlsPages/competitionRemotesPage.vue'
import testRemotePage from '../pages/RemoteControlsPages/testRemotePage.vue'


import disciplinePage from '../pages/BattlesPages/disciplinePage.vue'
import ageCategoryPage from '../pages/BattlesPages/ageCategoryPage.vue'
import weightCategoryPage from '../pages/BattlesPages/weightCategoryPage.vue'

import FullViewPage from '../pages/BattlesPages/FullViewPage.vue'
import FullViewRingPage from '../pages/BattlesPages/FullViewRingPage.vue'
import TeamFightsPage from '../pages/BattlesPages/TeamFightsPage.vue'
import WeighingQueuePage from '../pages/WeighingPages/WeighingQueuePage.vue'

import ResultsPage from '../pages/Results/ResultsPage.vue'
/*import TeamResultsPage from '../pages/Results/TeamResultsPage.vue'*/
import teamReportResultsPage from '../pages/Results/teamReportResultsPage'

import competitionsPage from '../pages/CompetitionPages/competitionsPage.vue'
import competitionPage from '../pages/CompetitionPages/competitionPage.vue'
import editCompetitionPage from '../pages/CompetitionPages/editCompetitionPage.vue'
import createCompetitionPage from '../pages/CompetitionPages/createCompetitionPage.vue'


import ringsPage from '../pages/RingsPages/ringsPage.vue'

import addRingPage from '../pages/RingsInCompetitionPages/addRingPage.vue'
import editRingPage from '../pages/RingsInCompetitionPages/editRingPage.vue'
import ringsInCompetitionPage from '../pages/RingsInCompetitionPages/ringsInCompetitionPage.vue'
import ringBattlesPage from '../pages/RingsInCompetitionPages/ringBattlesPage.vue'

import dashboardPage from '../pages/AdminPages/dashboardPage.vue'


import stylesPage from '../pages/StylesPages/stylesPage.vue'
import createStylePage from '../pages/StylesPages/createStylePage.vue'
import editStylePage from '../pages/StylesPages/editStylePage.vue'


import disciplinesPage from '../pages/DisciplinesPages/disciplinesPage.vue'
import createDisciplinePage from '../pages/DisciplinesPages/createDisciplinePage.vue'
import editDisciplinePage from '../pages/DisciplinesPages/editDisciplinePage.vue'

import lozesanasPage from '../pages/ParticipantsPages/lozesanasPage.vue'
import competitionClaimsPage from '../pages/ParticipantsPages/competitionClaimsPage.vue'
import applyParticipantPage from '../pages/ParticipantsPages/applyParticipantPage.vue'
import editParticipantPage from '../pages/ParticipantsPages/editParticipantPage.vue'
import applyTeamPage from '../pages/ParticipantsPages/applyTeamPage.vue'


import addAgeCategoryPage from '../pages/CategoriesInCompetitionPages/addAgeCategoryPage.vue'
import addWeightCategoryPage from '../pages/CategoriesInCompetitionPages/addWeightCategoryPage.vue'
import categoriesPage from '../pages/CategoriesInCompetitionPages/categoriesPage.vue'
import editCategoriesPage from '../pages/CategoriesInCompetitionPages/editCategoriesPage.vue'


import ageCategoriesPage from '../pages/AgeCategoriesPages/ageCategoriesPage.vue'
import createAgeCategoryPage from '../pages/AgeCategoriesPages/createAgeCategoryPage.vue'
import editAgeCategoryPage from '../pages/AgeCategoriesPages/editAgeCategoryPage.vue'

import teamsPage from '../pages/TeamsPages/teamsPage.vue'
import createTeamPage from '../pages/TeamsPages/createTeamPage.vue'
import editTeamPage from '../pages/TeamsPages/editTeamPage.vue'

import usersPage from '../pages/UsersPages/usersPage.vue'
import editUserPage from '../pages/UsersPages/editUserPage.vue'
import createUserPage from '../pages/UsersPages/createUserPage.vue'
import registrationPage from '../pages/UsersPages/registrationPage.vue'
import editPasswordPage from '../pages/UsersPages/editPasswordPage.vue'

import weightCategoriesPage from '../pages/WeightCategoriesPages/weightCategoriesPage.vue'

import editProfilePage from '../pages/ProfilePages/editProfilePage.vue'
import editProfilePasswordPage from '../pages/ProfilePages/editPasswordPage.vue'
import profilePage from '../pages/ProfilePages/profilePage.vue'

import CreateUserClaimPage from '../pages/UsersPages/CreateUserClaimPage.vue'

import createCmUserClaimPage from '../pages/ParticipantsPages/createCmUserClaimPage.vue'

import homePage from '../pages/MainPages/homePage.vue'
import FightPage from '../pages/FightPages/FightPage.vue'
import FightViewPage from '../pages/FightPages/FightViewPage.vue'
import PageNotFound from '../pages/MainPages/PageNotFound.vue'
/*import SecretaryPage from '../pages/SecretaryPage.vue'*/
import SecretaryCompetitionsPage from '../pages/PersonalFightsPages/SecretaryCompetitionsPage.vue'
import SecretaryFightPage from '../pages/FightPages/SecretaryFightPage.vue'
/*import Judge from '../pages/JudgePage.vue'*/
import JudgeCompetitionsPage from '../pages/PersonalFightsPages/JudgeCompetitionsPage.vue'
import JudgeFight from '../pages/FightPages/JudgeFightPage.vue'
import Auth from '../middlware'

import StreamCompetitionsPage from '../pages/PersonalFightsPages/StreamCompetitionsPage.vue'
import CoachCompetitionsPage from '../pages/PersonalFightsPages/CoachCompetitionsPage.vue'

/*fightsGenerationPage*/

import fightsGenerationPage from '../pages/ParticipantsPages/fightsGenerationPage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: homePage
    },
    {
      path: '/streams/competitions',
      name: 'Competitions',
      component: StreamCompetitionsPage
    },
    /*{
      path: '/streams/competitions',
      name: 'Competitions',
      component: Competitions
    },*/
    /*{
      path: '/streams/competitions/competition/:id',
      name: 'Competition',
      component: Competition
    },*/
    {
      path: '/ring/:ringId',
      name: 'Ring',
      component: FightPage,
    },

    {
      path: '/profile',
      name: 'profilePage',
      component: profilePage,
      meta: {requiresAuth: true}
    },

    {
      path: '/profile/edit/',
      name: 'editProfilePage',
      component: editProfilePage,
      meta: {requiresAuth: true}
    },

    {
      path: '/profile/password/edit',
      name: 'editProfilePasswordPage',
      component: editProfilePasswordPage,
      meta: {requiresAuth: true}
    },

    {
      path: '/streams/ring/:ringId/fight/:fightId',
      name: 'FightViewPage',
      component: FightViewPage,
    },
    /* { path: "/secretary/:id",
       name: 'SecretaryPage',
       component: SecretaryPage,
         meta: { requiresAuth: true,
             isSecretary: true
         }

     },*/
    {
      path: "/secretary/:id",
      name: 'SecretaryCompetitionsPage',
      component: SecretaryCompetitionsPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }

    },
    {
      path: "/secretary/:id/fight/:fightId",
      name: 'secretaryFight',
      component: SecretaryFightPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },
    /*   {
         path: '/judge/:judgeId',
         name: 'Judge',
         component: Judge,
           meta: { requiresAuth: true,
               isJudge: true
           }
       },*/
    {
      path: '/judge/:id',
      name: 'Judge',
      component: JudgeCompetitionsPage,
      meta: {
        requiresAuth: true,
        isJudge: true
      }
    },
    {
      path: '/judge/:id/fight/:fightId',
      name: 'JudgeFight',
      component: JudgeFight,
      meta: {
        requiresAuth: true,
        isJudge: true
      }
    },

    {
      path: "/coach/:id",
      name: 'CoachCompetitionsPage',
      component: CoachCompetitionsPage,
      meta: {
        requiresAuth: true,
        isCoach: true
      }

    },

    {
      path: "*",
      name: '404',
      component: PageNotFound
    },

    /*CM*/

    /* RemoteControls */


    {
      path: '/test-remote',
      name: 'testRemotePage',
      component: testRemotePage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    {
      path: '/remote-controls',
      name: 'remoteControlsPage',
      component: remoteControlsPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    {
      path: '/competition/remote-controls/:cmId',
      name: 'competitionRemotesPage',
      component: competitionRemotesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    /*Battles In Competition*/

    {
      path: '/competition/category/discipline/:cmId/:discipId',
      name: 'disciplinePage',
      component: disciplinePage,

    },
    {
      path: '/competition/category/age/:cmId/:discipId/:ageCategId',
      name: 'ageCategoryPage',
      component: ageCategoryPage,

    },
    {
      path: '/competition/category/weight/:cmId/:discipId/:ageCategId/:weightCategId',
      name: 'weightCategoryPage',
      component: weightCategoryPage,
    },
    {
      path: '/competition/full-view/:cmId',
      name: 'FullViewPage',
      component: FullViewPage,
    },

    {
      path: '/competition/full-view/:cmId/:ringId',
      name: 'FullViewRingPage',
      component: FullViewRingPage,
    },

    {
      path: '/competition/team-fights/:cmId',
      name: 'TeamFightsPage',
      component: TeamFightsPage,
      meta: {
        requiresAuth: true,
      }
    },

    /*Competitions*/
    {
      path: '/competitions',
      name: 'competitionsPage',
      component: competitionsPage,
    },
    {
      path: '/competition/show/:cmId',
      name: 'competitionPage',
      component: competitionPage,
    },

    {
      path: '/competition/edit/:cmId',
      name: 'editCompetitionPage',
      component: editCompetitionPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    {
      path: '/competition/weighing/:cmId',
      name: 'WeighingQueuePage',
      component: WeighingQueuePage,
    },

    {
      path: '/competition/create',
      name: 'createCompetitionPage',
      component: createCompetitionPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },
    /*Results Views*/

    {
      path: '/competition/results/:cmId',
      name: 'ResultsPage',
      component: ResultsPage,
    },
    {
      path: '/competition/discipline/results/:cmId/:discipId',
      name: 'ResultsInDisciplinePage',
      component: ResultsPage,
    },
    {
      path: '/competition/age-category/results/:cmId/:ageCategId',
      name: 'ResultsInAgeCategoryPage',
      component: ResultsPage,
    },
    {
      path: '/competition/weight-category/results/:cmId/:weightCategId',
      name: 'ResultsInWeightCategoryPage',
      component: ResultsPage,
    },
    {
      path: '/competition/ring/results/:cmId/:ringId',
      name: 'ResultsInRingPage',
      component: ResultsPage,
    },

    /*All Teams Results Views*/

/*    {
      path: '/competition/team-results/:cmId',
      name: 'TeamResultsPage',
      component: TeamResultsPage,
      meta: {
        requiresAuth: true,
      }
    },*/

    /*Team Results Views*/

    {
      path: '/competition/team-report-results/:cmId/:teamId',
      name: 'teamReportResultsPage',
      component: teamReportResultsPage,
    },
    {
      path: '/competition/discipline/team-report-results/:cmId/:teamId/:discipId',
      name: 'teamReportResultsInDisciplinePage',
      component: teamReportResultsPage,
    },
    {
      path: '/competition/age-category/team-report-results/:cmId/:teamId/:ageCategId',
      name: 'teamReportResultsInAgeCategoryPage',
      component: teamReportResultsPage,

    },
    {
      path: '/competition/weight-category/team-report-results/:cmId/:teamId/:weightCategId',
      name: 'teamReportResultsInWeightCategoryPage',
      component: teamReportResultsPage,

    },
    {
      path: '/competition/ring/team-report-results/:cmId/:teamId/:ringId',
      name: 'teamReportResultsInRingPage',
      component: teamReportResultsPage,
     
    },

    /*Categories In Competition*/
    {
      path: '/competition/categories/:cmId',
      name: 'categoriesPage',
      component: categoriesPage,
    },

    {
      path: '/competition/categories/edit/:cmId',
      name: 'editCategoriesPage',
      component: editCategoriesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },


    {
      path: '/competition/categories/addweightcategory/:cmId',
      name: 'addWeightCategoryPage',
      component: addWeightCategoryPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },
    {
      path: '/competition/categories/addagecategory/:cmId',
      name: 'addAgeCategoryPage',
      component: addAgeCategoryPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    /*Rings in competition*/

    {
      path: '/competition/rings/:cmId',
      name: 'ringsInCompetitionPage',
      component: ringsInCompetitionPage,
    },
    {
      path: '/competition/ring/:cmId/:ringId',
      name: 'ringBattlePage',
      component: ringBattlesPage,
    },

    {
      path: '/competition/rings/addring/:cmId',
      name: 'addRingPage',
      component: addRingPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },
    {
      path: '/competition/rings/ring/edit/:CmRingId/:cmId',
      name: 'editRingPage',
      component: editRingPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },


    /*Claims and Lozesana*/

    {
      path: '/competition/lozesana/:cmId',
      name: 'lozesanasPage',
      component: lozesanasPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    {
      path: '/competition/fights-generation/:cmId/:categId',
      name: 'fightsGenerationPage',
      component: fightsGenerationPage,
      meta: {
        requiresAuth: true,
        isSecretary: true
      }
    },

    {
      path: '/competition/claims/:cmId',
      name: 'competitionClaimsPage',
      component: competitionClaimsPage,
      meta: {
/*        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
        isDoctor: true,*/
      }
    },
    {
      path: '/competition/new-apply/:cmId',
      name: 'applyParticipantPage',
      component: applyParticipantPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
        isDoctor: true,
      }
    },
    {
      path: '/competition/team-apply/:cmId',
      name: 'applyTeamPage',
      component: applyTeamPage,
     /* meta: {
        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
        isDoctor: true,
      }*/
    },
    {
      path: '/competition/apply/edit/:cmId/:teamId/:userId',
      name: 'editParticipantPage',
      component: editParticipantPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
        isDoctor: true/*,
        isCoach: true*/
      }
    },
    {
      path: '/competition/external-apply/show/:cmId/:unkuserId',
      name: 'createCmUserClaimPage',
      component:  createCmUserClaimPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    /*Data Pages*/

    /*dashboard*/

    {
      path: '/dashboard',
      name: 'dashboardPage',
      component: dashboardPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    {
      path: '/createuserclaime/:userId',
      name: 'CreateUserClaimPage',
      component: CreateUserClaimPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    /*Users*/

    {
      path: '/users',
      name: 'usersPage',
      component: usersPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    {
      path: '/users/user/edit/:userId',
      name: 'editUserPage',
      component: editUserPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/users/user/create',
      name: 'createUserPage',
      component: createUserPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/users/user/edit/password/:userId',
      name: 'editPasswordPage',
      component: editPasswordPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    {
      path: '/registration',
      name: 'registrationPage',
      component: registrationPage
    },

    /* Styles */

    {
      path: '/styles',
      name: 'stylesPage',
      component: stylesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/styles/style/create',
      name: 'createStylePage',
      component: createStylePage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/styles/style/edit/:styleId',
      name: 'editStylePage',
      component: editStylePage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    /*Disciplines*/

    {
      path: '/disciplines',
      name: 'disciplinesPage',
      component: disciplinesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/disciplines/discipline/create',
      name: 'createDisciplinePage',
      component: createDisciplinePage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/disciplines/discipline/edit/:discipId',
      name: 'editDisciplinePage',
      component: editDisciplinePage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    /*Teams*/

    {
      path: '/teams',
      name: 'teamsPage',
      component: teamsPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
      }
    },
    {
      path: '/teams/team/edit/:teamId',
      name: 'editTeamPage',
      component: editTeamPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
      }
    },
    {
      path: '/teams/team/create',
      name: 'createTeamPage',
      component: createTeamPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
        isCoach: true,
      }
    },

    /*Rings*/

    {
      path: '/rings',
      name: 'ringsPage',
      component: ringsPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },

    /*Categories*/

    {
      path: '/categories/age',
      name: 'ageCategoriesPage',
      component: ageCategoriesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/categories/age/create',
      name: 'createAgeCategoryPage',
      component: createAgeCategoryPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/categories/age/edit/:ageCategId',
      name: 'editAgeCategoryPage',
      component: editAgeCategoryPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },
    {
      path: '/categories/weight',
      name: 'weightCategoriesPage',
      component: weightCategoriesPage,
      meta: {
        requiresAuth: true,
        isSecretary: true,
      }
    },


  ]
})

router.beforeEach(Auth.authCheck)

export default router
