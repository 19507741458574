<template>
  <div class="vue-good-table">
    <div class="vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon"><div class="magnifying-glass"></div></span><input type="text" placeholder="Search Table" class="vgt-input vgt-pull-left" v-model="string" @keydown.enter="searchValue(string)">
      </div>
    </div>
    <div class="table-block">
      <table class="table table-bordered" style="font-size: 16px;">
        <thead>
        <tr>
          <th>Nr</th>
          <th>Vecuma kategorija</th>
          <th>Svara kategorija</th>
          <th>Limenis</th>
          <th>Komanda</th>
          <th class="side-color--red">Sarkanais stūris</th>
          <th class="side-color--red"></th>
          <th>Rezultats</th>
          <th class="side-color--blue"></th>
          <th class="side-color--blue">Zilais stūris</th>
          <th>Komanda</th>
          <th>Winner</th>
          <th>Laiks līdz sakšanai</th>
          <th>Darbibas</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(data,index) in BattlesData" v-if="!Search" v-bind:class="data.Color">
          <td>{{data.Nr}}</td>
          <td>{{data.AgeCategory}}</td>
          <td>{{data.WeightCategory}}</td>
          <td><span v-if="data.CompetitionPart>1">1/</span>{{data.CompetitionPart}}</td>
          <td>{{data.RedTeam}}</td>
          <td>{{ data.RedNameSurname ? data.RedNameSurname : data.RedComesFrom }}</td>
          <td class="side-color--red"></td>
          <td class="results-color">{{data.Result}}</td>
          <td class="side-color--blue"></td>
          <td>{{ data.BlueNameSurname ? data.BlueNameSurname : data.BlueComesFrom }}</td>
          <td>{{data.BlueTeam}}</td>
          <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
          <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
          <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
          <td>{{data.LeftTime}}</td>
          <td><button class="btn btn-primary" v-on:click="openBattle(data.Id)">Atvērt ciņu</button></td>
        </tr>
        <tr v-for="(data,index) in Fights" v-if="((Search)&&(index!=0))" v-bind:class="data.Color">
          <td>{{data.Nr}}</td>
          <td>{{data.AgeCategory}}</td>
          <td>{{data.WeightCategory}}</td>
          <td><span v-if="data.CompetitionPart>1">1/</span>{{data.CompetitionPart}}</td>
          <td>{{data.RedTeam}}</td>
          <td>{{ data.RedNameSurname ? data.RedNameSurname : data.RedComesFrom }}</td>
          <td class="side-color--red"></td>
          <td class="results-color">{{data.Result}}</td>
          <td class="side-color--blue"></td>
          <td>{{ data.BlueNameSurname ? data.BlueNameSurname : data.BlueComesFrom }}</td>
          <td>{{data.BlueTeam}}</td>
          <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
          <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
          <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
          <td>{{data.LeftTime}}</td>
          <td><button class="btn btn-primary" v-on:click="openBattle(data.Id)">Atvērt ciņu</button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePickerComponent from 'vuejs-datepicker';

  export default {
    name: 'BattlesOnRingComponent',
    props: ["BattlesData"],
    data(){
      return {
        Selected: {
          Id: 0,
          Row: "",
          UserId: 0,
          Value: {
            Id:  0,
            Title: '',
            Field: ''
          },
          type: ""
        },
        updatable: false,
        Teams: [],
        Color: '',
        string: "",
        Search: false,
        Fights: [],
      };
    },
    methods: {
      openBattle(q){
       /* <div v-if="$route.params.cmId===undefined && $store.state.authUser && $store.state.authUser.type === 1">*//*
        if(this.$store.state.authUser) {
          if (this.$store.state.authUser.type===3) {
            this.$router.push({name:'JudgeFight', params: { id: this.$store.state.authUser.id ,fightId: q}})
          }else{*/
            this.$router.push({ name: 'FightViewPage', params: { ringId: this.$route.params.ringId, fightId: q}})
       /*   }
        } else{
          this.$router.push({ name: 'FightViewPage', params: { ringId: this.$route.params.ringId, fightId: q}})
        }*/
      },
      searchValue: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;

          this.Fights=[{}];

          console.log("eto Battles Data: ",this.BattlesData)

          let SearchFightsData = this.BattlesData;

          console.log("eto Battles search Data: ",SearchFightsData)

          SearchFightsData.forEach((FightData) => {
            console.log("fight data: ",FightData)
            let HaveFight=false;
            let Found=false;

            this.Fights.forEach((Fight)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",FightData.Id, " & ", Fight.Id)
              if(FightData.Id==Fight.Id){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log("Fight param ",FightParam)
                FightParam=""+FightParam
                console.log("val:", val)
                let Search=FightParam.search(val)
                console.log("Search ",Search)
                if (Search!=-1) {
                  console.log("pushim: ", FightData)
                  return Found=true;
                }
                console.log("neto: ", FightData)
              })
              if (Found==true) {
                this.Fights.push(FightData)
                console.log(this.Fights)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.Fights)
        }
      }
    },
/*    watch: {
      string: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;

          this.Fights=[{}];

          console.log("eto Battles Data: ",this.BattlesData)

          let SearchFightsData = this.BattlesData;

          console.log("eto Battles search Data: ",SearchFightsData)

          SearchFightsData.forEach((FightData) => {
            console.log("fight data: ",FightData)
            let HaveFight=false;
            let Found=false;

            this.Fights.forEach((Fight)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",FightData.Id, " & ", Fight.Id)
              if(FightData.Id==Fight.Id){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log("Fight param ",FightParam)
                FightParam=""+FightParam
                console.log("val:", val)
                let Search=FightParam.search(val)
                console.log("Search ",Search)
                if (Search!=-1) {
                  console.log("pushim: ", FightData)
                    return Found=true;
                }
                console.log("neto: ", FightData)
              })
              if (Found==true) {
                this.Fights.push(FightData)
                console.log(this.Fights)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.Fights)
        }
      }
    },*/
    mounted() {
   /*   this.$axios.get(`/api/get-teams/short-data`, {
      })
          .then(response => {
            this.Teams = response.data.Teams;
          })
          .catch(e => {
            this.errors.push(e)
          })*/
    },
    components: {
      DatePickerComponent, "multiselect": Multiselect
    },
  };
</script>

<style>
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;}
/*  .red-background {
    height: 9rem;
    background-color: #FB8585;

  }

  .green-background {
    height: 9rem;
    background-color: #88ff7b;
  }*/
  .table-block {
    overflow: auto;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>