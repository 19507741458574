<template>
  <div class="page" style="height: 50rem; background-color: #838383;">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Vadības panelis</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data" >
        <div class="page__content__data__interactive-menu">

          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateDataTab, leaveactive : animateMainTab, displayshow: showDataTab, displayhide: showMainTab}">
            <h2 class="page__content__data__interactive-menu__item--label">SACENSĪBAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/competitions"><img src="/static/competition.jpg" class="page__content__data__interactive-menu__item__content--img"></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateDataTab, leaveactive : animateMainTab, displayshow: showDataTab, displayhide: showMainTab}">
            <h2 class="page__content__data__interactive-menu__item--label">LIETOTAJI</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/users"><img src="/static/user.jpg" class="page__content__data__interactive-menu__item__content--img" ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateDataTab, leaveactive : animateMainTab, displayshow: showDataTab, displayhide: showMainTab}">
            <h2 class="page__content__data__interactive-menu__item--label">KOMANDAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/teams"><img src="/static/team.png" class="page__content__data__interactive-menu__item__content--img" ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateDataTab, leaveactive : animateMainTab, displayshow: showDataTab, displayhide: showMainTab}">
            <h2 class="page__content__data__interactive-menu__item--label">DATI SACENSĪBAM</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a v-on:click="showMainDataTabs()"><img src="/static/data.jpg" class="page__content__data__interactive-menu__item__content--img"
                                                      ></a>
            </div>
          </div>

          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateMainTab,  leaveactive : animateDataTab, displayshow: showMainTab, displayhide: showDataTab}">
            <h2 class="page__content__data__interactive-menu__item--label">ATPAKAĻ</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a v-on:click="showMainDataTabs()"><img src="/static/back.jpg" class="page__content__data__interactive-menu__item__content--img"
                                                      ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateMainTab,  leaveactive : animateDataTab, displayshow: showMainTab, displayhide: showDataTab}">
            <h2 class="page__content__data__interactive-menu__item--label">DISCIPLINAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/disciplines"><img src="/static/disciplines.jpg" class="page__content__data__interactive-menu__item__content--img"
                                          ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateMainTab,  leaveactive : animateDataTab, displayshow: showMainTab, displayhide: showDataTab}">
            <h2 class="page__content__data__interactive-menu__item--label">RINGI</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/rings"><img src="/static/ring.svg" class="page__content__data__interactive-menu__item__content--img"></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : animateMainTab,  leaveactive : animateDataTab, displayshow: showMainTab, displayhide: showDataTab}">
            <h2 class="page__content__data__interactive-menu__item--label">KATOGORIJAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a v-on:click="showDataCategoriesTabs()"><img src="/static/categories.png" class="page__content__data__interactive-menu__item__content--img"
                                                            ></a>
            </div>
          </div>

          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : !animateDataTab,  leaveactive : animateCategoriesTab, displayshow: !showDataTab, displayhide: showCategoriesTab}">
            <h2 class="page__content__data__interactive-menu__item--label">ATPAKAĻ</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a v-on:click="showDataCategoriesTabs()"><img src="/static/back.jpg" class="page__content__data__interactive-menu__item__content--img"
                                                            ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : !animateDataTab,  leaveactive : animateCategoriesTab, displayshow: !showDataTab, displayhide: showCategoriesTab}">
            <h2 class="page__content__data__interactive-menu__item--label">VECUMU KATOGORIJAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/categories/age"><img src="/static/age.jpg" class="page__content__data__interactive-menu__item__content--img" ></a>
            </div>
          </div>
          <div class="page__content__data__interactive-menu__item"
               v-bind:class="{enteractive : !animateDataTab,  leaveactive : animateCategoriesTab, displayshow: !showDataTab, displayhide: showCategoriesTab}">
            <h2 class="page__content__data__interactive-menu__item--label">SVARU KATEGORIJAS</h2>
            <div class="page__content__data__interactive-menu__item__content">
              <a href="/categories/weight"><img src="/static/weight.jpg" class="page__content__data__interactive-menu__item__content--img"
                                                ></a>
            </div>
          </div>

        </div>
        <div class="page__content__data-container">
          <div class="page__content__data-container__header">
            <div class="page__content__data-container__header__info-container">
              <p class="page__content__data-container__header__info-container--item"> Lietotaju pieteikumi </p>
            </div>
          </div>
          <div class="page__content__data-container__sub-content">
            <div class="list-grid">
              <div class="list-grid--header">
                Pieteikumi lietotaju izveidošanai
              </div>
              <div class="list-grid__item">
                <div class="list-grid__item__table">
                  <div class="list-grid__item__table__cell" v-for="(CreateUserClaim,index) in CreateUsersClaims" > <!--v-if="index<5">-->
                    <div class="list-grid__item__table__cell--text" v-on:click="openCreateUserClaim(CreateUserClaim.Id)">
                      <b> {{CreateUserClaim.Name}} {{CreateUserClaim.Surname}} </b> <br> {{CreateUserClaim.Email}}
                    </div>
                    <div class="list-grid__item__table__cell__btn-container">
                      <div class="list-grid__item__table__cell__btn-container--accept-btn" v-on:click="confirmUser(CreateUserClaim.Id)">
                        Pieteikt
                      </div>
                      <div class="list-grid__item__table__cell__btn-container--reject-btn" v-on:click="unconfirmUser(CreateUserClaim.Id)">
                        Atteikt
                      </div>
                    </div>
                  </div>
                  <div class="list-grid__item__table__empty-table-msg" v-if="CreateUsersClaims.length===0">
                    <div class="">
                      Pieteikumu nav
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-grid">
              <div class="list-grid--header">
                Pieteikumi sacensibam
              </div>
              <div class="list-grid__item">
                <div class="list-grid__item__table">
                  <div class="list-grid__item__table__cell" v-for="(CompetitionUserClaim,index) in CompetitionUsersClaims" >
                  <div class="list-grid__item__table__cell--text" v-on:click="openCompetitionUserClaim(CompetitionUserClaim.CompetitionId,CompetitionUserClaim.Id)">
                      <b> {{CompetitionUserClaim.Name}} {{CompetitionUserClaim.Surname}} </b> <br>
                      {{CompetitionUserClaim.CompetitionTitle}} <br>
                      pieteikts: {{CompetitionUserClaim.ApplyDate}}
                    </div>
                    <div class="list-grid__item__table__cell__btn-container">
                      <div class="list-grid__item__table__cell__btn-container--accept-btn" v-on:click="confirmCmUser(CompetitionUserClaim.Id)">
                        Pieteikt
                      </div>
                      <div class="list-grid__item__table__cell__btn-container--reject-btn" v-on:click="unconfirmCmUser(CompetitionUserClaim.Id)">
                        Atteikt
                      </div>
                    </div>
                  </div>
                  <div class="list-grid__item__table__empty-table-msg" v-if="CompetitionUsersClaims.length===0">
                    <div class="">
                      Pieteikumu nav
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuComponent from "../../components/common/MenuComponent";
  /*this.$router.push('/competition/apply/edit/'+this.$route.params.cmId+'/' + q)*/
  export default {
    name: 'dashboardPage',
    data() {
      return {
        show: false,
        animateMainTab: false,
        animateDataTab: true,
        animateCategoriesTab: true,
        showMainTab: false,
        showDataTab: true,
        showCategoriesTab: true,
        CompetitionTitle: null,
        CreateUsersClaims: [],
        CompetitionUsersClaims: [],
      }
    },
    methods: {
      /*ShowUserClaimModal(){
        this.UserClaimId=
        this.$modal.show('UserClaimModal');
      },*/
      displayMainDataTabs() {
        this.showMainTab = !this.showMainTab
        this.showDataTab = !this.showDataTab
      },
      showMainDataTabs() {
        this.animateMainTab = !this.animateMainTab
        this.animateDataTab = !this.animateDataTab
        setTimeout(() => {
          this.displayMainDataTabs()
        }, 300);
        /*  this.animateCategoriesTab = !this.animateCategoriesTab*/
      },
      displayDataCategoriesTabs() {
        this.showDataTab = !this.showDataTab
        this.showCategoriesTab = !this.showCategoriesTab
      },
      showDataCategoriesTabs() {
        this.animateDataTab = !this.animateDataTab
        this.animateCategoriesTab = !this.animateCategoriesTab
        setTimeout(() => {
          this.displayDataCategoriesTabs()
        }, 300);
      },
      getCreateUsersClaims() {
        this.$axios.get(`/api/create-users-claims` )
            .then(response => {
              this.CreateUsersClaims = response.data.CreateUsersData;
              /*this.UserTypes.forEach((Type)=>{
                if (Type.Id===this.CreateUsersClaims.UserTypeId){
                  this.CreateUsersClaims.UserType=Type.Type
                }
              })*/
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      getCompetitionUsersClaims() {
        this.$axios.get(`/api/competition-users-claims` )
            .then(response => {
              this.CompetitionUsersClaims = response.data.CompetitionUsersData;
              /*this.UserTypes.forEach((Type)=>{
                if (Type.Id===this.CreateUsersClaims.UserTypeId){
                  this.CreateUsersClaims.UserType=Type.Type
                }
              })*/
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      getData(){
        this.getCreateUsersClaims();
        this.getCompetitionUsersClaims();
      },
      openCreateUserClaim(Id){
        this.$router.push({name: 'CreateUserClaimPage', params: {userId: Id}})
      },
      openCompetitionUserClaim(CompetitionId,ClaimId){
        this.$router.push('/competition/apply/edit/'+CompetitionId+'/' + ClaimId)
      },
      confirmUser(Id){
        this.$socket.emit('confirm-user', {Id: Id});
      },
      unconfirmUser(Id){
        this.$socket.emit('unconfirm-user', {Id: Id});
      },
      confirmCmUser(Id){
        this.$socket.emit('confirm-fighter', {Id: Id});
      },
      unconfirmCmUser(Id){
        this.$socket.emit('unconfirm-fighter', {Id: Id});
      }
    },
    mounted(){
      this.getData();
      this.$socket.on('refresh-create-users-claims', response => {
          this.getCreateUsersClaims();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getCompetitionUsersClaims();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-create-users-claims');
      this.$socket.removeListener('refresh-cmusers-data');
    },
    components: {
      MenuComponent,
    }
  }
</script>

<style lang="scss" scoped>

/*
  .page__content {
    &__data-container {
      &__header {
        display: flex;
        margin: 5rem 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #212329;
        color: white;

        &__info-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;

          &--item {
            text-align: center;
            font-weight: bold;
            padding: 2rem 5rem 0 5rem;
            !*padding: 3rem 3rem 3rem 3rem;*!
          }
        }
      }

      &__sub-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }
*/


  .active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    opacity: 3;

  }

  .displayshow {
    display: block;
  }

  .leaveactive {
    transition: all .3s ease;
    opacity: 0;

  }

  .displayhide {
    display: none;
  }

</style>

