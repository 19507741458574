<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="TeamsData"
      @on-cell-click="onCellClick"
      :search-options="{
          enabled: true,
          trigger: 'enter'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Action'">
          <button class="btn btn-primary" v-on:click="editTeam(props.row.Id)">Labot</button>
          <button class="btn btn-danger" v-on:click="deleteTeam(props.row.Id)">Delete</button>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "TeamsTableComponent",
    props: ["TeamsData"],
    data(){
      return {
        columns: [
          {
            label: 'Nosaukums',
            field: 'Title',
          },
          {
            label: 'Talrunis',
            field: 'Phone',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Adrese',
            field: 'Address',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'e-paste',
            field: 'Email',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Darbibas',
            field: 'Action',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        TeamId: null,
      };
    },
    methods: {
      onCellClick(params) {
      /*  if (params.column.field=='Action') {
        this.TeamId = params.row.Id;
        this.$router.push('/admin/team/edit/' + this.TeamId)*/
      },
      editTeam(q) {
          this.$router.push('/teams/team/edit/' + q)
      },
      deleteTeam(q) {
          this.$socket.emit('delete-team', {teamId: q})

      },
    },
    mounted() {
    },
    components: {
    },
  };
</script>