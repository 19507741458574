var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", staticStyle: { padding: "0" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content", staticStyle: { padding: "0" } }, [
      _c(
        "div",
        {
          staticClass: "page__content__input",
          staticStyle: { "padding-top": "3rem" }
        },
        [
          _c("div", { staticClass: "page__content__input__form" }, [
            _c(
              "div",
              {
                staticClass: "page__content__input__form__item",
                staticStyle: { padding: "0" }
              },
              [
                _c("multiselect", {
                  staticStyle: { "padding-bottom": "4rem" },
                  attrs: {
                    options: _vm.AllTeams,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "open-direction": "below",
                    "options-direction": _vm.maxHeight,
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.TeamLabel
                  },
                  model: {
                    value: _vm.Team,
                    callback: function($$v) {
                      _vm.Team = $$v
                    },
                    expression: "Team"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page__content__input__form__item--link" },
              [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.ShowCreateTeamModal }
                  },
                  [_vm._v("Izveidot komandu")]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "page__content__input__form__btn-item" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary page__content__input__form__btn-item--btn",
                  on: { click: _vm.ShowApplyTeamModal }
                },
                [_vm._v("Tālāk")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "page__content__input__form__btn-item--btn",
                  on: {
                    click: function($event) {
                      _vm.$modal.hide("TeamSelectModal")
                    }
                  }
                },
                [_vm._v("\n            ❌ Aizvert\n          ")]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Izveleties komandu")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4da3c754", { render: render, staticRenderFns: staticRenderFns })
  }
}