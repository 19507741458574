<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="RingsData"
      @on-cell-click="onCellClick"
      :search-options="{
          enabled: true,
          trigger: 'enter'
      }"
      :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="EditParams.Show">
          <div v-if="props.column.field==SelectedColumn">
            <div v-if="props.row.Id==EditParams.Id">
              <div v-if="SelectedColumn=='RingTitle'">
                <div v-if="addblock">
                  <input v-model="EditParams.CellValue" v-on:keyup.enter="submit">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="props.column.field=='Actions'">
          <button class="btn btn-danger" v-on:click="deleteRing(props.row.Id)">Delete</button>
        </div>
        <span v-else>
              {{props.formattedRow[props.column.field]}}
          </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  export default {
    name: "RingsGridComponent",
    props: ["RingsData"],
    data(){
      return {
        columns: [
          {
            label: 'Nosaukums',
            field: 'RingTitle',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Darbibas',
            field: 'Actions',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        SelectedColumn: null,
        EditParams:{
          Show: false,
          Id: null,
          CellValue: null,
        },
        ColumnLabel: null,
        Teams: [],
        DateOfBirth: null,
        EditData: {
          Id: null,
          OldValue: null,
          NewValue: null,
          Update: false
        },
        addblock: true,
        updblock: false,
        RingId: null,
      }
    },
    methods: {
      onCellClick(params){
        if(this.SelectedColumn !== params.column.field) {
          console.log("params.row: ", params.row);
          /*console.log("params.column: ",params.column.field);*/
          console.log("Thats truth! Thats: ", params.row.Team);
          console.log("Vecums should: ", params.column.label);
          this.ColumnLabel = params.column.label;
          this.SelectedColumn = params.column.field;
          this.EditParams = {
            Show: true,
            Id: params.row.ID,
            CellValue: params.row[this.SelectedColumn],
          };
          this.EditData.Id = this.EditParams.Id;
          console.log("Edit Data: ", this.EditData);
          console.log("Cell Value: ", params.row[this.SelectedColumn]);
          console.log("EditParams: ", this.EditParams);

          console.log("Vecums should right: ", this.ColumnLabel);
        }
      },
      submit(){
          this.EditParams.Show = false;
          this.EditData.Id = this.EditParams.Id;
          this.EditData.NewValue = this.EditParams.CellValue;

        },
      deleteRing(q) {
          this.$socket.emit('delete-ring', {ringId: q} );
        /*this.$axios.post(`/api/rings/ring/delete`,{
          ringId: q,
        })
          .then(response => {
          })
          .catch(e => {
            this.errors.push(e)
          })*/
      },
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>