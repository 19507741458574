var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { staticClass: "timer" }, [
        _c(
          "div",
          {
            staticClass: "circle-container",
            class: [_vm.data.round_status === 1 ? "fight-going" : ""]
          },
          [
            _c("div", { staticClass: "outer-ring" }),
            _vm._v(" "),
            _c("div", { staticClass: "ring" }, [
              _c("div", { staticClass: "front" }, [
                _c("div", { staticClass: "timeout" }, [
                  _vm._v("\n          " + _vm._s(_vm.message) + "\n          "),
                  _c("div", { staticClass: "timeout-time" }, [
                    _vm._v(_vm._s(_vm.timeout))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "back" }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.data.round_nr ? _vm.data.round_nr : ""))
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.time) + " ")])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4458ccd2", { render: render, staticRenderFns: staticRenderFns })
  }
}