var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__data" }, [
        _c(
          "div",
          { staticClass: "page__content__data__categories" },
          _vm._l(_vm.Disciplines, function(Discipline, key, index) {
            return _c(
              "div",
              { staticClass: "page__content__data__categories__item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__data__categories__item--label"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "black" },
                        attrs: {
                          href:
                            "/competition/category/discipline/" +
                            _vm.$route.params.cmId +
                            "/" +
                            Discipline.CmDisciplineId
                        }
                      },
                      [_vm._v(_vm._s(Discipline.DisciplineTitle))]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data__categories__item__content"
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-bordered page__content__data__categories__item__content__table"
                      },
                      [
                        _c(
                          "tbody",
                          {
                            staticStyle: {
                              width: "100%",
                              background: "#eeecf6"
                            }
                          },
                          _vm._l(Discipline, function(AgeCategory, key, index) {
                            return index > 0
                              ? _c(
                                  "tr",
                                  {
                                    staticClass:
                                      "page__content__data__categories__item__content__table--row"
                                  },
                                  [
                                    index > 2
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "page__content__data__categories__item__content__table__cell--title"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/competition/category/age/" +
                                                    _vm.$route.params.cmId +
                                                    "/" +
                                                    Discipline.CmDisciplineId +
                                                    "/" +
                                                    AgeCategory.AgeCategoryId
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    AgeCategory.AgeCategoryTitle
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(AgeCategory, function(
                                      WeightCategory,
                                      key,
                                      index
                                    ) {
                                      return index > 2
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "page__content__data__categories__item__content__table__cell--data"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/competition/category/weight/" +
                                                      _vm.$route.params.cmId +
                                                      "/" +
                                                      Discipline.CmDisciplineId +
                                                      "/" +
                                                      AgeCategory.AgeCategoryId +
                                                      "/" +
                                                      WeightCategory.WeightCategoryId
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        WeightCategory.WeightCategoryTitle
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        WeightCategory.BattlesCount
                                                      ) +
                                                      " cīņas)\n                      "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        WeightCategory.FightersCount
                                                      ) +
                                                      " cīnītāji(-js))\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          })
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          })
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Kategorijas")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-286fb813", { render: render, staticRenderFns: staticRenderFns })
  }
}