<template>
  <div class="table-component">
    <div class="vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon"><div class="magnifying-glass"></div></span><input type="text" placeholder="Search Table" class="vgt-input vgt-pull-left"  v-model="string" @keydown.enter="searchValue(string)">
      </div>
    </div>
    <div class="table-component__container">
      <table class="table table-bordered" style="font-size: 16px;">
        <thead>
        <tr>
          <th>Vieta</th>
          <th>Nosaukums</th>
          <th>Zelts</th>
          <th>Sudrabs</th>
          <th>Bronza</th>
          <th>Pieteiktie - apstiprinātie ( kopā )</th>
          <th>Samaksats</th>
          <th>Nesamaksats</th>
          <th>Treneris</th>
          <th>E-pasts</th>
          <th>Telefons</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in ResultsData" v-if="!Search">
          <td>{{data.Place}}</td>
          <td>
            <router-link v-if="DataForRouter.ringId" :to="{ name: 'teamReportResultsInRingPage', params: {cmId: $route.params.cmId, teamId: data.Id, ringId: DataForRouter.ringId}}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.discipId && !DataForRouter.ageCategId && !DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInDisciplinePage', params: {cmId: $route.params.cmId, teamId: data.Id, discipId: DataForRouter.discipId}}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.ageCategId && !DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, teamId: data.Id, ageCategId: DataForRouter.ageCategId }}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, teamId: data.Id, weightCategId: DataForRouter.weightCategId}}">{{data.Title}}</router-link>
            <router-link v-else :to="{ name: 'teamReportResultsPage', params: {cmId: $route.params.cmId, teamId: data.Id}}">{{data.Title}}</router-link>
          </td>
          <td>{{data.Gold}}</td>
          <td>{{data.Silver}}</td>
          <td>{{data.Bronze}}</td>
          <td>{{data.Approved}}/({{data.AllCmUsers}})</td>
          <td>{{data.Payed}}</td>
          <td>{{data.NotPayed}}</td>
          <td>{{data.Coach}}</td>
          <td>{{data.Email}}</td>
          <td>{{data.Phone}}</td>
        </tr>
        <tr v-for="(data,index) in ResultsSearch" v-if="((Search)&&(index!=0))">
          <td>{{data.Place}}</td>
          <td>
            <router-link v-if="DataForRouter.ringId" :to="{ name: 'teamReportResultsInRingPage', params: {cmId: $route.params.cmId, teamId: data.Id, ringId: DataForRouter.ringId}}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.discipId && !DataForRouter.ageCategId && !DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInDisciplinePage', params: {cmId: $route.params.cmId, teamId: data.Id, discipId: DataForRouter.discipId}}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.ageCategId && !DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInAgeCategoryPage', params: {cmId: $route.params.cmId, teamId: data.Id, ageCategId: DataForRouter.ageCategId }}">{{data.Title}}</router-link>
            <router-link v-else-if="DataForRouter.weightCategId" :to="{ name: 'teamReportResultsInWeightCategoryPage', params: {cmId: $route.params.cmId, teamId: data.Id, weightCategId: DataForRouter.weightCategId}}">{{data.Title}}</router-link>
            <router-link v-else :to="{ name: 'teamReportResultsPage', params: {cmId: $route.params.cmId, teamId: data.Id}}">{{data.Title}}</router-link>
          </td>
          <td>{{data.Gold}}</td>
          <td>{{data.Silver}}</td>
          <td>{{data.Bronze}}</td>
          <td>{{data.Approved}}/({{data.AllCmUsers}})</td>
          <td>{{data.Payed}}</td>
          <td>{{data.NotPayed}}</td>
          <td>{{data.Coach}}</td>
          <td>{{data.Email}}</td>
          <td>{{data.Phone}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'ResultsTableComponent',
    props: ['ResultsData', 'DataForRouter'],
    data(){
      return {
        string: "",
        ResultsSearch: {}
      };
    },
    methods: {
      searchValue: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;
          this.ResultsSearch=[{}];
          let ResultsSearchData = this.ResultsData;
          ResultsSearchData.forEach((ResultSearchData) => {
            let HaveResults=false;
            let Found=false;
            this.ResultsSearch.forEach((ResultSearch)=>{
              if(ResultSearchData.Id==ResultSearch.Id){
                return HaveResults=true;
              }
            })
            if (HaveResults==false) {
              Object.keys(ResultSearchData).forEach((key) => {
                let ResultsParam = ResultSearchData[key];
                ResultsParam=""+ResultsParam
                let Search=ResultsParam.search(val)
                if (Search!=-1) {
                  return Found=true;
                }
              })
              if (Found==true) {
                this.ResultsSearch.push(ResultSearchData)
              }
            }
          })
        } else {
          this.Search=false;
        }
      }
    },
    /*watch: {
      string: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;
          this.ResultsSearch=[{}];
          let ResultsSearchData = this.ResultsData;
          ResultsSearchData.forEach((ResultSearchData) => {
            let HaveResults=false;
            let Found=false;
            this.ResultsSearch.forEach((ResultSearch)=>{
              if(ResultSearchData.Id==ResultSearch.Id){
                return HaveResults=true;
              }
            })
            if (HaveResults==false) {
              Object.keys(ResultSearchData).forEach((key) => {
                let ResultsParam = ResultSearchData[key];
                ResultsParam=""+ResultsParam
                let Search=ResultsParam.search(val)
                if (Search!=-1) {
                  return Found=true;
                }
              })
              if (Found==true) {
                this.ResultsSearch.push(ResultSearchData)
              }
            }
          })
        } else {
          this.Search=false;
        }
      }
    },*/
    mounted() {
      this.$axios.get(`/api/get-teams/short-data`, {
      })
          .then(response => {
            this.Teams = response.data.Teams;
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    components: {
    },
  };
</script>

<style lang="scss">
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;}

</style>