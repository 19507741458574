var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "home-page-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "home-page-container__content" },
        [
          _c(
            "router-link",
            {
              staticClass: "home-page-container__content__item",
              attrs: { to: { name: "competitionsPage" } }
            },
            [
              _c(
                "div",
                { staticClass: "home-page-container__content__item--img" },
                [
                  _c("img", {
                    attrs: {
                      src: "static/competitions.png",
                      height: "100%",
                      width: "100%"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "home-page-container__content__item--label"
                    },
                    [_vm._v("\n            COMPETITIONS\n          ")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "home-page-container__content__item",
              attrs: { to: { name: "Competitions" } }
            },
            [
              _c(
                "div",
                { staticClass: "home-page-container__content__item--img" },
                [
                  _c("img", {
                    attrs: {
                      src: "static/stream.jpg",
                      height: "100%",
                      width: "100%"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "home-page-container__content__item--label"
                    },
                    [_vm._v("\n            STREAMS\n          ")]
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home-page-container__head-container" }, [
      _c("div", { staticClass: "home-page-container__head-container__item" }, [
        _c(
          "div",
          { staticClass: "home-page-container__head-container__item--img" },
          [
            _c("img", {
              attrs: { src: "static/tmp.png", height: "100%", width: "100%" }
            })
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home-page-container__footer-container" }, [
      _c(
        "div",
        { staticClass: "home-page-container__footer-container__item" },
        [
          _c(
            "div",
            { staticClass: "home-page-container__footer-container__item--img" },
            [
              _c("img", {
                attrs: { src: "static/tmp.png", height: "100%", width: "100%" }
              })
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-08618083", { render: render, staticRenderFns: staticRenderFns })
  }
}