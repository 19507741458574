var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "router-link",
            { staticClass: "logo", attrs: { to: { name: "homePage" } } },
            [_vm._v("COMPETITION MANAGER")]
          ),
          _vm._v(" "),
          _c("nav", { staticClass: "nav" }, [
            _c("ul", [
              _c(
                "li",
                { staticClass: "active" },
                [
                  !_vm.$store.state.authUser ||
                  (_vm.$store.state.authUser.type !== 1 &&
                    _vm.$store.state.authUser.type !== 3 &&
                    _vm.$store.state.authUser.type !== 4)
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: "competitionsPage" } } },
                        [_vm._v("Sacensības")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  _vm.$store.state.authUser.type === 3
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Judge",
                              params: { id: _vm.$store.state.authUser.id }
                            }
                          }
                        },
                        [_vm._v("Judge")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  _vm.$store.state.authUser.type === 4
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "CoachCompetitionsPage",
                              params: { id: _vm.$store.state.authUser.id }
                            }
                          }
                        },
                        [_vm._v("Coach")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  _vm.$store.state.authUser.type === 1
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "SecretaryCompetitionsPage",
                              params: { id: _vm.$store.state.authUser.id }
                            }
                          }
                        },
                        [_vm._v("Secretary")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  !_vm.$store.state.authUser
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: "Competitions" } } },
                        [_vm._v("Streams")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  (_vm.$store.state.authUser.type != 1 &&
                    _vm.$store.state.authUser.type != 2)
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: "Competitions" } } },
                        [_vm._v("Streams")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  (_vm.$store.state.authUser.type === 1 ||
                    _vm.$store.state.authUser.type === 2)
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: "dashboardPage" } } },
                        [_vm._v("Dashboard")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              !_vm.$store.state.authUser
                ? _c(
                    "a",
                    {
                      staticClass: "sign-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.openLogin()
                        }
                      }
                    },
                    [_vm._v("Login")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.state.authUser
                ? _c(
                    "a",
                    {
                      staticClass: "sign-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.logout()
                        }
                      }
                    },
                    [_vm._v("Logout")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.state.authUser
                ? _c(
                    "router-link",
                    {
                      staticClass: "sign-link",
                      attrs: { to: { name: "profilePage" } }
                    },
                    [_vm._v("Profile ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            { attrs: { name: "login", height: "500px" } },
            [_c("LoginComponent")],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c94d4da", { render: render, staticRenderFns: staticRenderFns })
  }
}