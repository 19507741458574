var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-good-table" }, [
    _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
      _c("div", { staticClass: "vgt-global-search__input vgt-pull-left" }, [
        _vm._m(0),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.string,
              expression: "string"
            }
          ],
          staticClass: "vgt-input vgt-pull-left",
          attrs: { type: "text", placeholder: "Search Table" },
          domProps: { value: _vm.string },
          on: {
            keydown: function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.searchValue(_vm.string)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.string = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass: "table table-bordered",
        staticStyle: { "font-size": "16px" }
      },
      [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "draggble-item",
            attrs: { element: "tbody", move: _vm.getMoveParams },
            on: { update: _vm.ChangeBattleNr },
            model: {
              value: _vm.BattlesData,
              callback: function($$v) {
                _vm.BattlesData = $$v
              },
              expression: "BattlesData"
            }
          },
          [
            _vm._l(_vm.BattlesData, function(data, index) {
              return !_vm.Search
                ? _c("tr", { key: data.Nr, class: data.Color }, [
                    _c("td", [_vm._v(_vm._s(data.Nr))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.AgeCategory))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.WeightCategory))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.CompetitionPart))]),
                    _vm._v(" "),
                    !(
                      data.Id == _vm.Selected.Id &&
                      _vm.Selected.Row === "RedTeam"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "RedTeam")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.RedTeam) + "   "),
                            data.RedCmUserId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id == _vm.Selected.Id && _vm.Selected.Row === "RedTeam"
                      ? _c("td", [
                          !_vm.updatable
                            ? _c(
                                "div",
                                [
                                  _c("label", [_vm._v("Mainit: ")]),
                                  _vm._v(" "),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.Teams,
                                      "close-on-select": true,
                                      "clear-on-select": false,
                                      "open-direction": "below",
                                      placeholder: "",
                                      "track-by": "Id",
                                      "custom-label": _vm.TeamLabel
                                    },
                                    model: {
                                      value: _vm.Selected.Value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.Selected, "Value", $$v)
                                      },
                                      expression: "Selected.Value"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-ok",
                                    on: {
                                      click: function($event) {
                                        _vm.updateData(_vm.Selected)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-pencil",
                                    on: {
                                      click: function($event) {
                                        _vm.updatable = !_vm.updatable
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.updatable
                            ? _c("div", [
                                _c("label", [_vm._v("Atjaunot: ")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.Selected.Value.Title,
                                      expression: "Selected.Value.Title"
                                    }
                                  ],
                                  domProps: { value: _vm.Selected.Value.Title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.Selected.Value,
                                        "Title",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-ok",
                                  on: {
                                    click: function($event) {
                                      _vm.editData(_vm.Selected)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-pencil",
                                  on: {
                                    click: function($event) {
                                      _vm.updatable = !_vm.updatable
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !(
                      data.Id == _vm.Selected.Id &&
                      _vm.Selected.Row == "RedNameSurname"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "RedNameSurname")
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                data.RedNameSurname
                                  ? data.RedNameSurname
                                  : data.RedComesFrom
                              ) + "   "
                            ),
                            data.RedId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id == _vm.Selected.Id &&
                    _vm.Selected.Row == "RedNameSurname"
                      ? _c("td", [
                          _c("label", [_vm._v("Mainit: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Field,
                                expression: "Selected.Value.Field"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Field },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.updateData(_vm.Selected)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Field",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", { staticClass: "side-color--red" }),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "results-color",
                        on: {
                          click: function($event) {
                            _vm.ShowSelectWinnerModal(data)
                          }
                        }
                      },
                      [_vm._v(_vm._s(data.Result))]
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "side-color--blue" }),
                    _vm._v(" "),
                    !(
                      data.Id === _vm.Selected.Id &&
                      _vm.Selected.Row === "BlueNameSurname"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "BlueNameSurname")
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                data.BlueNameSurname
                                  ? data.BlueNameSurname
                                  : data.BlueComesFrom
                              ) + "    "
                            ),
                            data.BlueId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id === _vm.Selected.Id &&
                    _vm.Selected.Row === "BlueNameSurname"
                      ? _c("td", [
                          _c("label", [_vm._v("Mainit: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Field,
                                expression: "Selected.Value.Field"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Field },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.updateData(_vm.Selected)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Field",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !(
                      data.Id === _vm.Selected.Id &&
                      _vm.Selected.Row === "BlueTeam"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "BlueTeam")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.BlueTeam) + "   "),
                            data.BlueCmUserId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id === _vm.Selected.Id &&
                    _vm.Selected.Row === "BlueTeam"
                      ? _c("td", [
                          !_vm.updatable
                            ? _c(
                                "div",
                                [
                                  _c("label", [_vm._v("Mainit: ")]),
                                  _vm._v(" "),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.Teams,
                                      "close-on-select": true,
                                      "clear-on-select": false,
                                      "open-direction": "below",
                                      placeholder: "",
                                      "track-by": "Id",
                                      "custom-label": _vm.TeamLabel
                                    },
                                    model: {
                                      value: _vm.Selected.Value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.Selected, "Value", $$v)
                                      },
                                      expression: "Selected.Value"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-ok",
                                    on: {
                                      click: function($event) {
                                        _vm.updateData(_vm.Selected)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-pencil",
                                    on: {
                                      click: function($event) {
                                        _vm.updatable = !_vm.updatable
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.updatable
                            ? _c("div", [
                                _c("label", [_vm._v("Atjaunot: ")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.Selected.Value.Title,
                                      expression: "Selected.Value.Title"
                                    }
                                  ],
                                  domProps: { value: _vm.Selected.Value.Title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.Selected.Value,
                                        "Title",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-ok",
                                  on: {
                                    click: function($event) {
                                      _vm.editData(_vm.Selected)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-pencil",
                                  on: {
                                    click: function($event) {
                                      _vm.updatable = !_vm.updatable
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    data.WinnerId == data.RedCmUserId
                      ? _c("td", [_vm._v(_vm._s(data.RedNameSurname))])
                      : data.WinnerId == data.BlueCmUserId
                      ? _c("td", [_vm._v(_vm._s(data.BlueNameSurname))])
                      : data.WinnerId != data.RedCmUserId ||
                        data.WinnerId != data.BlueCmUserId
                      ? _c("td", [_vm._v("-")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.LeftTime))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              _vm.openBattle(data.Id)
                            }
                          }
                        },
                        [_vm._v("Atvērt ciņu")]
                      )
                    ])
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.Fights, function(data, index) {
              return _vm.Search && index != 0
                ? _c("tr", { class: data.Color }, [
                    _c("td", [_vm._v(_vm._s(data.Nr))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.AgeCategory))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.WeightCategory))]),
                    _vm._v(" "),
                    _c("td", [
                      data.CompetitionPart > 1
                        ? _c("span", [_vm._v("1/")])
                        : _vm._e(),
                      _vm._v(_vm._s(data.CompetitionPart))
                    ]),
                    _vm._v(" "),
                    !(
                      data.Id === _vm.Selected.Id &&
                      _vm.Selected.Row === "RedTeam"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "RedTeam")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.RedTeam) + "   "),
                            data.RedCmUserId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id === _vm.Selected.Id &&
                    _vm.Selected.Row === "RedTeam"
                      ? _c("td", [
                          !_vm.updatable
                            ? _c(
                                "div",
                                [
                                  _c("label", [_vm._v("Mainit: ")]),
                                  _vm._v(" "),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.Teams,
                                      "close-on-select": true,
                                      "clear-on-select": false,
                                      "open-direction": "below",
                                      placeholder: "",
                                      "track-by": "Id",
                                      "custom-label": _vm.TeamLabel
                                    },
                                    model: {
                                      value: _vm.Selected.Value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.Selected, "Value", $$v)
                                      },
                                      expression: "Selected.Value"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-ok",
                                    on: {
                                      click: function($event) {
                                        _vm.updateData(_vm.Selected)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-pencil",
                                    on: {
                                      click: function($event) {
                                        _vm.updatable = !_vm.updatable
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.updatable
                            ? _c("div", [
                                _c("label", [_vm._v("Atjaunot: ")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.Selected.Value.Title,
                                      expression: "Selected.Value.Title"
                                    }
                                  ],
                                  domProps: { value: _vm.Selected.Value.Title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.Selected.Value,
                                        "Title",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-ok",
                                  on: {
                                    click: function($event) {
                                      _vm.editData(_vm.Selected)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-pencil",
                                  on: {
                                    click: function($event) {
                                      _vm.updatable = !_vm.updatable
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !(
                      data.Id === _vm.Selected.Id &&
                      _vm.Selected.Row === "RedNameSurname"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "RedNameSurname")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.RedNameSurname) + "   "),
                            data.RedId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id === _vm.Selected.Id &&
                    _vm.Selected.Row === "RedNameSurname"
                      ? _c("td", [
                          _c("label", [_vm._v("Mainit: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Field,
                                expression: "Selected.Value.Field"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Field },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.updateData(_vm.Selected)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Field",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", { staticClass: "side-color--red" }),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "results-color",
                        on: {
                          click: function($event) {
                            _vm.ShowSelectWinnerModal(data)
                          }
                        }
                      },
                      [_vm._v(_vm._s(data.Result))]
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "side-color--blue" }),
                    _vm._v(" "),
                    !(
                      data.Id === _vm.Selected.Id &&
                      _vm.Selected.Row === "BlueNameSurname"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "BlueNameSurname")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.BlueNameSurname) + "   "),
                            data.BlueId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.Id === _vm.Selected.Id &&
                    _vm.Selected.Row === "BlueNameSurname"
                      ? _c("td", [
                          _c("label", [_vm._v("Mainit: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Field,
                                expression: "Selected.Value.Field"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Field },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.updateData(_vm.Selected)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Field",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !(
                      data.BlueId === _vm.Selected.Id &&
                      _vm.Selected.Row === "BlueTeam"
                    )
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                _vm.showEdit(data, "BlueTeam")
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(data.BlueTeam) + "   "),
                            data.BlueCmUserId
                              ? _c("span", {
                                  staticClass: "glyphicon glyphicon-pencil"
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    data.BlueId === _vm.Selected.Id &&
                    _vm.Selected.Row === "BlueTeam"
                      ? _c("td", [
                          !_vm.updatable
                            ? _c(
                                "div",
                                [
                                  _c("label", [_vm._v("Mainit: ")]),
                                  _vm._v(" "),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.Teams,
                                      "close-on-select": true,
                                      "clear-on-select": false,
                                      "open-direction": "below",
                                      placeholder: "",
                                      "track-by": "Id",
                                      "custom-label": _vm.TeamLabel
                                    },
                                    model: {
                                      value: _vm.Selected.Value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.Selected, "Value", $$v)
                                      },
                                      expression: "Selected.Value"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-ok",
                                    on: {
                                      click: function($event) {
                                        _vm.updateData(_vm.Selected)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    staticClass: "glyphicon glyphicon-pencil",
                                    on: {
                                      click: function($event) {
                                        _vm.updatable = !_vm.updatable
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.updatable
                            ? _c("div", [
                                _c("label", [_vm._v("Atjaunot: ")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.Selected.Value.Title,
                                      expression: "Selected.Value.Title"
                                    }
                                  ],
                                  domProps: { value: _vm.Selected.Value.Title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.Selected.Value,
                                        "Title",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-ok",
                                  on: {
                                    click: function($event) {
                                      _vm.editData(_vm.Selected)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "glyphicon glyphicon-pencil",
                                  on: {
                                    click: function($event) {
                                      _vm.updatable = !_vm.updatable
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    data.WinnerId == data.RedCmUserId
                      ? _c("td", [_vm._v(_vm._s(data.RedNameSurname))])
                      : data.WinnerId == data.BlueCmUserId
                      ? _c("td", [_vm._v(_vm._s(data.BlueNameSurname))])
                      : data.WinnerId != data.RedCmUserId ||
                        data.WinnerId != data.BlueCmUserId
                      ? _c("td", [_vm._v("-")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(data.LeftTime))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              _vm.openBattle(data.Id)
                            }
                          }
                        },
                        [_vm._v("Atvērt ciņu")]
                      )
                    ])
                  ])
                : _vm._e()
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input__icon" }, [
      _c("div", { staticClass: "magnifying-glass" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Vecuma kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Svara kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Limenis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }, [
          _vm._v("Sarkanais stūris")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }),
        _vm._v(" "),
        _c("th", [_vm._v("Rezultats")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }, [
          _vm._v("Zilais stūris")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", [_vm._v("Winner")]),
        _vm._v(" "),
        _c("th", [_vm._v("Laiks līdz sakšanai")]),
        _vm._v(" "),
        _c("th", [_vm._v("Darbibas")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d0e9bfc", { render: render, staticRenderFns: staticRenderFns })
  }
}