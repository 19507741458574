<template>
    <div>
        <vue-good-table
                :columns="columns"
                :rows="WeightCategoriesData"
                @on-cell-click="onCellClick"
                :search-options="{
                    enabled: true,
                    trigger: 'enter'
                }"
                :pagination-options="{
                    enabled: true,
                    perPage: 40,
                }"
                styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field=='Actions'">
                    <button class="btn btn-danger" v-on:click="deleteWeightCategory(props.row.Id)">Delete</button>
                </div>
                <span v-else>
              {{props.formattedRow[props.column.field]}}
          </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    export default {
        name: "WeightCategoriesGridComponent",
        props: ["WeightCategoriesData"],
        data(){
            return {
                columns: [
                    {
                        label: 'Maksimalais svars',
                        field: 'MaxWeight',
                        type: 'number',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        label: 'Darbības',
                        field: 'Actions',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ],
            }
        },
        methods: {
            deleteWeightCategory(q) {
                this.$socket.emit('delete-weight-category', {
                    WeightCategId: q,
                });
               /* this.$axios.post(`/api/categories/weight/delete`,{
                    WeightCategId: q,
                })
                    .then(response => {
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })*/
            },

        },
        components: {
        },
    };

</script>

<style lang="scss">
    .text-center{
        text-align:center;
    }
</style>