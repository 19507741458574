<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Komandu ciņas</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div v-if="TeamsRings" class="container" style="padding: 2rem;">
        <div class="list-group" v-if="ListShow" v-for="(TeamRings,key) in TeamsRings">
          <div class="list-group-item list-group-item-action team-title">
            <h2>{{key}}</h2>
          </div>
          <div v-if="index>0" class="list-group-item list-group-item-action ring-list-item" v-for="(Ring,index) in TeamRings"
               @click="showRingFights(Ring.Id, TeamRings[0])">
            <h4 >&ensp;{{Ring.Title}} Nr. {{ Ring.Nr }} </h4>
          </div>
        </div>
        <div v-if="!ListShow" class="fights-list">
          <span style="padding: 2rem;" @click="showList()"  class="fights-list--back-row"><img src="/static/backarrow.png" width="20rem"/> Atpakal pie sarakstam</span>
          <battles style="padding: 2rem;" :BattlesData="Battles" ></battles>
        </div>
      </div>
    <div  v-if="!TeamsRings" class="empty-message">
      <h2>Nav cīņas</h2>
    </div>
    </div>
  </div>
</template>

<script>

  import BattlesComponent from '../../components/public/RingsInCompetitionComponents/BattlesOnRingComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "TeamFightsPage",
    data () {
      return {
        show: false,
        CompetitionTitle: null,
        Battles: [],
        TeamTitle: "",
        TeamId: 0,
        ListShow: true,
        Rings: {},
        Showed: {},
        TeamsRings: {}
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/competition/team-fights-rings`, {
          params: {
            cmId: this.$route.params.cmId,
            userId: this.$store.state.authUser.id,
          }
        })
          .then(response => {
           /* this.TeamTitle = response.data.TeamData.TeamTitle;*/
          /*  this.TeamId = response.data.TeamData.TeamId;
            this.Rings = response.data.Rings;*/
            this.TeamsRings=response.data.TeamsRings;
           /* console.log(response.data)*/
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      showRingFights(ringId,teamId) {
        console.log(ringId);
        this.ListShow = false;
        this.$axios.get(`/api/competition/team-fights`, {
          params: {
            cmId: this.$route.params.cmId,
            teamId: teamId,
            ringId: ringId
          }
        })
            .then(response => {
              this.Battles = response.data.Battles;
              console.log(response.data)
            })
            .catch(e => {
              this.errors.push(e)
            })
        this.Showed={type: "ring", ringId: ringId}
      },
      showList() {
        this.ListShow = true;
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-battles-list', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-battles-list');

    },
    components: {
      'battles': BattlesComponent, MenuComponent
    }
  }
</script>

<style  lang="scss" scoped>

  .ring-list-item{
    background: #c7c5c3;
    color: #444444;
    &:hover{
      cursor: pointer;
      background: #afa195;
    }
  }
  .team-title{
    background: #273749;
    color: white;
  }
  .empty-message{
    text-align: center;
    width: 100%;
  }
  .fights-list{
    &--back-row{
      cursor: pointer;
    }
  }

</style>