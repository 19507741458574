var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page__section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__sort" }, [
          _c("span", { staticClass: "page__section__sort--item" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/competition/category/discipline/" +
                    _vm.$route.params.cmId +
                    "/" +
                    _vm.$route.params.discipId
                }
              },
              [_vm._v(_vm._s(_vm.Disciplines[_vm.$route.params.discipId]))]
            )
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedAgeCategory,
                  expression: "SelectedAgeCategory"
                }
              ],
              staticClass: "page__section__sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedAgeCategory = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.AgeCategories, function(AgeCategory, key) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: AgeCategory }
                },
                [_vm._v(_vm._s(key))]
              )
            })
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__team-sort" }, [
          _c(
            "a",
            {
              staticStyle: {
                "padding-left": "1rem",
                "padding-right": "1rem",
                cursor: "pointer"
              },
              on: { click: _vm.clearTeamSelect }
            },
            [_vm._v("Komanda:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedTeam,
                  expression: "SelectedTeam"
                }
              ],
              staticClass: "page__section__team-sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedTeam = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.Teams, function(team) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: team.Id }
                },
                [_vm._v(_vm._s(team.Title))]
              )
            })
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__data" },
          [
            _vm.HaveBattles == true && Object.keys(_vm.Fighters).length === 0
              ? _c("div", { staticClass: "emptycategory" }, [
                  _c("h1", [_vm._v("Šeit pagaidam nav ne viena ciņa")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.Fighters, function(WeightCategory, index) {
              return _c(
                "div",
                {
                  on: {
                    drop: function($event) {
                      _vm.dropFighter()
                    }
                  }
                },
                [
                  WeightCategory != ""
                    ? _c(
                        "a",
                        {
                          staticClass: "page__content__data--header",
                          attrs: {
                            href:
                              "/competition/category/weight/" +
                              _vm.$route.params.cmId +
                              "/" +
                              _vm.$route.params.discipId +
                              "/" +
                              _vm.SelectedAgeCategory +
                              "/" +
                              WeightCategory[0].WeightCategoryId
                          },
                          on: {
                            click: function($event) {
                              _vm.some()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(WeightCategory[0].WeightCategoryTitle) +
                              " Kg"
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  _vm.$store.state.authUser.type === 1 &&
                  WeightCategory != ""
                    ? _c("AdminNavigationInCategoryComponent", {
                        attrs: {
                          FightersData: WeightCategory,
                          BattlesData: _vm.BattlesInWeightCategory[index],
                          BattlesGraph: _vm.BattlesForGraph[index],
                          ParticipantsLozesanai: _vm.FightersLozesanai[index]
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$store.state.authUser && WeightCategory != ""
                    ? _c("PublicNavigationInCategoryComponent", {
                        attrs: {
                          FightersData: WeightCategory,
                          BattlesData: _vm.BattlesInWeightCategory[index],
                          BattlesGraph: _vm.BattlesForGraph[index]
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser &&
                  !(_vm.$store.state.authUser.type === 1) &&
                  WeightCategory != ""
                    ? _c("PublicNavigationInCategoryComponent", {
                        attrs: {
                          FightersData: WeightCategory,
                          BattlesData: _vm.BattlesInWeightCategory[index],
                          BattlesGraph: _vm.BattlesForGraph[index]
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "ParticipantCategoryModal", width: 900, height: 400 }
        },
        [
          _c("ParticipantCategoryModalComponent", {
            attrs: { ParticipantData: _vm.ParticipantData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "AddCategoriesModal",
            scrollable: true,
            width: "100%",
            height: "auto"
          }
        },
        [_c("AddCategoryModalComponent")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Age Category")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-20476b92", { render: render, staticRenderFns: staticRenderFns })
  }
}