<template>
  <div class="page">
    <div class="container">
     <h1>Secretary Page</h1>
      <div class="list-group competition-list"  v-if="ListShow" >
              <div class="list-group-item list-group-item-action competition-list__item"  v-for="(competition, key, index) in Competitions" >
                  <div class="competition-list__item--title" @click="showCompetitionFights(competition.Id)">
                      <h2> {{competition.Title}}</h2>
                  </div>
                  <div class="list-group-item list-group-item-action ring-list-item" v-for="Ring in competition.Rings" @click="showRingFights(competition.Id,Ring.Id)">
                      <h4>&ensp;{{Ring.Title}} Nr. {{ Ring.Nr }}</h4>
                  </div>
              </div>
          </div>

          <div v-if="CompetitionFightsShow" class="fights-list">
              <span @click="showList()" class="fights-list--back-row"><img src="/static/backarrow.png" width="20rem"/> Atpakal pie sarakstam</span>
              <h2>{{ CompetitionTitle }}</h2>
              <competition-fights :FightsData="CompetitionFights"></competition-fights>
          </div>
          <div v-if="RingFightsShow" class="fights-list">
              <span @click="showList()" class="fights-list--back-row"><img src="/static/backarrow.png" width="20rem"/> Atpakal pie sarakstam</span>
              <h2>{{ CompetitionTitle }}, cinas uz {{ RingTitle }} Nr. {{RingNr}}</h2>
              <competition-fights :FightsData="RingFights"></competition-fights>
          </div>
    </div>
  </div>
</template>

<script>
    import CompetitionFights from '../../components/Secretary/Battles/SecretaryFightsComponent.vue'
  export default {
  name: 'SecretaryCompetitionsPage',
  data () {
    return {
        fights: {},
        Competitions: [],
        CompetitionFights: [],
        RingFights:[],
        CompetitionTitle: "",
        RingTitle: "",
        RingNr: "",
        ListShow: true,
        CompetitionFightsShow: false,
        RingFightsShow: false,
        Showed: "",
    }
  },
  methods: {
      showCompetitionFights(cmId){
          this.ListShow=false;
          this.RingFightsShow=false;
          this.CompetitionFightsShow=true;
          this.$axios.get(`/api/secretary-competition-fights`, {
              params: {
                  secretaryId: this.$route.params.id,
                  cmId: cmId
              }
            })
              .then(response => {
                  this.CompetitionFights = response.data.CompetitionFights;
                  this.CompetitionTitle = response.data.CompetitionTitle;
              })
              .catch(e => {
                  this.errors.push(e)
              })
          this.Showed={type: "cm", cmId: cmId}
        },
      showRingFights(cmId,ringId){
          this.ListShow=false;
          this.CompetitionFightsShow=false;
          this.RingFightsShow=true;
          this.$axios.get(`/api/secretary-ring-fights`, {
              params: {
                  secretaryId: this.$route.params.id,
                  ringId: ringId
              }
          })
              .then(response => {
                  this.RingFights = response.data.RingFights;
                  this.CompetitionTitle = response.data.CompetitionTitle;
                  this.RingTitle = response.data.RingTitle;
                  this.RingNr = response.data.RingNr;
              })
              .catch(e => {
                  this.errors.push(e)
              })
        this.Showed={type: "ring",cmId: cmId, ringId: ringId }
      },
      showList(){
          this.ListShow=true;
          this.CompetitionFightsShow=false;
          this.RingFightsShow=false;
      },
      getData(){
        this.$axios.get(`/api/secretary-competitions`,{
          params: {secretaryId: this.$route.params.id}
        })
            .then(response => {
              this.fights = response.data;
              this.Competitions = response.data.SecretaryCompetitions;
            })
            .catch(e => {
              this.errors.push(e)
            })
      }
  },
  mounted () {
    this.getData();
    this.$socket.on( 'refresh-battles-list', response => {
      if (this.Showed.type==="cm"){
        this.showCompetitionFights(this.Showed.cmId)
      }
      else if (this.Showed.type==="ring") {
        this.showRingFights(this.Showed.cmId,this.Showed.ringId)
      } else {
        this.getData();
      }
    });
  },
  created() {
    this.getData()
    this.$socket.on('refresh-competition-data', response => {
      this.getData();
    });
    this.$socket.on('refresh-competitions-list', response => {
      this.getData();
    });

  },
  beforeDestroy() {
    this.$socket.removeListener('refresh-competition-data');
    this.$socket.removeListener('refresh-competitions-list');
    this.$socket.removeListener('refresh-battles-list');
  },
  components: {
      "competition-fights": CompetitionFights
  }
}
</script>

<style lang="scss" scoped>
  .ring-list-item{
    background: #c7c5c3;
    color: #444444;
    &:hover{
      cursor: pointer;
      background: #afa195; /* #ff7c00*/ /*#85bcfd; *///#8ec3ff
    }
  }
  .competition-list {
    &__item {
      &--title {
        :hover {
          cursor: pointer;
        }
      }
      background: #273749;
      color: white;
    }
  }
  .fights-list{
    &--back-row{
      cursor: pointer;
    }
  }
/*    .ring-list-item{
        &:hover{
            background: #85bcfd; //#8ec3ff
        }
    }
    .competition-list-item{
        &:hover{
            background: #DBE2E8; //#8ec3ff
        }
    }*/

</style>
