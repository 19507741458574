<template>
  <div class="col-md-12 clear-pads text-center confirm-status-modal bold">
    <button type="button" class="close" @click="hideModal">
      <span>&times;</span>
    </button>
    <div class="col-md-12 question">
      Are you ready?
    </div>

    <div class="col-md-12 text-center">
      <div class="col-md-6">
        <button class="btn btn-success" @click="confirmStatus( 1 )">Yes</button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-danger" @click="confirmStatus( 0 )">No</button>
      </div>
    </div>
  </div>
</template>
<script>

  export default{
    name: 'ConfirmReadyStatus',

    methods: {
      hideModal(){
        this.$modal.hide('confirm-ready-status');
      },
      confirmStatus( status ){
        let data = {
          judgeId: this.$store.state.authUser ? this.$store.state.authUser.id : null,
          battleId: this.$route.params.fightId,
          status: status,
          room: this.$route.params.fightId
        };
        this.$socket.emit('confirm-judge-status', data );
        this.hideModal();
      }
    },
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
  .confirm-status-modal{
    padding-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 15px;
  }
  .question{
    margin-bottom: 15px;
  }
</style>