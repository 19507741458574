var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ring-battles" }, [
    _c("div", { staticClass: "ring-battles__data-container" }, [
      _c("div", { staticClass: "ring-battles__data-container--table" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.BattlesData, function(data) {
              return _c("tr", { class: data.Color }, [
                _c("td", [_vm._v(_vm._s(data.Nr))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.AgeCategory))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.WeightCategory))]),
                _vm._v(" "),
                _c("td", [
                  data.CompetitionPart > 1
                    ? _c("span", [_vm._v("1/")])
                    : _vm._e(),
                  _vm._v(_vm._s(data.CompetitionPart))
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.RedTeam))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      data.RedNameSurname
                        ? data.RedNameSurname
                        : data.RedComesFrom
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "side-color--red" }),
                _vm._v(" "),
                _c("td", { staticClass: "results-color" }, [
                  _vm._v(_vm._s(data.Result))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "side-color--blue" }),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      data.BlueNameSurname
                        ? data.BlueNameSurname
                        : data.BlueComesFrom
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(data.BlueTeam))]),
                _vm._v(" "),
                data.WinnerId == data.RedCmUserId
                  ? _c("td", [_vm._v(_vm._s(data.RedNameSurname))])
                  : data.WinnerId == data.BlueCmUserId
                  ? _c("td", [_vm._v(_vm._s(data.BlueNameSurname))])
                  : data.WinnerId != data.RedCmUserId ||
                    data.WinnerId != data.BlueCmUserId
                  ? _c("td", [_vm._v("-")])
                  : _vm._e()
              ])
            })
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ring-battles__data-container--qrcode" },
        [
          _c("qrcode-vue", { attrs: { value: _vm.Url, size: 200, level: "H" } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Vecuma kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Svara kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Limenis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }, [
          _vm._v("Sarkanais stūris")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }),
        _vm._v(" "),
        _c("th", [_vm._v("Resultats")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }, [
          _vm._v("Zilais stūris")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", [_vm._v("Winner")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-29d7d9cc", { render: render, staticRenderFns: staticRenderFns })
  }
}