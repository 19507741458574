<template>
  <div class="page">
    <div class="home-page-container">
      <div class="home-page-container__head-container">
        <div class="home-page-container__head-container__item">
          <div class="home-page-container__head-container__item--img">
            <img src="static/tmp.png" height="100%" width="100%">
          </div>
        </div>
      </div>
      <div class="home-page-container__content">
        <router-link :to="{name: 'competitionsPage'}" class="home-page-container__content__item">
          <div class="home-page-container__content__item--img">
            <img src="static/competitions.png" height="100%" width="100%">
            <div class="home-page-container__content__item--label">
              COMPETITIONS
            </div>
          </div>
        </router-link>
        <router-link :to="{name: 'Competitions'}" class="home-page-container__content__item">
          <div class="home-page-container__content__item--img">
            <img src="static/stream.jpg" height="100%" width="100%">
            <div class="home-page-container__content__item--label">
              STREAMS
            </div>
          </div>
        </router-link>
      </div>
      <div class="home-page-container__footer-container">
        <div class="home-page-container__footer-container__item">
          <div class="home-page-container__footer-container__item--img">
            <img src="static/tmp.png" height="100%" width="100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  name: 'homePage',
  data () {
    return {
		statuses:{}
    }
  },
  mounted () {
      this.$axios.get(`/api/`, {
        params: {id: this.$route.params.id}
      })
      .then(response => {
        this.statuses = response.data;
      /*  console.log(this.statuses);
*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  }
}
</script>

<style scoped lang="scss" >
  #app{
    background: black;
  }
  body {
    background: black;
  }

  .page {
    background: black;
    height: 100vh;
  }

  .home-page-container{
  /*  width:100%;*/
    /*height:100%;*/
    margin-top: 0;
    /* padding-top: 17rem;*/
    background-color: black;
    &__head-container{
   /*   display: flex;
      flex-direction: row;
      height: 17rem;*/
      opacity: 0.5;
      &__item{
       &--img{
         /*height: 100%;*/
       }
      }
    }
    &__content{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    /*  background: linear-gradient(to top, #080808, #403939);*/
      &__item{
        background-color: black;
        opacity:0.8;
        width:50%;
        &--img{
          position: relative;
          margin: 0 auto;
          &:hover .home-page-container__content__item--label{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 1;
          }
          &:hover{
           opacity: 0.5;
          }
        }
        &--label{
          position:absolute;
          left:0;
          bottom:0;
          right:0;
          top:0;
         /* background-color: #212121;*/
          color: white;
          font-size: 56px;
          display:none;
      }
    }
  }
  &__footer-container{
    /*display: flex;
    flex-direction: row;*/
   /* height: 17rem;*/
    opacity: 0.5;
    &__item{
      &--img{
       /* height: 100%;*/
      }
    }
  }
}
  @media (max-width:1050px){
    .home-page-container__content__item {
      width: 100%;
    }
    .home-page-container__content__item--label{
      font-size: 2rem;
    }
  }

h1 {
  color: red;
}
</style>
