<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Weight Category</h1>
      </div>
      <div class="page__section__sort">
        <span class="page__section__sort--item" ><a :href="'/competition/category/discipline/'+$route.params.cmId+'/'+$route.params.discipId">{{ Disciplines[$route.params.discipId] }}</a></span>
        <span class="page__section__sort--item" ><a :href="'/competition/category/age/'+$route.params.cmId+'/'+$route.params.discipId+'/'+$route.params.ageCategId">{{ AgeCategories[$route.params.ageCategId] }}</a></span>
        <select class="page__section__sort--select" v-model="SelectedWeightCategory" v-on:change="getSelected">
          <option v-for="(WeightCategory, key, index) in WeightCategories" :value="WeightCategory" selected="selected">{{ key }}</option>
        </select>
      </div>
      <div class="page__section__team-sort">
        <a style="padding-left: 1rem; padding-right: 1rem; cursor: pointer;" @click="clearTeamSelect">Komanda:</a>
        <select class="page__section__team-sort--select" v-model="SelectedTeam" v-on:change="getSelected">
          <option v-for="team in Teams" :value="team.Id" selected="selected">{{ team.Title }}</option>
        </select>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <div class="emptycategory" v-if="JSON.stringify(BattlesInWeightCategory)=='[]'">
          <h1 >Šeit pagaidam nav ne viena ciņa</h1>
        </div>
       <!--   <div v-for="(WeightCategory, index) in Fighters">-->
          <!--  {{WeightCategory[0]}}-->
          <div>
            <a v-if="Fighters!=''" class="page__content__data--header" v-on:click="some()">{{ Fighters[0].WeightCategoryTitle }} Kg</a>
            <AdminNavigationInCategoryComponent v-if="($store.state.authUser) && ($store.state.authUser.type === 1) && (Fighters!='')" :FightersData="Fighters" :BattlesData="BattlesInWeightCategory" :BattlesGraph="BattlesForGraph" :ParticipantsLozesanai="Fighters"></AdminNavigationInCategoryComponent>
            <PublicNavigationInCategoryComponent v-if="(!$store.state.authUser) && (Fighters!='')" :FightersData="Fighters" :BattlesData="BattlesInWeightCategory" :BattlesGraph="BattlesForGraph"></PublicNavigationInCategoryComponent>
            <PublicNavigationInCategoryComponent v-if="($store.state.authUser) && !($store.state.authUser.type === 1) && (Fighters!='')" :FightersData="Fighters" :BattlesData="BattlesInWeightCategory" :BattlesGraph="BattlesForGraph"></PublicNavigationInCategoryComponent>

          </div>
      </div>
    </div>
    <modal name="ParticipantCategoryModal" :width="900" :height="400">
      <ParticipantCategoryModalComponent :ParticipantData="ParticipantData"></ParticipantCategoryModalComponent>
    </modal>
    <modal name="AddCategoriesModal" :scrollable="true" width="100%" :height="'auto'">
      <AddCategoryModalComponent></AddCategoryModalComponent>
    </modal>
  </div>
</template>

<script>

    import AdminNavigationInCategoryComponent from '../../components/admin/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';
    import AddCategoryModalComponent from '../../components/admin/ParticipantTableComponents/AddCategoryModalComponent.vue';
    import PublicNavigationInCategoryComponent from '../../components/public/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';
    import MenuComponent from "../../components/common/MenuComponent";
    import ParticipantCategoryModalComponent from '../../components/admin/ParticipantTableComponents/ParticipantCategoryModalComponent.vue';


    export default {
    name: 'weightCategoryPage',
    data () {
      return {
        show: false,
        CompetitionTitle: "",
        Disciplines: [],
        AgeCategories: [],
        Fighters: [],
        FightersLozesanai: [],
        WeightCategories: null,
        SelectedWeightCategory: null,
        BattlesInWeightCategory: {},
        BattlesForGraph: {},
        ParticipantData:{},
        SelectedTeam: null,
        Teams: [],
      }
    },
    methods: {
      clearTeamSelect: function(){
        this.SelectedTeam = null;
        this.getSelected();
      },
      getTeams: function(){
        this.$axios.get(`/api/competition/weight-category/get-teams`, {
          params: {
            cmId: this.$route.params.cmId,
            WeightCategoryId: this.$route.params.weightCategId,
          }
        })
          .then(response => {
            this.Teams = response.data.Teams;
            console.log(this.Teams)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getSelected: function(){
        this.$router.replace({ name: "weightCategoryPage", params: {cmId: this.$route.params.cmId, discipId: this.$route.params.discipId, ageCategId: this.$route.params.ageCategId, weightCategId: this.SelectedWeightCategory}})
        this.$axios.get(`/api/weight`, {
          params: {
            cmId: this.$route.params.cmId,
            disciplineId: this.$route.params.discipId,
            ageCategId: this.$route.params.ageCategId,
            weightCategId: this.SelectedWeightCategory,
            TeamId: this.SelectedTeam,
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.SelectedWeightCategory=response.data.SelectedWeightCategID;
            this.Fighters = response.data.Fighters;
            this.FightersLozesanai = response.data.FightersLozesanai;
            this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
            this.BattlesForGraph=response.data.BattlesForGraph;
            console.log(this.AgeCategories);
            console.log(this.Disciplines);
            console.log(response.data);
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.getTeams()
      },
      getData: function(){
        this.$axios.get(`/api/weight`, {
          params: {
            cmId: this.$route.params.cmId,
            disciplineId: this.$route.params.discipId,
            ageCategId: this.$route.params.ageCategId,
            weightCategId: this.$route.params.weightCategId,
            TeamId: this.SelectedTeam,
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.Disciplines=response.data.Disciplines;
            this.AgeCategories=response.data.AgeCategories;
            this.WeightCategories=response.data.WeightCategories;
            this.SelectedWeightCategory=response.data.SelectedWeightCategID;
            this.Fighters = response.data.Fighters;
            this.FightersLozesanai = response.data.FightersLozesanai;
            this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
            this.BattlesForGraph=response.data.BattlesForGraph;
            console.log(response.data);
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.getTeams()
      }
    },
    mounted () {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-battles-list', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-tossup-data', response => {
        this.getSelected();
      });
      this.$bus.$on("CmUserData", (data)=>{
        this.ParticipantData=data;
        console.log("geted data: ",data)
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-battles-list');
      this.$socket.removeListener('refresh-tossup-data');
    },
    components: {
        MenuComponent,
        AdminNavigationInCategoryComponent,
        PublicNavigationInCategoryComponent,
      ParticipantCategoryModalComponent,
      AddCategoryModalComponent
    }
  }
</script>


<style lang="scss" scoped>
  .emptycategory{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
  }

</style>