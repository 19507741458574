<template>
  <div class="vue-good-table">
    <table class="table table-bordered" style="font-size: 16px;">
      <thead>
      <tr>
        <th>Vards, Uzvārds</th>
        <th>Komanda</th>
        <th>Vecums</th>
        <th>Svars</th>
        <th>Lozes numurs</th>
        <th>Darbibas</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="data in ParticipantsLozesanaiData" v-bind:class="{'young-mark' : data.Young}" @drag="dragFighter()">
        <td>{{data.NameSurname}} ({{data.CmUserId}})</td>
        <td>{{data.Team}}</td>
        <td>{{data.Age}}</td>
        <td>{{data.Weight}}</td>
        <td @click="showEdit(data, 'CmUserNr')" v-if="!((data.CmUserId===Selected.Id) && (Selected.Row==='CmUserNr'))">{{data.CmUserNr}}</td>
        <td v-if="(data.CmUserId===Selected.Id) && (Selected.Row==='CmUserNr') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value"  @focus="$event.target.select()" @keyup.enter="updateData(Selected)">
        </td>
        <td>
          <button class="btn btn-primary" v-on:click="ShowChangeCategoryModal(data)">Parvietot</button>
        </td>
      </tr>
      </tbody>
    </table>
    <a class="btn btn-success" style="width: 25rem; float:right;" v-on:click="tossup()">Automātiska lozēšana</a>
    <a class="btn btn-primary" style="width: 25rem; float:right;" v-on:click="generateBattles()">Rediģet ciņas</a>
  </div>
</template>

<script>

  import { Drag, Drop } from 'vue-drag-drop';

  export default {
    name: "LozesanaTableComponent",
    props: ["ParticipantsLozesanaiData", "AlgorithmId"],
    data(){
      return {
        clearFightersData: [],
        CategoryId: this.ParticipantsLozesanaiData[0].CategoryId,
        Selected: {
          Id: 0,
          Row: '',
          Value: 0,
        },
        ParticipantData: {},
      };
    },
    methods: {
      dragFighter() {
        console.log('drogged')
      },
      ShowChangeCategoryModal(data){
        this.$modal.show('ParticipantCategoryModal');
        this.$bus.$emit("CmUserData",data);
        console.log("Data: ",data)
      },
      tossup() {
        this.$socket.emit('tossup', {
          cmId: this.$route.params.cmId,
          CategoryId: this.ParticipantsLozesanaiData[0].CategoryId,
        });
        console.log("something goo")
      },
      generateBattles() {
        this.$router.push({name: 'fightsGenerationPage', params: {cmId: this.$route.params.cmId, categId: this.CategoryId}})
      },
      showEdit(data,row){
        this.Selected = {
          Id: data.CmUserId,
          Row: row,
          CategoryId: data.CategoryId,
          Value: data[row],
        }
        console.log(data.CmUserId)
      },
      updateData(rowData){
        this.Selected= {}
        console.log("Will update: ", rowData)
        if (rowData.Row == 'CmUserNr') {
          this.$socket.emit('edit-cmusernr', rowData);
        }
      },
    },
    mounted() {
      console.log('participants data: ',this.ParticipantsLozesanaiData)

    },
    components: {
     /* ParticipantCategoryModalComponent*/ 'drag': Drag, 'drop': Drop
    },
  };
</script>

<style>
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;
  }
  .young-mark{
    background-color: #dcff80;
  }
</style>
