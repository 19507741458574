<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Pieteikuma izskatišāna</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <br>
        <div class="page__content__input__form__item" >
          <h1 class="page__content__input__form__item--label control-label " style="z-index:2;">Dalibnieks</h1>
          <h4 v-if="!UserData" style="color: red;">Dalibnieks neeksistē</h4>
          <h4 v-if="UserData" style="color: #6db500;">Dalibnieks eksistē</h4>
          <multiselect v-model="UserData" :options="Users" :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="" track-by="Id" :custom-label="NameSurname" ></multiselect>
        </div>
        <h3 class="page__content__input__form__item">Dalibnieka dati saglabāšanai:</h3>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Vards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.User.Name" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="ApplyData.User.Surname" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item" >
            <label class="page__content__input__form__item--label control-label " style="left:-2rem; ">Dzimsanas Datums<sup>*</sup></label>
            <div class="page__content__input__form__item--input" style="z-index: 1;">
              <DatePicker calendar-class="calendar" :monday-first="true" v-model="ApplyData.User.DateOfBirth" placeholder="00-00-00" :bootstrap-styling="true" @closed="setCategories()"></DatePicker>
            </div>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index:0;">Dzimums<sup>*</sup></label>
            <multiselect v-model="ApplyData.User.Gender" :options="Genders" :close-on-select="true"
                         :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" @input="setCategories()">
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Weight<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Weight" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index:0;">Kategorijas<sup>*</sup></label>
            <multiselect v-model="ApplyData.Categories" :options="Categories" :close-on-select="true"
                         :multiple="true" :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="CategoryLabel" ></multiselect>
          </div>
        </div>
        <div class="page__content__input__form__item">
          <h1 class="page__content__input__form__item--label control-label " style="z-index:0;">Komanda</h1>
          <h4 v-if="!ApplyData.Team" style="color: red;">Komanda neeksistē</h4>
          <h4 v-if="ApplyData.Team" style="color: #6db500;">Komanda eksistē</h4>
          <multiselect v-model="ApplyData.Team" :options="Teams" :close-on-select="true"
                       :clear-on-select="false" :open-direction="'below'"
                       placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
        </div>
        <h3 class="page__content__input__form__item">Komandas dati saglabāšanai:</h3>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Komandas nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.UnknownUsersTeam.Title">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Treneris<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.UnknownUsersTeam.Coach">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Komandas adrese<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.UnknownUsersTeam.Address">
          </div>
            <div class="page__content__input__form__item">
                <label class="page__content__input__form__item--label control-label ">Komandas talrunis<sup>*</sup></label>
                <input class="page__content__input__form__item--input form-control" v-model="ApplyData.UnknownUsersTeam.Phone">
            </div>
            <div class="page__content__input__form__item">
                <label class="page__content__input__form__item--label control-label ">Komandas email<sup>*</sup></label>
                <input class="page__content__input__form__item--input form-control" v-model="ApplyData.UnknownUsersTeam.Email">
            </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="approveExternalCmClaim(ApplyData)">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePicker from 'vuejs-datepicker';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "createCmUserClaimPage",
    data(){
      return {
        show: false,
        Users: [],
        UserData: null,
        TeamData: null,
        ApplyData: {
          User: {Name:"", Surname:"", Gender:{}},
          Categories: {},
          DateOfBirth: new Date(),
          Weight: 0,
          UnknownUsersTeam: {},
          Payed: false,
          PaymentComments: '',
          CmId: this.$route.params.cmId ,
        },
        Genders: [{Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        Categories: [],
        Teams: [],
        AllCategories: [],
      }
    },
    methods: {
      approveExternalCmClaim(Data){
        let ApplyCategories = Data.Categories
        this.DateOfBirth = new Date( Data.User.DateOfBirth);

        let Now = new Date
        let DateOfBirth = new Date(Data.User.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        if ((typeof Data.User.Name!='undefined') && (typeof Data.User.Surname!='undefined')){
          if (Data.User.Name.length < 3) {
            alert("Vārds ievadīts ne pareizi!")
            return;
          } else if (Data.User.Surname.length < 2) {
            alert("Uzvārds ievadīts ne pareizi!")
            return;
          }
        }
        if ((typeof Data.User.Name=='undefined') || (typeof Data.User.Surname=='undefined')){
          alert("Dalibnieka dati neievadīti!")
          return;
        }
        if ((Age<=5) || (!DateOfBirth)) {
          alert("Dzimšanas datums neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (!Data.User.Gender) {
          alert("Dzimums nav ievadīts!")
          return;
        }
        if ((Data.Weight.length<2) || (!Data.Weight)){
          alert("Svars neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (Data.Categories.length==0) {
          alert("Kategorija neievadīta!")
          return;
        }
        if (Data.UnknownUsersTeam.Title.length<2) {
          alert("Komandas nosaukums neievadīts!")
          return;
        }
        let CoachNameSurname = Data.UnknownUsersTeam.Coach;
        CoachNameSurname = CoachNameSurname.split(' ');
        if (CoachNameSurname.length>2) {
          alert("Treneris neievadīts!")
          return;
        }
        if (Data.UnknownUsersTeam.Address.length<2) {
          alert("Adrese neievadīts!")
          return;
        }
        if (Data.UnknownUsersTeam.Phone.length<8) {
          alert("Talrunis neievadīts!")
          return;
        }

        Data.Categories = []
        ApplyCategories.forEach((ApplyCategory)=>{
          console.log(`Category: `,ApplyCategory)
          Data.Categories.push(ApplyCategory.Id)
        })
        Data.User.DateOfBirth = this.$moment( Data.User.DateOfBirth).format( "YYYY-MM-DD" )
        this.$socket.emit('approve-external-cm-claim', Data)
      },
      setCategories(){
        if (!this.ApplyData.User){
          this.ApplyData.User={Gender:{}}
        }
        let now = new Date;
        let DateOfBirth = new Date(this.ApplyData.User.DateOfBirth);
        let Age=((now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        console.log("DateOfBirth: ",this.ApplyData.User.DateOfBirth)
        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          let CheckWeight=true;
          if(item.CategorySide==1){
            CheckWeight = (item.WeightMax<=this.ApplyData.Weight);
          }else{
            CheckWeight = (item.WeightMax>=this.ApplyData.Weight);
          }
          if (CheckWeight && (item.AgeMin<=Age) && (item.AgeMax>=Age) && (item.Gender==this.ApplyData.User.Gender.Id)) {
            this.Categories.push(item);
          }
        })
      },
      getDataForApply() {
        this.$axios.get(`/api/participant/data-for-cmusers-apply`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
                .then(response => {
                  this.Teams = response.data.Teams;
                  this.Users = response.data.Users;
                  this.Categories = response.data.Categories;
                  this.AllCategories = response.data.Categories;
                  console.log('response.data for apply: ', response.data)
                  this.getClaimData()
                })
                .catch(e => {
                  this.errors.push(e)
                })
      },
      getClaimData(){
        this.$axios.get(`/api/participant/edit-data/external-claim`,{
          params:{
            cmId: this.$route.params.cmId,
            UnkUserId: this.$route.params.unkuserId
          }
        })
          .then(response => {
            this.ApplyData = response.data.ApplyData;
            console.log('this.ApplyData: ',this.ApplyData)
            if(this.ApplyData.User.Id){
              console.log('this.Users: ',this.Users)
              console.log('this.Users: ',this.Users)
              console.log('this.ApplyData.User.Id: ',this.ApplyData.User.Id)
              if (this.Users.some(user => user.Id == this.ApplyData.User.Id)){
                this.UserData = Object.assign( {}, this.ApplyData.User)
                console.log('this.UserData: ',this.UserData)
              } else {
                console.log('UserId Unknown this.UserData: ',this.UserData)
              }
            } else {
              console.log('UserId Unknown this.UserData: ',this.UserData)
            }
            if(this.ApplyData.UnknownUsersTeam.ExistTeamId){
              if (this.Teams.some(team => team.Id == this.ApplyData.UnknownUsersTeam.ExistTeamId)){
                this.ApplyData.Team = {
                  Id: this.ApplyData.UnknownUsersTeam.ExistTeamId,
                  Title: this.ApplyData.UnknownUsersTeam.Title,
                }
              }
            }
            console.log(response)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getData: function () {
        this.getDataForApply()
      },
      clearUser(){
        this.ApplyData.User={Gender:{}}
        this.ApplyData.Weight=0;
        this.ApplyData.Category={};
        this.setCategories()
      },
      NameSurname (option) {
        let now = new Date;
        let UserBirthDate=option.DateOfBirth;
        UserBirthDate = new Date(UserBirthDate);
        let Age=((now.getTime() - UserBirthDate) / (24 * 3600 * 365.25 * 1000) | 0);
        if ((typeof option.Name=='undefined')||(typeof option.Surname=='undefined')){return '-'}
        return `${option.Name} - ${option.Surname} (Age: ${Age})`
      },
      CategoryLabel (option) {
        if ((typeof option.AgeCategory=='undefined')&&(typeof option.WeightMax=='undefined')){return '-'}
        let Side = '';

        if(option.CategorySide==1){
          Side = ' + ';
        }else{
          Side = ' - ';
        }
        return `${option.DisciplineTitle} ${option.AgeCategory} ${Side}${option.WeightMax} (Age: ${option.AgeMin} - ${option.AgeMax})`
      },
      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('success', response => {
        console.log('response: ',response)
        if (response===true){
          this.$router.push({name: 'competitionClaimsPage', params: {cmId: this.$route.params.cmId}})
        }else{
          if (response!='') {
            alert(response)
          } else {
            alert("Something goes wrong! Check all fields on filled right")
          }
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-categories-data');
    },
    components: {
      MenuComponent,
      DatePicker, "multiselect": Multiselect
    },
  }
</script>


<style lang="scss" scoped>

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>