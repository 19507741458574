var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", [_vm._v("Secretary Page")]),
      _vm._v(" "),
      _vm.ListShow
        ? _c(
            "div",
            { staticClass: "list-group competition-list" },
            _vm._l(_vm.Competitions, function(competition, key, index) {
              return _c(
                "div",
                {
                  staticClass:
                    "list-group-item list-group-item-action competition-list__item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "competition-list__item--title",
                      on: {
                        click: function($event) {
                          _vm.showCompetitionFights(competition.Id)
                        }
                      }
                    },
                    [_c("h2", [_vm._v(" " + _vm._s(competition.Title))])]
                  ),
                  _vm._v(" "),
                  _vm._l(competition.Rings, function(Ring) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "list-group-item list-group-item-action ring-list-item",
                        on: {
                          click: function($event) {
                            _vm.showRingFights(competition.Id, Ring.Id)
                          }
                        }
                      },
                      [
                        _c("h4", [
                          _vm._v(
                            " " + _vm._s(Ring.Title) + " Nr. " + _vm._s(Ring.Nr)
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.CompetitionFightsShow
        ? _c(
            "div",
            { staticClass: "fights-list" },
            [
              _c(
                "span",
                {
                  staticClass: "fights-list--back-row",
                  on: {
                    click: function($event) {
                      _vm.showList()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "/static/backarrow.png", width: "20rem" }
                  }),
                  _vm._v(" Atpakal pie sarakstam")
                ]
              ),
              _vm._v(" "),
              _c("h2", [_vm._v(_vm._s(_vm.CompetitionTitle))]),
              _vm._v(" "),
              _c("competition-fights", {
                attrs: { FightsData: _vm.CompetitionFights }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.RingFightsShow
        ? _c(
            "div",
            { staticClass: "fights-list" },
            [
              _c(
                "span",
                {
                  staticClass: "fights-list--back-row",
                  on: {
                    click: function($event) {
                      _vm.showList()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "/static/backarrow.png", width: "20rem" }
                  }),
                  _vm._v(" Atpakal pie sarakstam")
                ]
              ),
              _vm._v(" "),
              _c("h2", [
                _vm._v(
                  _vm._s(_vm.CompetitionTitle) +
                    ", cinas uz " +
                    _vm._s(_vm.RingTitle) +
                    " Nr. " +
                    _vm._s(_vm.RingNr)
                )
              ]),
              _vm._v(" "),
              _c("competition-fights", {
                attrs: { FightsData: _vm.RingFights }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0d4196cc", { render: render, staticRenderFns: staticRenderFns })
  }
}