<template>
  <div class="page">
    <div class="" v-if="Object.keys(fight).length">
      <div class="fight-info">
        <div class="fight-info__header">
          <h1 class="fight-info__header--item" >{{fight.title}}</h1>
        </div>
      </div>
      <FightInfo :fight="fight"></FightInfo>
      <div v-if="fight.judges" class="difference-chart">
        <div class="difference-chart__item" v-for="judge in fight.judges">
          <div  :class="[judge.red_score - judge.blue_score < 0 ? 'col-md-push-6' : '', 'col-md-6']">
            <div class="difference-chart__item__point"
                 v-for="n in Math.abs((judge.red_score - judge.blue_score))"
                 :class="[(judge.red_score - judge.blue_score) > 0 ? 'difference-chart__item__point--positive' : 'difference-chart__item__point--negative']">
            </div>
          </div>
        </div>
      </div>
      <div v-if="fight.battle_status===2">
        <h1 style="width: 100%; text-align: center;" v-if="fight.winner_id===fight.red_user_id">Winner is {{fight.red_name}} {{fight.red_surname}} </h1>
        <h1 style="width: 100%; text-align: center;" v-if="fight.winner_id===fight.blue_user_id">Winner is  {{fight.blue_name}} {{fight.blue_surname}} </h1>
      </div>
      <div  v-if="fight.finalScore" class="fighters-score">
        <h1 class="fighters-score__item">{{ fight.finalScore.red }}</h1>
        <h1 class="fighters-score__item"> : </h1>
        <h1 class="fighters-score__item">{{ fight.finalScore.blue }}</h1>
      </div>
      <div v-if="fight.judges" class="judges-score">
        <div class="judges-score__item" v-for="(judge, index) in fight.judges">
          <div class="judges-score__item__result">
            <p class="judges-score__item__result__score--red">{{ judge.red_score }}</p>
            <p class="judges-score__item__result__score">:</p>
            <p class="judges-score__item__result__score--blue">{{ judge.blue_score }}</p>
          </div>
          <h1 class="judges-score__item__name">{{ judge.name + ' ' + judge.surname }}</h1>
        </div>
      </div>
    </div>
    <h1 style="width: 100%; text-align: center; margin-top: 8rem" v-else>No active fights</h1>
  </div>
</template>

<script>
  import Timer from '../../components/JudgingComponents/FightInfoComponents/Timer.vue'
  import FightInfo from '../../components/JudgingComponents/FightInfoComponents/FightInfo.vue'
  export default {
  name: 'FightPage',
  data () {
    return {
      queryParams: {
        ringId: this.$route.params.ringId
      },
      fight: {},
    }
  },
  methods:{
    getFightResults(){
      this.$socket.emit("get-fight-results", {
        battleId: this.$route.params.fightId,
        ring: this.$route.params.ringId
      });
    }
  },
  mounted () {

    this.$socket.emit('ring-join', this.queryParams.ringId);
    this.$socket.on('refresh-result', resp => {
      if(!this.fight.id){
        this.$socket.emit('room-join', resp.id);
      }
      this.fight = resp;
    });

    this.$socket.on('refresh-public-scores', resp => {
      console.log('geted scores: ',resp)
      if(!this.fight.id){
        this.$socket.emit('room-join', resp.id);
      }
      this.fight.judges = resp.judges;
      this.fight.diffInRaunds = resp.diffInRaunds;
      this.fight.finalScore = resp.finalScore;
    });

    this.$socket.on( 'round-status', data => {
      this.fight.roundDetails = data.response;
      if(data.emit){
        this.$bus.$emit(data.emit, data.response);
      }
    });

  },
  components: {
    Timer,
    FightInfo
  },
  beforeDestroy () {
    this.$socket.emit('ring-leave', this.queryParams.ringId);
    this.$socket.emit('room-leave', this.fight.id);
/*    this.$socket.removeAllListeners('refresh-result');
    this.$socket.removeAllListeners('refresh-status');*/
  }
}
</script>

<style lang="scss" scoped>


  .fighters-score{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 5rem 0;
    position: relative;
    &__item{
      font-size: 10rem;
      padding: 0 1rem;
      font-weight: bold;
      margin: 0;
    }
  }
  .judges-score{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 5rem 0;
    position: relative;
    &__item {
      &__result {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: 1rem 0;
        font-size: 3rem;
        position: relative;
        &__score {
          display: flex;
          &--red{
            color:red;
            padding: 0 3rem;
          }
          &--blue{
            color:blue;
            padding: 0 3rem;
          }
        }
      }
      &__name {
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        text-align:center;
      }
    }
  }
  .difference-chart {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10rem;
    &__item {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0;
      &__point {
        border-radius: .3rem;
        width: 4rem;
        height: 4rem;
        background-color: #2b81af;
        display: inline-block;
        margin: .5rem .5rem;
        &--positive {
          float: right;
        }
      }
    }
  }

  .fight-info {
    &__header {
      &--item {
        text-align: center;
      }
    }
  }



</style>
