<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Tiesneša pulti</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__table">
                <div class="page__content__table__actions">
                    <div class="page__content__table__actions--button">
                        <button class="btn circle-btn" style="padding-left:1rem;" @click="$modal.show('addingRemoteModal')">+</button>
                        <button class="btn square-btn" @click="$modal.show('addingRemoteModal')">Pievienot pultu</button>
                    </div>
                </div>
                <CompetitionRemotesTableComponent :RemoteControls="RemoteControls" ></CompetitionRemotesTableComponent>
            </div>
          <modal name="addingRemoteModal" :clickToClose="false" :scrollable="true" height="auto" >
            <addCompetitionRemoteModalComponent></addCompetitionRemoteModalComponent>
          </modal>
        </div>
    </div>
</template>

<script>
    import addCompetitionRemoteModalComponent from "../../components/admin/RemoteControlsComponents/addCompetitionRemoteModalComponent.vue"
    import CompetitionRemotesTableComponent from "../../components/admin/RemoteControlsComponents/CompetitionRemotesTableComponent.vue"
    import MenuComponent from "../../components/common/MenuComponent";

    export default {
        name: "competitionRemoteControlsPage",
        data() {
            return{
                show: false,
                RemoteControls: [],
            }
        },
        methods: {

            getData: function(){
                this.$axios.get(`/api/competition/remote-controls/list`, {
                    cmId: this.$route.params.cmId,
                })
                    .then(response => {
                        this.RemoteControls=response.data.RemoteControls;
                        console.log(response.data)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
        },
        mounted() {
            this.getData()
            this.$socket.on('refresh-competition-remotes-list',response => {
                this.getData()
            })

        },
        beforeDestroy () {
            this.$socket.removeListener('refresh-competition-remotes-list');
        },
        components:{
            MenuComponent, CompetitionRemotesTableComponent, addCompetitionRemoteModalComponent
        },
    }
</script>

<style lang="scss" scoped>

    body {
        font-size: 14px;
    }

    * {
        box-sizing: border-box;
    }


</style>