var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "change-category-modal" }, [
    _c("div", { staticClass: "change-category-modal__participant-data" }, [
      _c(
        "h1",
        {
          staticClass: "change-category-modal__participant-data--name-surname"
        },
        [_vm._v(_vm._s(_vm.ParticipantData.NameSurname))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "change-category-modal__set-category-container" },
      [
        _c("multiselect", {
          staticClass: "change-category-modal__set-category-container--select",
          attrs: {
            options: _vm.Categories,
            "max-height": 140,
            "close-on-select": true,
            "clear-on-select": false,
            limit: 4,
            placeholder: "Select category",
            "track-by": "Id",
            "custom-label": _vm.CategoryLabel
          },
          model: {
            value: _vm.Data.Category,
            callback: function($$v) {
              _vm.$set(_vm.Data, "Category", $$v)
            },
            expression: "Data.Category"
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-primary change-category-modal__set-category-container--save-btn",
            on: {
              click: function($event) {
                _vm.ChangeParticipantCategory()
              }
            }
          },
          [_vm._v("Saglabat")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-65d97d34", { render: render, staticRenderFns: staticRenderFns })
  }
}