<template>
  <div class="page">
    <div class="page__section">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Labošana</h1>
    </div>
  </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <h1 class="page__content__input--title">Paroles labošana</h1>
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Ievadiet parole<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="PasswordData.Password" type="password">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Atkartojiet parole<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="PasswordData.RepeatPassword" type="password">
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="editPasswordData">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "editUserPage",
    data(){
      return {
          show: false,
          PasswordData: {
              Password: "",
              RepeatPassword: "",
          },
      }
    },
    methods: {
      editPasswordData(){
          if (this.PasswordData.Password===this.PasswordData.RepeatPassword) {
              this.Password = this.PasswordData.Password;
              this.$axios.post(`/api/user/password/edit`,{
                  userId: this.$route.params.userId,
                  password: this.Password
              })
                  .then(response => {
                      if (response.status===200){
                          this.$router.push({ name: 'usersPage' })
                      }
                  })
                  .catch(e => {
                      this.errors.push(e)
                  })
          }else{
              alert("Paroli nav vienadi!")
              return;
          }
      },
    },
    mounted() {
    },
    components: {
        MenuComponent,
      DatePicker
    },
  }
</script>

<style lang="scss" scoped>

</style>