var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.CompetitionTitle)),
          _c("br"),
          _c(
            "span",
            { staticClass: "page__section__name-container--subtitle" },
            [_vm._v(_vm._s(_vm.Subtitle))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__section__sort" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.SelectedTeam,
                expression: "SelectedTeam"
              }
            ],
            staticClass: "page__section__sort--select",
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.SelectedTeam = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.SelectTeam
              ]
            }
          },
          _vm._l(_vm.Teams, function(team, key) {
            return _c(
              "option",
              { attrs: { selected: "selected" }, domProps: { value: team.Id } },
              [_vm._v(_vm._s(team.Title))]
            )
          })
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__data" },
        [_c("ResultsTableComponent", { attrs: { ResultsData: _vm.Results } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7c68183a", { render: render, staticRenderFns: staticRenderFns })
  }
}