<template>
  <div class="change-category-modal">
    <div class="change-category-modal__participant-data">
      <h1 class="change-category-modal__participant-data--name-surname">{{ParticipantData.NameSurname}}</h1>
    </div>
    <div class="change-category-modal__set-category-container">
      <multiselect class="change-category-modal__set-category-container--select" v-model="Data.Category" :options="Categories"
                   :max-height="140"
                   :close-on-select="true"
                   :clear-on-select="false"
                   :limit="4"
                   placeholder="Select category" track-by="Id" :custom-label="CategoryLabel">
      </multiselect>
      <button class="btn btn-primary change-category-modal__set-category-container--save-btn" v-on:click="ChangeParticipantCategory()">Saglabat</button>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "ClaimedParticipantsTableComponent",
    props: ["ParticipantData"],
    data(){
      return {
        Categories: [],
        Data:{
          CmUserId: 0,
          Category: {
            Id: this.ParticipantData.CategoryId,
            DisciplineTitle: this.ParticipantData.DisciplineTitle,
            Title: this.ParticipantData.AgeCategoryTitle,
            AgeMax: this.ParticipantData.AgeMax,
            AgeMin: this.ParticipantData.AgeMin,
            CategorySide: this.ParticipantData.CategorySide,
            WeightMax: this.ParticipantData.WeightMax,
          }
        },
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/get-participant-categories`, { params: {
            cmId: this.$route.params.cmId,
          }
        })
            .then(response => {
              this.Categories=response.data.Categories;
              console.log(this.Categories)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      ChangeParticipantCategory(){
        this.Data.CmUserId=this.ParticipantData.CmUserId;
        this.Data.cmId = this.$route.params.cmId;
        this.Data.ChangeUser = this.$store.state.authUser.id;
        this.$socket.emit('change-participant-category', this.Data);
        this.$modal.hide('ParticipantCategoryModal');
      },
      CategoryLabel (option) {
        if ((typeof option.Title=="undefined")||(typeof option.AgeMin=="undefined")||(typeof option.AgeMax=="undefined")||(typeof option.WeightMax=="undefined")){
          return `-`
        }
        let Side = '';

        if(option.CategorySide==1){
          Side = ' + ';
        }else{
          Side = ' - ';
        }
        return `${option.DisciplineTitle} ${option.Title} age: ${option.AgeMin}-${option.AgeMax} weight: ${Side}${option.WeightMax} kg`
      },
    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      })
      console.log('ParticipantData: ',this.ParticipantData)
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-categories-data');
    },
    components: {
      Multiselect
    },
  };
</script>

<style lang="scss">
  .change-category-modal{
    padding: 5rem 10rem 10rem 10rem;
    &__participant-data{
      padding-bottom: 2rem;
      &--name-surname{
        text-align:center;
      }
    }
    &__set-category-container{
      &--select{
        padding-bottom: 4rem;
      }
      &--save-btn{
        float: right;
        width: 14rem;
        height: 5rem;
      }
    }
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>