<template>
    <div style="color: black; padding: 1rem">
        <form novalidate>
            <h1 style="text-align: center;">Login</h1>
            <hr>
            <label for="email"><b>Email</b></label>
            <input id="email" v-model="user.email" type="text" placeholder="Enter Email" name="email" required ref="email">
            <label for="psw"><b>Password</b></label>
            <input id="psw" v-model="user.psw" type="password" placeholder="Enter Password" name="psw" required>
            <div class="error-msg" v-if="validation.credentialsInvalid"><p>{{ validation.message }}</p></div>
            <h3 style="text-align: left;"><a href="/registration">Reģistracija</a></h3>
            <div class="clearfix">
                <button type="button" class="cancelbtn" @click.prevent="clear">Clear</button>
                <button type="submit" class="signupbtn" @click.prevent="login" v-bind:disabled="validation.formInvalid" >Sign Up</button>
            </div>
        </form>
    </div>
</template>
<script>


    export default{
        name: 'LoginComponent',
        data(){
            return{
                user: {
                    email: '',
                    psw: ''
                },
                validation: {
                    formInvalid: true,
                    credentialsInvalid: false,
                    message: ''
                }

            }
        },
        methods: {
            login (e){
                this.validation.message = '';
                this.$axios.post( '/api/login', this.user )
                  .then( response => {
                    this.validation.credentialsInvalid = false;
                    let user = response.data[0];

                    this.$store.commit('SET_USER', user);
                    this.$modal.hide('login');


                    switch( user.type ){
                        case 1:
                            this.$router.push({ path: `/dashboard` });
                            break;
                        case 2:
                            this.$router.push({ path: `/dashboard` });
                            break;
                        case 3:
                            this.$router.push({ name: 'homePage' });
                            break;
                        default:
                            this.$router.push( { name:"homePage" } );
                    }
                  })
                  .catch( (error) => {
                    this.validation.credentialsInvalid = true;
                    this.validation.message = error.response.data.error;
                  });
            },
            clear(e){
                this.user = {
                    email: '',
                    psw: ''
                }
            }
        },
        mounted () {
            this.$refs.email.focus();
        },
        watch: {
            'user.email': function ( val ) {
                this.validation.formInvalid = ( this.user.email == '' || this.user.psw == '' )
            },
            'user.psw': function ( val ) {
                this.validation.formInvalid = ( this.user.email == '' || this.user.psw == '' )
            }
        }
    }
</script>
<style lang="scss" scoped>
    .error-msg{
        height: 50px;
    }
    input{
        box-sizing:border-box;
    }
    input[type=text], input[type=password] {
        width: 100%;
        padding: 15px;
        margin: 5px 0 22px 0;
        display: block;
        border: none;
        background: #f1f1f1;
    }
    input[type=text]:focus, input[type=password]:focus {
        background-color: #ddd;
        outline: none;
    }
    hr {
        border: 1px solid #f1f1f1;
        margin-bottom: 25px;
    }
    /* Set a style for all buttons */
    button {
        background-color: #4CAF50;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;
    }
    button:hover {
        opacity:1;
    }

    /* Extra styles for the cancel button */
    .cancelbtn {
        padding: 14px 20px;
        background-color: #f44336;
    }
    /* Float cancel and signup buttons and add an equal width */
    .cancelbtn, .signupbtn {
        float: left;
        width: 50%;
    }


    /* Change styles for cancel button and signup button on extra small screens */
    @media screen and (max-width: 300px) {
        .cancelbtn, .signupbtn {
            width: 100%;
        }
    }
</style>