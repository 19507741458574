<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Lietotāji</h1>
      </div>
    </div>
    <div class="page__content">
        <div class="page__content__navbar-container" v-bind:class="{slide : show}">
          <MenuComponent></MenuComponent>
          <div @click="show = !show" class="page__content__navbar-container--open-btn">
            <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
          </div>
        </div>
      <div class="page__content__table">
        <div style="padding: 1rem 4rem;">
          <router-link :to="{ name: 'createUserPage'}" class="btn btn-info" >Pievienot lietotaju +</router-link>
        </div>
        <UsersTableComponent :UsersData="Users"></UsersTableComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import UsersTableComponent from '../../components/admin/CompetitionDataTablesComponents/UsersTableComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "usersPage",
    data(){
      return {
        show: false,
        Users: {},
      }
    },
    methods:{
        getData(){
          this.$axios.get(`/api/getusers`)
              .then(response => {
                  this.Users = response.data.Users;
                  console.log("Dannie zaleteli")
              })
              .catch(e => {
                  this.errors.push(e)
              })
        }
    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-users-data', response => {
          this.getData();
      });
      this.$socket.on('refresh-create-users-claims', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-create-users-claims');
    },
    components:{
        MenuComponent,
      UsersTableComponent
    }
  }
</script>

<style scoped lang="scss">
  .container{
    width:100%;
    height: 20rem;
    background-color: black;
  }

  .page__content{
    float:right;
    width: 100%;
    &__table{
      display: flex;
      flex-direction: column;
      background-color: #eeecf6;
      z-index: 0;
      width: 100%;
      &--list{
      }
    }
  }

</style>