<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Kategorijas labošana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
    <div class="page__content__data">
      <div class="page__content__data__categories">
        <div class="page__content__data__categories__item" v-for="(Discipline, key, index) in Disciplines">
          <div class="page__content__data__categories__item--label">
            <a :href="'/competition/category/discipline/'+$route.params.cmId+'/'+Discipline.CmDisciplineId" style="color: black;">{{Discipline.DisciplineTitle}}</a>
          </div>
          <div class="page__content__data__categories__item__content">
            <table class="table table-bordered page__content__data__categories__item__content__table">
              <tbody style="width: 100%; background: #eeecf6;">
              <tr class="page__content__data__categories__item__content__table--row" v-for="(AgeCategory, key, index) in Discipline" v-if="index>2">
                <td class="page__content__data__categories__item__content__table__cell--title" v-if="index>2">
                  <a :href="'/competition/category/age/'+$route.params.cmId+'/'+Discipline.CmDisciplineId+'/'+AgeCategory.AgeCategoryId">{{AgeCategory.AgeCategoryTitle}}</a>
                  <a style="margin: -1rem 0; color:red;" @click="removeAgeCategory(AgeCategory.DisciplineAgeCategoryId, Discipline.CmDisciplineId, AgeCategory.AgeCategoryId)" href="#">X</a>
                </td>
                <td class="page__content__data__categories__item__content__table__cell--data"
                    v-for="(WeightCategory, key, index) in AgeCategory" v-if="index>2">
                  <a :href="'/competition/category/weight/'+$route.params.cmId+'/'+Discipline.CmDisciplineId+'/'+AgeCategory.AgeCategoryId+'/'+WeightCategory.WeightCategoryId">{{WeightCategory.WeightCategoryTitle}}
                    <br> ({{WeightCategory.BattlesCount}} cīņas)
                    <br> ({{WeightCategory.FightersCount}} cīnītāji(-js))
                  </a>
                  <a style="margin: -1rem 0; color:red;" @click="removeWeightCategory(WeightCategory.AgeWeightCategoryId, WeightCategory.WeightCategoryId)" href="#">X</a>
                </td>
                <td v-if="!((Discipline.DisciplineId==Data.DisciplineId) && (AgeCategory.AgeCategoryId==Data.AgeCategory.Id) && (Data.type=='weight'))" @click="ShowInputWeightCategory(Discipline.DisciplineId,AgeCategory.AgeCategoryId)" class="page__content__data__categories__item__content__table__cell--data">
                  <p style="color: green; font-weight: bold; padding: 2rem; text-align: center; width: 100%; height: 100%; cursor: pointer;">+</p>
                </td>
                <td v-if="((Discipline.DisciplineId==Data.DisciplineId) && (AgeCategory.AgeCategoryId==Data.AgeCategory.Id) && (Data.type=='weight'))" class="page__content__data__categories__item__content__table__cell--title" style="display: flex; flex-direction: row; width:45rem;">
                  <div style="width:34rem; padding-right:2rem;">
                    <multiselect v-model="Data.WeightCategory" :options="WeightCategories" :close-on-select="true"
                                 :clear-on-select="false" :open-direction="'below'"
                                 placeholder="" track-by="Id" :custom-label="WeightCategoryLabel"></multiselect>
                  </div>
                  <button @click="AddWeightCategory()" class="btn btn-primary" >Pievienot</button>
                </td>
              </tr>
              <tr class="page__content__data__categories__item__content__table--row">
                <td v-if="!((Discipline.CmDisciplineId==Data.DisciplineId) && (Data.type=='age'))" @click="ShowInputAgeCategory(Discipline.DisciplineId,Discipline.CmDisciplineId)" class="page__content__data__categories__item__content__table__cell--title">
                  <p style="color: green; font-weight: bold; padding: 2rem; text-align: center; width: 100%; height: 100%; cursor: pointer;">+</p>
                </td>
                <td v-if="((Discipline.CmDisciplineId==Data.DisciplineId) && (Data.type=='age'))" class="page__content__data__categories__item__content__table__cell--title" style="display: flex; flex-direction: row; width:45rem;">
                  <div style="width:34rem; padding-right:2rem;">
                    <multiselect v-model="Data.AgeCategory" :options="AgeCategories" :close-on-select="true"
                                 :clear-on-select="false" :open-direction="'below'"
                                 placeholder="" track-by="Id" :custom-label="AgeCategoryLabel"></multiselect>
                  </div>
                  <button @click="AddAgeCategory(Discipline.DisciplineId)" class="btn btn-primary" >Pievienot</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

  import Multiselect from 'vue-multiselect';
  import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "editCategoriesPage",
    data() {
      return {
        show: false,
        Disciplines: null,
        CompetitionTitle: "",
        Data: {type: '-',DisciplineId: 0,AgeCategory: {}, WeightCategory: {}},
        WeightCategories: [],
        AgeCategories: [],
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/competition/categories`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.Disciplines = response.data.Disciplines;
            this.CompetitionTitle = response.data.CompetitionTitle;
            console.log("Geted data for categories!")
            console.log(this.CompetitionTitle)
            console.log(this.Disciplines)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getWeightCategoryAddingData(){
        this.$axios.get(`/api/competitions/category/weight/data`,{
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.WeightCategories = response.data.WeightCategories;
            console.log(this.WeightCategories)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getAgeCategoryAddingData(disciplineId){
        this.$axios.get(`/api/competitions/category/age/data`,{
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.AgeCategories = response.data.AgeCategories[disciplineId];
            console.log(this.AgeCategories)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      ShowInputWeightCategory(disciplineId,ageCategoryId){
        this.Data={AgeCategory:{}}
        this.getWeightCategoryAddingData();
        this.Data.type = 'weight'
        this.Data.DisciplineId=disciplineId;
        this.Data.AgeCategory.Id=ageCategoryId;
      },
      AddWeightCategory(){
        this.Data.CompetitionId=this.$route.params.cmId
        console.log(this.Data)
        this.$socket.emit('add-weight-category', this.Data);
      },
      ShowInputAgeCategory(disciplineId,cmdisciplineId){
        console.log("disciplineId: ",disciplineId)
        this.Data={}
        this.getAgeCategoryAddingData(disciplineId);
        this.Data.type = 'age'
        this.Data.DisciplineId=cmdisciplineId;
      },
      AddAgeCategory(){
        this.Data.CompetitionId=this.$route.params.cmId
        console.log(this.Data)
        this.$socket.emit('add-age-category', this.Data);
      },
      removeWeightCategory(categoryId,weightCategoryId){
        this.Data.CompetitionId=this.$route.params.cmId
        this.Data.CategoryId=categoryId
        this.Data.WeightCategoryId=weightCategoryId
        console.log("removeData: ",this.Data)
        let ApproveDelete = confirm('Are you sure want to remove this category?')
        if (ApproveDelete==true) {
          this.$socket.emit('remove-weight-category', this.Data);
        }
      },
      removeAgeCategory(categoryId,disciplineId,ageCategoryId){
        this.Data.CompetitionId=this.$route.params.cmId
        this.Data.CategoryId=categoryId
        this.Data.AgeCategoryId=ageCategoryId
        this.Data.DisciplineId=disciplineId
        console.log("removeData: ",this.Data)
        let ApproveDelete = confirm('Are you sure want to remove this category?')
        if (ApproveDelete==true) {
          this.$socket.emit('remove-age-category', this.Data);
        }
      },
      WeightCategoryLabel (option) {
        if (typeof option.WeightMax=='undefined'){return `-`}
        return `${option.WeightMax} kg`
      },
      AgeCategoryLabel (option) {
        if ((typeof option.Title=='undefined') && (typeof option.AgeMin=='undefined') && (typeof option.AgeMax=='undefined')){return `-`}
        return `${option.Title} age: ${option.AgeMin}-${option.AgeMax} ${option.Rounds}x${option.RoundTime} min`
      },
    },
    mounted() {
      this.getData()

      this.$socket.on('success', response => {
        if (response!=true){
          alert(response);
        }
      });
      this.$socket.on('refresh-agecategories-data', response => {
        this.Data = {
          type: '-',
          DisciplineId: 0,
          AgeCategory: {},
          WeightCategory: {}
        }
        this.getData()
      })

      this.$socket.on('refresh-weightcategories-data', response => {
        this.Data = {
          type: '-',
          DisciplineId: 0,
          AgeCategory: {},
          WeightCategory: {}
        }
        this.getData()
      })

      this.$socket.on('refresh-categories-data', response => {
        this.Data = {
          type: '-',
          DisciplineId: 0,
          AgeCategory: {},
          WeightCategory: {}
        }
        this.getData()
      });
    },
    beforeDestroy() {
      this.$socket.removeListener('refresh-agecategories-data');
      this.$socket.removeListener('refresh-weightcategories-data');
      this.$socket.removeListener('refresh-categories-data');
      this.$socket.removeListener('success');
    },
    components: {
      "multiselect": Multiselect,MenuComponent
    }
  }
</script>

<style lang="scss" >
  /* .multiselect__input{
     width:16rem !important;
   }*/
  /*body {
    background: linear-gradient(to top left, #fefcea, #f1da36);
  }*/
  /*page__content__data__categories__item__content__table__cell--title*/
  /*.page__content__data__categories__item__content__table__cell--data{
    width: 9rem;
    height: 6rem;
  }*/

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>