var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    Object.keys(_vm.fight).length
      ? _c(
          "div",
          {},
          [
            _c("div", { staticClass: "fight-info" }, [
              _c("div", { staticClass: "fight-info__header" }, [
                _c("h1", { staticClass: "fight-info__header--item" }, [
                  _vm._v(_vm._s(_vm.fight.title))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("FightInfo", { attrs: { fight: _vm.fight } }),
            _vm._v(" "),
            _vm.fight.judges
              ? _c(
                  "div",
                  { staticClass: "difference-chart" },
                  _vm._l(_vm.fight.judges, function(judge) {
                    return _c(
                      "div",
                      { staticClass: "difference-chart__item" },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              judge.red_score - judge.blue_score < 0
                                ? "col-md-push-6"
                                : "",
                              "col-md-6"
                            ]
                          },
                          _vm._l(
                            Math.abs(judge.red_score - judge.blue_score),
                            function(n) {
                              return _c("div", {
                                staticClass: "difference-chart__item__point",
                                class: [
                                  judge.red_score - judge.blue_score > 0
                                    ? "difference-chart__item__point--positive"
                                    : "difference-chart__item__point--negative"
                                ]
                              })
                            }
                          )
                        )
                      ]
                    )
                  })
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.fight.battle_status === 2
              ? _c("div", [
                  _vm.fight.winner_id === _vm.fight.red_user_id
                    ? _c(
                        "h1",
                        {
                          staticStyle: { width: "100%", "text-align": "center" }
                        },
                        [
                          _vm._v(
                            "Winner is " +
                              _vm._s(_vm.fight.red_name) +
                              " " +
                              _vm._s(_vm.fight.red_surname) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fight.winner_id === _vm.fight.blue_user_id
                    ? _c(
                        "h1",
                        {
                          staticStyle: { width: "100%", "text-align": "center" }
                        },
                        [
                          _vm._v(
                            "Winner is  " +
                              _vm._s(_vm.fight.blue_name) +
                              " " +
                              _vm._s(_vm.fight.blue_surname) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.fight.finalScore
              ? _c("div", { staticClass: "fighters-score" }, [
                  _c("h1", { staticClass: "fighters-score__item" }, [
                    _vm._v(_vm._s(_vm.fight.finalScore.red))
                  ]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "fighters-score__item" }, [
                    _vm._v(" : ")
                  ]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "fighters-score__item" }, [
                    _vm._v(_vm._s(_vm.fight.finalScore.blue))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.fight.judges
              ? _c(
                  "div",
                  { staticClass: "judges-score" },
                  _vm._l(_vm.fight.judges, function(judge, index) {
                    return _c("div", { staticClass: "judges-score__item" }, [
                      _c("div", { staticClass: "judges-score__item__result" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "judges-score__item__result__score--red"
                          },
                          [_vm._v(_vm._s(judge.red_score))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "judges-score__item__result__score" },
                          [_vm._v(":")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "judges-score__item__result__score--blue"
                          },
                          [_vm._v(_vm._s(judge.blue_score))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("h1", { staticClass: "judges-score__item__name" }, [
                        _vm._v(_vm._s(judge.name + " " + judge.surname))
                      ])
                    ])
                  })
                )
              : _vm._e()
          ],
          1
        )
      : _c(
          "h1",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              "margin-top": "8rem"
            }
          },
          [_vm._v("No fight")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4bce7fda", { render: render, staticRenderFns: staticRenderFns })
  }
}