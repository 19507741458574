<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Cīņu stili</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__table">
        <div style="padding: 1rem 4rem;">
          <router-link class="btn btn-info"  :to="{ name: 'createStylePage'}" >Izveidot stilu +</router-link>
        </div>
        <StylesTableComponent :StylesData="Styles"></StylesTableComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import StylesTableComponent from '../../components/admin/CompetitionDataTablesComponents/StylesTableComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "stylesPage",
      data(){
      return {
        show: false,
        Styles: [],
      }
    },
    methods:{
      getData(){
        this.$axios.get(`/api/styles`)
            .then(response => {
                this.Styles = response.data.Styles;
                console.log(this.Disciplines);
            })
            .catch(e => {
                this.errors.push(e)
            })
      },
    },
    mounted() {
        this.getData()
        this.$socket.on('refresh-styles-data', response => {
            this.getData();
        });
    },
    beforeDestroy () {
        this.$socket.removeListener('refresh-styles-data');
    },
    components:{
      MenuComponent,
      StylesTableComponent
    }
  }
</script>

<style scoped lang="scss">

</style>