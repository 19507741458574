<template>
  <div class="col-md-12 clear-pads judge-statuses text-center bold">
    <div class="col-md-12 clear-pads">
      <button type="button" class="close" @click="hideModal">
        <span>&times;</span>
      </button>
    </div>
    <div class="col-md-4 item" v-for="judge in judges">
      <div class="col-md-12 clear-pads judge">{{ judge.name }} {{ judge.surname }}</div>
      <div v-if="judge.status === 0" class="col-md-12 clear-pads status red">Refused</div>
      <div v-if="judge.status === 1" class="col-md-12 clear-pads status green">Ready</div>
      <div v-if="judge.status === null" class="col-md-12 clear-pads status gray">?</div>
    </div>
  </div>
</template>
<script>

  export default{
    name: 'ReadyCheck',

    props: [ 'judges' ],
    methods: {
      hideModal(){
        this.$modal.hide('ready-check');
      }
    },
    mounted() {}
  }
</script>
<style lang="scss" scoped>
  .judge-statuses{
    padding-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 15px;

  }
  .item{}

  .judge, .status{
    margin-bottom: 15px;
  }
</style>