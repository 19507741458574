var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__table" },
        [
          _c("div", { staticStyle: { padding: "1rem 4rem" } }, [
            _vm.CreationForm
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.CreationForm = !_vm.CreationForm
                      }
                    }
                  },
                  [_vm._v("Izveidot svaras kategoriju +")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.CreationForm
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.CreationForm = !_vm.CreationForm
                      }
                    }
                  },
                  [_vm._v("Aizvert")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.CreationForm
            ? _c(
                "div",
                {
                  staticClass:
                    "display: flex; flex-direction: row; flex-wrap: wrap;"
                },
                [
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-left": "1.5rem"
                      }
                    },
                    [_vm._v("Maksimalais svars ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.WeightCategoryData.MaxWeight,
                        expression: "WeightCategoryData.MaxWeight"
                      }
                    ],
                    staticClass: " control-label",
                    staticStyle: {
                      "text-align": "left",
                      "padding-left": "1.5rem"
                    },
                    domProps: { value: _vm.WeightCategoryData.MaxWeight },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.WeightCategoryData,
                          "MaxWeight",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-left": "1.5rem"
                      }
                    },
                    [_vm._v("More ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.WeightCategoryData.More,
                        expression: "WeightCategoryData.More"
                      }
                    ],
                    staticStyle: {
                      "text-align": "left",
                      "margin-left": "1.5rem",
                      width: "2rem",
                      height: "2rem"
                    },
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.WeightCategoryData.More)
                        ? _vm._i(_vm.WeightCategoryData.More, null) > -1
                        : _vm.WeightCategoryData.More
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.WeightCategoryData.More,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.WeightCategoryData,
                                "More",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.WeightCategoryData,
                                "More",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.WeightCategoryData, "More", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          _vm.createWeightCategory()
                        }
                      }
                    },
                    [_vm._v("Izveidot svaras kategoriju")]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("weight-categories-grid", {
            attrs: { WeightCategoriesData: _vm.WeightCategories }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Svara kategorijas saraksts")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-580e17da", { render: render, staticRenderFns: staticRenderFns })
  }
}