var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__data" },
          [
            _c(
              "H1",
              {
                staticClass: "page__content__data--header",
                staticStyle: { color: "black" }
              },
              [_vm._v(_vm._s(_vm.CompetitionTitle))]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
              [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm.$store.state.authUser &&
                (_vm.$store.state.authUser.type === 1 ||
                  _vm.$store.state.authUser.type === 4 ||
                  _vm.$store.state.authUser.type === 6)
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "applyParticipantPage" } } },
                          [
                            _vm._v(
                              "\n            Pieteikt dalibnieku +\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.state.authUser &&
                (_vm.$store.state.authUser.type === 1 ||
                  _vm.$store.state.authUser.type === 4 ||
                  _vm.$store.state.authUser.type === 6)
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "applyTeamPage" } } },
                          [
                            _vm._v(
                              "\n            Pieteikt komandu +\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.state.authUser &&
                (_vm.$store.state.authUser.type === 1 ||
                  _vm.$store.state.authUser.type === 6)
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.ShowWeighingModal }
                        },
                        [_vm._v("\n            Sveršana\n          ")]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane active",
                  attrs: { id: "confirmedparticipants", role: "tabpanel" }
                },
                [
                  _vm.$store.state.authUser &&
                  (_vm.$store.state.authUser.type === 1 ||
                    _vm.$store.state.authUser.type === 6)
                    ? _c("admin-participants-table", {
                        attrs: { ParticipantsData: _vm.Participants.Approved }
                      })
                    : _c("public-participants-table", {
                        attrs: { ParticipantsData: _vm.Participants.Approved }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  attrs: { id: "unconfirmedparticipants", role: "tabpanel" }
                },
                [
                  _vm.$store.state.authUser &&
                  (_vm.$store.state.authUser.type === 1 ||
                    _vm.$store.state.authUser.type === 6)
                    ? _c("admin-participants-table", {
                        attrs: {
                          ParticipantsData: _vm.Participants.DisApproved
                        }
                      })
                    : _c("public-participants-table", {
                        attrs: {
                          ParticipantsData: _vm.Participants.DisApproved
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  attrs: { id: "externalclaims", role: "tabpanel" }
                },
                [
                  _vm.$store.state.authUser &&
                  (_vm.$store.state.authUser.type === 1 ||
                    _vm.$store.state.authUser.type === 6)
                    ? _c("admin-external-claims-table", {
                        attrs: { ParticipantsData: _vm.Participants.External }
                      })
                    : _c("public-external-claims-table", {
                        attrs: { ParticipantsData: _vm.Participants.External }
                      })
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.$store.state.authUser &&
      (_vm.$store.state.authUser.type === 1 ||
        _vm.$store.state.authUser.type === 4 ||
        _vm.$store.state.authUser.type === 6)
        ? _c(
            "modal",
            {
              attrs: {
                name: "TeamSelectModal",
                height: 400,
                clickToClose: false
              }
            },
            [_c("SelectTeamModalComponent")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser &&
      (_vm.$store.state.authUser.type === 1 ||
        _vm.$store.state.authUser.type === 4 ||
        _vm.$store.state.authUser.type === 6)
        ? _c(
            "modal",
            {
              attrs: {
                name: "ApplyTeamModal",
                clickToClose: false,
                scrollable: true,
                height: "auto"
              }
            },
            [
              _c("ApplyTeamModalComponent", {
                attrs: { TeamData: _vm.TeamData }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser &&
      (_vm.$store.state.authUser.type === 1 ||
        _vm.$store.state.authUser.type === 6)
        ? _c(
            "modal",
            {
              attrs: {
                name: "WeighingModal",
                clickToClose: false,
                height: "auto"
              }
            },
            [_c("WeighingModalComponent")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Iesniegumi sacensibai")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "active" }, [
      _c(
        "a",
        {
          attrs: {
            "data-toggle": "tab",
            href: "#confirmedparticipants",
            role: "tab"
          }
        },
        [_vm._v("\n            Apstiprinatie dalibnieki\n          ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            "data-toggle": "tab",
            href: "#unconfirmedparticipants",
            role: "tab"
          }
        },
        [_vm._v("\n            Neapstiprinatie dalibnieki\n          ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: { "data-toggle": "tab", href: "#externalclaims", role: "tab" }
        },
        [_vm._v("\n            Pieteikumi pārskatā\n          ")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b1cde99", { render: render, staticRenderFns: staticRenderFns })
  }
}