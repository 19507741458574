var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.RingTitle))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__full-view-page-content" }, [
      _c(
        "div",
        { staticClass: "page__full-view-page-content__data" },
        [
          _c("public-ring-battles", {
            attrs: {
              BattlesData: _vm.RingBattles,
              Url: "http://localhost:8000" + _vm.$route.path + "/" + _vm.RingId
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d678818", { render: render, staticRenderFns: staticRenderFns })
  }
}