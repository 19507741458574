var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("ul", { staticClass: "nav nav-tabs", attrs: { role: "tablist" } }, [
      _c("li", { staticClass: "active" }, [
        _c(
          "a",
          {
            attrs: {
              id: "participants-tab",
              "data-toggle": "tab",
              href:
                "#participants-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tab"
            }
          },
          [_vm._v("\n        Pieteiktie dalībnieki")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              id: "battles-list-tab",
              "data-toggle": "tab",
              href:
                "#battleslist-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tab"
            }
          },
          [_vm._v("\n        Cīņu saraksts")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              id: "battles-graph-tab",
              "data-toggle": "tab",
              href:
                "#battlesgraph-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tab"
            }
          },
          [_vm._v("\n        Cīņu grafiks")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              id: "lozesana-tab",
              "data-toggle": "tab",
              href:
                "#lozesana-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tab"
            }
          },
          [_vm._v("\n        Lozesana")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function($event) {
                _vm.ShowAddCategory()
              }
            }
          },
          [_vm._v("\n        Pievienot Kategoriju")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content", staticStyle: { overflow: "auto" } },
      [
        _c(
          "div",
          {
            staticClass: "tab-pane active",
            attrs: {
              id:
                "participants-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tabpanel"
            }
          },
          [
            _c("ParticipantsTableComponent", {
              attrs: { FighterData: _vm.FightersData }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-pane",
            attrs: {
              id:
                "battleslist-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tabpanel"
            }
          },
          [
            _c("BattlesListTableComponent", {
              attrs: { BattlesListData: _vm.BattlesData }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-pane",
            attrs: {
              id:
                "battlesgraph-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tabpanel"
            }
          },
          [
            _c("BattlesGraphComponent", {
              attrs: { BattlesGraphData: _vm.BattlesGraph }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-pane",
            attrs: {
              id:
                "lozesana-" +
                _vm.FightersData[0].AgeCategId +
                _vm.FightersData[0].WeightCategoryId,
              role: "tabpanel"
            }
          },
          [
            _c("LozesanaTableComponent", {
              attrs: { ParticipantsLozesanaiData: _vm.ParticipantsLozesanai }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-55f11296", { render: render, staticRenderFns: staticRenderFns })
  }
}