<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="ParticipantsData"
      @on-cell-click="onCellClick"
      :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <div class="btn-group">
            <button class="btn btn-primary btn-sm"  v-on:click="showCmClaim(props.row.UnkUserId)">Apskatit</button>
            <button class="btn btn-danger btn-sm"  v-on:click="deleteUserClaim(props.row.Id)">Dzest</button>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "ExternalClaimsTableComponent",
    props: ["ParticipantsData"],
    data(){
      return {
        columns: [
          {
            label: 'Dalibnieks',
            field: 'NameSurname',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Svars',
            field: 'Weight',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Komanda',
            field: 'TeamTitle',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Treneris',
            field: 'Coach',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Kategorija',
            field: 'Category',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Darbibas',
            field: 'Actions',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
      }
    },
    methods: {
      showCmClaim(q) {
      /*  this.$socket.emit('show-unk-cmuser', {
          cmuserId: q,
        });
*/
        this.$router.push({name: 'createCmUserClaimPage', params: {unkuserId: q, cmId: this.$route.params.cmId } })
      },
      deleteUserClaim(q) {
        this.$socket.emit('delete-single-unk-cmuser', {
          cmuserId: q
        });
      }
/*      deleteUserClaim(q) {
          this.$socket.emit('delete-cmuser', {
              usercmId: q,
              cmId: this.$route.params.cmId
          });
        /!*this.$axios.post(`/api/deletecmuser`,{
          usercmId: q,
        })
          .then(response => {
          })
          .catch(e => {
            this.errors.push(e)
          })*!/
      },*/
    },
    mounted() {
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>