var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-component" }, [
    _c("div", { staticClass: "vue-good-table" }, [
      _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
        _c("div", { staticClass: "vgt-global-search__input vgt-pull-left" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.string,
                expression: "string"
              }
            ],
            staticClass: "vgt-input vgt-pull-left",
            attrs: { type: "text", placeholder: "Search Table" },
            domProps: { value: _vm.string },
            on: {
              keydown: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                _vm.searchValue(_vm.string)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.string = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-component__container" }, [
        _c(
          "table",
          {
            staticClass: "table table-bordered",
            staticStyle: { "font-size": "16px" }
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.ResultsData, function(data) {
                  return !_vm.Search
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(data.Place))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.DataForRouter.ringId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "teamReportResultsInRingPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          ringId: _vm.DataForRouter.ringId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.discipId &&
                                !_vm.DataForRouter.ageCategId &&
                                !_vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInDisciplinePage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          discipId: _vm.DataForRouter.discipId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.ageCategId &&
                                !_vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInAgeCategoryPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          ageCategId:
                                            _vm.DataForRouter.ageCategId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInWeightCategoryPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          weightCategId:
                                            _vm.DataForRouter.weightCategId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "teamReportResultsPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(data.Approved) +
                              "/(" +
                              _vm._s(data.AllCmUsers) +
                              ")"
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Gold))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Silver))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Bronze))])
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.ResultsSearch, function(data, index) {
                  return _vm.Search && index != 0
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(data.Place))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.DataForRouter.ringId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "teamReportResultsInRingPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          ringId: _vm.DataForRouter.ringId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.discipId &&
                                !_vm.DataForRouter.ageCategId &&
                                !_vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInDisciplinePage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          discipId: _vm.DataForRouter.discipId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.ageCategId &&
                                !_vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInAgeCategoryPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          ageCategId:
                                            _vm.DataForRouter.ageCategId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _vm.DataForRouter.weightCategId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "teamReportResultsInWeightCategoryPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id,
                                          weightCategId:
                                            _vm.DataForRouter.weightCategId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "teamReportResultsPage",
                                        params: {
                                          cmId: _vm.$route.params.cmId,
                                          teamId: data.Id
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(data.Title))]
                                )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(data.Approved) +
                              "/(" +
                              _vm._s(data.AllCmUsers) +
                              ")"
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Gold))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Silver))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.Bronze))])
                      ])
                    : _vm._e()
                })
              ],
              2
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input__icon" }, [
      _c("div", { staticClass: "magnifying-glass" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Vieta")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nosaukums")]),
        _vm._v(" "),
        _c("th", [_vm._v("Pieteiktie - apstiprinātie ( kopā )")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zelts")]),
        _vm._v(" "),
        _c("th", [_vm._v("Sudrabs")]),
        _vm._v(" "),
        _c("th", [_vm._v("Bronza")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-eae5836e", { render: render, staticRenderFns: staticRenderFns })
  }
}