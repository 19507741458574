<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section--cover-img ">
        <img src="/static/thumb.jpg" height="100%">
      </div>
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Sacensību saraksts</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__table">
        <div v-if="$store.state.authUser && $store.state.authUser.type === 1" class="page__content__table__actions">
          <div class="page__content__table__actions--button">
            <router-link class="circle-btn" :to="{ name: 'createCompetitionPage'}">+</router-link>
            <router-link class="square-btn" :to="{ name: 'createCompetitionPage'}">Pievienot sacinsību</router-link>
          </div>
        </div>
        <admin-competitions-list v-if="$store.state.authUser && $store.state.authUser.type === 1" :CmNtfcId="CmNtfcId"
                                 :CompetitionsData="Competitions"
                                 class="page__content__table--list"></admin-competitions-list>
        <public-competitions-list v-if="!$store.state.authUser" :CompetitionsData="Competitions"
                                  class="page__content__table--list"></public-competitions-list>
        <public-competitions-list v-if="$store.state.authUser && !($store.state.authUser.type === 1)"
                                  :CmNtfcId="CmNtfcId" :CompetitionsData="Competitions"
                                  class="page__content__table--list"></public-competitions-list>
        <div class="info-text" v-if="Competitions.length===0"><h2>Pagaidam nav ne vienas sacensības</h2></div>
      </div>
    </div>
  </div>
</template>

<script>
  import AdminCompetitionsListComponent
    from '../../components/admin/CompetitionDataTablesComponents/CompetitionsListComponent.vue';

  import PublicCompetitionsListComponent
    from '../../components/public/CompetitionDataTablesComponents/CompetitionsListComponent.vue';

  import MenuComponent from '../../components/common/MenuComponent.vue';

  export default {
    name: "competitionsPage",
    data() {
      return {
        show: false,
        Competitions: [],
        CmNtfcId: null
      }
    },
    methods: {
      getNotificationOption() {
        this.$axios.get('api/notification-competition', {
          params: {
            UserId: this.$store.state.authUser.id
          }
        })
            .then(response => {
              this.CmNtfcId = response.data.CmNtfcId;
              console.log(this.CmNtfcId)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      getData() {
        if (this.$store.state.authUser) {
          this.getNotificationOption()
        }
        this.$axios.get(`/api/competitions`)
            .then(response => {
              this.Competitions = response.data.Competitions;
              console.log(this.Competitions)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
    },
    created() {
      this.getData()
      this.$socket.on('refresh-competition-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-competitions-list', response => {
        this.getData();
      });

    },
    beforeDestroy() {
      this.$socket.removeListener('refresh-competition-data');
      this.$socket.removeListener('refresh-competitions-list');
    },
    components: {
      'admin-competitions-list': AdminCompetitionsListComponent,
      'public-competitions-list': PublicCompetitionsListComponent,
      MenuComponent
    }
  }
</script>

<style scoped lang="scss">


  body {
    font-size: 14px;
  }

  * {
    box-sizing: border-box;
  }

/*  .circle-btn {
    display: block;
    color: #fff;
    width: 6rem;
    height: 6rem;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    background: #428bca;
    border-radius: 6rem;
    padding-top: 1rem;
    padding-left: 0.2rem;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
  }

  .square-btn {
   !* display: none;*!
    height: 4.5rem;
    margin: 0.8rem -1.5rem;
    background: #428bca;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-right: 2rem;
    color: white;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }*/

  .info-text{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6rem 6rem 12rem;
  }

</style>