<template>
  <div>
          <vue-good-table
            :columns="columns"
            :rows="BattlesListData"
            @on-cell-click="onCellClick"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 10,
            }"
            styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
            </template>
          </vue-good-table>
  </div>
</template>

<script>
  export default {
    name: 'BattlesListTableComponent',
    props: ["BattlesListData"],
    data(){
      return {
        columns: [
          {
            label: 'Nr',
            field: 'Nr',
            thClass: 'text-center',
            tdClass: 'text-center',
            type: 'number',
          },
          {
            label: 'Limenis',
            field: 'lvl',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Komanda',
            field: 'RedTeam',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Sarkanais sturis',
            field: 'RedNameSurname',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Resultats',
            field: 'Result',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Zils Sturis',
            field: 'BlueNameSurname',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Komanda',
            field: 'BlueTeam',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
      }
    },
    methods: {

    },
    components: {

    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }

</style>