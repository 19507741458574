import store from "./store";
import axios from 'axios'
export default {
  authCheck (to, from, next) {



    if(!store.state.authUser){
      axios.get( '/api/user')
        .then( response => {
            if(response.data) {
              store.commit('SET_USER', response.data[0]);

              let meta = {
                IsSec: to.matched.some(record => record.meta.isSecretary),
                IsCoach: to.matched.some(record => record.meta.isCoach),
                IsJudge: to.matched.some(record => record.meta.isJudge),
                IsAdmin: to.matched.some(record => record.meta.isAdmin),
                IsDoctor: to.matched.some(record => record.meta.isDoctor),
              };

              let MetaEmpty=true;

              Object.keys(meta).forEach((key) => {
                let metaParam=meta[key]
                if (metaParam==true){
                  return MetaEmpty=false;
                }
              })
              /* meta

*/
              if (MetaEmpty==false) {
                let stateData = store.state;
                if (stateData.authUser.type == 1) {
                  if (to.matched.some(record => record.meta.isSecretary)) {
                    next()
                  }
                  else {
                    next({
                      name: 'competitionsPage'
                    })
                  }
                }
                if (stateData.authUser.type == 3) {
                  if (to.matched.some(record => record.meta.isJudge)) {
                    next()
                  }
                  else {
                    next({
                      name: 'competitionsPage'
                    })
                  }
                }
                if (stateData.authUser.type == 4) {
                  if (to.matched.some(record => record.meta.isCoach)) {
                    next()
                  }
                  else {
                    next({
                      name: 'competitionsPage'
                    })
                  }
                }
                if (stateData.authUser.type == 6) {
                  if (to.matched.some(record => record.meta.isDoctor)) {
                    next()
                  }
                  else {
                    next({
                      name: 'competitionsPage'
                    })
                  }
                }
              } else {
                next()
              }
              /* if (to.matched.some(record => record.meta.isSecretary)) {
                   console.log(store.state);
                   console.log(store.state.authUser);
                   let stateData = store.state;
                   if (stateData.authUser.type == 1) {
                       next()
                   }
                   else {
                       next({
                           name: 'competitionsPage'
                       })
                   }
               } else {
                   next()
               }
               if (to.matched.some(record => record.meta.isAdmin)) {
                   console.log(store.state);
                   console.log(store.state.authUser);
                   console.log(store.state.authUser.type);
                   console.log(store.state.authUser.type == 2);
                   let stateData = store.state;
                   if (stateData.authUser.type == 2) {
                       next()
                   }
                   else {
                       next({
                           name: 'competitionsPage'
                       })
                   }
               } else {
                   next()
               }
               if (to.matched.some(record => record.meta.isCoach)) {
                   console.log(store.state);
                   console.log(store.state.authUser);
                   console.log(store.state.authUser.type);
                   console.log(store.state.authUser.type == 4);
                   let stateData = store.state;
                   if (stateData.authUser.type == 4) {
                       next()
                   }
                   else {
                       next({
                           name: 'competitionsPage'
                       })
                   }
               } else {
                   next()
               }
               if (to.matched.some(record => record.meta.isJudge)) {
                   console.log(store.state)
                   console.log(store.state.authUser)
                   let stateData = store.state
                   if (stateData.authUser.type == 3) {
                       next()
                   }
                   else {
                       next({
                           name: 'competitionsPage'
                       })
                   }
               } else {
                   next()
               }
           }*/
            } else {
              if(to.meta.requiresAuth) {
                next({
                  name: 'homePage'
                })
              } else {
                next()
              }
            }
        })
        .catch( (error) => {

        });
    }else {
      next()
    }
    }

}

/*
else if(to.meta.isAdmin){
    if(response.data[0].type == 2){
        next()
    }
    else{
        next({
            name: 'competitionsPage'
        })
    }
}*/
/*

if(to.meta.isAdmin) {
    if (response.data[0].type == 2) {
        next({
            name: 'Home'
        })
    }
    else {
        next({
            name: 'competitionsPage'
        })
    }
}else{next()}*/


/*

if(!store.state.authUser){
    axios.get( '/api/user')
        .then( response => {
            if(response.data){
                store.commit('SET_USER', response.data[0]);
                next()
            }
            else {
                if(to.meta.requiresAuth) {
                    next({
                        name: 'Home'
                    })
                } else {
                    next()
                }
            }
        })
        .catch( (error) => {

        });
}else {
    next()
}
}
}*/
