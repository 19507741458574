<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="RemoteControls"
      @on-cell-click="onCellClick"
      :search-options="{
          enabled: true,
          trigger: 'enter'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 30,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <button v-if="!props.row.Active" class="btn btn-primary" v-on:click="rewriteRemoteKey(props.row.RemoteAddress, props.row.CmRemoteId)">Aktivet</button>
          <button v-if="props.row.Active" class="btn btn-danger" v-on:click="deactivateRemoteKey(props.row.RemoteAddress,props.row.CmRemoteId,props.row.RemoteId)">Deaktivet</button>
          <button class="btn btn-danger" v-on:click="deleteRemoteControl(props.row.RemoteAddress,props.row.CmRemoteId)">Dzest</button>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "CompetitionRemotesTableComponent",
    props: ["RemoteControls"],
    data(){
      return {
        columns: [
          {
            label: 'Adrese',
            field: 'RemoteAddress',
          },
          {
            label: 'Identifikators',
            field: 'RemoteKey',
          },
          {
            label: 'Tiesnesis',
            field: 'JudgeNameSurname',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
              label: 'Darbibas',
              field: 'Actions',
              thClass: 'text-center',
              tdClass: 'text-center',
          },
        ],
        CmId: null,
      }
    },
    methods: {
      rewriteRemoteKey(RemoteAddress,CmRemoteId){
        console.log('RemoteAddress: ',RemoteAddress,' RemoteId: ',CmRemoteId)
        this.$socket.emit('rewrite-remote-key', {RemoteAddress: RemoteAddress,CmRemoteId: CmRemoteId})
      },
      deactivateRemoteKey(RemoteAddress,CmRemoteId,RemoteId){
        console.log('deactivate-remote-competition!')
        this.$socket.emit('deactivate-remote-competition', {RemoteAddress: RemoteAddress, CmRemoteId: `${CmRemoteId}`, RemoteId: `${RemoteId}`})
      },
      deleteRemoteControl(RemoteAddress,CmRemoteId) {
        this.$socket.emit('delete-competition-remote', {CmRemoteId: `${CmRemoteId}`, RemoteAddress: RemoteAddress})
      },
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }

</style>