<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Vecuma kategorijas veidošana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.Title">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Īsais Nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.Short">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Gender<sup>*</sup></label>
            <multiselect v-model="AgeCategoryData.Gender" :options="Genders" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" >
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Minimalais vecums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.MinAge">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Maksimalais vecums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.MaxAge">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Raunžu skaits<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.Rounds">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Raunda īlgums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.RoundTime">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Pārtrukuma īlgums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="AgeCategoryData.RestTime">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Iepriekšsakšanas laiks<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="AgeCategoryData.PreliminaryTime">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Discipline<sup>*</sup></label>
            <multiselect v-model="AgeCategoryData.Discipline" :options="Disciplines" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="DisciplineTitle"></multiselect>
          </div>
        </div>
        <div class="page__content__input__form__btn-item">
          <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="CreateCategory">Izveidot</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "createAgeCategoryPage",
    data(){
      return {
        show: false,
        Disciplines: [],
        Genders: [],
        AgeCategoryData: {
            Title: "",
            MinAge: "",
            MaxAge: "",
            Short: "",
            Rounds: "",
            RoundTime: "",
            RestTime: "",
            PreliminaryTime: "",
            Gender: {},
            Discipline: {Id: "", Title: ""},
        },
      }
    },
    methods: {
      CreateCategory(){
          this.$socket.emit('create-age-category', this.AgeCategoryData);
      },
      DisciplineTitle (option) {
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
    },
    mounted() {
      this.$axios.get(`/api/categories/age/createdata`,{
      })
        .then(response => {
          this.Genders = response.data.Genders;
          this.Disciplines = response.data.Disciplines;
          console.log(this.Disciplines)
        })
        .catch(e => {
          this.errors.push(e)
        })
        this.$socket.on('success', response => {
            if (response===true){
                this.$router.push('/categories/age')
            }else{alert("Check all fields on filled right")}
        });
    },
    beforeDestroy () {
        this.$socket.removeListener('success');
    },
    components: {
        MenuComponent,
        "multiselect": Multiselect
    }
  }
</script>

<style lang="scss" scoped>
/*  .page__content{
    &__input__form{
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: 0.5s;
      transform: translateX(-5%);
      background-color: #eeecf6;
      z-index: 0;
      padding: 7rem 20rem;
      &__item{
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;
        &--label{
          text-align: right;
        }
        &__input{
        }
        &--btn{
          text-align: left;
          padding-right: 3rem;
          width: 15rem;
        }
      }
    }
  }*/

  #app {
    margin-top: 20px;
  }

  pre {
    margin-top: 20px;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
