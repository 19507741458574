<template>
	<div class="page">
		<div class="page__section">
			<div class="page__section__name-container">
				<h1 class="page__section__name-container--title">Disciplines</h1>
			</div>
			<div class="page__section__sort">
				<select class="page__section__sort--select" v-model="SelectedDiscipline" v-on:change="getSelected">
					<option v-for="(discipline, key) in Disciplines" :value="discipline" selected="selected">{{ key }}</option>
				</select>
			</div>
			<div class="page__section__team-sort">
				<a style="padding-left: 1rem; padding-right: 1rem; cursor: pointer;" @click="clearTeamSelect">Komanda:</a>
				<select class="page__section__team-sort--select" v-model="SelectedTeam" v-on:change="getSelected">
					<option v-for="team in Teams" :value="team.Id" selected="selected">{{ team.Title }}</option>
				</select>
			</div>
		</div>
		<div class="page__content">
			<div class="page__content__navbar-container" v-bind:class="{slide : show}">
				<MenuComponent></MenuComponent>
				<div @click="show = !show" class="page__content__navbar-container--open-btn">
					<span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
				</div>
			</div>
			<div class="page__content__data">
				<div class="emptycategory" v-if="JSON.stringify(BattlesInWeightCategory)=='{}'">
					<h1 >Šeit pagaidam nav ne viena ciņa</h1>
				</div>
				<div v-for="(AgeCategory, AgeCategoryTitle) in Fighters">
					<div v-for="(WeightCategory, index, key) in AgeCategory">

						<a v-if="key==0" class="page__content__data--header" :href="'/competition/category/age/'+$route.params.cmId+'/'+SelectedDiscipline+'/'+WeightCategory[0].CmAgeCategoryId">{{ WeightCategory[0].AgeCategoryTitle }}</a><br>
						<a class="page__content__data--header" :href="'/competition/category/weight/'+$route.params.cmId+'/'+SelectedDiscipline+'/'+WeightCategory[0].CmAgeCategoryId+'/'+WeightCategory[0].CmWeightCategoryId">{{ WeightCategory[0].WeightCategoryTitle }} Kg</a>

						<AdminNavigationInCategoryComponent v-if="$store.state.authUser && $store.state.authUser.type === 1" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]" :ParticipantsLozesanai="FightersLozesanai[AgeCategoryTitle][index]"></AdminNavigationInCategoryComponent>
						<PublicNavigationInCategoryComponent v-if="!$store.state.authUser" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
						<PublicNavigationInCategoryComponent v-if="$store.state.authUser && !($store.state.authUser.type === 1)" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
					</div>
				</div>
			</div>
		</div>
		<modal name="ParticipantCategoryModal" :width="900" :height="400">
			<ParticipantCategoryModalComponent :ParticipantData="ParticipantData"></ParticipantCategoryModalComponent>
		</modal>
		<modal name="AddCategoriesModal" :scrollable="true" width="100%" :height="'auto'">
			<AddCategoryModalComponent></AddCategoryModalComponent>
		</modal>
	</div>
</template>

<script>
	import AdminNavigationInCategoryComponent from '../../components/admin/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';

    import PublicNavigationInCategoryComponent from '../../components/public/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';
    import MenuComponent from "../../components/common/MenuComponent";
  import AddCategoryModalComponent from '../../components/admin/ParticipantTableComponents/AddCategoryModalComponent.vue';

  import ParticipantCategoryModalComponent from '../../components/admin/ParticipantTableComponents/ParticipantCategoryModalComponent.vue';


  export default {
  name: 'disciplinePage',
  data () {
    return {
      	show: false,
				CompetitionTitle: null,
      	Fighters: {},
				Disciplines: {},
      	SelectedDiscipline: this.$route.params.discipId,
				BattlesInWeightCategory: {},
      	BattlesForGraph: {},
      	FightersLozesanai: {},
      	ParticipantData:{},
				SelectedTeam: null,
				Teams: [],
    }
  },
    methods: {
      clearTeamSelect: function(){
        this.SelectedTeam = null;
        this.getSelected();
      },
			getTeams: function(){
				this.$axios.get(`/api/competition/discipline/get-teams`, {
					params: {
						cmId: this.$route.params.cmId,
						DisciplineId: this.$route.params.discipId,
					}
				})
				.then(response => {
					this.Teams = response.data.Teams;
					console.log(this.Teams)
				})
				.catch(e => {
					this.errors.push(e)
				})
			},
      getSelected: function(){
        this.$router.replace({ name: "disciplinePage", params: {cmId: this.$route.params.cmId, discipId: this.SelectedDiscipline}})
				this.$axios.get(`/api/discipline`, {
					params: {
						cmId: this.$route.params.cmId,
						DisciplineId: this.SelectedDiscipline,
						TeamId: this.SelectedTeam,
					}
				})
				.then(response => {
					this.Fighters= {};
					this.BattlesInWeightCategory={};
					this.CompetitionTitle = response.data.CompetitionTitle;
					this.Fighters = response.data.Fighters;
					this.SelectedDiscipline=response.data.SelectedDiscipId;
					this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
					this.BattlesForGraph=response.data.BattlesForGraph;
					this.FightersLozesanai = response.data.FightersLozesanai;
					console.log(response.data);
				})
				.catch(e => {
					this.errors.push(e)
				})
				this.getTeams()
			},
			getData: function(){
				this.$axios.get(`/api/discipline`, {
					params: {
						cmId: this.$route.params.cmId,
						DisciplineId: this.$route.params.discipId,
						TeamId: this.SelectedTeam,
					}
				})
				.then(response => {
					this.Disciplines={};
					this.CompetitionTitle = response.data.CompetitionTitle;
					this.Fighters = response.data.Fighters;
					this.Disciplines=response.data.Disciplines;
					this.SelectedDiscipline=response.data.SelectedDiscipId;
					this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
					this.BattlesForGraph=response.data.BattlesForGraph;
					this.FightersLozesanai = response.data.FightersLozesanai;
					console.log(response.data);
					/*console.log(this.CompetitionTitle)*/

				})
				.catch(e => {
					this.errors.push(e)
				})
				this.getTeams()
			}
    },
	mounted () {
		this.getData();
		this.$socket.on('refresh-users-data', response => {
			this.getSelected();
		});
		this.$socket.on('refresh-cmusers-data', response => {
			this.getSelected();
		});
		this.$socket.on('refresh-battles-list', response => {
			this.getSelected();
		});
		this.$socket.on('refresh-tossup-data', response => {
			this.getSelected();
		});
    this.$bus.$on("CmUserData", (data)=>{
      this.ParticipantData=data;
      console.log("geted data: ",data)
    });
	},
	beforeDestroy () {
		this.$socket.removeListener('refresh-users-data');
		this.$socket.removeListener('refresh-cmusers-data');
		this.$socket.removeListener('refresh-battles-list');
		this.$socket.removeListener('refresh-tossup-data');
	},
  components: {
    MenuComponent,
    AdminNavigationInCategoryComponent,
    PublicNavigationInCategoryComponent,
    ParticipantCategoryModalComponent,
    AddCategoryModalComponent
  }
}
</script>

<style  lang="scss" scoped>
	.emptycategory{
		display: flex;
		justify-content: center;
		align-items: center;
		height:100%;
	}

</style>