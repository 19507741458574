<template>
  <div class="page">
    <div class="page__section">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Lietotaja izveidošana</h1>
    </div>
  </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <h1 class="page__content__input--title">Lietotajs: {{UserData.Name}} {{UserData.Surname}}</h1>
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Vards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Name">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="UserData.Surname" >
          </div>
          <div class="page__content__input__form__item" >
            <label class="page__content__input__form__item--label control-label ">Dzimsanas Datums<sup>*</sup></label>
            <div class="page__content__input__form__item--input" style="left:-2rem;">
              <DatePicker calendar-class="calendar" :monday-first="true" v-model="DateOfBirth" placeholder="00-00-00" :bootstrap-styling="true"></DatePicker>
            </div>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Email<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Email" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Gender<sup>*</sup></label>
            <multiselect v-model="UserData.Gender" :options="Genders" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" >
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Lietotaju tips<sup>*</sup></label>
            <multiselect v-model="UserData.UserType" :options="UserTypes" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="UserTypeLabel" >
            </multiselect>
          </div>
         <!-- <div class="page__content__input__form__item">
            <label class="page__content__input__form__item&#45;&#45;label control-label ">Weight<sup>*</sup></label>
            <input class="page__content__input__form__item&#45;&#45;input form-control" v-model="UserData.Weight" >
          </div>-->
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Valsts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Country" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Adrese<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Place" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Clubs<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Club" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Work<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Work" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Hobijs<sup>*</sup></label>
            <textarea class="page__content__input__form__item--input form-control" rows="10" v-model="UserData.Hobby" >
            </textarea>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Pieredze<sup>*</sup></label>
            <textarea class="page__content__input__form__item--input form-control" rows="10" v-model="UserData.Xp" ></textarea>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Tiesnesa tipi<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.JudgeTypes" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Trials<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="UserData.Trials" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " type="checkbox">Apstiprinats</label>
            <input class="page__content__input__form__item--input form-control" style="width:5rem;" type="checkbox" v-model="UserData.Approved">
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="createUser">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import Multiselect from "vue-multiselect";
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "createUserPage",
    data(){
      return {
        show: false,
        UserData: {
          Name: "",
          Surname: "",
          DateOfBirth: new Date(),
          Email: "",
          Approved: "",
          Gender: {},
          UserType: {},
          Weight: "",
          Country:"",
          Place: "",
          Club: "",
          Coach: "",
          Work: "",
          Hobby: "",
          Xp: "",
          JudgeTypes: "",
          Trials: "",
        },
        Genders: [{Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        UserTypes: [{Id: 1, Title: "Galvenais tiesnesis"},
          {Id: 2, Title: "Administrators"},
          {Id: 3, Title: "Tiesnesis"},
          {Id: 4, Title: "Treneris"},
          {Id: 5, Title: "Dalibnieks"},
          {Id: 6, Title: "Arsts"},
        ],
        DateOfBirth: null,
      }
    },
    methods: {
      createUser(){
        this.DateOfBirth = new Date( this.DateOfBirth);
        this.UserData.DateOfBirth = this.DateOfBirth.getFullYear()+
          "-"+
          parseInt(this.DateOfBirth.getMonth()+1)+
          "-"+
          this.DateOfBirth.getDate();
        console.log(this.UserData)
          this.$socket.emit('create-user', this.UserData);
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      UserTypeLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
    },
    mounted(){
        this.$socket.on('success', response => {
        if (response===true){
            this.$router.push('/users')
        }else{alert("Check all fields on filled right")}
    });
    },
    beforeDestroy () {
        this.$socket.removeListener('success');
    },
    components: {
        MenuComponent,
      DatePicker,
      'multiselect' : Multiselect
    },
  }
</script>

<style lang="scss" scoped>

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>