<template>
  <div class="page">
    <div class="container">
   <h1>Streams</h1>
    <div class="list-group competition-list">
            <div class="list-group-item list-group-item-action competition-list__item"  v-for="(competition, key, index) in Competitions" >
                <div class="competition-list__item--title">
                    <h2> {{competition.Title}}</h2>
                </div>
                <div class="list-group-item list-group-item-action ring-list-item" v-for="Ring in competition.Rings" @click="showRingFight(Ring.Id)">
                    <h4>&ensp;{{Ring.Title}} Nr. {{ Ring.Nr }}</h4>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
/*    import CompetitionFights from '../components/common/Battles/StreamFightsComponent.vue'*/
  export default {
  name: 'StreamCompetitionsPage',
  data () {
    return {
        Competitions: [],
    }
  },
  methods: {
      showRingFight(ringId){
        let RouterData = this.$router.resolve({ name: 'Ring', params: { ringId: ringId}})
        window.open(RouterData.href, '_blank');
      },
  },
  mounted () {
      this.$axios.get(`/api/stream-competitions`,{
          params: {judgeId: this.$route.params.id}
      })
          .then(response => {
              this.Competitions = response.data.StreamCompetitions;
              console.log(this.Competitions)
          })
          .catch(e => {
              this.errors.push(e)
          })
  },
  created() {
    this.getData()
    this.$socket.on('refresh-competition-data', response => {
      this.getData();
    });
    this.$socket.on('refresh-competitions-list', response => {
      this.getData();
    });

  },
  beforeDestroy() {
    this.$socket.removeListener('refresh-competition-data');
    this.$socket.removeListener('refresh-competitions-list');
  },
  components: {
 /*     "competition-fights": CompetitionFights*/
  }
}
</script>

<style lang="scss" scoped>
    .ring-list-item{
      background: #c7c5c3;
      color: #444444;
        &:hover{
          cursor: pointer;
          background: #afa195; /* #ff7c00*/ /*#85bcfd; *///#8ec3ff
        }
    }
    .competition-list {
      &__item {
        background: #273749;
        color: white;
      }
    }

</style>
