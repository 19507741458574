<template>
    <div class="vue-good-table">
      <table class="table table-bordered" style="font-size: 16px;">
        <thead>
        <tr>
          <th>Vards, Uzvārds</th>
          <th>Komanda</th>
          <th>Vecums</th>
          <th>Svars</th>
          <th>Treneris</th>
          <th>Apstiprinats</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in FighterData" >
          <td @click="showEdit(data, 'NameSurname')" v-if="!((data.ID==Selected.Value.UserId) && (Selected.Row=='NameSurname'))" style="cursor: pointer;">{{data.NameSurname}} &emsp; <span class="glyphicon glyphicon-pencil"></span></td>
          <td v-if="(data.ID==Selected.Value.UserId) && (Selected.Row=='NameSurname') ">
              <label>Mainit: </label>
              <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
          </td>
          <td @click="showEdit(data, 'Team')" v-if="!((data.ID===Selected.Id) && (Selected.Row==='Team'))" style="cursor: pointer;">{{data.Team}} &emsp; <span class="glyphicon glyphicon-pencil"></span></td>
          <td v-if="(data.ID===Selected.Id) && (Selected.Row==='Team') " >
            <div v-if="!updatable" >
              <label>Mainit: </label>
              <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                            :clear-on-select="false" :open-direction="'below'"
                           placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
              <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
              <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
            </div>
            <div v-if="updatable">
              <label>Atjaunot: </label>
              <input v-model="Selected.Value.Title">
              <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
              <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
            </div>
          </td>
          <td>{{data.Age}}</td>
          <td>{{data.Weight}}</td>
          <td>{{data.Coach}}</td>
          <td>
            <button v-if="data.Approved == 1" class="btn btn-danger" v-on:click="unconfirmFighter(data.CmUserId)">Atcelt</button>
            <button v-else class="btn btn-primary" v-on:click="confirmFighter(data.CmUserId)">Apstiprinat</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePickerComponent from 'vuejs-datepicker';

  export default {
    name: 'ParticipantsTableComponent',
    props: ["FighterData"],
    data(){
      return {
          Selected: {
              Id: 0,
              Row: "",
              UserId: 0,
              Value: {
                Id: 0,
                UserId: 0,
                Field: '',
                Title: ''
              },
              type: ""
          },
          updatable: false,
          Teams: [],
          clearFightersData: [],
      };
    },
    methods: {
        showEdit(data,row){
          if (row!='Team') {
            this.Selected = {
                Row: row,
                Value: {
                  UserId: data.ID,
                  Field: data[row]
                }
            }
          } else {
              this.$axios.get(`/api/get-teams/short-data`, {
              })
                  .then(response => {
                      this.Teams = response.data.Teams;
                  })
                  .catch(e => {
                      this.errors.push(e)
                  })
            this.Selected= {
                Id: data.ID,
                Row: row,
                UserId: data.CmUserId,
                Value: {
                  Id:  data.TeamId,
                  Title: data[row]
                },
            }
          }
          console.log(data.ID)
        },
        editData(rowData){
            this.Selected= {
                  Id: 0,
                  Row: "",
                  UserId: 0,
                  Value: {
                Id: 0,
                    UserId: 0,
                    Field: '',
                    Title: ''
              },
              type: ""
            }
            console.log("Will edit: ", rowData)
            if (rowData.Row == 'Team') {
                rowData.type='edit'
                this.$socket.emit('edit-team-title', rowData);
            }
        },
        updateData(rowData){
            this.Selected= {
              Id: 0,
              Row: "",
              UserId: 0,
              Value: {
                Id: 0,
                UserId: 0,
                Field: '',
                Title: ''
              },
              type: ""
            }
            console.log("Will update: ", rowData)
            if (rowData.Row == 'NameSurname') {
                this.$socket.emit('edit-name-surname', rowData);
            }
            if (rowData.Row == 'Team') {
                rowData.type='update'
                this.$socket.emit('edit-team-title', rowData);
            }
        },
        confirmFighter: function(fighterId){
            this.$socket.emit('confirm-fighter', {
                Id: fighterId
            });
        },
        unconfirmFighter: function(fighterId){
            this.$socket.emit('unconfirm-fighter', {
                Id: fighterId
            });
        },
        TeamLabel (option) {
            if ((typeof option.Title=='undefined')){return '-'}
            return `${option.Title}`
        },

    },
    mounted() {
    },
    components: {
       DatePickerComponent, "multiselect": Multiselect
    },
  };
</script>

<style>
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>