<template>
  <div>
    <vue-good-table
        :columns="columns"
        :rows="UsersData"
        @on-cell-click="onCellClick"
        :search-options="{
          enabled: true,
          trigger: 'enter'
        }"
        :pagination-options="{
            enabled: true,
            perPage: 5,
          }"
        styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <div v-if="props.row.Approved==1">
            <div class="btn-group text-center">
              <button class="btn btn-danger btn-sm"  v-on:click="unconfirmUser(props.row.Id)">Atcelt apstiprinajumu</button>
              <button class="btn btn-primary btn-sm"  v-on:click="editUser(props.row.Id)">Labot</button>
              <button class="btn btn-danger btn-sm"  v-on:click="deleteUser(props.row.Id)">Dzest</button>
            </div>
          </div>
          <div v-else-if="props.row.Approved==0">
            <div class="btn-group text-center">
              <button class="btn btn-success btn-sm"  v-on:click="confirmUser(props.row.Id)">Apstiprinat</button>
              <button class="btn btn-primary btn-sm"  v-on:click="editUser(props.row.Id)">Labot</button>
              <button class="btn btn-danger btn-sm"  v-on:click="deleteUser(props.row.Id)">Dzest</button>
            </div>
          </div>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "UsersTableComponent",
    props: ["UsersData"],
    data(){
      return {
        columns: [
          {
            label: 'ID',
            field: 'Id',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '4rem',
          },
          {
            label: 'Vards',
            field: 'Name',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '9rem',
          },
          {
            label: 'Uzvards',
            field: 'Surname',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '9rem',
          },
          {
            label: 'Dzimsanas datums',
            field: 'DateOfBirth',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '6rem',
          },
          {
            label: 'Dzimums',
            field: 'Gender',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '3rem',
          },
          {
            label: 'E-pasts',
            field: 'Email',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '12rem',
          },
          {
            label: 'Lietotaja tips',
            field: 'UserType',
            thClass: 'text-center',
            tdClass: 'text-center',
            type: "number",
            width: '2rem',
          },
          /*  {
              label: 'Svars',
              field: 'Weight',
              thClass: 'text-center',
              tdClass: 'text-center',
            },*/
          {
            label: 'Izveidots',
            field: 'Created',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '6rem',
          },
          {
            label: 'Modificets',
            field: 'LastUpdate',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '6rem',
          },
       /*   {
            label: 'Apstiprinats',
            field: 'Approved',
            thClass: 'text-center',
            tdClass: 'text-center',
          },*/
          {
            label: 'Darbibas',
            field: 'Actions',
            width: '15rem',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],

        UserId: null,
      }
    },
    methods: {onCellClick(params) {
        this.UserId = params.row.Id;
      },
      editUser(q) {
        this.$router.push('/users/user/edit/' + q)
      },
      deleteUser(q) {
        this.$socket.emit('delete-user', {userId: q});
        /*this.$axios.post(`/api/deleteuser`,{
          userId: q,
        })
          .then(response => {
          })
          .catch(e => {
            this.errors.push(e)
          })*/
      },
      confirmUser(Id){
        this.$socket.emit('confirm-user', {Id: Id});
      },
      unconfirmUser(Id){
        this.$socket.emit('unconfirm-user', {Id: Id});
      },
    },
    components: {

    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>