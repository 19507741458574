<template>
  <div class="page" style="height:100%;">
    <div class="queue-container">
      <div class="queue-container__item">
        <h1 class="queue-container__item--text" v-if="TeamData">
          {{TeamData.TeamTitle}}
        </h1>
        <h1 class="queue-container__item--text" v-if="!TeamData">
          Svēršana beidzas
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WeighingQueuePage",
    data(){
      return{
        /*UserData: {Nr: 0,Name: "", Surname: ""}*/
        TeamData: {Nr: 0, TeamTitle: ''}
      }
    },
    methods:{
      getData: function() {
        this.$axios.get(`/api/weighing-queue`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
            .then(response => {
              this.TeamData = response.data.TeamData;
              console.log(response.data)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('next-on-weighing',response=>{
        response!=null ? this.TeamData=response : this.TeamData=null
      })
    },
    beforeDestroy () {
      this.$socket.removeListener('next-on-weighing');
    }
  }
</script>

<style lang="scss" scoped>
  .queue-container {
    height:100%;
    &__item {
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &--number {
        margin: 0;
        text-align: center;
        font-size: 50rem;
        width: 100%;
        word-break: break-all;
      }
      &--text {
        margin: 0;
        text-align: center;
        font-size: 25rem;
        width: 100%;
        word-break: break-all;
      }
    }
  }
</style>