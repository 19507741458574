<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">{{CompetitionTitle}} </h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
       <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <h1 class="page__content__data--header" style="color:black; font-size: 3rem;">Cīņas shēma {{CategoryTitle}}</h1>
          <div style="text-align: right;margin: 0 5rem 0;">
              <a class="btn btn-primary" style="width: 25rem;" v-on:click="generateBattles()">Generēt cīņas</a>
          </div>
        <div class="graph">
          <div class="competition">
            <div class="competition__part" v-for="(CompetitionPart, CompIndex) in BattlesForGraph">
              <div class="competition__part__battle-block" v-for="(Battle, BattleIndex) in CompetitionPart">
                <div class="competition__part__battle-block__connect--first" v-if="!(CompIndex===0)">
                  <div class="competition__part__battle-block__connect" >
                    <div class="competition__part__battle-block__connect--middle_connection" ></div>
                  </div>
                </div>
                <div class="competition__part__battle-block__fighters" >
                  <drop class="competition__part__battle-block__fighters_red" @drop="dropFighter( {Id: Battle.RedCmUserId, Nr: Battle.RedCmNr}  )">
                    <drag v-if="Battle.RedNameSurname && Battle.RedCmNr" class="competition__part__battle-block__fighters_red--name" @drag="dragFighter( {Id: Battle.RedCmUserId, Nr: Battle.RedCmNr} )">
                      <div style="width: 20rem; margin: 1rem 1rem;">
                        {{ Battle.RedNameSurname }} ({{ Battle.RedCmNr }})
                        <br>
                        <span style="color: #413636;">{{Battle.RedTeam}}</span>
                      </div>
                    </drag>
                    <div v-else class="competition__part__battle-block__fighters_red--name">
                      <div style="width: 20rem; margin: 1rem 1rem;">{{ Battle.RedComesFromNr }}</div>
                    </div>
                    <div class="competition__part__battle-block__fighters_red__result">
                      <div v-if="(Battle.CompetitionPart==1) && (Battle.WinnerId==Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--first">
                        {{Battle.RedResult}}
                      </div>
                      <div v-else-if="(Battle.CompetitionPart==1) && (Battle.WinnerId!=Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--second">
                        {{Battle.RedResult}}
                      </div>
                      <div v-else-if="(Battle.CompetitionPart!=1) && (Battle.WinnerId==Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--winner">
                        {{Battle.RedResult}}
                      </div>
                      <!--<div v-else-if="(Battle.CompetitionPart==2) && (BattleIndex==1) && (Battle.WinnerId!=Battle.RedCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result&#45;&#45;third">
                        {{Battle.RedResult}}
                      </div>-->
                      <div v-else class="competition__part__battle-block__fighters_red__result--dif">
                        {{Battle.RedResult}}
                      </div>
                    </div>
                  </drop>
                  <drop class="competition__part__battle-block__fighters_blue" @drop="dropFighter( {Id: Battle.BlueCmUserId, Nr: Battle.BlueCmNr} )">
                    <drag v-if="Battle.BlueNameSurname && Battle.BlueCmNr" class="competition__part__battle-block__fighters_blue--name" @drag="dragFighter( {Id: Battle.BlueCmUserId, Nr: Battle.BlueCmNr} )">
                      <div style="width: 20rem; margin: 1rem 1rem;">
                        {{ Battle.BlueNameSurname }} ({{ Battle.BlueCmNr }})
                        <br>
                        <span style="color: #413636;">{{ Battle.BlueTeam }}</span>
                      </div>
                    </drag>
                    <div v-else class="competition__part__battle-block__fighters_red--name">
                      <div style="width: 20rem; margin: 1rem 1rem;">{{ Battle.BlueComesFromNr }}</div>
                    </div>
                    <div class="competition__part__battle-block__fighters_blue__result">
                      <div v-if="(Battle.CompetitionPart==1) && (Battle.WinnerId==Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--first">
                        {{Battle.BlueResult}}
                      </div>
                      <div v-else-if="(Battle.CompetitionPart==1) && (Battle.WinnerId!=Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--second">
                        {{Battle.BlueResult}}
                      </div>
                      <div v-else-if="(Battle.CompetitionPart!=1) && (Battle.WinnerId==Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result--winner">
                        {{Battle.BlueResult}}
                      </div>
                      <!--<div v-else-if="(Battle.CompetitionPart==2) && (BattleIndex==1) && (Battle.WinnerId!=Battle.BlueCmUserId) && (Battle.WinnerId!=null)" class="competition__part__battle-block__fighters_red__result&#45;&#45;third">
                        {{Battle.BlueResult}}
                      </div>-->
                      <div v-else class="competition__part__battle-block__fighters_red__result--dif">
                        {{Battle.BlueResult}}
                      </div>
                    </div>
                  </drop>
                </div>
                <div class="competition__part__battle-block__connect" v-if="!(CompetitionPart.length===1)">
                  <div class="competition__part__battle-block__connect--end" v-if="!(BattleIndex & 1)">
                    <div class="competition__part__battle-block__connect--top" v-bind:class="{ 'top-padding' : (BattlesForGraph.length>3) && (Battle.CompetitionPart==2) }" v-if="!(BattlesForGraph.length === 3 && CompIndex===1)"></div>
                    <div class="competition__part__battle-block__connect--top" v-bind:class="{'four-parts-connect': BattlesForGraph.length === 3 && CompIndex===1 }" v-else ></div>
                  </div>
                  <div class="competition__part__battle-block_connect" v-if="BattleIndex & 1">
                    <div class="competition__part__battle-block__connect--bottom" v-bind:class="{ 'bottom-padding' : (BattlesForGraph.length>3) &&((Battle.CompetitionPart==2) || (Battle.CompetitionPart==4))}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuComponent from "../../components/common/MenuComponent";
  import { Drag, Drop } from 'vue-drag-drop';

  export default {
    name: "lozesanasPage",
    data() {
      return {
        show: false,
        CompetitionTitle: '',
        CategoryTitle: '',
        BattlesForGraph: [],
        dragFighterData: {},
        dropFighterData: {}
      }
    },
    methods:{
      dragFighter(data) {
        this.dragFighterData = data
      },
      dropFighter(data){
        this.dropFighterData = data
        this.showData()
      },
      showData(){
        console.log(`dragFighter: ${JSON.stringify(this.dragFighterData)}, dropFighter: ${JSON.stringify(this.dropFighterData)}`)
        this.$socket.emit('change-cmuser-nr', {
          CategoryId: this.$route.params.categId,
          FirstFighter: this.dragFighterData,
          SecondFighter: this.dropFighterData,
        });
      },
      getData(){
        this.$axios.get(`/api/competition/fights-generation/list`, {
            params: {
              cmId: this.$route.params.cmId,
              categId: this.$route.params.categId,
            }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.CategoryTitle = response.data.CategoryTitle;
            this.BattlesForGraph = response.data.BattlesForGraph;

            console.log(response.data);
            console.log('send emit get data')
          })
          .catch(e => {
              this.errors.push(e)
          })
      },
      generateBattles(){
        this.$axios.get(`/api/competition/category/generate-fights`, {
          params: {
            cmId: this.$route.params.cmId,
            categId: this.$route.params.categId,
          }
        })
          .then(response => {
            this.BattlesForGraph = response.data.Battles
          /*  this.$router.go(0);*/
          })
          .catch(e => {
            this.errors.push(e)
          })
      },

    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-battles-for-graph',resp => {
        console.log('geted battles data: ',resp)
        this.BattlesForGraph = resp.Battles
        /*this.getData()*/
      })
      this.$socket.on('refresh-tossup-data', resp => {
        this.getData()
      })
      console.log('data: ',this.$route.params)
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-battles-for-graph');
      this.$socket.removeListener('refresh-tossup-data')
     /* this.$socket.removeListener('refresh-tossup-data');
      this.$socket.removeListener('refresh-cmusers-data');
      this.$socket.removeListener('refresh-users-data');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('generation-response');*/
    },
    components:{
      MenuComponent, 'drag': Drag, 'drop': Drop
    },

  }
</script>

<style lang="scss" scoped>
  .graph{
    overflow: auto;
  }
  .competition{
    display:flex;
    flex-direction: row;
    width:100rem;
    margin: 0 3rem 8rem;
    &__part{
      display:flex;
      flex-direction: column;
      justify-content: center;
      &__battle-block {
        display:flex;
        flex-direction: row;
        &__fighters {
          width: 25rem;
          padding-top: 3rem;
          &_red {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: rgba(255, 124, 124, 0.6);
            color: red;
            height: 8rem;
            width: 23rem;
            &--name{

            }
            &__result{
              background-color:white;
              &--first{
                background-color: goldenrod;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--second{
                background-color: grey;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--third{
                background-color: chocolate;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--winner{
                background-color: #b5ff45;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--dif{
                background-color:white;
                padding: 1rem 1rem;
              }
            }
          }
          &_blue {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: rgba(152, 157, 255, 0.6);
            color: blue;
            height: 8rem;
            width: 23rem;
            &--name{

            }
            &__result{
              background-color:white;
              &--first{
                background-color: goldenrod;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--second{
                background-color: grey;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--third{
                background-color: chocolate;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--winner{
                background-color: #b5ff45;
                color: #ffecec;
                padding: 1rem 1rem;
              }
              &--dif{
                background-color:white;
                padding: 1rem 1rem;
              }
            }
          }
        }
        &__connect{
          &--end{
            margin-top: 9.9rem;
          }
          &--first {
            margin-top: 10rem;
          }
          &--top{
            width: 5rem;
            height: 10.5rem;
            border-top: 0.3rem solid greenyellow;
            border-right: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              height: 10.5rem;
              border-top: 0.3rem solid greenyellow;
              border-left: 0.3rem solid greenyellow;
            }
          }
          &--bottom{
            width: 5rem;
            height: 10.5rem;
            border-bottom: 0.3rem solid greenyellow;
            border-right: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              height: 10.5rem;
              border-bottom: 0.3rem solid greenyellow;
              border-left: 0.3rem solid greenyellow;
            }
          }
          &--middle{
            width: 5rem;
            border-bottom: 0.3rem solid greenyellow;
            &_connection{
              width: 5rem;
              border-bottom: 0.3rem solid greenyellow;
            }
          }
        }
      }
    }
  }

  .four-parts-connect {
    padding-top: 29rem;
  }

  .top-padding{
    padding-top: 20rem;
  }
  .bottom-padding{
    padding-bottom: 10rem;
  }

  .connection{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .connect{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
  }
</style>