<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="ParticipantsData"
      @on-cell-click="onCellClick"
      :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <div v-if="props.row.Approved==1">
            <div class="btn-group">
              <button class="btn btn-danger btn-sm"  v-on:click="unconfirmFighter(props.row.Id)">Atcelt apstiprinajumu</button>
              <button class="btn btn-primary btn-sm"  v-on:click="editUserClaim(props.row)">Labot</button>
              <button class="btn btn-danger btn-sm"  v-on:click="deleteUserClaim(props.row)">Dzest</button>
            </div>
          </div>
          <div v-else-if="props.row.Approved==0">
            <div class="btn-group">
              <button class="btn btn-danger btn-sm"  v-on:click="confirmFighter(props.row.Id)">Apstiprinat</button>
              <button class="btn btn-primary btn-sm"  v-on:click="editUserClaim(props.row)">Labot</button>
              <button class="btn btn-danger btn-sm"  v-on:click="deleteUserClaim(props.row)">Dzest</button>
            </div>
          </div>
        </div>
        <div v-else-if="props.column.field=='Payment'">
          <div v-if="props.row.Payment=='0'">
            Nav
          </div>
          <div v-else>
            Ir
          </div>
        </div>
        <div v-else-if="props.column.field=='WeighingNr'">
          <div v-if="props.row.WeighingNr=='0'">
            -
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
        </div>
        <span v-else>
            {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "ClaimedParticipantsTableComponent",
    props: ["ParticipantsData"],
    data(){
      return {
        columns: [
          {
            label: 'Dalibnieks',
            field: 'Participant',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Svars',
            field: 'Weight',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Komanda',
            field: 'Team',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Kategorija',
            field: 'Category',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Cīnītaju skaits kategorijā',
            field: 'UsersCountInCategory',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Cīņu skaits kategorijā',
            field: 'BattlesCountInCategories',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          /*{
            label: 'Sveršanas rindas numurs',
            field: 'WeighingNr',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
                enabled: true,
            }
          },
          {
            label: 'Cinas',
            field: 'Fights',
              type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Uzvaras',
            field: 'Victory',
              type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Nokdauni',
            field: 'Knockouts',
              type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },*/
          {
            label: 'Darbibas',
            field: 'Actions',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
      }
    },
    methods: {
      confirmFighter(fighterId){
        this.$socket.emit('confirm-fighter', {
          Id: fighterId
        });
      },
      unconfirmFighter(fighterId){
        this.$socket.emit('unconfirm-fighter', {
          Id: fighterId
        });
      },
      editUserClaim(data) {
/*        this.$router.push('/competition/apply/edit/'+this.$route.params.cmId+'/' + )*/
        this.$router.push({ name: 'editParticipantPage', params: { cmId: this.$route.params.cmId, teamId: data.TeamId, userId: data.UserId}})
      },
      deleteUserClaim(data) {
          this.$socket.emit('delete-cmuser', {
            cmId: this.$route.params.cmId,
            userId: data.UserId,
            teamId: data.TeamId,
            applyId: data.Id
          });
        /*this.$axios.post(`/api/deletecmuser`,{
          usercmId: q,
        })
          .then(response => {
          })
          .catch(e => {
            this.errors.push(e)
          })*/
      },
    },
    mounted() {
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>