var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page__content__navbar-container__navbar" }, [
    _vm.$route.params.cmId === undefined &&
    _vm.$store.state.authUser &&
    _vm.$store.state.authUser.type === 1
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "stylesPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-leaf" }),
                  _vm._v("   Cīņu stili\n        ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "disciplinesPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-copy" }),
                  _vm._v("   Disciplinas\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "ageCategoriesPage" } } },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Vecuma kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "weightCategoriesPage" } } },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-sort-by-attributes"
                    }),
                    _vm._v("   Svaras kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "ringsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-th-large" }),
                  _vm._v("   Ringi\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "usersPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-user" }),
                  _vm._v("   Lietotai\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "createUserPage" } } }, [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__navbar-container__navbar--bottom-item"
                },
                [_vm._v("\n                Izveidot lietotaju\n            ")]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "teamsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-flag" }),
                  _vm._v("   Komandas\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "createTeamPage" } } }, [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__navbar-container__navbar--bottom-item"
                },
                [_vm._v("\n                Izveidot komandu\n            ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "remoteControlsPage" } } },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-phone" }),
                    _vm._v("   Tiesneša pulti\n          ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !(_vm.$route.params.cmId === undefined) &&
    _vm.$store.state.authUser &&
    _vm.$store.state.authUser.type === 1
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionClaimsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikumi\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "applyTeamPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n            Pieteikt komandu\n          ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "lozesanasPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Lozešana\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "categoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-chevron-left"
                    }),
                    _vm._v("   Kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "editCategoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [
                    _vm._v(
                      "\n            Kategorijas saraksta labošana\n          "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "addAgeCategoryPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [
                    _vm._v(
                      "\n                Pievienot vecuma kategoriju\n            "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "addWeightCategoryPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [
                    _vm._v(
                      "\n                Pievienot svara kategoriju\n            "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ringsInCompetitionPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close"
                    }),
                    _vm._v("   Ringi\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "addRingPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pievienot ringu\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionRemotesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-phone" }),
                    _vm._v("   Tiesneša pulti\n          ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FullViewPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list" }),
                    _vm._v("   Pilnais skats\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$route.params.ringId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInRingPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ringId: _vm.$route.params.ringId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n          ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.discipId &&
                !_vm.$route.params.ageCategId &&
                !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInDisciplinePage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          discipId: _vm.$route.params.discipId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n          ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.ageCategId && !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInAgeCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ageCategId: _vm.$route.params.ageCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n          ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInWeightCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          weightCategId: _vm.$route.params.weightCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n          ")
                      ]
                    )
                  ]
                )
              : _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsPage",
                        params: { cmId: _vm.$route.params.cmId }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n          ")
                      ]
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "WeighingQueuePage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Svēršanas rinda\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Competitions",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-facetime-video"
                    }),
                    _vm._v("   Streams\n            ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.params.cmId === undefined && !_vm.$store.state.authUser
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "Competitions" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", {
                    staticClass: "glyphicon glyphicon-facetime-video"
                  }),
                  _vm._v("   Streams\n            ")
                ]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !(_vm.$route.params.cmId === undefined) && !_vm.$store.state.authUser
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionClaimsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikumi\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "applyTeamPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikt komandu\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "categoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-chevron-left"
                    }),
                    _vm._v("   Kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ringsInCompetitionPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close"
                    }),
                    _vm._v("   Ringi\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FullViewPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list" }),
                    _vm._v("   Pilnais skats\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$route.params.ringId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInRingPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ringId: _vm.$route.params.ringId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.discipId &&
                !_vm.$route.params.ageCategId &&
                !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInDisciplinePage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          discipId: _vm.$route.params.discipId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.ageCategId && !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInAgeCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ageCategId: _vm.$route.params.ageCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInWeightCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          weightCategId: _vm.$route.params.weightCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsPage",
                        params: { cmId: _vm.$route.params.cmId }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "WeighingQueuePage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Svēršanas rinda\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Competitions",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-facetime-video"
                    }),
                    _vm._v("   Streams\n            ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !(_vm.$route.params.cmId === undefined) &&
    _vm.$store.state.authUser &&
    !(_vm.$store.state.authUser.type === 1) &&
    !(_vm.$store.state.authUser.type === 4) &&
    !(_vm.$store.state.authUser.type === 6)
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionClaimsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikumi\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "applyTeamPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikt komandu\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "categoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-chevron-left"
                    }),
                    _vm._v("   Kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ringsInCompetitionPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close"
                    }),
                    _vm._v("   Ringi\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "TeamFightsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-briefcase"
                    }),
                    _vm._v("   Komandas cīņas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FullViewPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list" }),
                    _vm._v("   Pilnais skats\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$route.params.ringId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInRingPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ringId: _vm.$route.params.ringId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.discipId &&
                !_vm.$route.params.ageCategId &&
                !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInDisciplinePage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          discipId: _vm.$route.params.discipId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.ageCategId && !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInAgeCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ageCategId: _vm.$route.params.ageCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInWeightCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          weightCategId: _vm.$route.params.weightCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsPage",
                        params: { cmId: _vm.$route.params.cmId }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "WeighingQueuePage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Svēršanas rinda\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Competitions",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-facetime-video"
                    }),
                    _vm._v("   Streams\n            ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.params.cmId === undefined &&
    _vm.$store.state.authUser &&
    _vm.$store.state.authUser.type === 4
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "teamsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-flag" }),
                  _vm._v("   Komandas\n        ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "createTeamPage" } } }, [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__navbar-container__navbar--bottom-item"
                },
                [_vm._v("\n          Izveidot komandu\n        ")]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.params.cmId === undefined && _vm.$store.state.authUser
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "Competitions" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", {
                    staticClass: "glyphicon glyphicon-facetime-video"
                  }),
                  _vm._v("   Streams\n            ")
                ]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !(_vm.$route.params.cmId === undefined) &&
    _vm.$store.state.authUser &&
    _vm.$store.state.authUser.type === 4
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionClaimsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n          Pieteikumi\n        ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "applyTeamPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n            Pieteikt komandu\n          ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "categoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-chevron-left"
                    }),
                    _vm._v("   Kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ringsInCompetitionPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close"
                    }),
                    _vm._v("   Ringi\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "TeamFightsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-briefcase"
                    }),
                    _vm._v("   Komandas cīņas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "teamsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-flag" }),
                  _vm._v("   Komandas\n          ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: { name: "createTeamPage" } } }, [
              _c(
                "div",
                {
                  staticClass:
                    "page__content__navbar-container__navbar--bottom-item"
                },
                [_vm._v("\n            Izveidot komandu\n          ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FullViewPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list" }),
                    _vm._v("   Pilnais skats\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "WeighingQueuePage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Svēršanas rinda\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$route.params.ringId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInRingPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ringId: _vm.$route.params.ringId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.discipId &&
                !_vm.$route.params.ageCategId &&
                !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInDisciplinePage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          discipId: _vm.$route.params.discipId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.ageCategId && !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInAgeCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ageCategId: _vm.$route.params.ageCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInWeightCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          weightCategId: _vm.$route.params.weightCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsPage",
                        params: { cmId: _vm.$route.params.cmId }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Competitions",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-facetime-video"
                    }),
                    _vm._v("   Streams\n            ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !(_vm.$route.params.cmId === undefined) &&
    _vm.$store.state.authUser &&
    _vm.$store.state.authUser.type === 6
      ? _c(
          "div",
          [
            _c("router-link", { attrs: { to: { name: "competitionsPage" } } }, [
              _c(
                "div",
                {
                  staticClass: "page__content__navbar-container__navbar--item"
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-home" }),
                  _vm._v("   Sacensības\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "competitionClaimsPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__navbar-container__navbar--bottom-item"
                  },
                  [_vm._v("\n                Pieteikumi\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "categoriesPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-chevron-left"
                    }),
                    _vm._v("   Kategorijas\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ringsInCompetitionPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close"
                    }),
                    _vm._v("   Ringi\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FullViewPage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list" }),
                    _vm._v("   Pilnais skats\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$route.params.ringId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInRingPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ringId: _vm.$route.params.ringId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.discipId &&
                !_vm.$route.params.ageCategId &&
                !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInDisciplinePage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          discipId: _vm.$route.params.discipId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.ageCategId && !_vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInAgeCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          ageCategId: _vm.$route.params.ageCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _vm.$route.params.weightCategId
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsInWeightCategoryPage",
                        params: {
                          cmId: _vm.$route.params.cmId,
                          weightCategId: _vm.$route.params.weightCategId
                        }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                )
              : _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "ResultsPage",
                        params: { cmId: _vm.$route.params.cmId }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page__content__navbar-container__navbar--item"
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-star" }),
                        _vm._v("   Rezultāts\n            ")
                      ]
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "WeighingQueuePage",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-list-alt" }),
                    _vm._v("   Svēršanas rinda\n            ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Competitions",
                    params: { cmId: _vm.$route.params.cmId }
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "page__content__navbar-container__navbar--item"
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-facetime-video"
                    }),
                    _vm._v("   Streams\n            ")
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64d35fbd", { render: render, staticRenderFns: staticRenderFns })
  }
}