<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Registracija</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input__form" >
        <h1>Lietotajs: {{UserData.Name}} {{UserData.Surname}}</h1>
        <br>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Vards<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control" v-model="UserData.Name">
        </div>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Uzvards<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control" type="text" v-model="UserData.Surname">
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label " style="left:-2rem;">Dzimsanas
            Datums<sup>*</sup></label>
          <div class="page__content__input__form__item--input" style="left:-2rem;">
            <!--placeholder="00-00-00"-->
            <DatePicker :monday-first="true" v-model="DateOfBirth"
                        :bootstrap-styling="true"></DatePicker>
          </div>
        </div>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Gender<sup>*</sup></label>
          <multiselect v-model="Gender" :options="Genders" :close-on-select="true"
                        :clear-on-select="false" :open-direction="'below'"
                       placeholder="" track-by="Id" :custom-label="GenderLabel" >
          </multiselect>
        </div>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Email<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control" v-model="UserData.Email">
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label ">Lietotaju
            tips<sup>*</sup></label>
          <multiselect v-model="UserType" :options="UserTypes" :close-on-select="true"
                        :clear-on-select="false" :open-direction="'below'"
                       placeholder="" track-by="Id" :custom-label="UserTypeLabel" >
          </multiselect>
        </div>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Ieladejiet jusu fotografiju ar pase<sup>*</sup></label>
          <input class="page__content__input__form__item--input control-label" type="file" name="image"
                 v-on:change="onFileSelected">
        </div>
        <div class="page__content__input__form__item">
          <label
              class="page__content__input__form__item--label control-label ">Parole<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control" v-model="UserData.Parole" type="password">
        </div>
        <div class="page__content__input__form__item">
          <label class="page__content__input__form__item--label control-label ">Atkārtot
            paroli<sup>*</sup></label>
          <input class="page__content__input__form__item--input form-control" v-model="UserData.RepeatParole" type="password">
        </div>
          <button class="page__content__input__form__item--btn btn btn-primary" v-on:click="createUser">Saglabat</button>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import Multiselect from "vue-multiselect";
  import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "registrationPage",
    data() {
      return {
        show: false,
        UserData: {
          Picture: "",
          Name: "",
          Surname: "",
          DateOfBirth: new Date(),
          Email: "",
          Gender: "",
          UserType: "",
          Parole: "",
          RepeatParole: "",
        },
        Genders: [{Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        UserTypes: [
          {Id: 5, Title: "Dalibnieks"},
          {Id: 4, Title: "Treneris"},
        ],
        Gender: {Id:null},
        UserType: {Id:null},
        DateOfBirth: new Date(),
        SelectedFile: null,
      }
    },
    methods: {
      createUser() {
        let Now = new Date
        let DateOfBirth = new Date(this.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        if (this.UserData.Name.length<3) {
          alert("Vārds ievadits ne pareizi!")
          return;
        }
        else if (this.UserData.Surname.length<2) {
          alert("Uzvārds ievadits ne pareizi!")
          return;
        }
        else if ((Age<=5) || (!DateOfBirth)) {
          alert("Dzimšanas datums nav ievadits vai ievadits nekorekti!")
          return;
        }
        else if (!this.Gender.Id) {
          alert("Dzimums nav ievadits!")
          return;
        }
        else if (this.UserData.Email.length<6) {
          alert("E-pasts ievadits ne pareizi!")
          return;
        }
        else if (!this.UserType.Id) {
          alert("Lietotaja tips nav ievadits!")
          return;
        }
        else if (this.UserData.Parole === this.UserData.RepeatParole) {
          if(this.UserData.Parole.length < 6){
            alert("Parolem jābūt vairāk par 6 simboliem!")
            return;
          }else{
            this.UserData.Password = this.UserData.Parole;
          }
        } else {
          alert("Paroli nav vienadi!")
          return;
        }
        this.UserData.Gender=this.Gender.Id;
        this.UserData.UserType=this.UserType.Id;
        this.DateOfBirth = new Date(this.DateOfBirth);
        this.UserData.DateOfBirth = this.DateOfBirth.getFullYear() +
            "-" +
            parseInt(this.DateOfBirth.getMonth() + 1) +
            "-" +
            this.DateOfBirth.getDate();
        const PostData = new FormData();
        PostData.append('image', this.SelectedFile);
        Object.keys(this.UserData).forEach((key)=>{
          let param=this.UserData[key];
          PostData.append(String(key), param);
        });
        this.$axios.post('/api/user/registrate', PostData)
            .then(response => {
              if (response.status == 200) {
                this.$router.push({name: 'competitionsPage'})
              } else {
                alert("Parbaudiet vai visi lauki aispilditi pareizi!")
              }
            })
            .catch((error) => {
              this.errors.push(e)
            });
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      UserTypeLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      onFileSelected(event) {
        this.SelectedFile = event.target.files[0]
      },
/*      uploadImage(){
        const PostData = new FormData();
        PostData.append('image', this.SelectedFile) /!*, this.SelectedFile.name)*!/
        this.$axios.post('/api/user/picture', PostData)
            .then(response => {
              console.log(response)
            })
            .catch((error) => {
              this.errors.push(e)
            });
      }*/
    },
    components: {
      MenuComponent,
      DatePicker,
      'multiselect' : Multiselect
    },
  }
</script>

<style lang="scss" scoped>
  .page__content__input__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
 
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>