<template>
  <div class="page" style="padding: 0;">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">{{TeamData.Title}}</h1>
    </div>
    <div class="page__team-apply-form">
      <div class="page__team-apply-form__list-block">
        <h4>Komandas dalibnieki</h4>
        <button class="page__team-apply-form__list-block__list--item btn btn-primary" v-on:click="getApplyData(0)">Pievienot</button>
        <select size="7" class="page__team-apply-form__list-block__list" v-if="SelectType" v-model="ApplyId" v-on:change="getApplyData(ApplyId)">
          <option class="page__team-apply-form__list-block__list--item" v-for="(Participant) in TeamParticipants" :value="Participant.UserId">{{Participant.Name}} {{Participant.Surname}}</option>
        </select>
        <select size="7" class="page__team-apply-form__list-block__list" v-if="!SelectType" v-model="ApplyId" @click="deleteUserClaim(ApplyId)">
          <option class="page__team-apply-form__list-block__list--delete-item" v-for="(Participant) in TeamParticipants" :value="Participant.UserId">{{Participant.Name}} {{Participant.Surname}}</option>
        </select>
        <button class="page__team-apply-form__list-block__list--delete-item btn btn-danger" v-on:click="SelectType=false">Dzēst</button>
      </div>

      <div class="page__team-apply-form__input">
        <ul class="nav nav-tabs" role="tablist">
          <li class="active">
            <a id="personal-data" data-toggle="tab" :href="'#personal-data-'+ApplyNr" role="tab">
              Personiskie dati</a>
          </li>
          <li >
            <a id="specifications" data-toggle="tab" :href="'#specifications-'+ApplyNr" role="tab">
              Cīnītāja īpašības</a>
          </li>
          <li >
            <a  id="category" data-toggle="tab" :href="'#category-'+ApplyNr" role="tab">
              Kategorija</a>
          </li>
        </ul>
        <div class="tab-content page__team-apply-form__input__data-tabs">
          <div class="tab-pane active tab-content page__team-apply-form__input__data-tabs__item" :id="'personal-data-'+ApplyNr" role="tabpanel">
            <h4>Atrast dalibnieku: </h4>
            <multiselect v-model="ApplyData.User" :options="Users" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="NameSurnameLabel" @input="setCategories(index)"></multiselect>
            <h4>Vards*: </h4>
            <input class="page__content__team-apply-form__item--input form-control" type="text" v-model="ApplyData.User.Name" @change="setCategories()"/>
            <h4>Uzvards*: </h4>
            <input class="page__content__team-apply-form__item--input form-control" type="text" v-model="ApplyData.User.Surname" @change="setCategories()"/>
          </div>
          <div class="tab-pane tab-content page__team-apply-form__input__data-tabs__item" :id="'specifications-'+ApplyNr" role="tabpanel">
            <h4>Dzimsanas Datums*: </h4>
            <DatePicker calendar-class="calendar" :monday-first="true" v-model="ApplyData.User.DateOfBirth" :bootstrap-styling="true" @closed="setCategories()" style="padding-bottom: 10rem;"></DatePicker>
            <h4>Gender*:</h4>
            <multiselect v-model="ApplyData.User.Gender" :options="Genders" :close-on-select="true"
                         :clear-on-select="false"
                         :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" @input="setCategories()">
            </multiselect>
            <h4>Svars*:</h4>
            <input class="page__content__team-apply-form__item--input form-control" v-model="ApplyData.Weight" @change="setCategories()">
          </div>
          <div class="tab-pane tab-content page__team-apply-form__input__data-tabs__item" :id="'category-'+ApplyNr" role="tabpanel">
            <h4>Kategorija*:</h4>
            <multiselect v-model="ApplyData.Categories" :options="Categories" :close-on-select="true"
                         :clear-on-select="false" :multiple="true" :open-direction="'below'" :limit="4"
                         placeholder="" track-by="Id" :custom-label="CategoryLabel" >
            </multiselect>
            <div class="page__team-apply-form__btn-item">
              <button class="page__team-apply-form__btn-item--btn btn btn-primary" v-on:click="applyUser(ApplyData)">Saglabāt</button>
                <img v-if="ApplySaved" src="/static/green-tick.png" alt="" width="40" height="30">
            </div>
          </div>
        </div>
      </div>
      </div>
    <button style="margin: 1rem; width: 17rem;" @click="$modal.hide('ApplyTeamModal')">
      ❌ Aizvert
    </button>
    </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePicker from 'vuejs-datepicker';

  export default {
    name: "ApplyTeamModalComponent",
    props: ["TeamData"],
    data(){
      return {
        ApplyId: 0,
        ApplyNr: 1,
        ApplySaved: false,
        SelectType: true,
        Users: [],
        ApplyData: {
          User: {Gender:{}},
          Categories: [],
          DateOfBirth: new Date(),
          Weight: 0,
          Fights: 0,
          Victory: 0,
          Knockouts: 0,
          Team: this.TeamData,
          Payed: false,
          PaymentComments: '',
          CmId: this.$route.params.cmId ,
        },
        Genders: [
          {Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        Categories: [],
        Teams: [],
        AllCategories: [],
        TeamParticipants: []
      }
    },
    methods: {
      setCategories(){
        this.ApplySaved=false;
        if (!this.ApplyData.User){
          this.ApplyData.User={Gender:{}}
        }
        let now = new Date;
        let DateOfBirth = new Date(this.ApplyData.User.DateOfBirth);
        let Age=((now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        console.log("DateOfBirth: ",this.ApplyData.User.DateOfBirth)
        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          let CheckWeight=true;
          if(item.CategorySide==1){
            CheckWeight = (item.WeightMax<=this.ApplyData.Weight);
          }else{
            CheckWeight = (item.WeightMax>=this.ApplyData.Weight);
          }
          if (CheckWeight && (item.AgeMin<=Age) && (item.AgeMax>=Age) && (item.Gender==this.ApplyData.User.Gender.Id)) {
            this.Categories.push(item);
          }
        })
      },
      getApplyData(option) {
        this.ApplySaved=false;
        this.SelectType=true;
        if (option == 0) {
          this.ApplyId = 0;
          this.ApplyData = {
            User: {Gender: {}},
            Category: {},
            DateOfBirth: new Date(),
            Weight: 0,
            Fights: 0,
            Victory: 0,
            Knockouts: 0,
            Team: this.TeamData,
            Payed: false,
            PaymentComments: "",
            CmId: this.$route.params.cmId,
          }
        } else {
          this.$axios.get(`/api/participant/editdata`, {
            params: {
              cmId: this.$route.params.cmId,
              userId: option,
              teamId: this.ApplyData.Team.Id
            }
          })
              .then(response => {
                this.ApplyData = response.data.ApplyData;
              })
              .catch(e => {
                this.errors.push(e)
              })
          }
      },
      applyUser(Data) {
        this.ApplySaved=false;
        let DataApply = Object.assign({}, Data);
        let Now = new Date
        let DateOfBirth = new Date(Data.User.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        if ((typeof Data.User.Name!='undefined') && (typeof Data.User.Surname!='undefined')){
          if (Data.User.Name.length < 3) {
            alert("Vārds ievadīts ne pareizi!")
            return;
          } else if (Data.User.Surname.length < 2) {
            alert("Uzvārds ievadīts ne pareizi!")
            return;
          }
        }
        if ((typeof Data.User.Name=='undefined') || (typeof Data.User.Surname=='undefined')){
          alert("Dalibnieka dati neievadīti!")
          return;
        }
        if ((Age<=5) || (!DateOfBirth)) {
          alert("Dzimšanas datums neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (!Data.User.Gender.Id) {
          alert("Dzimums nav ievadīts!")
          return;
        }
        if ((Data.Weight.length<2) || (!Data.Weight)){
          alert("Svars neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (this.ApplyData.Categories.length==0) {
          alert("Kategorija neievadīta!")
          return;
        }
        if (!Data.Team) {
          alert("Komanda nav ievadīta!")
          return;
        }

        console.log('this.ApplyData: ',this.ApplyData)
        console.log('DataApply: ',DataApply)
        console.log('Data: ',Data)

        DataApply.Categories=[]
        console.log('after')
        console.log('this.ApplyData: ',this.ApplyData)
        console.log('DataApply: ',DataApply)
        console.log('Data: ',Data)

        Data.Categories.forEach((ApplyCategory)=>{
          console.log(`Category: `,ApplyCategory)
          DataApply.Categories.push(ApplyCategory.Id)
        })

        console.log(`Categories: `,DataApply.Categories)

        DataApply.User.DateOfBirth = DateOfBirth.getFullYear() +
          "-" +
          parseInt(DateOfBirth.getMonth() + 1) +
          "-" +
          DateOfBirth.getDate();
        console.log(DataApply.User.DateOfBirth)

        if (this.ApplyId===0) {
          this.$socket.emit('apply-cmuser', DataApply);
        }else {
          DataApply.CmUserId=this.ApplyId;
          this.$socket.emit('edit-cmuser', DataApply);
        }
        this.ApplyNr++;
      },

      deleteUserClaim(q) {
        this.$socket.emit('delete-cmuser', {
          usercmId: q,
          cmId: this.$route.params.cmId
        });
      },
      NameSurnameLabel (option) {
        let now = new Date;
        let UserBirthDate=option.DateOfBirth;
        UserBirthDate = new Date(UserBirthDate);
        let Age=((now.getTime() - UserBirthDate) / (24 * 3600 * 365.25 * 1000) | 0);
        if ((typeof option.Name == 'undefined') && (typeof option.Surname == 'undefined')) {return '-'}
        return `${option.Name} - ${option.Surname} (Age: ${Age})`
      },
      CategoryLabel (option) {
        if ((typeof option.AgeCategory=='undefined')&&(typeof option.WeightMax=='undefined')){return '-'}
        let Side = '';

        if(option.CategorySide==1){
          Side = ' + ';
        }else{
          Side = ' - ';
        }
        return `${option.DisciplineTitle} ${option.AgeCategory} ${Side}${option.WeightMax} (Age: ${option.AgeMin} - ${option.AgeMax})`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
      getData() {
        this.$axios.get(`/api/participant/team-apply-data`, {
          params: {
            userType: this.$store.state.authUser.type,
            userId: this.$store.state.authUser.id,
            cmId: this.$route.params.cmId,
            teamId: this.TeamData.Id
          }
        })
        .then(response => {
          this.Users = response.data.Users;
          this.Categories = response.data.Categories;
          this.AllCategories = response.data.Categories;
          this.Teams = response.data.Teams;
          this.TeamParticipants = response.data.AppliedUsers;
          console.log(this.Categories)
          console.log(this.Users)
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
    },
    mounted() {
      this.getData()
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      })
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('success', response => {
        console.log('response: ',response)
        if (response.message) {
          alert(response.message)
        }
        if (response.status===true){
            this.ApplySaved=true;
            console.log('saved: ',this.ApplySaved)
        }
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-categories-data');
      this.$socket.removeListener('refresh-cmusers-data');
    },
    components: {
      DatePicker, "multiselect": Multiselect
    },
  };
</script>

<style lang="scss">
  .team-apply-modal{
    padding: 5rem 10rem 25rem 10rem;
    &__participant-data{

    }
  }
  .page {
    &__team-apply-form {
      display: flex;
      flex-direction: row;
      background-color: #eeecf6;
      z-index: 0;
      padding: 3rem;
      width:100%;
      &__list-block {
        display: flex;
        flex-direction: column;
        width: 30rem;
        &__list {
          width: 20rem;
          &--item {
            padding: 1rem;
            cursor: pointer;
            &:checked,
            &:focus,
            &:active,
            &:hover {
              color: #fff;
              font-weight: bold;
              background: #428bca;
              box-shadow: 0 0 10px 100px #428bca inset;
            }
          }
          &--delete-item {
            padding: 1rem;
            cursor: pointer;
            &:checked,
            &:focus,
            &:active,
            &:hover {
              color: #fff;
              font-weight: bold;
              background: #ca4f56;
              box-shadow: 0 0 10px 100px #ca4f56 inset;
            }
          }
        }
      }
      &__input {
        padding-left: 3rem;
        width: 55rem;
        font-size: 1rem;
        &__data-tabs {
          &__item {
            display: flex;
            flex-direction: column;
          }
        }
      }

      &__btn-item {
        margin: 1rem;

        &--btn {
          padding-right: 3rem;
          width: 15rem;
        }
      }
    }
  }
   /*   &__item{
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;
        min-width: 18rem;
        &--label{
          text-align: right;
        }
        &--input{
        }
        &--btn{
          padding-right: 3rem;
          width: 15rem;
        }
      }
      &__select-item{
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;
        min-width: 26rem;
      }
      
    }
  }*/


  .tab-disabled{
    pointer-events: none;
  }
  .tab-disabled{
    pointer-events: none;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>