var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.RingsData,
          "search-options": {
            enabled: true,
            trigger: "enter"
          },
          "pagination-options": {
            enabled: true,
            perPage: 10
          },
          styleClass: "vgt-table striped bordered"
        },
        on: { "on-cell-click": _vm.onCellClick },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                _vm.EditParams.Show
                  ? _c("div", [
                      props.column.field == _vm.SelectedColumn
                        ? _c("div", [
                            props.row.Id == _vm.EditParams.Id
                              ? _c("div", [
                                  _vm.SelectedColumn == "RingTitle"
                                    ? _c("div", [
                                        _vm.addblock
                                          ? _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.EditParams.CellValue,
                                                    expression:
                                                      "EditParams.CellValue"
                                                  }
                                                ],
                                                domProps: {
                                                  value:
                                                    _vm.EditParams.CellValue
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !("button" in $event) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.submit($event)
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.EditParams,
                                                      "CellValue",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ])
                                          : _vm._e()
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                props.column.field == "Actions"
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          on: {
                            click: function($event) {
                              _vm.deleteRing(props.row.Id)
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n        "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5576a2e2", { render: render, staticRenderFns: staticRenderFns })
  }
}