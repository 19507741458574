import Vue from 'vue';
import App from './App.vue'
import router from './router'
import store  from  './store.js';
import 'es6-promise/auto'
import io from 'socket.io-client/dist/socket.io.js'
import axios from 'axios'
import './assets/scss/main.scss'
import './plugins'
import moment from 'moment'
import DateFilter from './filters/date'

import VueGoodTable from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

Vue.use(VueGoodTable);


const EventBus = new Vue();

Vue.prototype.$socket = io(`${process.env.BASE_URL}:${process.env.PORT}`);
/*Vue.prototype.$socket = io('http://localhost:8000');*/
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$bus = EventBus;

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.filter( 'date', DateFilter )

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: {
    App
  },
  template: '<App/>',
});