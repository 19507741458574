var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { color: "black", padding: "1rem" } }, [
    _c("form", { attrs: { novalidate: "" } }, [
      _c("h1", { staticStyle: { "text-align": "center" } }, [_vm._v("Login")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.email,
            expression: "user.email"
          }
        ],
        ref: "email",
        attrs: {
          id: "email",
          type: "text",
          placeholder: "Enter Email",
          name: "email",
          required: ""
        },
        domProps: { value: _vm.user.email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "email", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.psw,
            expression: "user.psw"
          }
        ],
        attrs: {
          id: "psw",
          type: "password",
          placeholder: "Enter Password",
          name: "psw",
          required: ""
        },
        domProps: { value: _vm.user.psw },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "psw", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.validation.credentialsInvalid
        ? _c("div", { staticClass: "error-msg" }, [
            _c("p", [_vm._v(_vm._s(_vm.validation.message))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }, [
        _c(
          "button",
          {
            staticClass: "cancelbtn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.clear($event)
              }
            }
          },
          [_vm._v("Clear")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "signupbtn",
            attrs: { type: "submit", disabled: _vm.validation.formInvalid },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.login($event)
              }
            }
          },
          [_vm._v("Sign Up")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "email" } }, [
      _c("b", [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "psw" } }, [
      _c("b", [_vm._v("Password")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticStyle: { "text-align": "left" } }, [
      _c("a", { attrs: { href: "/registration" } }, [_vm._v("Reģistracija")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a40fbe5a", { render: render, staticRenderFns: staticRenderFns })
  }
}