var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("FightInfo", { attrs: { fight: _vm.testFight } }),
      _vm._v(" "),
      _vm.testFight
        ? _c("div", { staticClass: "fight-info__fight-bottom-description" }, [
            _c(
              "div",
              {
                staticClass:
                  "fight-info__fight-bottom-description__fight-main-info"
              },
              [
                _c(
                  "h4",
                  {
                    staticClass:
                      "fight-info__fight-bottom-description__fight-main-info--data"
                  },
                  [
                    _vm._v(
                      "Weight Category: " +
                        _vm._s(_vm.testFight.weight_category) +
                        " KG"
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    staticClass:
                      "fight-info__fight-bottom-description__fight-main-info--data"
                  },
                  [_vm._v("Ring: " + _vm._s(_vm.testFight.ring))]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    staticClass:
                      "fight-info__fight-bottom-description__fight-main-info--data"
                  },
                  [_vm._v("Ring Nr: " + _vm._s(_vm.testFight.ring_nr))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fight-info__fight-bottom-description__timer" },
              [
                _vm.testFight.roundDetails
                  ? _c("timer", {
                      attrs: {
                        data: _vm.testFight.roundDetails,
                        lvl: _vm.testFight.lvl
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "fight-control" }, [
        _c("div", { staticClass: "fight-control__table-red" }, [
          _vm.testFight.scores
            ? _c(
                "table",
                {
                  staticClass:
                    "table table-bordered table-hover text-center table1"
                },
                [
                  _c("thead", [
                    _vm._m(0),
                    _vm._v(" "),
                    !_vm.testFight.judgesConfirmed
                      ? _c(
                          "tr",
                          { staticClass: "atcive" },
                          [
                            _vm._l(_vm.testFight.judges_count, function(
                              item,
                              key
                            ) {
                              return _c("th", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.judgesStaff[key],
                                        expression: "judgesStaff[key]"
                                      }
                                    ],
                                    staticClass: "table-select",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.judgesStaff,
                                          key,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  _vm._l(_vm.testFight.judgesList, function(
                                    judge
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        staticClass: "table-select__option",
                                        domProps: { value: judge.id }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(judge.name) +
                                            " " +
                                            _vm._s(judge.surname)
                                        )
                                      ]
                                    )
                                  })
                                )
                              ])
                            }),
                            _vm._v(" "),
                            _c("th", { staticClass: "mpoint" }, [
                              _vm._v("Kicks")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "mpoint" }, [
                              _vm._v("Fouls")
                            ])
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.testFight.judgesConfirmed
                      ? _c(
                          "tr",
                          { staticClass: "atcive" },
                          [
                            _vm._l(_vm.testFight.judges, function(judge) {
                              return _c(
                                "th",
                                { staticStyle: { width: "90px" } },
                                [
                                  _vm._v(
                                    _vm._s(judge.name) +
                                      " " +
                                      _vm._s(judge.surname) +
                                      "\n          "
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "mpoint",
                                staticStyle: { cursor: "pointer" }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info",
                                    attrs: {
                                      disabled: !(
                                        _vm.testFight.winner_id === null
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.addPoint(
                                          1,
                                          _vm.testFight.red_user_id,
                                          "kick"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Kicks")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "mpoint",
                                staticStyle: { cursor: "pointer" }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info",
                                    attrs: {
                                      disabled: !(
                                        _vm.testFight.winner_id === null
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.addPoint(
                                          1,
                                          _vm.testFight.red_user_id,
                                          "foul"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Fouls")]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.testFight.scores.red, function(score, index) {
                        return _vm.testFight.judgesConfirmed
                          ? _c(
                              "tr",
                              {
                                class: {
                                  activeRound:
                                    index + 1 ===
                                    _vm.testFight.roundDetails.round_nr
                                }
                              },
                              [
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(index + 1))]
                                ),
                                _vm._v(" "),
                                _vm._l(score.judges, function(item) {
                                  return _c("td", [_vm._v(_vm._s(item))])
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "mpoint" }, [
                                  _vm._v(_vm._s(score.kicks))
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "mpoint" }, [
                                  _vm._v(_vm._s(score.fouls))
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(score.result))])
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("tr", { staticClass: "t_totalmax" }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: "6" }
                          },
                          [_vm._v("Red-total")]
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.testFight.scores.total.red))
                        ])
                      ])
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fight-control__table-blue" }, [
          _vm.testFight.scores
            ? _c(
                "table",
                {
                  staticClass:
                    "table table-bordered table-hover text-center table2"
                },
                [
                  _c("thead", [
                    _vm._m(1),
                    _vm._v(" "),
                    !_vm.testFight.judgesConfirmed
                      ? _c(
                          "tr",
                          { staticClass: "atcive" },
                          [
                            _c("th", { staticClass: "mpoint" }, [
                              _vm._v("Kicks")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "mpoint" }, [
                              _vm._v("Fouls")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.testFight.judges_count, function(
                              item,
                              key
                            ) {
                              return _c("th", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.judgesStaff[key],
                                        expression: "judgesStaff[key]"
                                      }
                                    ],
                                    staticClass: "table-select",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.judgesStaff,
                                          key,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  _vm._l(_vm.testFight.judgesList, function(
                                    judge
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        staticClass: "table-select__option",
                                        domProps: { value: judge.id }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(judge.name) +
                                            " " +
                                            _vm._s(judge.surname)
                                        )
                                      ]
                                    )
                                  })
                                )
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.testFight.judgesConfirmed
                      ? _c(
                          "tr",
                          { staticClass: "atcive" },
                          [
                            _c(
                              "th",
                              {
                                staticClass: "mpoint",
                                staticStyle: { cursor: "pointer" }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info",
                                    attrs: {
                                      disabled: !(
                                        _vm.testFight.winner_id === null
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.addPoint(
                                          2,
                                          _vm.testFight.blue_user_id,
                                          "kick"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Kicks")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "mpoint",
                                staticStyle: { cursor: "pointer" }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info",
                                    attrs: {
                                      disabled: !(
                                        _vm.testFight.winner_id === null
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.addPoint(
                                          2,
                                          _vm.testFight.blue_user_id,
                                          "foul"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Fouls")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.testFight.judges, function(judge) {
                              return _c(
                                "th",
                                { staticStyle: { width: "90px" } },
                                [
                                  _vm._v(
                                    _vm._s(judge.name) +
                                      " " +
                                      _vm._s(judge.surname) +
                                      "\n          "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.testFight.scores.blue, function(score, index) {
                        return _vm.testFight.judgesConfirmed
                          ? _c(
                              "tr",
                              {
                                class: {
                                  activeRound:
                                    index + 1 ===
                                    _vm.testFight.roundDetails.round_nr
                                }
                              },
                              [
                                _c("td", [_vm._v(_vm._s(score.result))]),
                                _vm._v(" "),
                                _c("td", { staticClass: "mpoint" }, [
                                  _vm._v(_vm._s(score.kicks))
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "mpoint" }, [
                                  _vm._v(_vm._s(score.fouls))
                                ]),
                                _vm._v(" "),
                                _vm._l(score.judges, function(item) {
                                  return _c("td", [_vm._v(_vm._s(item))])
                                }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(index + 1))]
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.testFight.scores.total.blue))
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-left", attrs: { colspan: "6" } },
                          [_vm._v("Blue-total")]
                        )
                      ])
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.testFight
        ? _c("div", { staticClass: "fight-control__action-buttons" }, [
            _vm.testFight.judgesConfirmed
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-warning ",
                    staticStyle: { width: "18rem", "margin-bottom": "2rem" },
                    on: { click: _vm.showOnMainScreen }
                  },
                  [_vm._v("\n      Show on main screen\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            (!_vm.testFight.roundDetails ||
              (_vm.testFight.roundDetails &&
                !_vm.testFight.roundDetails.battle_status) ||
              (_vm.testFight.roundDetails &&
                _vm.testFight.roundDetails.round_status === 2)) &&
            (!_vm.testFight.roundDetails ||
              (_vm.testFight.roundDetails &&
                _vm.testFight.roundDetails.battle_status !== 2))
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-success fight-control__action-buttons--item",
                    attrs: { disabled: !_vm.testFight.roundDetails },
                    on: { click: _vm.startRound }
                  },
                  [_vm._v("\n      Start\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.testFight.roundDetails &&
            _vm.testFight.roundDetails.round_status === 1 &&
            _vm.testFight.roundDetails.pause === 0
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-danger fight-control__action-buttons--item",
                    on: { click: _vm.stopRound }
                  },
                  [_vm._v("\n      Stop\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.testFight.roundDetails &&
            _vm.testFight.roundDetails.round_status === 1 &&
            _vm.testFight.roundDetails.pause === 1
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-success fight-control__action-buttons--item",
                    on: { click: _vm.continueRound }
                  },
                  [_vm._v("\n      Continue\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-warning fight-control__action-buttons--item",
                on: { click: _vm.checkJudgesStatus }
              },
              [_vm._v("\n      Ready check\n    ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _vm.handicap.color && _vm.handicap.points
          ? _c(
              "div",
              { staticClass: " text-center bold", class: _vm.handicap.color },
              [
                _c("span", { staticClass: "capitalize" }, [
                  _vm._v(_vm._s(_vm.handicap.color))
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(" corner have ")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.handicap.points))]),
                _vm._v(" "),
                _c("span", [_vm._v(" points more!")])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.testFight.judgesConfirmed
          ? _c("div", { staticClass: " text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success confirm_judges",
                  staticStyle: { height: "70px", width: "180px" },
                  attrs: {
                    disabled:
                      _vm.judgesStaff.length < _vm.testFight.judges_count
                  },
                  on: { click: _vm.confirmJudges }
                },
                [_vm._v("Confirm judges\n      ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.testFight.winner_id
          ? _c(
              "div",
              {
                staticClass: "col-md-12 col-lg-12",
                staticStyle: { margin: "0 auto", "padding-top": "20px" }
              },
              [
                !_vm.testFight.winner_id &&
                _vm.finalScore.red === _vm.finalScore.blue &&
                _vm.calculated
                  ? _c(
                      "table",
                      {
                        staticClass:
                          "table table-bordered table-hover text-center table-remarks",
                        attrs: { align: "center" }
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better in last round")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[0],
                                          expression: "remarks[0]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark1",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[0], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 0, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[0],
                                          expression: "remarks[0]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark1",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[0], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 0, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("More active")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[1],
                                          expression: "remarks[1]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark2",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[1], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 1, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[1],
                                          expression: "remarks[1]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark2",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[1], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 1, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better kicks")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[2],
                                          expression: "remarks[2]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark3",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[2], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 2, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[2],
                                          expression: "remarks[2]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark3",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[2], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 2, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better defense")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[3],
                                          expression: "remarks[3]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark4",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[3], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 3, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[3],
                                          expression: "remarks[3]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark4",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[3], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 3, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better technic")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[4],
                                          expression: "remarks[4]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark5",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[4], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 4, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[4],
                                          expression: "remarks[4]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark5",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[4], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 4, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better condition")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[5],
                                          expression: "remarks[5]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark6",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[5], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 5, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[5],
                                          expression: "remarks[5]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark6",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[5], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 5, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better moving")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[6],
                                          expression: "remarks[6]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark7",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[6], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 6, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[6],
                                          expression: "remarks[6]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark7",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[6], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 6, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Better power")
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "red-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[7],
                                          expression: "remarks[7]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark8",
                                        type: "radio",
                                        value: "red"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[7], "red")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 7, "red")
                                        }
                                      }
                                    }),
                                    _vm._v("Red\n              "),
                                    _c("span", {
                                      staticClass: "red-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "blue-radio-label",
                                attrs: { colspan: "2" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "remark-container" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.remarks[7],
                                          expression: "remarks[7]"
                                        }
                                      ],
                                      attrs: {
                                        name: "Remark8",
                                        type: "radio",
                                        value: "blue"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.remarks[7], "blue")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.$set(_vm.remarks, 7, "blue")
                                        }
                                      }
                                    }),
                                    _vm._v("Blue\n              "),
                                    _c("span", {
                                      staticClass: "blue-remark-checkmark"
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: " text-center",
                    staticStyle: { height: "100px" }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        staticStyle: { height: "70px", width: "180px" },
                        on: { click: _vm.calculate }
                      },
                      [_vm._v("\n\n          Calculate results\n        ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.calculated
                  ? _c("div", { staticClass: " text-center" }, [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-bordered table-hover text-center table-battle-results",
                          attrs: { align: "center" }
                        },
                        [
                          _vm._m(3),
                          _vm._v(" "),
                          _c("tbody", [
                            _vm._m(4),
                            _vm._v(" "),
                            _c("tr", { staticClass: "atcive" }, [
                              _c("th", { attrs: { colspan: "2" } }, [
                                _vm._v("Scores")
                              ]),
                              _vm._v(" "),
                              _c("th", { attrs: { colspan: "2" } }, [
                                _vm._v(_vm._s(_vm.finalScore.red))
                              ]),
                              _vm._v(" "),
                              _c("th", { attrs: { colspan: "2" } }, [
                                _vm._v(_vm._s(_vm.finalScore.blue))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", { attrs: { colspan: "2" } }, [
                                _vm._v("Winner")
                              ]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass: "red-radio-label",
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "radio-container" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.winner,
                                            expression: "winner"
                                          }
                                        ],
                                        attrs: { type: "radio", value: "red" },
                                        domProps: {
                                          checked: _vm._q(_vm.winner, "red")
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.winner = "red"
                                          }
                                        }
                                      }),
                                      _vm._v("Red\n                "),
                                      _c("span", {
                                        staticClass: "red-checkmark"
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass: "blue-radio-label",
                                  attrs: { colspan: "2", test: "ddd" }
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "radio-container" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.winner,
                                            expression: "winner"
                                          }
                                        ],
                                        attrs: { type: "radio", value: "blue" },
                                        domProps: {
                                          checked: _vm._q(_vm.winner, "blue")
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.winner = "blue"
                                          }
                                        }
                                      }),
                                      _vm._v("Blue\n                "),
                                      _c("span", {
                                        staticClass: "blue-checkmark"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.calculated
                  ? _c("div", [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-bordered table-hover text-center table-decision",
                          attrs: { align: "center" }
                        },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", { staticClass: "atcive" }, [
                              _c("th", { attrs: { width: "200px" } }, [
                                _vm._v("Winner: "),
                                _c("h1", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.testFight[_vm.winner + "_name"]
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.testFight[_vm.winner + "_surname"]
                                      )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("th", { attrs: { width: "200px" } }, [
                                _vm._v("Club / Nationality: "),
                                _c("h2", [
                                  _vm._v(
                                    _vm._s(_vm.testFight[_vm.winner + "_club"])
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.calculated
                  ? _c(
                      "table",
                      {
                        staticClass:
                          "table table-bordered table-hover table-won-by",
                        attrs: { align: "center" }
                      },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("tbody", [
                          _vm._m(7),
                          _vm._v(" "),
                          _c("tr", { staticClass: "atcive" }, [
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: { name: "P", type: "radio", value: "1" },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "1")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "1"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "KO/KD",
                                  type: "radio",
                                  value: "2"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "2")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "2"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "DISQ",
                                  type: "radio",
                                  value: "3"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "3")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "3"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "RSC",
                                  type: "radio",
                                  value: "4"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "4")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "4"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "RSCH",
                                  type: "radio",
                                  value: "5"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "5")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "5"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "W.O",
                                  type: "radio",
                                  value: "6"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "6")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "6"
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.win_type,
                                    expression: "win_type"
                                  }
                                ],
                                attrs: {
                                  name: "AB",
                                  type: "radio",
                                  value: "7"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.win_type, "7")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.win_type = "7"
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(8)
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.calculated
                  ? _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { height: "200px" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            staticStyle: { height: "70px", width: "180px" },
                            attrs: { disabled: _vm.win_type === "" },
                            on: { click: _vm.confirmResults }
                          },
                          [_vm._v("Confirm results\n        ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _c(
              "h1",
              {
                staticStyle: {
                  width: "100%",
                  "text-align": "center",
                  display: "inline-block",
                  margin: "10rem 0"
                }
              },
              [
                _vm._v(
                  "\n      Winner is " +
                    _vm._s(
                      _vm.testFight.winner_id === _vm.testFight.red_user_id
                        ? _vm.testFight.red_name +
                            " " +
                            _vm.testFight.red_surname
                        : _vm.testFight.blue_name +
                            " " +
                            _vm.testFight.blue_surname
                    ) +
                    "\n    "
                )
              ]
            )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { name: "ready-check", height: "auto" } },
        [_c("ready-check", { attrs: { judges: _vm.testFight.judges } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "atcive" }, [
      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Round")]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "3" } }, [_vm._v("Score")]),
      _vm._v(" "),
      _c("th", { staticClass: "mpoint", attrs: { colspan: "2" } }, [
        _vm._v("Minus point")
      ]),
      _vm._v(" "),
      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Result")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "atcive" }, [
      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Result")]),
      _vm._v(" "),
      _c("th", { staticClass: "mpoint", attrs: { colspan: "2" } }, [
        _vm._v("Minus point")
      ]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "3" } }, [_vm._v("Score")]),
      _vm._v(" "),
      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Round")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c("th", { attrs: { colspan: "6" } }, [
          _vm._v("Remarks when points equal:")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c(
          "th",
          { staticStyle: { "text-align": "center" }, attrs: { colspan: "6" } },
          [_vm._v("Battle Results")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "atcive" }, [
      _c("th", { attrs: { colspan: "2" } }),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Red Fighter")]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Blue Fighter")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c(
          "th",
          { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
          [_vm._v("Decision:")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c(
          "th",
          { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
          [_vm._v("Won by:")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "atcive" }, [
      _c("th", [_vm._v("P")]),
      _vm._v(" "),
      _c("th", [_vm._v("KO/KD")]),
      _vm._v(" "),
      _c("th", [_vm._v("DISQ")]),
      _vm._v(" "),
      _c("th", [_vm._v("RSC")]),
      _vm._v(" "),
      _c("th", [_vm._v("RSCH")]),
      _vm._v(" "),
      _c("th", [_vm._v("W.O")]),
      _vm._v(" "),
      _c("th", [_vm._v("AB")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("P Winner by points")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "4" } }, [
          _vm._v("RSC Referee stop contest")
        ])
      ]),
      _vm._v(" "),
      _c("tr", { staticClass: "atcive" }, [
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("KO Knock out")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "4" } }, [
          _vm._v("RSCH Hard blows to the head")
        ])
      ]),
      _vm._v(" "),
      _c("tr", { staticClass: "atcive" }, [
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("KD Knock down")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("W.O. Walk over")])
      ]),
      _vm._v(" "),
      _c("tr", { staticClass: "atcive" }, [
        _c("th", { attrs: { colspan: "3" } }, [
          _vm._v("DISQ Disqualification")
        ]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("AB Abandonment")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23453981", { render: render, staticRenderFns: staticRenderFns })
  }
}