var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.ParticipantsData,
          "pagination-options": {
            enabled: true,
            perPage: 10
          },
          styleClass: "vgt-table striped bordered"
        },
        on: { "on-cell-click": _vm.onCellClick },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.field == "WeighingNr"
                  ? _c("div", [
                      props.row.WeighingNr == "0"
                        ? _c("div", [_vm._v("\n          -\n        ")])
                        : _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.formattedRow[props.column.field]) +
                                "\n        "
                            )
                          ])
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n          "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3fd015c2", { render: render, staticRenderFns: staticRenderFns })
  }
}