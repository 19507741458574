var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__table" },
        [
          _c(
            "div",
            { staticStyle: { padding: "1rem 4rem" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-info",
                  attrs: { to: { name: "createAgeCategoryPage" } }
                },
                [_vm._v("Izveidot vecuma categoriju +")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("age-categories-grid", {
            attrs: { AgeCategoriesData: _vm.AgeCategories }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Vecuma kategorijas saraksts")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b961bf56", { render: render, staticRenderFns: staticRenderFns })
  }
}