var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__input__form__item" },
          [
            _c(
              "h1",
              {
                staticClass:
                  "page__content__input__form__item--label control-label ",
                staticStyle: { "z-index": "2" }
              },
              [_vm._v("Dalibnieks")]
            ),
            _vm._v(" "),
            !_vm.UserData
              ? _c("h4", { staticStyle: { color: "red" } }, [
                  _vm._v("Dalibnieks neeksistē")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.UserData
              ? _c("h4", { staticStyle: { color: "#6db500" } }, [
                  _vm._v("Dalibnieks eksistē")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.Users,
                "close-on-select": true,
                "clear-on-select": false,
                placeholder: "",
                "track-by": "Id",
                "custom-label": _vm.NameSurname
              },
              model: {
                value: _vm.UserData,
                callback: function($$v) {
                  _vm.UserData = $$v
                },
                expression: "UserData"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "page__content__input__form__item" }, [
          _vm._v("Dalibnieka dati saglabāšanai:")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.User.Name,
                  expression: "ApplyData.User.Name"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.User.Name },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData.User, "Name", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.User.Surname,
                  expression: "ApplyData.User.Surname"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.ApplyData.User.Surname },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData.User, "Surname", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__input__form__item--input",
                staticStyle: { "z-index": "1" }
              },
              [
                _c("DatePicker", {
                  attrs: {
                    "calendar-class": "calendar",
                    "monday-first": true,
                    placeholder: "00-00-00",
                    "bootstrap-styling": true
                  },
                  on: {
                    closed: function($event) {
                      _vm.setCategories()
                    }
                  },
                  model: {
                    value: _vm.ApplyData.User.DateOfBirth,
                    callback: function($$v) {
                      _vm.$set(_vm.ApplyData.User, "DateOfBirth", $$v)
                    },
                    expression: "ApplyData.User.DateOfBirth"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Genders,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.GenderLabel
                },
                on: {
                  input: function($event) {
                    _vm.setCategories()
                  }
                },
                model: {
                  value: _vm.ApplyData.User.Gender,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData.User, "Gender", $$v)
                  },
                  expression: "ApplyData.User.Gender"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.Weight,
                  expression: "ApplyData.Weight"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.Weight },
              on: {
                change: function($event) {
                  _vm.setCategories()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.ApplyData, "Weight", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(6),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Categories,
                  "close-on-select": true,
                  multiple: true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "",
                  "track-by": "Id",
                  "custom-label": _vm.CategoryLabel
                },
                model: {
                  value: _vm.ApplyData.Categories,
                  callback: function($$v) {
                    _vm.$set(_vm.ApplyData, "Categories", $$v)
                  },
                  expression: "ApplyData.Categories"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__input__form__item" },
          [
            _c(
              "h1",
              {
                staticClass:
                  "page__content__input__form__item--label control-label ",
                staticStyle: { "z-index": "0" }
              },
              [_vm._v("Komanda")]
            ),
            _vm._v(" "),
            !_vm.ApplyData.Team
              ? _c("h4", { staticStyle: { color: "red" } }, [
                  _vm._v("Komanda neeksistē")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ApplyData.Team
              ? _c("h4", { staticStyle: { color: "#6db500" } }, [
                  _vm._v("Komanda eksistē")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.Teams,
                "close-on-select": true,
                "clear-on-select": false,
                "open-direction": "below",
                placeholder: "",
                "track-by": "Id",
                "custom-label": _vm.TeamLabel
              },
              model: {
                value: _vm.ApplyData.Team,
                callback: function($$v) {
                  _vm.$set(_vm.ApplyData, "Team", $$v)
                },
                expression: "ApplyData.Team"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "page__content__input__form__item" }, [
          _vm._v("Komandas dati saglabāšanai:")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(7),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.UnknownUsersTeam.Title,
                  expression: "ApplyData.UnknownUsersTeam.Title"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.UnknownUsersTeam.Title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.ApplyData.UnknownUsersTeam,
                    "Title",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(8),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.UnknownUsersTeam.Coach,
                  expression: "ApplyData.UnknownUsersTeam.Coach"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.UnknownUsersTeam.Coach },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.ApplyData.UnknownUsersTeam,
                    "Coach",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(9),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.UnknownUsersTeam.Address,
                  expression: "ApplyData.UnknownUsersTeam.Address"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.UnknownUsersTeam.Address },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.ApplyData.UnknownUsersTeam,
                    "Address",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(10),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.UnknownUsersTeam.Phone,
                  expression: "ApplyData.UnknownUsersTeam.Phone"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.UnknownUsersTeam.Phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.ApplyData.UnknownUsersTeam,
                    "Phone",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(11),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ApplyData.UnknownUsersTeam.Email,
                  expression: "ApplyData.UnknownUsersTeam.Email"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.ApplyData.UnknownUsersTeam.Email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.ApplyData.UnknownUsersTeam,
                    "Email",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__btn-item" }, [
            _c(
              "button",
              {
                staticClass:
                  "page__content__input__form__btn-item--btn btn btn-primary",
                on: {
                  click: function($event) {
                    _vm.approveExternalCmClaim(_vm.ApplyData)
                  }
                }
              },
              [_vm._v("Saglabat")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Pieteikuma izskatišāna")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Vards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Uzvards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { left: "-2rem" }
      },
      [_vm._v("Dzimsanas Datums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { "z-index": "0" }
      },
      [_vm._v("Dzimums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Weight"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { "z-index": "0" }
      },
      [_vm._v("Kategorijas"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Komandas nosaukums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Treneris"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Komandas adrese"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Komandas talrunis"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Komandas email"), _c("sup", [_vm._v("*")])]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-329512e4", { render: render, staticRenderFns: staticRenderFns })
  }
}