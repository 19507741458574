<template>
  <div class="page">
    <FightInfo :fight="testFight"></FightInfo>
    <div v-if="testFight" class="fight-info__fight-bottom-description">
      <div class="fight-info__fight-bottom-description__fight-main-info">
        <h4 class="fight-info__fight-bottom-description__fight-main-info--data">Weight Category: {{ testFight.weight_category }} KG</h4>
        <h4 class="fight-info__fight-bottom-description__fight-main-info--data">Ring: {{ testFight.ring }}</h4>
        <h4 class="fight-info__fight-bottom-description__fight-main-info--data">Ring Nr: {{ testFight.ring_nr }}</h4>
      </div>
      <div class="fight-info__fight-bottom-description__timer">
        <timer v-if="testFight.roundDetails" :data="testFight.roundDetails" :lvl="testFight.lvl"></timer>
      </div>
    </div>
    <div class="fight-control">
      <div class="fight-control__table-red">
        <table v-if="testFight.scores" class="table table-bordered table-hover text-center table1">
          <thead>
          <tr class="atcive">
            <th rowspan="2">Round</th>
            <th colspan="3">Score</th>
            <th colspan="2" class="mpoint">Minus point</th>
            <th rowspan="2">Result</th>
          </tr>
          <tr class="atcive" v-if="!testFight.judgesConfirmed">
            <th v-for="(item, key) in testFight.judges_count"><select class="table-select" v-model="judgesStaff[key]">
              <option class="table-select__option" v-for="judge in testFight.judgesList" :value="judge.id">{{judge.name}} {{judge.surname}}</option>
            </select>
            </th>
            <th class="mpoint">Kicks</th>
            <th class="mpoint">Fouls</th>
          </tr>
          <tr class="atcive" v-if="testFight.judgesConfirmed">
            <th style="width:90px;" v-for="judge in testFight.judges">{{judge.name}} {{judge.surname}}
            </th>
            <th class="mpoint" style="cursor: pointer"><button class="btn btn-info" @click="addPoint(1, testFight.red_user_id, 'kick')" :disabled="!(testFight.winner_id===null)">Kicks</button></th>
            <th class="mpoint" style="cursor: pointer"><button class="btn btn-info" @click="addPoint(1, testFight.red_user_id, 'foul')" :disabled="!(testFight.winner_id===null)">Fouls</button></th>
          </tr>
          </thead>
          <tbody>
          <tr :class="{ activeRound: (index+1) === testFight.roundDetails.round_nr}" v-if="testFight.judgesConfirmed" v-for="(score, index) in testFight.scores.red">
            <td style="font-weight: bold">{{index + 1}}</td>
            <td v-for="item in score.judges">{{item}}</td>
            <td class="mpoint">{{score.kicks}}</td>
            <td class="mpoint">{{score.fouls}}</td>
            <td>{{score.result}}</td>
          </tr>
          <tr class="t_totalmax">
            <td colspan="6" class="text-right">Red-total</td>
            <td>{{testFight.scores.total.red}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="fight-control__table-blue">
        <table v-if="testFight.scores" class="table table-bordered table-hover text-center table2">
          <thead>
          <tr class="atcive">
            <th rowspan="2">Result</th>
            <th colspan="2" class="mpoint">Minus point</th>
            <th colspan="3">Score</th>
            <th rowspan="2">Round</th>
          </tr>
          <tr class="atcive" v-if="!testFight.judgesConfirmed">
            <th class="mpoint">Kicks</th>
            <th class="mpoint">Fouls</th>
            <th v-for="(item, key) in testFight.judges_count"><select class="table-select" v-model="judgesStaff[key]">
              <option class="table-select__option" v-for="judge in testFight.judgesList" :value="judge.id">{{judge.name}} {{judge.surname}}</option>
            </select>
            </th>
          </tr>
          <tr class="atcive" v-if="testFight.judgesConfirmed">
            <th class="mpoint" style="cursor: pointer"><button class="btn btn-info" @click="addPoint(2, testFight.blue_user_id, 'kick')" :disabled="!(testFight.winner_id===null)">Kicks</button></th>
            <th class="mpoint" style="cursor: pointer"><button class="btn btn-info" @click="addPoint(2, testFight.blue_user_id, 'foul')" :disabled="!(testFight.winner_id===null)">Fouls</button></th>
            <th style="width:90px;" v-for="judge in testFight.judges">{{judge.name}} {{judge.surname}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :class="{ activeRound: (index+1) === testFight.roundDetails.round_nr}" v-if="testFight.judgesConfirmed" v-for="(score, index) in testFight.scores.blue">
            <td>{{score.result}}</td>
            <td class="mpoint">{{score.kicks}}</td>
            <td class="mpoint">{{score.fouls}}</td>
            <td v-for="item in score.judges">{{item}}</td>
            <td style="font-weight: bold">{{index + 1}}</td>
          </tr>
          <tr>
            <td>{{testFight.scores.total.blue}}</td>
            <td colspan="6" class="text-left">Blue-total</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="testFight" class="fight-control__action-buttons">
      <button v-if="testFight.judgesConfirmed" class="btn btn-warning " style="width: 18rem; margin-bottom: 2rem;" @click="showOnMainScreen"> <!--style="height:70px; width:180px; margin-top: 2rem"-->
        Show on main screen
      </button>
      <button
              class="btn btn-success fight-control__action-buttons--item"
              @click="startRound"
              v-if=" ( !testFight.roundDetails || (testFight.roundDetails && !testFight.roundDetails.battle_status) ||
            (testFight.roundDetails && testFight.roundDetails.round_status === 2) ) && (!testFight.roundDetails ||
             ( testFight.roundDetails && testFight.roundDetails.battle_status !== 2) )"
              :disabled="!testFight.roundDetails">
        Start
      </button>
      <button
              class="btn btn-danger fight-control__action-buttons--item"
              @click="stopRound"

              v-if="testFight.roundDetails && testFight.roundDetails.round_status === 1 && testFight.roundDetails.pause === 0">
        Stop
      </button>
      <button
              class="btn btn-success fight-control__action-buttons--item" v-if="testFight.roundDetails && testFight.roundDetails.round_status === 1 && testFight.roundDetails.pause === 1"
              @click="continueRound">
        Continue
      </button>
      <button class="btn btn-warning fight-control__action-buttons--item" @click="checkJudgesStatus">  <!--style="height:70px; width:180px; margin-top: 2rem"-->
        Ready check
      </button>
    </div>
    <div>
      <div class=" text-center bold" v-if="handicap.color && handicap.points" v-bind:class="handicap.color">
        <span class="capitalize">{{ handicap.color }}</span>
        <span> corner have </span>
        <span>{{ handicap.points }}</span>
        <span> points more!</span>
      </div>



      <div class=" text-center" v-if="!testFight.judgesConfirmed">
        <!--<table class="table table-bordered table-hover text-center" align="center"
               style="text-align:center; width:505px; height:120px;">
          <thead>
          <tr class="atcive">
            <th colspan="7" style="text-align:center;">Judges</th>
          </tr>
          <tr class="atcive">
            <th style="width:185px;" v-for="(item, key) in testFight.judges_count">Judge {{key + 1}}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="atcive">
            <th v-for="(judges) in judgesStaff">{{judges}}</th>
          </tr>
          </tbody>
        </table>-->
        <button class="btn btn-success confirm_judges" style="height:70px; width:180px;" v-on:click="confirmJudges"
                :disabled="judgesStaff.length < testFight.judges_count">Confirm judges
        </button>
      </div>
      <div v-if="!testFight.winner_id" class="col-md-12 col-lg-12" style="margin: 0 auto; padding-top:20px;">
        <table v-if="(!testFight.winner_id) && (finalScore.red===finalScore.blue) && (calculated)" class="table table-bordered table-hover text-center table-remarks" align="center">
          <thead>
          <tr class="atcive">
            <th colspan="6" >Remarks when points equal:</th>
          </tr>
          </thead>
          <tbody>
          <tr class="atcive">
            <th colspan="2" >Better in last round</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark1" type="radio" v-model="remarks[0]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark1" type="radio" v-model="remarks[0]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >More active</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark2" type="radio" v-model="remarks[1]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark2" type="radio" v-model="remarks[1]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better kicks</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark3" type="radio" v-model="remarks[2]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark3" type="radio" v-model="remarks[2]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better defense</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark4" type="radio" v-model="remarks[3]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark4" type="radio" v-model="remarks[3]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better technic</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark5" type="radio" v-model="remarks[4]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark5" type="radio" v-model="remarks[4]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better condition</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark6" type="radio" v-model="remarks[5]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark6" type="radio" v-model="remarks[5]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better moving</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark7" type="radio" v-model="remarks[6]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark7" type="radio" v-model="remarks[6]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          <tr class="atcive">
            <th colspan="2" >Better power</th>
            <th colspan="2" class="red-radio-label">
              <label class="remark-container">
                <input name="Remark8" type="radio" v-model="remarks[7]" value="red">Red
                <span class="red-remark-checkmark"></span>
              </label>
            </th>
            <th colspan="2" class="blue-radio-label">
              <label class="remark-container">
                <input name="Remark8" type="radio" v-model="remarks[7]" value="blue">Blue
                <span class="blue-remark-checkmark"></span>
              </label>
            </th>
          </tr>
          </tbody>
        </table>
        <div class=" text-center" style="height:100px;">
          <button
                  class="btn btn-warning"
                  style=" height:70px; width:180px;"
                  @click="calculate">

            Calculate results
          </button>
        </div>
        <!--:disabled="remarks.length < 8"-->
        <div v-if="calculated" class=" text-center">
          <table class="table table-bordered table-hover text-center table-battle-results" align="center">
            <thead>
            <tr class="atcive">
              <th colspan="6" style="text-align:center;">Battle Results</th>
            </tr>
            </thead>
            <tbody>
            <tr class="atcive">
              <th colspan="2"></th>
              <th colspan="2">Red Fighter</th>
              <th colspan="2">Blue Fighter</th>
            </tr>
            <tr class="atcive">
              <th colspan="2">Scores</th>
              <th colspan="2">{{finalScore.red}}</th>
              <th colspan="2">{{finalScore.blue}}</th>
            </tr>
            <tr>
              <th colspan="2">Winner</th>
              <th colspan="2" class="red-radio-label">
                <label class="radio-container">
                  <input type="radio" v-model="winner" value="red">Red
                  <span class="red-checkmark"></span>
                </label>
              </th>

              <th colspan="2" class="blue-radio-label" test="ddd">
                <label class="radio-container">
                  <input type="radio" v-model="winner" value="blue">Blue
                  <span class="blue-checkmark"></span>
                </label>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="calculated" >
          <table class="table table-bordered table-hover text-center table-decision" align="center">
            <thead>
            <tr class="atcive">
              <th colspan="7" style="text-align:center;">Decision:</th>
            </tr>
            </thead>
            <tbody>
            <tr class="atcive">
              <th width="200px">Winner: <h1>{{testFight[`${winner}_name`]}} {{testFight[`${winner}_surname`]}}</h1></th>
              <th width="200px">Club / Nationality: <h2>{{testFight[`${winner}_club`]}}</h2></th>
            </tr>
            </tbody>
          </table>
        </div>
        <table v-if="calculated" class="table table-bordered table-hover table-won-by" align="center">
          <thead>
          <tr class="atcive">
            <th colspan="7" style="text-align:center;">Won by:</th>
          </tr>
          </thead>
          <tbody>
          <tr class="atcive">
            <th>P</th>
            <th>KO/KD</th>
            <th>DISQ</th>
            <th>RSC</th>
            <th>RSCH</th>
            <th>W.O</th>
            <th>AB</th>
          </tr>
          <tr class="atcive">
            <th><input name="P" type="radio" v-model="win_type" value="1"></th>
            <th><input name="KO/KD" type="radio" v-model="win_type" value="2"></th>
            <th><input name="DISQ" type="radio" v-model="win_type" value="3"></th>
            <th><input name="RSC" type="radio" v-model="win_type" value="4"></th>
            <th><input name="RSCH" type="radio" v-model="win_type" value="5"></th>
            <th><input name="W.O" type="radio" v-model="win_type" value="6"></th>
            <th><input name="AB" type="radio" v-model="win_type" value="7"></th>
          </tr>
          </tbody>
          <thead>
          <tr class="atcive">
            <th colspan="3">P Winner by points</th>
            <th colspan="4">RSC Referee stop contest</th>
          </tr>
          <tr class="atcive">
            <th colspan="3">KO Knock out</th>
            <th colspan="4">RSCH Hard blows to the head</th>
          </tr>
          <tr class="atcive">
            <th colspan="3">KD Knock down</th>
            <th colspan="4">W.O. Walk over</th>
          </tr>
          <tr class="atcive">
            <th colspan="3">DISQ Disqualification</th>
            <th colspan="4">AB Abandonment</th>
          </tr>
          </thead>
        </table>
        <div v-if="calculated" class="text-center" style="height:200px;">
          <button class="btn btn-success" style="height:70px; width:180px;" @click="confirmResults"
                  :disabled="win_type === ''">Confirm results
          </button>
        </div>
      </div>
      <h1 style="width: 100%; text-align: center; display: inline-block; margin: 10rem 0" v-else>
        Winner is {{testFight.winner_id === testFight.red_user_id ? testFight.red_name + ' ' + testFight.red_surname : testFight.blue_name + ' ' + testFight.blue_surname}}
      </h1>
    </div>
    <modal name="ready-check" :height="'auto'">
      <ready-check :judges="testFight.judges"></ready-check>
    </modal>
  </div>
</template>
<script>
  import ReadyCheck from '../../components/JudgingComponents/ReadyCheckComponents/ReadyCheck.vue';
  import FightInfo from '../../components/JudgingComponents/FightInfoComponents/FightInfo.vue'
  import Timer from '../../components/JudgingComponents/FightInfoComponents/Timer.vue'
  export default {
    name: 'SecretaryFightPage',
    data: function () {
      return {
        testFight: {},
        judgesStaff: [],
        remarks: [],
        finalScore:{red: 0, blue: 0},
        battleId: this.$route.params.fightId,
        calculated: false,
        win_type: '',
        winner: '',
        handicap: {
          color: null,
          points: null
        }
      }
    },
    components: {
      ReadyCheck,
      FightInfo,
      Timer
    },
    methods: {
      //todo: create single method to add kick or foul
      addPoint(color, userId, type) {
        let data = {
          main_judge_id: this.$route.params.id,
          battleId: this.$route.params.fightId,
          color_id: color,
          user_id: userId,
          time: this.$moment(new Date()).format('HH:mm:ss'),
          kick: 0,
          foul: 0,
          valid: 1,
          round: this.testFight.roundDetails.round_nr,
          room: this.$route.params.fightId
        };
        type === 'kick' ? data.kick = 1 : (type === 'foul' ? data.foul = 1 : data.valid = 0);
        this.$socket.emit('add-minus-point', data)
      },
      showOnMainScreen() {
        this.$socket.emit("show-on-main-screen", {
          battleId: this.battleId,
          ring: this.testFight.ring_id
        });
      },
      confirmJudges: function () {
        /*console.log(this.testFight)*/
        /* this.$axios.post(`/api/confirm-judges`, {
             battleId: this.battleId,
             judgesStaff: this.judgesStaff,
             categoryId: this.testFight.category_id
         })
           .then(response => {
             this.testFight = response.data;
           })
           .catch(e => {
           this.errors.push(e)
       })*/
        this.$socket.emit('confirm-judges',{
          cmId: this.testFight.cmId,
          room: this.$route.params.fightId,
          battleId: this.battleId,
          judgesStaff: this.judgesStaff,
          categoryId: this.testFight.category_id
        })
      },
      startRound: function () {
        let roundNr = 1;
        if( this.testFight.roundDetails ){
          roundNr = ( this.testFight.roundDetails.round_status === 2 && this.testFight.roundDetails.battle_status !== 2 ) ? this.testFight.roundDetails.round_nr + 1 : this.testFight.roundDetails.round_nr;
        }
        this.remarks = [];
        this.$socket.emit("round-manage", {
          battleId: this.battleId,
          roundNr: roundNr,
          room: this.battleId,
          req_type: 'startRound',
          JudgesIds: this.testFight.BattleJudgesIds
        });
      },
      stopRound: function () {
        this.$socket.emit("round-manage", {
          battleId: this.battleId,
          roundNr: this.testFight.roundDetails.round_nr,
          room: this.battleId,
          req_type: 'stopRound'
        });
      },
      continueRound: function () {
        this.remarks = [];
        this.$socket.emit("round-manage", {
          battleId: this.battleId,
          roundNr: this.testFight.roundDetails.round_nr,
          room: this.battleId,
          req_type: 'continueRound',
          JudgesIds: this.testFight.BattleJudgesIds
        });
      },
      refreshControlPagesResults() {
        this.$socket.emit("refresh-battles-list");
      },

      confirmResults: function () {
        if (this.win_type !== '') {
          let data = {};
          data.cmId=this.testFight.cmId;
          data.battleId = this.testFight.id;
          data.secretaryId =  this.$route.params.id;
          data.ring_id = this.testFight.ring_id;
          data.category_id = this.testFight.category_id;
          data.red_id = this.testFight.red_user_id;
          data.blue_id = this.testFight.blue_user_id;
          data.winType = this.win_type;
          data.winner = this.winner;
          data.round_nr = this.testFight.roundDetails.round_nr;
          data.scores = {red: {kicks: 0, fouls: 0}, blue: {kicks: 0, fouls: 0}};
          data.scores.red.score = this.finalScore.red;
          data.scores.blue.score = this.finalScore.blue;
          data.scores.red.winType = this.winner === 'red' ? this.win_type : null;
          data.scores.blue.winType = this.winner === 'blue' ? this.win_type : null;
          data.judges = [];
          data.remarks = {red:[],
            blue: []
          };

          this.testFight.scores.red.forEach(item => {
            data.scores.red.kicks += item.kicks;
            data.scores.red.fouls += item.fouls;
          });

          this.testFight.scores.blue.forEach(item => {
            data.scores.blue.kicks += item.kicks;
            data.scores.blue.kicks +=item.fouls;
          });

          console.log("remarks: ", this.remarks)
          this.remarks.forEach(remark => {
            data.remarks.red.push(remark === 'red' ? 1 : 0)
            data.remarks.blue.push(remark === 'blue' ? 1 : 0)
            console.log("calculate")
          });

          console.log("data remarks: ", data.remarks)
          if ((data.remarks.red=="") && (data.remarks.blue=="")){
            data.remarks = {
              red:[0,0,0,0,0,0,0,0],
              blue: [0,0,0,0,0,0,0,0]
            };
          }

          this.testFight.judges.forEach(judge => {
            let totalScore = {judgeId: judge.judge_id, red: 0, blue: 0};

            this.testFight.scores.red.forEach(item => {
              totalScore.red += item.judges[judge.judge_id];
            });

            this.testFight.scores.blue.forEach(item => {
              totalScore.blue += item.judges[judge.judge_id];
            });

            data.judges.push(totalScore);
          });
     /*     this.$axios.post(`/api/send-battle-results`, data)
                  .then(response => {
                    if (response.status==200){
                      alert("Results has saved!")
                      this.showOnMainScreen()
                      this.refreshControlPagesResults()
                    }
                  })
                  .catch(e => {
                    this.errors.push(e)
                  })*/

          this.$socket.emit('send-secretary-battle-results', data)

        }
      },
      calculate: function () {
        this.finalScore.red = this.testFight.scores.total.red;
        this.finalScore.blue = this.testFight.scores.total.blue;
        if(this.remarks.length==8) {
          this.remarks.forEach(remark => {
            remark === 'red' ? this.finalScore.red++ : this.finalScore.blue++;
          });
        }
        console.log(this.remarks)
        this.calculated = true;
      },
      comparePointHandicap(){
        if( this.testFight.scores.total.red - 14 >= this.testFight.scores.total.blue ){
          this.handicap.color = 'red';
          this.handicap.points = this.testFight.scores.total.red - this.testFight.scores.total.blue;
        }

        else if( this.testFight.scores.total.blue - 14 >= this.testFight.scores.total.red ){
          this.handicap.color = 'blue';
          this.handicap.points = this.testFight.scores.total.blue - this.testFight.scores.total.red;
        }
        else{
          this.handicap.color = null;
          this.handicap.points = null;
        }
      },
      checkJudgesStatus(){
        this.$modal.show('ready-check');
        this.$socket.emit('ready-check', this.$route.params.fightId );
      }
    },
    mounted: function () {
      /*this.$socket.emit( 'competition-join', this.testFight.cmId );*/
      this.$socket.emit( 'room-join', this.$route.params.fightId );

      this.$axios.get(`/api/secretary-fight-info`, {
        params: {battleId: this.battleId}
      })
              .then(response => {
                this.testFight = response.data;
                this.comparePointHandicap();
                console.log(this.testFight)
              })
              .catch(e => {
                this.errors.push(e)
              });

      this.$socket.on( 'round-status', data => {
        this.testFight.roundDetails = data.response;
        if(data.emit){
          this.$bus.$emit(data.emit, data.response);
        }
      });

      this.$socket.on('refresh-secretary-result', response => {
        this.testFight = response;
        this.comparePointHandicap();
      });
      this.$socket.on('judges-confirmed',response => {
        console.log(response)
        this.testFight = response;
        console.log(this.testFight)
      })
      this.$socket.on('refresh-secretary-scores', response => {
        this.testFight.scores = response;
        this.comparePointHandicap();
      });
      this.$socket.on('secretary-battle-result-saved', response => {
        if(response) {
          alert("Results has saved!")
          this.showOnMainScreen()
        }
      })
    },
    beforeDestroy () {
      this.$socket.removeListener('secretary-battle-result-saved');
    }
  /*  beforeDestroy () {
      this.$socket.removeListener('round-status');
      this.$socket.removeListener('refresh-secretary-result');
    }*/
  }
</script>
<style lang="scss" scoped>
  .fight-control {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top:10rem;
    margin-bottom: 2rem;
    &__table {
      display: flex;
      &-red {
        color: #EA0101;
        font-size: 22px;
      }
      &-blue {
        color: #020EEB;
        font-size: 22px;
      }
    }
    &__action-buttons{
      display: flex;
      /*   flex-direction: column;
         padding-top: 5rem;*/
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      &--item{
        padding: 1rem 1rem;
        margin-bottom: 2rem;
        height: 7rem;
        width: 18rem;
      }
    }
  }









  th {
    text-align: center;
  }
  div .altop {

  }
  .activeRound {
    background-color: yellow;
  }
  .redside {
    background-color: #FB8585;
    color: #EA0101;
    height: 150px;
  }

  h1 .cheader {
    font-size: 52px;
    padding-left: 30px;
  }

  div .rfighter-info {
    color: #EA0101;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 36px;
  }

  h2 .nsheader {
    font-size: 42px;
    padding-top: 20px;
  }

  .alleft {
    float: left;
  }

  div .table1 {
    color: #EA0101;
    font-size: 22px;
  }

  #rd_nr {
    color: white;
    font-size: 72px;
    position: absolute;
    padding-left: 39%;
    padding-top: 10%;
  }

  h3 .timer-label {
    padding-top: 20px;
  }

  div .btndiv {
    padding-top: 70px;
  }

  div .blueside {
    background-color: #979BF1;
    color: #020EEB;
    height: 150px;
  }

  div .bluefighter-info {
    color: #020EEB;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 36px;
  }

  div .alright {
    float: right;
  }

  div .table2 {
    color: #020EEB;
    font-size: 22px;
  }

  div .altop-center {
    padding-top: 15px;
    height: 800px;
  }
  .red-radio-label{
    color:#EA0101;
  }
  .blue-radio-label{
    color:#020EEB;
  }

  .table-battle-results{
    text-align:center;
    width: 1000px;
    height:120px;
    font-size:18px;
  }

  .table-decision{
    text-align:center;
    width: 1000px;
    height:200px;
    font-size:18px;
  }

  .table-remarks{
    text-align:center;
    width:1000px;
    font-size:18px;
  }

  .table-won-by{
    text-align:center;
    width: 1000px;
    height:150px;
    font-size:18px;
  }

  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .red-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .radio-container:hover input ~ .red-checkmark {
    background-color: #ccc;
  }

  .radio-container input:checked ~ .red-checkmark {
    background-color: red;
  }

  .red-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-container input:checked ~ .red-checkmark:after {
    display: block;
  }

  .radio-container .red-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .blue-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .radio-container:hover input ~ .blue-checkmark {
    background-color: #ccc;
  }

  .radio-container input:checked ~ .blue-checkmark {
    background-color: blue;
  }

  .blue-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-container input:checked ~ .blue-checkmark:after {
    display: block;
  }

  .radio-container .blue-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* REMARKS */
  .remark-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .remark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .red-remark-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  .remark-container:hover input ~ .red-remark-checkmark {
    background-color: #ccc;
  }


  .remark-container input:checked ~ .red-remark-checkmark {
    background-color: red;
  }

  .red-remark-checkmark:after {
    position: absolute;
    display: none;
  }

  .remark-container input:checked ~ .red-remark-checkmark:after {
    display: block;
  }

  .remark-container .red-remark-checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .blue-remark-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  .remark-container:hover input ~ .blue-remark-checkmark {
    background-color: #ccc;
  }

  .remark-container input:checked ~ .blue-remark-checkmark {
    background-color: blue;
  }

  .blue-remark-checkmark:after {
    position: absolute;
    display: none;
  }

  .remark-container input:checked ~ .blue-remark-checkmark:after {
    display: block;
  }

  .remark-container .blue-remark-checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
  }

  .table-select{
    width: 10rem;
    &__options{
      width: 12rem;
    }
  }

  @media (max-width: 1720px) {

    .t_totalmin {
      display: none;
    }

    .t_totalmax {
      display: table-row;
    }
  }


</style>
