var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.CompetitionTitle)),
          _c("br"),
          _c(
            "span",
            { staticClass: "page__section__name-container--subtitle" },
            [_vm._v(_vm._s(_vm.Subtitle))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__section__sort" }, [
        _c("span", { staticClass: "page__section__sort--item" }, [
          _vm._v(
            "\n        Dalibnieku skaits: " +
              _vm._s(_vm.ParticipantsCount) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__section__sort" }, [
        _c("span", { staticClass: "page__section__sort--item" }, [
          _vm._v(
            "\n        Komandu skaits: " + _vm._s(_vm.TeamsCount) + "\n      "
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__data" },
        [
          !_vm.$store.state.authUser
            ? _c("PublicResultsTableComponent", {
                attrs: {
                  ResultsData: _vm.Results,
                  DataForRouter: _vm.DataForRouter
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && _vm.$store.state.authUser.type != 1
            ? _c("PublicResultsTableComponent", {
                attrs: {
                  ResultsData: _vm.Results,
                  DataForRouter: _vm.DataForRouter
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser &&
          (_vm.$store.state.authUser.type === 1 ||
            _vm.$store.state.authUser.type === 2)
            ? _c("AdminResultsTableComponent", {
                attrs: {
                  ResultsData: _vm.Results,
                  DataForRouter: _vm.DataForRouter
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0df1c3cb", { render: render, staticRenderFns: staticRenderFns })
  }
}