<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Komandas veidošana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
       <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <h1 class="page__content__input--title">Komanda: {{TeamData.Title}}</h1>
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item" v-if="!($store.state.authUser.type==4)">
            <label class="page__content__input__form__item--label control-label ">Coach<sup>*</sup></label>
            <multiselect v-model="Coach" :options="CoachsData" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="Select coach" track-by="Id" :custom-label="CoachLabel">
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="TeamData.Title" >
  
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Saīsināts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Short">
  
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Adrese<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Address" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">E-pasts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Email" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Telefons<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Phone" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Saits<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="TeamData.Site" >
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="createTeam">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "createTeamPage",
    data(){
      return {
        show: false,
        Coach: {Id: 0, Name: "", Surname: ""},
        TeamData: {
            CoachId: "",
            Title: "",
            Short: "",
            Address: "",
            Email: "",
            Phone: "",
            Site: "",
        },
        CoachsData: [],
      }
    },
    methods: {
      createTeam(){
        console.log(this.TeamData)
        if (this.$store.state.authUser.type==4) {
          this.TeamData.CoachId = this.$store.state.authUser.id;
        }else {
          this.TeamData.CoachId = this.Coach.Id;
        }
          this.$socket.emit('create-team',  this.TeamData);
        /*this.$axios.post( '/api/teams/new-team/create', this.TeamData)
          .then( response => {
              this.$router.push('/admin/teams')
          })
          .catch( (error) => {
            this.errors.push(e)
          });*/
      },
      CoachLabel (option) {
        if ((option.Name=='') && (option.Surname=='')){
            return '-'
        }
          return `${option.Name} ${option.Surname}`
      },
      getData(){
        this.$axios.get(`/api/teams/new-team/data`,{
        })
        .then(response => {
            this.CoachsData = response.data.CoachsData;
            console.log(this.CoachsData)
        })
        .catch(e => {
            this.errors.push(e)
        })
      }
    },
    mounted() {
      this.getData()
        this.$socket.on('team-created', response => {
            if (response.status){
              if (this.$store.state.authUser.type==4){
                this.$router.push('/teams')
              }else {
                this.$router.push('/teams')
              }
            }else{alert("Check all fields on filled right")}
        });
    },
    beforeDestroy () {
      this.$socket.removeListener('team-created');
    },
      components:{
          MenuComponent,
        Multiselect
      }
  }
</script>

<style lang="scss" scoped>

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>