<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Labošana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
      <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <h1 class="page__content__input--title">Sacensibu labošana: </h1>
        <br>
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control"  v-model="CompetitionData.Title">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Apakšvirsraksts<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="CompetitionData.Subtitle">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Tiesnesis<sup>*</sup></label>
            <!--  <input class="page__content__input__form__item&#45;&#45;input form-control" list="coachs" v-model="TeamData.CoachId">-->
            <select class="page__content__input__form__item--input form-control" v-model="CompetitionData.JudgeId">
              <option v-for="Judge in MainJudges" :value="Judge.Id">{{ Judge.Name }} {{ Judge.Surname }}</option>
            </select>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Datums<sup>*</sup></label>
            <datetime input-class="page__content__input__form__item--input form-control" type="date" v-model="StartDate" format="yyyy-MM-dd"></datetime>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Pieteikšanās līdz<sup>*</sup></label>
            <datetime input-class="page__content__input__form__item--input form-control" type="datetime" v-model="AppUntil" format="yyyy-MM-dd HH:mm"></datetime>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Uzaicinājums<sup>*</sup></label>
            <div v-if="MceVisible.b"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.b = !MceVisible.b" >
              <div class="input-notice" v-html="CompetitionData.Invitation"></div>
            </div>
            <div v-if="!MceVisible.b">
              <tinymce v-model="CompetitionData.Invitation" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.b = !MceVisible.b" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Programa<sup>*</sup></label>
            <div  v-if="MceVisible.c"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.c = !MceVisible.c" >
              <div v-html="CompetitionData.Program"></div>
            </div>
            <div v-if="!MceVisible.c">
              <tinymce v-model="CompetitionData.Program" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.c = !MceVisible.c" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Noteikumi<sup>*</sup></label>
            <div  v-if="MceVisible.d"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.d = !MceVisible.d" >
              <div v-html="CompetitionData.Rules"></div>
            </div>
            <div v-if="!MceVisible.d">
              <tinymce v-model="CompetitionData.Rules" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.d = !MceVisible.d" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Ambulance<sup>*</sup></label>
            <div  v-if="MceVisible.e"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.e = !MceVisible.e" >
              <div v-html="CompetitionData.Ambulance"></div>
            </div>
            <div v-if="!MceVisible.e">
              <tinymce v-model="CompetitionData.Ambulance" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.e = !MceVisible.e" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Apbalvošana<sup>*</sup></label>
            <div  v-if="MceVisible.f"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.f = !MceVisible.f" >
              <div v-html="CompetitionData.Prizes"></div>
            </div>
            <div v-if="!MceVisible.f">
              <tinymce v-model="CompetitionData.Prizes" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.f = !MceVisible.f" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Ekipejums<sup>*</sup></label>
            <div  v-if="MceVisible.g"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.g = !MceVisible.g" >
              <div v-html="CompetitionData.Equipment"></div>
            </div>
            <div v-if="!MceVisible.g">
              <tinymce v-model="CompetitionData.Equipment" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.g = !MceVisible.g" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item" style="overflow: auto;">
            <label class="page__content__input__form__item--label control-label ">Sadarbība<sup>*</sup></label>
            <div  v-if="MceVisible.a"  style=" width: 100%; min-height: 10rem; border: 0.3rem solid greenyellow;" v-on:click="MceVisible.a = !MceVisible.a" >
              <div v-html="CompetitionData.Cooperation"></div>
            </div>
            <div v-if="!MceVisible.a">
              <tinymce v-model="CompetitionData.Cooperation" :plugins="McePlagins" :other="MceOtherOptions"></tinymce>
              <button v-on:click="MceVisible.a = !MceVisible.a" class="btn btn-info control-label" >Change view</button>
            </div>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Cena</label>
            <input class="page__content__input__form__item--input form-control" v-model="CompetitionData.Price" >
          </div>
        <!--  <div class="page__content__input__form__item">
            <label class="page__content__input__form__item&#45;&#45;label control-label ">Rings<sup>*</sup></label>
            <multiselect v-model="CompetitionData.RingsIds" :options="Rings" :multiple="true" track-by="Id" :custom-label="RingLabel"></multiselect>
          </div>-->
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Discipline ids<sup>*</sup></label>
              <multiselect v-model="CompetitionData.DisciplineIds" :options="Disciplines" :multiple="true" track-by="Id" :custom-label="DisciplineLabel"></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Ciņas generacijas algoritms</label>
            <multiselect v-model="CompetitionData.Algorithm" :options="Algorithms" track-by="Id" :custom-label="AlgorithmLabel"></multiselect>
          </div>
        <!--  <div class="page__content__input__form__item">
            <label class="page__content__input__form__item&#45;&#45;label control-label ">Grafika tips</label>
            <input class="page__content__input__form__item&#45;&#45;input form-control" value="Single Elimination">
          </div>-->
        <!--  <div class="page__content__input__form__item">
            <label class="page__content__input__form__item&#45;&#45;label control-label " type="checkbox">Cīņa par 3/4 vietu</label>
            <input class="page__content__input__form__item&#45;&#45;input form-control" style="width:5rem;" type="checkbox">
          </div>-->
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="editCompetitionData">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueEasyTinyMCE from 'vue-easy-tinymce';
import DatePicker from 'vuejs-datepicker';
import { Datetime } from 'vue-datetime';
import Multiselect from 'vue-multiselect'
import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "editCompetitionPage",
    data(){
      return {
        show: false,
        CompetitionData: {},
        Disciplines: {},
        MainJudges: {},
        SelectedValue: {Id: "", Title: ""},
        StartDate: "",
        AppUntil: "",
   /*     Rings: "",*/
        /*   RingsIds: "",*/
        Algorithms: [
          {Id: 1, Title: 'Algoritms'},
          {Id: 2, Title: 'Algoritms'},
        ],
        McePlagins: ['advlist autolink lists link image charmap print preview anchor textcolor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table contextmenu paste code directionality template colorpicker textpattern'],
        MceVisible: {
            a: true,
            b: true,
            c: true,
            d: true,
            e: true,
            f: true,
            g: true,
            h: true,
        },
        MceOtherOptions: {
            width: 800
        }
      }
    },
    methods: {
      editCompetitionData(){
        let BeginDate = new Date( this.StartDate);
        this.CompetitionData.BeginDate = BeginDate.getFullYear()+
          "-"+
          parseInt(BeginDate.getMonth()+1)+
          "-"+
          BeginDate.getDate();
        let AppUntil = new Date( this.AppUntil);
        this.CompetitionData.AppUntil = AppUntil.getFullYear()+
          "-"+
          parseInt(AppUntil.getMonth()+1)+
          "-"+
          AppUntil.getDate() + " "+
          AppUntil.getHours()+ ":" + AppUntil.getMinutes() + ":00";
        this.CompetitionData.Id=this.$route.params.cmId;
        console.log(this.CompetitionData)
          this.$socket.emit('edit-competition', this.CompetitionData);
      },
      DisciplineLabel (option) {
        return `${option.Id} - ${option.Title}`
      },
      AlgorithmLabel (option) {
        return `${option.Id}. - ${option.Title}`
      },
    },
    mounted() {
      this.$axios.get(`/api/competition/geteditdata`,{
        params: {
          cmId: this.$route.params.cmId
        }
      })
        .then(response => {
          this.CompetitionData = response.data.CompetitionData;
          this.Disciplines = response.data.Disciplines;
          this.StartDate = response.data.CompetitionData.BeginDate;
          this.AppUntil = response.data.CompetitionData.AppUntil;
          this.MainJudges = response.data.MainJudges;
          this.CompetitionData.Algorithm = {Id: response.data.CompetitionData.Algorithm.Id, Title: this.Algorithms[response.data.CompetitionData.Algorithm.Id - 1].Title}
     /*     this.Rings = response.data.Rings;*/
      /*    console.log(this.Disciplines)
          console.log(this.CompetitionData)
          console.log(this.MainJudges)*/
  /*        console.log(this.Rings)*/
        })
        .catch(e => {
          this.errors.push(e)
        })
        this.$socket.on('success', response => {
            if (response===true){
                this.$router.push('/competitions')
            }else{alert("Check all fields on filled right")}
        });
      this.$socket.on('refresh-discipline-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-discipline-data');
    },
    components: {
        MenuComponent,
        'tinymce' : VueEasyTinyMCE,
      Multiselect, datetime :Datetime
    }
  }
</script>

<style lang="scss">
/*
  .page__content{
  &__input__form{
     display: flex;
     flex-direction: column;
     width: 100%;
     transition: 0.5s;
     transform: translateX(-5%);
     background-color: #eeecf6;
     z-index: 0;
     padding: 7rem 20rem;
  &__item{
     display: flex;
     flex-direction: row;
     justify-content: left;
     padding-bottom: 2rem;
  &--label{
     text-align: right;
   }
  &__input{
   }
  &--btn{
     text-align: left;
     padding-right: 3rem;
     width: 15rem;
   }
  }
  }
  }
  #coachs{}
  .slide{
    transform: translateX(0%);
    transition: 0.5s;
    z-index: 0;
  }
*/

  #app {
    margin-top: 20px;
  }

  .input-notice img{
    width: 100%;
    height: 100%;
  }

  pre {
    margin-top: 20px;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>