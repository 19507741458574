<template>
  <div class="page__content__data" >
    <div class="page__content__data-container">
      <div class="page__content__data-container__sub-content">
        <div class="list-grid">
          <div class="list-grid--header">
            Saglabatie pulti
          </div>
          <div class="list-grid__item">
            <div class="list-grid__item__table">
              <div class="list-grid__item__table__cell" v-if="RemoteControls.length!==0" v-for="RemoteControl in RemoteControls" > <!--v-if="index<5">-->
                <div class="list-grid__item__table__cell--text" style="font-size: 1.5rem !important;">
                  {{RemoteControl.RemoteAddress}}
                </div>
                <div class="list-grid__item__table__cell__btn-container">
                  <div v-if="!RemoteControl.Active" class="list-grid__item__table__cell__btn-container--accept-btn" v-on:click="activateRemoteControl(RemoteControl.Id)">
                    Aktivet
                  </div>
                  <div v-if="RemoteControl.Active" class="list-grid__item__table__cell__btn-container--reject-btn" v-on:click="deactivateRemoteControl(RemoteControl.Id)">
                    Deaktivet
                  </div>
                </div>
              </div>
              <div class="list-grid__item__table__empty-table-msg" v-if="RemoteControls.length===0">
                <div class="">
                  Pultu nav
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-grid">
          <div class="list-grid--header">
            Ienakošie dati
          </div>
          <div class="list-grid__item">
            <div class="list-grid__item__table">
              <div class="list-grid__item__table__cell" v-if="RemotesData.length!==0" v-for="Package in RemotesData" >
                <div class="list-grid__item__table__cell--text" style="font-size: 1.5rem !important; padding: 0.5rem 3rem 0;">
                  Remote: {{Package.RemoteAddress}}
                  <br/> Color: {{Package.Color}}
                  <br/> TimeStamp: {{Package.TimeStamp}}
                </div>
                <div class="list-grid__item__table__cell__btn-container">
                  <div v-if="Package.Valid"  class="list-grid__item__table__cell__btn-container--accept-btn">
                    Valid
                  </div>
                  <div v-if="!Package.Valid" class="list-grid__item__table__cell__btn-container--reject-btn">
                    Invalid
                  </div>
                </div>
              </div>
              <div class="list-grid__item__table__empty-table-msg" v-if="RemotesData.length===0">
                <div class="">
                  Datu nav
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 /* import draggable from 'vuedraggable'
  import Multiselect from 'vue-multiselect';
  import DatePickerComponent from 'vuejs-datepicker';
*/
  export default {
    name: 'RemoteControlsComponent',
    props: ['RemoteControls','RemotesData'],
    data(){
      return {
        RemoteControls: this.RemoteControls,
        RemotesData: this.RemotesData
      }
    },
    methods: {
      activateRemoteControl(Id){
        this.$socket.emit('activate-remote',{RemoteId: Id})
      },
      deactivateRemoteControl(Id){
        this.$socket.emit('deactivate-remote',{RemoteId: Id})
      }
    },
    mounted() {

    },
    components: {
/*      DatePickerComponent, "multiselect": Multiselect,draggable*/
    },
  };
</script>

<style lang="scss" scoped>

 /* .list-grid__item__table__cell--text {
    font-size: 2rem;
  }
*/
  .list-grid__item__table__cell__btn-container--accept-btn {
    width: 10rem;
  }

  .list-grid__item__table__cell__btn-container--reject-btn {
    width: 10rem;
  }

 @media (max-width: 400px) {
   .list-grid__item__table__cell__btn-container--accept-btn .list-grid__item__table__cell__btn-container--reject-btn{
     width: auto;
   }
 }
 @media (max-width: 479px) {
   .list-grid__item__table__cell--text{
     padding-left: 0.5rem !important;
   }
 }

</style>

<!--
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
