var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page adding-remote-container" }, [
    _c("div", { staticClass: "page__content__input" }, [
      _c(
        "h1",
        {
          staticClass: "page__content__input--title",
          staticStyle: { padding: "0" }
        },
        [_vm._v("Pultu pieslegšana")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "list-grid adding-remote-list" }, [
        _c("div", { staticClass: "list-grid__item" }, [
          _c(
            "div",
            { staticClass: "list-grid__item__table" },
            [
              _vm._l(_vm.FoundRemotes, function(FoundRemote) {
                return _c(
                  "div",
                  { staticClass: "list-grid__item__table__cell" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "list-grid__item__table__cell--text",
                        staticStyle: { "font-size": "3rem !important" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(FoundRemote.Nr) +
                            " - " +
                            _vm._s(FoundRemote.RemoteAddress) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "list-grid__item__table__cell__btn-container"
                      },
                      [
                        _vm.checkSaved(FoundRemote.RemoteAddress)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "list-grid__item__table__cell__btn-container--accept-btn",
                                on: {
                                  click: function($event) {
                                    _vm.addRemoteController(
                                      FoundRemote.RemoteAddress
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Saglabat\n              "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm.FoundRemotes.length === 0
                ? _c(
                    "div",
                    { staticClass: "list-grid__item__table__empty-table-msg" },
                    [
                      _c("div", {}, [
                        _vm._v("\n              Pultu nav\n            ")
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input__form__btn-item" }, [
        _c(
          "button",
          {
            staticClass:
              "page__content__input__form__btn-item--btn btn btn-secondary",
            on: {
              click: function($event) {
                _vm.$modal.hide("addRemoteModal")
              }
            }
          },
          [_vm._v("❌ Aizvert\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d3e05fd", { render: render, staticRenderFns: staticRenderFns })
  }
}