<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Lozešana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide: show}">
       <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <H1 class="page__content__data--header" style="color:black;">{{CompetitionTitle}}</H1>
        <ul class="nav nav-tabs" role="tablist">
          <li class="active" v-for="(WeightCategory,key,index) in WeightCategoryFighters" v-if="index==0" v-on:click="getparticipantcount(key)">
            <a  data-toggle="tab" :href="'#'+index" role="tab">
              {{Category=key}} kg ({{WeightCategory.Fighters.length}}) <img v-if="WeightCategory.TossUpStatus" src="/static/green-tick.png" alt="" width="40" height="30">
              <img v-if="!WeightCategory.TossUpStatus" src="/static/cross.png" alt="" width="40" height="30">
            </a>
          </li>
          <li v-for="(WeightCategory,key,index) in WeightCategoryFighters" v-if="index!=0" v-on:click="getparticipantcount(key)">
            <a  data-toggle="tab" :href="'#'+index" role="tab">
              {{key}} kg ({{WeightCategory.Fighters.length}}) <img v-if="WeightCategory.TossUpStatus" src="/static/green-tick.png" alt="" width="40" height="30">
              <img v-if="!WeightCategory.TossUpStatus" src="/static/cross.png" alt="" width="40" height="30">
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" v-for="(WeightCategory,key,index) in WeightCategoryFighters" v-if="index==0" :id="index" role="tabpanel">
            <participants-table :ParticipantsLozesanaiData="WeightCategory.Fighters" :AlgorithmId="AlgorithmId"></participants-table>
          </div>
          <div class="tab-pane" v-for="(WeightCategory,key,index) in WeightCategoryFighters" v-if="index!=0" :id="index" role="tabpanel">
            <participants-table :ParticipantsLozesanaiData="WeightCategory.Fighters" :AlgorithmId="AlgorithmId"></participants-table>
          </div>
        </div>
        <div style="text-align:right;">
          <a class="btn btn-primary" style="width: 25rem;" v-on:click="generateBattles()">Ģenerēt cīnas</a> <!--v-if="AlgorithmId<3"-->
        </div>
      </div>
    </div>
    <modal name="ParticipantCategoryModal" :width="900" :height="400">
      <ParticipantCategoryModalComponent :ParticipantData="ParticipantData"></ParticipantCategoryModalComponent>
    </modal>
  </div>
</template>

<script>
  import ParticipantCategoryModalComponent from '../../components/admin/ParticipantTableComponents/ParticipantCategoryModalComponent.vue';
  import LozesanaTableComponent from '../../components/admin/BattlesInCategoriesComponents/LozesanaTableComponent';
  import MenuComponent from "../../components/common/MenuComponent";

  export default {
    name: "lozesanasPage",
    data() {
      return {
        show: false,
        WeightCategoryFighters: {},
        CompetitionTitle: null,
        ParticipantCount: null,
        Category: null,
        Battles: [],
        ParticipantData:{},
        AlgorithmId: 0
      }
    },
    methods:{
      getparticipantcount(count) {
        this.Category=count;
        console.log(count)
      },
      generateBattles(){
        let generate=confirm("Are you sure? This move can delete all battles! ")

        if (generate==true){
          this.$axios.get(`/api/generatebattles`, {
            params: {
              cmId: this.$route.params.cmId,
              type: 'competition'
            }
          })
            .then(response => {
              console.log(response)
             /* this.Battles=response.data.Battles
              console.log(response);*/
              if (typeof response.data!=='object'){
                alert(response.data)
              }
            })
            .catch(e => {
              this.errors.push(e)
            })
        }else{
          console.log("stop")
        }
      },
      getData(){
          this.$axios.get(`/api/competition/lozesana`, {
              params: {
                  cmId: this.$route.params.cmId,
              }
          })
              .then(response => {
                  this.WeightCategoryFighters = response.data.WeightCategoryFighters;
                  this.CompetitionTitle = response.data.CompetitionTitle;
                  this.AlgorithmId = response.data.AlgorithmId;
                  /*this.ParticipantCount = response.data.WeightCategoryFighters[0][0].WeightCategoryId;*/
                  /*console.log(this.WeightCategoryFighters);*/
                console.log(response.data)
              })
              .catch(e => {
                  this.errors.push(e)
              })
      }

    },
    mounted() {
      this.getData();
      this.$socket.on('refresh-tossup-data', response => {
          this.getData();
      });
      this.$socket.on('refresh-users-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('generation-response', response =>{
        if (response!=true){
          alert(response)
        }
      });

      this.$bus.$on("CmUserData", (data)=>{
        this.ParticipantData=data;
        console.log("geted data: ",data)
      });
    },
      beforeDestroy () {
        this.$socket.removeListener('refresh-tossup-data');
        this.$socket.removeListener('refresh-cmusers-data');
        this.$socket.removeListener('refresh-users-data');
        this.$socket.removeListener('refresh-teams-data');
        this.$socket.removeListener('generation-response');
      },
    components:{
      MenuComponent, "participants-table": LozesanaTableComponent,
      ParticipantCategoryModalComponent
    },

  }
</script>

<style lang="scss" scoped>

</style>