var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.RemoteControls,
          "search-options": {
            enabled: true,
            trigger: "enter"
          },
          "pagination-options": {
            enabled: true,
            perPage: 30
          },
          styleClass: "vgt-table striped bordered"
        },
        on: { "on-cell-click": _vm.onCellClick },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.field == "Actions"
                  ? _c("div", [
                      !props.row.Active
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  _vm.rewriteRemoteKey(
                                    props.row.RemoteAddress,
                                    props.row.CmRemoteId
                                  )
                                }
                              }
                            },
                            [_vm._v("Aktivet")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.Active
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function($event) {
                                  _vm.deactivateRemoteKey(
                                    props.row.RemoteAddress,
                                    props.row.CmRemoteId,
                                    props.row.RemoteId
                                  )
                                }
                              }
                            },
                            [_vm._v("Deaktivet")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          on: {
                            click: function($event) {
                              _vm.deleteRemoteControl(
                                props.row.RemoteAddress,
                                props.row.CmRemoteId
                              )
                            }
                          }
                        },
                        [_vm._v("Dzest")]
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n      "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-22bf4ca7", { render: render, staticRenderFns: staticRenderFns })
  }
}