<template>
  <div>
    <vue-good-table
        :columns="columns"
        :rows="ParticipantsData"
        @on-cell-click="onCellClick"
        :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
        styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <!--<div v-if="props.column.field=='Payment'">
            <div v-if="props.row.Payment=='0'">
                Nav
            </div>
            <div v-else>
                Ir
            </div>
        </div>-->
        <div v-if="props.column.field=='WeighingNr'">
          <div v-if="props.row.WeighingNr=='0'">
            -
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
        </div>
        <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "ClaimedParticipantsTableComponent",
    props: ["ParticipantsData"],
    data() {
      return {
        columns: [
          {
            label: 'Dalibnieks',
            field: 'Participant',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Svars',
            field: 'Weight',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Komanda',
            field: 'Team',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Kategorija',
            field: 'Category',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Cīnītaju skaits kategorijā',
            field: 'UsersCountInCategory',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Cīņu skaits kategorijā',
            field: 'BattlesCountInCategories',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          /*  {
              label: 'Samaksats',
              field: 'Payment',
              thClass: 'text-center',
              tdClass: 'text-center',
              filterOptions: {
                enabled: true,
              }
            },*/
          /* {
             label: 'Sveršanas rindas numurs',
             field: 'WeighingNr',
             type: "Number",
             thClass: 'text-center',
             tdClass: 'text-center',
             filterOptions: {
               enabled: true,
             }
           },*/
          /*  {
              label: 'Cinas',
              field: 'Fights',
              type: "Number",
              thClass: 'text-center',
              tdClass: 'text-center',
              filterOptions: {
                enabled: true,
              }
            },
            {
              label: 'Uzvaras',
              field: 'Victory',
              type: "Number",
              thClass: 'text-center',
              tdClass: 'text-center',
              filterOptions: {
                enabled: true,
              }
            },
            {
              label: 'Nokdauni',
              field: 'Knockouts',
              type: "Number",
              thClass: 'text-center',
              tdClass: 'text-center',
              filterOptions: {
                enabled: true,
              }
            },*/
          /* {
             label: 'Approved',
             field: 'Approved',
             thClass: 'text-center',
             tdClass: 'text-center',
             filterOptions: {
               enabled: true,
             }
           },*/
        ],
      }
    },
    methods: {},
    mounted() {
    },
    components: {},
  };
</script>

<style lang="scss">
  .text-center {
    text-align: center;
  }
</style>