<template>
  <div>
    <div class="page__team-apply-form">
      <div class="page__team-apply-form__list-block">
        <h4 class="page__content__input__form__item--label control-label" >Komandas dalibnieki</h4>
        <button class="page__team-apply-form__list-block__list--item btn btn-primary" style="margin-top: 2rem;" v-on:click="getApplyData(0)">Pievienot</button>
        <select size="7" class="page__team-apply-form__list-block__list" v-if="SelectType" v-model="ApplyId" v-on:change="getApplyData(ApplyId)">
          <option class="page__team-apply-form__list-block__list--item" v-for="(Participant) in TeamParticipants" :value="Participant.UserId">{{Participant.Name}} {{Participant.Surname}}</option>
        </select>
        <select size="7" class="page__team-apply-form__list-block__list" v-if="!SelectType" v-model="Participant" @click="deleteUserClaim(Participant)">
          <option class="page__team-apply-form__list-block__list--delete-item" v-for="(Participant) in TeamParticipants" :value="Participant.UserId">{{Participant.Name}} {{Participant.Surname}}</option>
        </select>
        <button class="page__team-apply-form__list-block__list--delete-item btn btn-danger" v-on:click="SelectType=false">Dzēst</button>
      </div>

      <div class="page__team-apply-form__input">
        <ul class="nav nav-tabs" role="tablist">
          <li class="active">
            <a id="personal-data" data-toggle="tab" :href="'#personal-data-'+ApplyNr" role="tab">
              Personiskie dati</a>
          </li>
          <li >
            <a id="specifications" data-toggle="tab" :href="'#specifications-'+ApplyNr" role="tab">
              Cīnītāja īpašības</a>
          </li>
          <li >
            <a  id="category" data-toggle="tab" :href="'#category-'+ApplyNr" role="tab">
              Kategorija</a>
          </li>
        </ul>
        <div class="tab-content page__content__input__form">
          <div class="tab-pane active tab-content page__content__input__form__item " :id="'personal-data-'+ApplyNr" role="tabpanel">
            <h4 class="page__content__input__form__item--label control-label " >Atrast dalibnieku: </h4>
            <multiselect v-model="ApplyData.User" :options="Users" :close-on-select="true"
                         :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="NameSurnameLabel" @input="setCategories(index)"></multiselect>
            <h4 class="page__content__input__form__item--label control-label " >Vards*: </h4>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="ApplyData.User.Name" @change="setCategories()"/>
            <h4 class="page__content__input__form__item--label control-label " >Uzvards*: </h4>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="ApplyData.User.Surname" @change="setCategories()"/>
          </div>
          <div class="tab-pane tab-content page__content__input__form__item" :id="'specifications-'+ApplyNr" role="tabpanel">
            <h4 class="page__content__input__form__item--label control-label " >Dzimsanas Datums*: </h4>
            <DatePicker calendar-class="calendar" :monday-first="true" v-model="ApplyData.User.DateOfBirth" :bootstrap-styling="true" @closed="setCategories()" style="padding-bottom: 10rem;"></DatePicker>
            <h4 class="page__content__input__form__item--label control-label " >Gender*:</h4>
            <multiselect v-model="ApplyData.User.Gender" :options="Genders" :close-on-select="true"
                         :clear-on-select="false"
                         :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" @input="setCategories()">
            </multiselect>
            <h4 class="page__content__input__form__item--label control-label " >Svars*:</h4>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Weight" @change="setCategories()">
          </div>
          <div class="tab-pane tab-content tab-content page__content__input__form__item" :id="'category-'+ApplyNr" role="tabpanel">
            <h4 class="page__content__input__form__item--label control-label " >Kategorija*:</h4>
            <multiselect v-model="ApplyData.Categories" :options="Categories" :close-on-select="true"
                         :clear-on-select="false" :multiple="true" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="CategoryLabel" >
            </multiselect>
            <div class="page__team-apply-form__btn-item">
              <button class="page__team-apply-form__btn-item--btn btn btn-primary" v-on:click="applyUser(ApplyData)">Saglabāt</button>
              <img v-if="ApplySaved" src="/static/green-tick.png" alt="" width="40" height="30">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePicker from 'vuejs-datepicker';

  export default {
    name: "ApplyTeamComponent",
    props: ["TeamData", "UnkUsersTeamData"],
    data() {
      return {
        ApplyId: 0,
        ApplyNr: 1,
        ApplySaved: false,
        SelectType: true,
        Users: [],
        ApplyData: {
          User: {Gender:{}},
          Categories: [],
          DateOfBirth: new Date(),
          Weight: 0,
          Fights: 0,
          Victory: 0,
          Knockouts: 0,
          Team: this.TeamData,
          UnknownUsersTeam:  this.UnkUsersTeamData,
          Payed: false,
          PaymentComments: '',
          CmId: this.$route.params.cmId ,
        },
        Genders: [
          {Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        Categories: [],
        AllCategories: [],
        TeamParticipants: []
      }
    },
    methods: {
      setCategories(){
        this.ApplySaved=false;
        if (!this.ApplyData.User){
          this.ApplyData.User={Gender:{}}
        }
        let now = new Date;
        let DateOfBirth = new Date(this.ApplyData.User.DateOfBirth);
        let Age=((now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        console.log("DateOfBirth: ",this.ApplyData.User.DateOfBirth)
        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          let CheckWeight=true;
          if(item.CategorySide==1){
            CheckWeight = (item.WeightMax<=this.ApplyData.Weight);
          }else{
            CheckWeight = (item.WeightMax>=this.ApplyData.Weight);
          }
          if (CheckWeight && (item.AgeMin<=Age) && (item.AgeMax>=Age) && (item.Gender==this.ApplyData.User.Gender.Id)) {
            this.Categories.push(item);
          }
        })
      },
      getApplyData(option) {
        console.log('option: ',option)
        this.ApplySaved=false;
        this.SelectType=true;
        if (option == 0) {
          this.ApplyId = 0;
          this.ApplyData = {
            User: {Gender: {}},
            Category: {},
            DateOfBirth: new Date(),
            Weight: 0,
            Fights: 0,
            Victory: 0,
            Knockouts: 0,
            Team: this.TeamData,
            UnknownUsersTeam: this.UnkUsersTeamData,
            Payed: false,
            PaymentComments: "",
            CmId: this.$route.params.cmId,
          }
        } else {
          if(this.$store.state.authUser) {
            if ((this.$store.state.authUser.type === 1) || (this.$store.state.authUser.type === 4) || (this.$store.state.authUser.type === 5)) {
              console.log('get data for internal-claim')
              this.$axios.get(`/api/participant/edit-data/internal-claim`, {
                params: {
                  cmId: this.$route.params.cmId,
                  userId: option,
                  teamId: this.ApplyData.Team.Id
                }
              })
                      .then(response => {
                        this.ApplyData = response.data.ApplyData;
                        this.ApplyData.Team = this.TeamData;
                      })
                      .catch(e => {
                        this.errors.push(e)
                      })
            } else {
              console.log('get data for external-claim')
              this.$axios.get(`/api/participant/edit-data/external-claim`, {
                params: {
                  cmId: this.$route.params.cmId,
                  UnkUserId: option
                }
              })
                      .then(response => {
                        this.ApplyData = response.data.ApplyData;
                        this.ApplyData.UnknownUsersTeam = this.UnkUsersTeamData;
                      })
                      .catch(e => {
                        this.errors.push(e)
                      })
            }
          } else {
            console.log('get data for external-claim')
            this.$axios.get(`/api/participant/edit-data/external-claim`, {
              params: {
                cmId: this.$route.params.cmId,
                UnkUserId: option
              }
            })
                    .then(response => {
                      this.ApplyData = response.data.ApplyData;
                      this.ApplyData.UnknownUsersTeam = this.UnkUsersTeamData;
                    })
                    .catch(e => {
                      this.errors.push(e)
                    })
          }
        }
      },
      applyUser(Data) {
        this.ApplySaved = false;
        let DataApply = Object.assign({}, Data);
        let Now = new Date
        let DateOfBirth = new Date(Data.User.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        if ((typeof Data.User.Name != 'undefined') && (typeof Data.User.Surname != 'undefined')) {
          if (Data.User.Name.length < 3) {
            alert("Vārds ievadīts ne pareizi!")
            return;
          } else if (Data.User.Surname.length < 2) {
            alert("Uzvārds ievadīts ne pareizi!")
            return;
          }
        }
        if ((typeof Data.User.Name == 'undefined') || (typeof Data.User.Surname == 'undefined')) {
          alert("Dalibnieka dati neievadīti!")
          return;
        }
        if ((Age <= 5) || (!DateOfBirth)) {
          alert("Dzimšanas datums neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (!Data.User.Gender.Id) {
          alert("Dzimums nav ievadīts!")
          return;
        }
        if ((Data.Weight.length < 2) || (!Data.Weight)) {
          alert("Svars neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (this.ApplyData.Categories.length == 0) {
          alert("Kategorija neievadīta!")
          return;
        }

        console.log('this.ApplyData: ', this.ApplyData)
        console.log('DataApply: ', DataApply)
        console.log('Data: ', Data)

        DataApply.Categories = []
        console.log('after')
        console.log('this.ApplyData: ', this.ApplyData)
        console.log('DataApply: ', DataApply)
        console.log('Data: ', Data)

        Data.Categories.forEach((ApplyCategory) => {
          console.log(`Category: `, ApplyCategory)
          DataApply.Categories.push(ApplyCategory.Id)
        })

        console.log(`Categories: `, DataApply.Categories)

        DataApply.User.DateOfBirth = DateOfBirth.getFullYear() +
                "-" +
                parseInt(DateOfBirth.getMonth() + 1) +
                "-" +
                DateOfBirth.getDate();
        console.log(DataApply.User.DateOfBirth)
        if (!DataApply.Team) {
          console.log('apply from unknown')
          if (this.ApplyId === 0) {
            console.log('apply unknown cmuser')
            this.$socket.emit('apply-cmuser-from-unknown', DataApply);
          } else {
            console.log('edit unknown cmuser')
            DataApply.UnkUserId = this.ApplyId;
            this.$socket.emit('edit-cmuser-from-unknown', DataApply);
          }
        } else {
          console.log('apply from known')
          if (this.ApplyId === 0) {
            console.log('apply')
            this.$socket.emit('apply-cmuser-from-known', DataApply);
          } else {
            console.log('edit')
            DataApply.CmUserId = this.ApplyId;
            console.log('sended: ',DataApply)
            this.$socket.emit('edit-cmuser-from-known', DataApply);
          }
        }
        this.ApplyNr++;
      },

      getDataForApply() {
        this.$axios.get(`/api/participant/data-for-cmusers-apply`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
                .then(response => {
                  this.Users = response.data.Users;
                  this.Categories = response.data.Categories;
                  this.AllCategories = response.data.Categories;
                  console.log('response.data for apply: ', response.data)
                })
                .catch(e => {
                  this.errors.push(e)
                })
      },
      getTeamApplications() {
        if(this.$store.state.authUser){
          if ((this.$store.state.authUser.type === 1) || (this.$store.state.authUser.type === 4) || (this.$store.state.authUser.type === 5)){
            this.$axios.get(`/api/participant/team-applications`, {
              params: {
                cmId: this.$route.params.cmId,
                teamId: this.TeamData.Id
              }
            })
                    .then(response => {
                      this.TeamParticipants = response.data.AppliedUsers;
                      console.log('response.data applications: ', response.data)
                    })
                    .catch(e => {
                      this.errors.push(e)
                    })
          }
        } else {
          this.$axios.get(`/api/participant/unknown-team-applications`, {
            params: {
              cmId: this.$route.params.cmId,
              teamId: this.UnkUsersTeamData.Id
            }
          })
                  .then(response => {
                    this.TeamParticipants = response.data.AppliedUsers;
                    console.log('response.data applications: ', response.data)
                  })
                  .catch(e => {
                    this.errors.push(e)
                  })
        }
      },
      deleteUserClaim(Apply) {
        console.log('this.UnkUsersTeamData: ',this.UnkUsersTeamData)
        console.log('this.TeamData: ',this.TeamData)
        if (this.UnkUsersTeamData) {
          console.log('delete from unknown users')
          this.$socket.emit('delete-cmuser-from-unknown', {
            cmId: this.$route.params.cmId,
            userId: Apply,
            teamId: this.UnkUsersTeamData.Id
          });
        } else {
          console.log('delete from real users')
          this.$socket.emit('delete-cmuser-from-known', {
            cmId: this.$route.params.cmId,
            userId: Apply,
            teamId: this.TeamData.Id
          });
        }
        this.getTeamApplications()
      },
      /* labels */
      NameSurnameLabel (option) {
        let now = new Date;
        let UserBirthDate=option.DateOfBirth;
        UserBirthDate = new Date(UserBirthDate);
        let Age=((now.getTime() - UserBirthDate) / (24 * 3600 * 365.25 * 1000) | 0);
        if ((typeof option.Name == 'undefined') && (typeof option.Surname == 'undefined')) {return '-'}
        return `${option.Name} - ${option.Surname} (Age: ${Age})`
      },
      CategoryLabel (option) {
        if ((typeof option.AgeCategory=='undefined')&&(typeof option.WeightMax=='undefined')){return '-'}
        let Side = '';

        if(option.CategorySide==1){
          Side = ' + ';
        }else{
          Side = ' - ';
        }
        return `${option.DisciplineTitle} ${option.AgeCategory} ${Side}${option.WeightMax} (Age: ${option.AgeMin} - ${option.AgeMax})`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
    },
    mounted() {
      console.log('this.TeamData: ',this.TeamData)
      console.log('this.UnkUsersTeamData: ',this.UnkUsersTeamData)
      if(this.TeamData){
        this.ApplyData.Team = this.TeamData;
        this.getDataForApply()
        this.getTeamApplications()
        console.log("real team")
      } else if(this.UnkUsersTeamData) {
        this.getDataForApply()
        this.ApplyData.UnknownUsersTeam = this.UnkUsersTeamData
        console.log("external team")
      }
      this.$socket.on('refresh-categories-data', response => {
        this.getDataForApply()
      })
      this.$socket.on('refresh-cmusers-data', response => {
        this.getTeamApplications()
      });
      this.$socket.on('applied-unknown-users-claims', response => {
        console.log('response: ',response)
        if (response.message) {
          alert(response.message)
        }
        if(response.UnkUserId) {
          console.log('this.ApplyId = ',response.UnkUserId)
          this.ApplyId = response.UnkUserId
        }
        this.getApplyData(this.ApplyId)
        this.getTeamApplications()
        if (response.status){
          this.ApplySaved=true;
          console.log('saved: ',this.ApplySaved)
        }
      });
      this.$socket.on('edited-unknown-users-claims', response => {
        console.log('response: ',response)
        if (response.message) {
          alert(response.message)
        }
        this.getApplyData(this.ApplyId)
        this.getTeamApplications()
        if (response.status){
          this.ApplySaved=true;
          console.log('saved: ',this.ApplySaved)
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getDataForApply()
      });

      this.$socket.on('applied-known-users-claims', response => {
        console.log('applied-known-users-claims: ',response)
        if (response.message) {
          alert(response.message)
        }
        if(response.UserId) {
          console.log('this.ApplyId = ',response.UserId)
          this.ApplyId = response.UserId
        }
        this.getApplyData(this.ApplyId)
        this.getTeamApplications()
        if (response.status){
          this.ApplySaved=true;
          console.log('saved: ',this.ApplySaved)
        }
      });

      this.$socket.on('edited-known-users-claims', response => {
        console.log('edited-known-users-claims: ',response)
        if (response.message) {
          alert(response.message)
        }
        this.getApplyData(this.ApplyId)
        this.getTeamApplications()
        if (response.status){
          this.ApplySaved=true;
          console.log('saved: ',this.ApplySaved)
        }
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-categories-data');
      this.$socket.removeListener('refresh-cmusers-data');
    },
    components: {
      DatePicker, "multiselect": Multiselect,
    },
  };
</script>

<style lang="scss">
  .page {
    &__team-apply-form {
      display: flex;
      flex-direction: row;
      /*      justify-content: stretch;*/
      background-color: #eeecf6;
      z-index: 0;
      padding: 3rem;
      width:100%;
      &__list-block {
        display: flex;
        flex-direction: column;
        width: 55rem;
        padding-left: 4rem;
        &__list {
          &--item {
            padding: 1rem;
            cursor: pointer;
            &:checked,
            &:focus,
            &:active,
            &:hover {
              color: #fff;
              font-weight: bold;
              background: #428bca;
              box-shadow: 0 0 10px 100px #428bca inset;
            }
          }
          &--delete-item {
            padding: 1rem;
            cursor: pointer;
            &:checked,
            &:focus,
            &:active,
            &:hover {
              color: #fff;
              font-weight: bold;
              background: #ca4f56;
              box-shadow: 0 0 10px 100px #ca4f56 inset;
            }
          }
        }
      }
      &__input {
        padding: 0 0 5rem 3rem;
        width: 100%;
        font-size: 1.8rem;
        &__data-tabs {
          &__item {
          }
        }
      }

      &__btn-item {
        margin: 1rem;

        &--btn {
          padding-right: 3rem;
          width: 15rem;
        }
      }
    }
  }
  .info-text{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6rem 6rem 12rem;
  }

  .tab-disabled{
    pointer-events: none;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>