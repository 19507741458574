var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page__section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__sort" }, [
          _c("span", { staticClass: "page__section__sort--item" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/competition/category/discipline/" +
                    _vm.$route.params.cmId +
                    "/" +
                    _vm.$route.params.discipId
                }
              },
              [_vm._v(_vm._s(_vm.Disciplines[_vm.$route.params.discipId]))]
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "page__section__sort--item" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/competition/category/age/" +
                    _vm.$route.params.cmId +
                    "/" +
                    _vm.$route.params.discipId +
                    "/" +
                    _vm.$route.params.ageCategId
                }
              },
              [_vm._v(_vm._s(_vm.AgeCategories[_vm.$route.params.ageCategId]))]
            )
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedWeightCategory,
                  expression: "SelectedWeightCategory"
                }
              ],
              staticClass: "page__section__sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedWeightCategory = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.WeightCategories, function(WeightCategory, key, index) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: WeightCategory }
                },
                [_vm._v(_vm._s(key))]
              )
            })
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__section__team-sort" }, [
          _c(
            "a",
            {
              staticStyle: {
                "padding-left": "1rem",
                "padding-right": "1rem",
                cursor: "pointer"
              },
              on: { click: _vm.clearTeamSelect }
            },
            [_vm._v("Komanda:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.SelectedTeam,
                  expression: "SelectedTeam"
                }
              ],
              staticClass: "page__section__team-sort--select",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.SelectedTeam = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getSelected
                ]
              }
            },
            _vm._l(_vm.Teams, function(team) {
              return _c(
                "option",
                {
                  attrs: { selected: "selected" },
                  domProps: { value: team.Id }
                },
                [_vm._v(_vm._s(team.Title))]
              )
            })
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__data" }, [
          JSON.stringify(_vm.BattlesInWeightCategory) == "[]"
            ? _c("div", { staticClass: "emptycategory" }, [
                _c("h1", [_vm._v("Šeit pagaidam nav ne viena ciņa")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.Fighters != ""
                ? _c(
                    "a",
                    {
                      staticClass: "page__content__data--header",
                      on: {
                        click: function($event) {
                          _vm.some()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Fighters[0].WeightCategoryTitle) + " Kg"
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.state.authUser &&
              _vm.$store.state.authUser.type === 1 &&
              _vm.Fighters != ""
                ? _c("AdminNavigationInCategoryComponent", {
                    attrs: {
                      FightersData: _vm.Fighters,
                      BattlesData: _vm.BattlesInWeightCategory,
                      BattlesGraph: _vm.BattlesForGraph,
                      ParticipantsLozesanai: _vm.Fighters
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.$store.state.authUser && _vm.Fighters != ""
                ? _c("PublicNavigationInCategoryComponent", {
                    attrs: {
                      FightersData: _vm.Fighters,
                      BattlesData: _vm.BattlesInWeightCategory,
                      BattlesGraph: _vm.BattlesForGraph
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.state.authUser &&
              !(_vm.$store.state.authUser.type === 1) &&
              _vm.Fighters != ""
                ? _c("PublicNavigationInCategoryComponent", {
                    attrs: {
                      FightersData: _vm.Fighters,
                      BattlesData: _vm.BattlesInWeightCategory,
                      BattlesGraph: _vm.BattlesForGraph
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "ParticipantCategoryModal", width: 900, height: 400 }
        },
        [
          _c("ParticipantCategoryModalComponent", {
            attrs: { ParticipantData: _vm.ParticipantData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "AddCategoriesModal",
            scrollable: true,
            width: "100%",
            height: "auto"
          }
        },
        [_c("AddCategoryModalComponent")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Weight Category")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e08982a", { render: render, staticRenderFns: staticRenderFns })
  }
}