var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(_vm.CompetitionsData, function(Competition) {
              return _c(
                "div",
                {
                  staticClass:
                    "list-group-item list-group-item-action competition-img"
                },
                [
                  _c("div", {
                    staticClass: "notice",
                    domProps: { innerHTML: _vm._s(Competition.Notice) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "list-group-item list-group-item-action competition-info"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "competition-info__text",
                          on: {
                            click: function($event) {
                              _vm.openCompetition(Competition.Id)
                            }
                          }
                        },
                        [
                          _c("h3", [_vm._v(" " + _vm._s(Competition.Title))]),
                          _vm._v(" "),
                          _c("h4", [
                            _vm._v(" Datums: " + _vm._s(Competition.BeginDate))
                          ]),
                          _vm._v(" "),
                          _c("h4", [
                            _vm._v(
                              " Pieteikšana līdz: " +
                                _vm._s(Competition.AppUntil)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "competition-info__actions" }, [
                        Competition.Id != _vm.CmNtfcId
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    _vm.joinCompetition(Competition.Id)
                                  }
                                }
                              },
                              [_vm._v("Saņemt paziņojumus")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        Competition.Id === _vm.CmNtfcId
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function($event) {
                                    _vm.leaveCompetition(Competition.Id)
                                  }
                                }
                              },
                              [_vm._v("Nesaņemt paziņojumus")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                _vm.editCompetition(Competition.Id)
                              }
                            }
                          },
                          [_vm._v("Labot")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function($event) {
                                _vm.deleteCompetition(Competition.Id)
                              }
                            }
                          },
                          [_vm._v("Dzest")]
                        )
                      ])
                    ]
                  )
                ]
              )
            })
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-13d28aa6", { render: render, staticRenderFns: staticRenderFns })
  }
}