<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="RingsData"
      @on-cell-click="onCellClick"
      :search-options="{
            enabled: false,
          }"
      :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <button class="btn btn-primary" v-on:click="editRing(props.row.Id)">Labot</button>
          <button class="btn btn-danger" v-on:click="deleteRing(props.row.Id)">Delete</button>
          <button class="btn btn-warning" v-on:click="generateBattles(props.row.Id)">Generet ciņas</button>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
    </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "RingsGridInCompetitionComponent",
    props: ["RingsData"],
    data(){
      return {
        columns: [
          {
            label: 'Nr',
            field: 'RingNr',
          },
          {
            label: 'Nosaukums',
            field: 'RingTitle',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
              label: 'Kategorijas',
              field: 'Categories',
              thClass: 'text-center',
              tdClass: 'text-center',
          },
          {
            label: 'Galvenais tiesnesis',
            field: 'MainJudge',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Tiesneši ringā',
            field: 'Judges',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Darbibas',
            field: 'Actions',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        CmId: null,
        RingId: null,
      }
    },
    methods: {
      onCellClick(params){
        if (params.column.field!= "Actions") {
          this.CmId = this.$route.params.cmId;
          this.ringId = params.row.Id;
          console.log(this.ringId)
          this.$router.push('/competition/ring/' + this.CmId + '/' + this.ringId)
        }
      },
      editRing(q) {
        this.$router.push('/competition/rings/ring/edit/' + q +'/'+ this.$route.params.cmId)
      },
      deleteRing(q) {
        let DeleteRing = confirm("Are you sure? This move will delete this ring with all battles! ")

        if (DeleteRing == true) {this.$socket.emit('delete-competition-ring', {ringId: q} );}
       /* this.$axios.post(`/api/competition/rings/ring/delete`,{
          ringId: q,
        })
          .then(response => {
          })
          .catch(e => {
            this.errors.push(e)
          })*/
      },
      generateBattles(q){
        let generate=confirm("Are you sure? This move can delete all battles! ")

        if (generate==true){
          this.$axios.get(`/api/generatebattles`, {
            params: {
              cmId: this.$route.params.cmId,
              ringId: q,
              type: 'ring'
            }
          })
          .then(response => {
            console.log(response)
          /*  if (typeof response.data!=='object'){
              alert(response.data)
            }*/
          })
          .catch(e => {
            this.errors.push(e)
          })
        }else{
          console.log("stop")
        }
      },
    },
    mounted() {
      this.$socket.on('ring-delete-response', response => {
        if(response.message){
          alert(response.message)
        }
      });
    },
    components: {

    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>