var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__table" },
        [
          _c("div", { staticStyle: { padding: "1rem 4rem" } }, [
            _vm.CreationForm
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.CreationForm = !_vm.CreationForm
                      }
                    }
                  },
                  [_vm._v("Izveidot ringu +")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.CreationForm
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.CreationForm = !_vm.CreationForm
                      }
                    }
                  },
                  [_vm._v("Aizvert")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.CreationForm
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "1rem 4rem",
                    "background-color": "#dadada"
                  }
                },
                [
                  _c("label", {}, [_vm._v("Nosaukums ")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.RingTitle,
                        expression: "RingTitle"
                      }
                    ],
                    attrs: { value: "Ring" },
                    domProps: { value: _vm.RingTitle },
                    on: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.createRing($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.RingTitle = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: {
                        click: function($event) {
                          _vm.createRing()
                        }
                      }
                    },
                    [_vm._v("Izveidot ringu")]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("rings-grid", { attrs: { RingsData: _vm.Rings } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Ringu saraksts")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7b7e4156", { render: render, staticRenderFns: staticRenderFns })
  }
}