<template>
 <div>
   <template>
     <div class="container">
       <div class="list-group" >
         <div class="list-group-item list-group-item-action competition-img" v-for="Competition in CompetitionsData" >
           <div class="notice" v-html="Competition.Notice"></div>
           <div class="list-group-item list-group-item-action competition-info">
             <div class="competition-info__text" @click="openCompetition(Competition.Id)">
               <h3> {{Competition.Title}}</h3>
               <h4> Datums: {{Competition.BeginDate}}</h4>
               <h4> Pieteikšana līdz: {{Competition.AppUntil}}</h4>
             </div>
             <div class="competition-info__actions">
               <button v-if="Competition.Id!=CmNtfcId" class="btn btn-primary" v-on:click="joinCompetition(Competition.Id)">Saņemt paziņojumus</button>
               <button v-if="Competition.Id===CmNtfcId" class="btn btn-danger" v-on:click="leaveCompetition(Competition.Id)">Nesaņemt paziņojumus</button>
               <button class="btn btn-primary" v-on:click="editCompetition(Competition.Id)">Labot</button>
               <button class="btn btn-danger" v-on:click="deleteCompetition(Competition.Id)">Dzest</button>
             </div>
           </div>
         </div>
       </div>
     </div>
   </template>
 </div>
</template>

<script>
 export default {
   name: "CompetitionsListComponent",
   props: ["CompetitionsData","CmNtfcId"],
   data(){
     return {
       CmId: null,
     }
   },
   methods: {
     editCompetition(Id) {
       this.$router.push('/competition/edit/' + Id)
     },
     openCompetition(Id) {
       this.$router.push({name: 'competitionPage', params: {cmId: Id}})
     },
     deleteCompetition(Id) {
       let ApproveDelete = confirm("Are you sure want to delete this competition?")
       if (ApproveDelete==true) {
         this.$socket.emit('delete-competition', {cmId: Id});
       }
     },
     joinCompetition(cmId){
       this.$socket.emit('competition-join',{CmId: cmId, UserId: this.$store.state.authUser.id})
     },
     leaveCompetition(cmId){
       this.$socket.emit('competition-leave',{CmId: cmId, UserId: this.$store.state.authUser.id})
     },

   },
   created(){
     /*this.$store.state.authUser ? this.columns[4].hidden = false : this.columns[4].hidden = true;*/
   },
   components: {
   },
 };
</script>

<style lang="scss">
 .text-center{
   text-align:center;
 }

 .competition-img{
   background: #273749;
   color: white;
   padding: 2rem;
   margin-top: 2rem;
 }

 .notice {
   background-color: black;
 }

 .notice img{
   width: 70rem;
   height: auto;
 }

 .competition-info{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap-reverse;
   justify-content: space-between;
   background: #c7c5c3;
   color: #444444;
   cursor: pointer;
   &:hover{
     background: #afa195;
   }
   &__text{
     width: 70%;
   }
   &__actions{
   }
 }

  @media (max-width: 991px){
    .notice img{
      width:100%;
      height: auto;
    }
  }

</style>