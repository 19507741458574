var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page__team-apply-form",
      staticStyle: { "margin-bottom": "9rem" }
    },
    [
      _c("div", { staticClass: "page__team-apply-form__list-block" }, [
        _c("div", { staticClass: "vue-good-table" }, [
          _c(
            "table",
            {
              staticClass: "table table-bordered",
              staticStyle: { "font-size": "16px" }
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.TeamParticipants, function(Participant) {
                    return _vm.ApplyId != Participant.UserId
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n             " +
                                _vm._s(Participant.Name) +
                                " " +
                                _vm._s(Participant.Surname) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(Participant.Name) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(Participant.Surname) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm
                                    .$moment(Participant.DateOfBirth)
                                    .format("YYYY MM DD")
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(Participant.Gender) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(Participant.Weight) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _vm._l(Participant.Categories, function(
                                Category
                              ) {
                                return _c("p", [
                                  _vm._v(" " + _vm._s(Category) + " ")
                                ])
                              }),
                              _c("br")
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "page__team-apply-form__btn-item"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "page__team-apply-form__list-block__list--list-item btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        _vm.getApplyData(Participant.UserId)
                                      }
                                    }
                                  },
                                  [_vm._v("Labot")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "page__team-apply-form__list-block__list--delete-item btn btn-danger",
                                    on: {
                                      click: function($event) {
                                        _vm.deleteUserClaim(Participant.UserId)
                                      }
                                    }
                                  },
                                  [_vm._v("Dzēst")]
                                ),
                                _vm._v(" "),
                                _vm.ApplySaved.Id == Participant.UserId
                                  ? _c("img", {
                                      attrs: {
                                        src: "/static/green-tick.png",
                                        alt: "",
                                        width: "40",
                                        height: "30"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.Users,
                            "close-on-select": true,
                            "clear-on-select": false,
                            "open-direction": "below",
                            placeholder: "",
                            "track-by": "Id",
                            "custom-label": _vm.NameSurnameLabel
                          },
                          on: {
                            input: function($event) {
                              _vm.setCategories(_vm.index)
                            }
                          },
                          model: {
                            value: _vm.ApplyData.User,
                            callback: function($$v) {
                              _vm.$set(_vm.ApplyData, "User", $$v)
                            },
                            expression: "ApplyData.User"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ApplyData.User.Name,
                            expression: "ApplyData.User.Name"
                          }
                        ],
                        staticClass:
                          "page__content__input__form__item--input form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.ApplyData.User.Name },
                        on: {
                          change: function($event) {
                            _vm.setCategories()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ApplyData.User,
                              "Name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ApplyData.User.Surname,
                            expression: "ApplyData.User.Surname"
                          }
                        ],
                        staticClass:
                          "page__content__input__form__item--input form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.ApplyData.User.Surname },
                        on: {
                          change: function($event) {
                            _vm.setCategories()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ApplyData.User,
                              "Surname",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("DatePicker", {
                          attrs: {
                            "calendar-class": "calendar",
                            "monday-first": true,
                            "bootstrap-styling": true
                          },
                          on: {
                            closed: function($event) {
                              _vm.setCategories()
                            }
                          },
                          model: {
                            value: _vm.ApplyData.User.DateOfBirth,
                            callback: function($$v) {
                              _vm.$set(_vm.ApplyData.User, "DateOfBirth", $$v)
                            },
                            expression: "ApplyData.User.DateOfBirth"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.Genders,
                            "close-on-select": true,
                            "clear-on-select": false,
                            "open-direction": "below",
                            placeholder: "",
                            "track-by": "Id",
                            "custom-label": _vm.GenderLabel
                          },
                          on: {
                            input: function($event) {
                              _vm.setCategories()
                            }
                          },
                          model: {
                            value: _vm.ApplyData.User.Gender,
                            callback: function($$v) {
                              _vm.$set(_vm.ApplyData.User, "Gender", $$v)
                            },
                            expression: "ApplyData.User.Gender"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ApplyData.Weight,
                            expression: "ApplyData.Weight"
                          }
                        ],
                        staticClass:
                          "page__content__input__form__item--input form-control",
                        domProps: { value: _vm.ApplyData.Weight },
                        on: {
                          change: function($event) {
                            _vm.setCategories()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ApplyData,
                              "Weight",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.Categories,
                            "close-on-select": true,
                            "clear-on-select": false,
                            multiple: true,
                            "open-direction": "below",
                            placeholder: "",
                            "track-by": "Id",
                            "custom-label": _vm.CategoryLabel
                          },
                          model: {
                            value: _vm.ApplyData.Categories,
                            callback: function($$v) {
                              _vm.$set(_vm.ApplyData, "Categories", $$v)
                            },
                            expression: "ApplyData.Categories"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "page__team-apply-form__btn-item" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "page__team-apply-form__btn-item--btn btn btn-primary",
                              on: {
                                click: function($event) {
                                  _vm.applyUser(_vm.ApplyData)
                                }
                              }
                            },
                            [_vm._v("Saglabāt")]
                          )
                        ]
                      )
                    ])
                  ])
                ],
                2
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Atrast dalibnieku")]),
        _vm._v(" "),
        _c("th", [_vm._v("Vards")]),
        _vm._v(" "),
        _c("th", [_vm._v("Uzvards")]),
        _vm._v(" "),
        _c("th", [_vm._v("Dzimšanas Datums*")]),
        _vm._v(" "),
        _c("th", [_vm._v("Gender*")]),
        _vm._v(" "),
        _c("th", [_vm._v("Svars*")]),
        _vm._v(" "),
        _c("th", [_vm._v("Kategorija*")]),
        _vm._v(" "),
        _c("th", [_vm._v("Darbības")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1aff5c4c", { render: render, staticRenderFns: staticRenderFns })
  }
}