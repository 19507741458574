<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Mans profils</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data-container">
        <div class="page__content__data-container__header">
          <div class="page__content__data-container__header__info-container">
            <img src="/static/avatar.png" width="20rem" class="page__content__data-container__header--avatar">
            <p class="page__content__data-container__header__info-container--item">Fights: <br> {{ UserData.Fights }}
            </p>
            <p class="page__content__data-container__header__info-container--item"> Uzvaras: <br> {{ UserData.Victories}} </p>
            <p class="page__content__data-container__header__info-container--item"> Nokauti: <br> {{UserData.Knockouts}} </p>
          </div>
        </div>
        <div class="page__content__data-container__sub-content">
          <div class="page__content__data-container__sub-content__primary-info-container">
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Mani dati
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">Vards,
                Uzvards: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info ">
                {{UserData.Name}} {{UserData.Surname}} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">Dzimsanas
                datums: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info ">
                {{UserData.DateOfBirth}} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                E-pasts: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Email }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Parole: </label>
              <router-link class="page__content__data-container__sub-content__primary-info-container__item--info "
                           :to="{name: 'editProfilePasswordPage'}"> Mainit parole
              </router-link>
            </div>
          </div>
          <user-options :WarmUp="UserData.WarmUp"></user-options>
          <div class="page__content__data-container__sub-content__primary-info-container">
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Kontaktinformācija
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label
                  class="page__content__data-container__sub-content__primary-info-container__item--label ">Valsts: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Country }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Adrese: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Adress }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Personīga informācija
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Clubs: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Club }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Work: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Work }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Hobijs: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Hobby }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Pieredze: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Xp }} </p>
            </div>
          </div>
          <div class="list-grid" v-if="($store.state.authUser.type==4)||($store.state.authUser.type==5)">
            <div v-if="$store.state.authUser.type === 4" class="list-grid--header">
              Komandas
            </div>
            <div v-if="$store.state.authUser.type === 4" class="list-grid__item">
              <div class="list-grid__item__table">
                <div class="list-grid__item__table__cell" v-for="(Team,index) in Teams">
                  <div class="list-grid__item__table__cell--text">
                   {{Team.Title}}
                  </div>
                  <div class="list-grid__item__table__cell__btn-container">
                    <div class="list-grid__item__table__cell__btn-container--reject-btn"
                         v-on:click="deleteTeam(Team.Id)">
                      Dzest
                    </div>
                  </div>
                </div>
                <div v-if="Teams.length===0"  class="list-grid__item__table__empty-table-msg" >
                  <div class="">
                    Komandas nav
                  </div>
                </div>
              </div>
            </div>
            <div class="list-grid--header">
              Pieteikumi sacensibam
            </div>
            <div class="list-grid__item">
              <div class="list-grid__item__table">
                <div class="list-grid__item__table__cell"
                     v-for="(CompetitionUserClaime,index) in ClaimsData">
                  <div class="list-grid__item__table__cell--text">
                   <b> {{CompetitionUserClaime.Name}} {{CompetitionUserClaime.Surname}} </b> <br>
                    {{ CompetitionUserClaime.CompetitionTitle }} &emsp; {{ CompetitionUserClaime.CompetitionDate }}
                  </div>
                  <div class="list-grid__item__table__cell__btn-container">
                    <div class="list-grid__item__table__cell__btn-container--reject-btn"
                         v-on:click="deleteCmUser(CompetitionUserClaime.CmUserId)">
                      Atteikt
                    </div>
                  </div>
                </div>
                <div v-if="ClaimsData.length===0" class="list-grid__item__table__empty-table-msg" >
                  <div class="">
                    Pieteikumu nav
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="page__content__data-container__sub-content__claims-info-container">
              <div class="page__content__data-container__sub-content__claims-info-container__item" >
                  <label class="page__content__data-container__sub-content__claims-info-container__item&#45;&#45;label " style="z-index:1;">Mani pieteikumi: </label>
              </div>
              <div class="page__content__data-container__sub-content__claims-info-container__table">
                  <div class="page__content__data-container__sub-content__claims-info-container__table&#45;&#45;info " v-for="ClaimData in ClaimsData" >
                      {{ ClaimData.CompetitionTitle }} &emsp; {{ ClaimData.CompetitionDate }} &emsp; Atteikties/Pieteikties
                  </div>
                  <div class="page__content__data-container__sub-content__claims-info-container__table&#45;&#45;info " v-if="ClaimsData==''" > Pagaidam nav </div>
       &lt;!&ndash;           <p class="page__content__data-form__claims-info-container__item&#45;&#45;info " v-for="ClaimData in ClaimsData" > {{ClaimData.CompetitionTitle}} </p> &ndash;&gt;&lt;!&ndash;v-model=""&ndash;&gt;
                 &lt;!&ndash; <p class="page__content__data-form__claims-info-container__item&#45;&#45;info " v-if="ClaimsData==''" > Pagaidam nav </p>&ndash;&gt;
              </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import MenuComponent from "../../components/common/MenuComponent";
  import UserOptionsComponent from "../../components/common/UserOptionsComponent";

  export default {
    name: "profilePage",
    data() {
      return {
        show: false,
        UserData: [],
        ClaimsData: [],
        UserTypes: [{Id: 1, Type: "Galvenais tiesnesis"},
          {Id: 2, Type: "Administrators"},
          {Id: 3, Type: "Tiesnesis"},
          {Id: 4, Type: "Treneris"},
          {Id: 5, Type: "Dalibnieks"},
        ],
        Teams: []
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/profile`, {
          params: {
            userType: this.$store.state.authUser.type,
            userId: this.$store.state.authUser.id,
          }
        })
            .then(response => {
              this.UserData = response.data.UserData;
              this.UserTypes.forEach((Type) => {
                if (Type.Id === this.UserData.UserTypeId) {
                  this.UserData.UserType = Type.Type
                }
              })
              this.ClaimsData = response.data.ClaimsData;
              this.Teams= response.data.Teams;
              console.log("User Data: ", this.UserData)
              console.log("Claims Data: ", this.ClaimsData)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
     /* confirmCmUser(Id) {
        this.$socket.emit('confirm-fighter', {Id: Id});
      },*/
      deleteCmUser(Id) {
        let unconfirm = confirm("Are you real want to delete apply?");
        console.log(unconfirm)
        if (unconfirm==true) {
          console.log(`will deleted ${Id}`)
          this.$socket.emit('delete-cmuser', { usercmId: Id });
        }
      },
      deleteTeam(Id) {
        let unconfirm = confirm("Are you real want to delete team?");
        console.log(unconfirm)
        if (unconfirm==true) {
          console.log(`will deleted ${Id}`)
          this.$socket.emit('delete-team', { teamId: Id });
        }
      }
    },
    created() {
      this.getData();
      this.$socket.on('refresh-cmusers-data', response => {
        this.getData();
      });
    },
    mounted() {
      if(this.$store.state.authUser.type===4) {
        this.$socket.on('refresh-teams-data', response => {
          this.getData();
        });
      }
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-cmusers-data');
      if(this.$store.state.authUser.type===4) {
        this.$socket.removeListener('refresh-teams-data');
      }
    },
    watch: {},
    components: {
      MenuComponent, "user-options": UserOptionsComponent
    },
  }
</script>


<style lang="scss" scoped>
  .page__content {
    &__data-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    /*  transition: 0.5s;
      transform: translateX(-5%);*/
      background: #cad3ff;
      /*  background-color: #66A6FF;*/
      z-index: 0;
      padding: 7rem 0;
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #212329;
        color: white;
        &--avatar {
          width: 10rem;
        }
        &__info-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;
          width: 100%;
          &--item {
            text-align: center;
            font-weight: bold;
            padding: 2rem 0;
            /*padding: 3rem 3rem 3rem 3rem;*/
          }
        }
      }
      &__sub-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 7rem;

        &__primary-info-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: white;
          padding: 3rem 7rem 0 0;

          &--header {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background: #373739;
            padding: 3rem 0 2rem 0;
          }

          &__item {
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 3rem 1rem 2rem 5rem;
            font-size: 18px;
            background: #373739;

            &--label {
              padding-right: 2rem;
              font-weight: bold;
            }

            &--info {
            }

            &--btn {
              padding-right: 3rem;
              width: 15rem;
            }
          }
        }
      }}}
/*      .list-grid {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: white;
          padding: 7rem 7rem 3rem 0;
          &--header {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            background: #373739;
            padding: 3rem 0 2rem 0;
          }
          &__item {
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 3rem 1rem 2rem 1rem;
            font-size: 18px;
            width: 100%;
            background: #373739;
            &--label {
              padding-right: 2rem;
              font-weight: bold;
            }
            &__table {
              display: flex;
              flex-direction: column;
              width: 100%;
              !*align-items:center;*!
              background: #373739;
              min-height: 7rem;
              max-height: 35rem;
              overflow-y: scroll;
              &__cell {
                display: flex;
                flex-direction: row;
                width: 100%;
                z-index: 0;
                background: #EAEAEA;
                min-height: 7rem;
                border-bottom: 1px solid #474a56;
                cursor: pointer;
                &--text {
                  width: 100%;
                  text-align: left;
                  padding-left: 3rem;
                  align-self: center;
                  color: #474a56;
                }
                &__btn-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  z-index: 1;
                  text-align: right;
                  &--accept-btn {
                    padding: 3rem 4rem;
                    background: #b5ff45;
                    &:hover {
                      opacity: 0.7;
                      background: #b5ff45;
                      !*  background: #474a56;*!
                    }
                  }
                  &--reject-btn {
                    padding: 3rem 4rem;
                    background: #ff5157;
                    &:hover {
                      opacity: 0.8;
                      background: #ff5157;
                      !*background: #474a56;*!
                    }
                  }
                }
                &:hover {
                  opacity: 0.8;
                  background: #979BF1;
                }
              }
            }
          }
        }*/

   /*     &__teams {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: white;
          padding: 7rem 7rem 3rem 0;
          &--header {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            background: #373739;
            padding: 3rem 0 2rem 0;
          }
          &__item {
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 3rem 1rem 2rem 1rem;
            font-size: 18px;
            width: 100%;
            background: #373739;
            &--label {
              padding-right: 2rem;
              font-weight: bold;
            }
            &__table {
              display: flex;
              flex-direction: column;
              width: 100%;
              !*align-items:center;*!
              background: #373739;
              min-height: 7rem;
              max-height: 35rem;
              overflow-y: scroll;
              &__cell {
                display: flex;
                flex-direction: row;
                width: 100%;
                z-index: 0;
                background: #EAEAEA;
                min-height: 7rem;
                border-bottom: 1px solid #474a56;
                cursor: pointer;
                &--text {
                  width: 100%;
                  text-align: left;
                  padding-left: 3rem;
                  align-self: center;
                  color: #474a56;
                }
                &__btn-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  z-index: 1;
                  &--reject-btn {
                    padding: 1rem 3rem;
                    background: #ff5157;
                    &:hover {
                      opacity: 0.8;
                      background: #ff5157;
                      !*background: #474a56;*!
                    }
                  }
                }
                &:hover {
                  opacity: 0.8;
                  background: #979BF1;
                }
              }
            }
          }
        }
      }
    }
  }*/

</style>
