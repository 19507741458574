var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", staticStyle: { height: "100%" } }, [
    _c("div", { staticClass: "queue-container" }, [
      _c("div", { staticClass: "queue-container__item" }, [
        _vm.TeamData
          ? _c("h1", { staticClass: "queue-container__item--text" }, [
              _vm._v("\n        " + _vm._s(_vm.TeamData.TeamTitle) + "\n      ")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.TeamData
          ? _c("h1", { staticClass: "queue-container__item--text" }, [
              _vm._v("\n        Svēršana beidzas\n      ")
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-26827930", { render: render, staticRenderFns: staticRenderFns })
  }
}