<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Disciplinas labošāna</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__input">
                <h1 class="page__content__input--title">Dati Labošanai</h1>
                <br>
                <div class="page__content__input__form">
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
                        <input class="page__content__input__form__item--input form-control"  v-model="DisciplineData.Title">
                    </div>
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Īsais Nosaukums<sup>*</sup></label>
                        <input class="page__content__input__form__item--input form-control"  v-model="DisciplineData.Short">
                    </div>
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Apraksts<sup>*</sup></label>
                        <textarea  class="page__content__input__form__item--input form-control" rows="10" v-model="DisciplineData.Description">
                        </textarea>
                    </div>
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Stils<sup>*</sup></label>
                        <multiselect v-model="DisciplineData.Style" :options="Styles" :close-on-select="true"
                                      :clear-on-select="false" :open-direction="'below'"
                                     placeholder="Select style" track-by="Id" :custom-label="StyleTitle">
                        </multiselect>
                    </div>
                    <div class="page__content__input__form__btn-item">
                        <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="editDiscipline">Saglabat</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import MenuComponent from "../../components/common/MenuComponent";
    export default {
        name: "createDisciplinePage",
        data(){
            return {
                show: false,
                Styles: [],
                DisciplineData: {
                    Title: "",
                    Short: "",
                    Style: {Id: "", Title: ""},
                    Description: "",
                },
            }
        },
        methods: {
            editDiscipline(){
                this.$socket.emit('edit-discipline', this.DisciplineData);
            },
            StyleTitle (option) {
                return `${option.Title}`
            },
        },
        mounted() {
            this.$axios.get(`/api/disciplines/geteditdata`,{
                params:{
                    discipId: this.$route.params.discipId
                }
            })
                .then(response => {
                    this.DisciplineData = response.data.DisciplineData;
                    this.DisciplineData.Style={
                        Id:  this.DisciplineData.StyleId,
                        Title: this.DisciplineData.Style
                    }
                    this.Styles = response.data.Styles;
                    console.log(this.Styles)
                })
                .catch(e => {
                    this.errors.push(e)
                })
            this.$socket.on('success', response => {
                if (response===true){
                    this.$router.push('/disciplines')
                }else{alert("Check all fields on filled right")}
            });
        },
        beforeDestroy () {
            this.$socket.removeListener('success');
        },
        components: {
            MenuComponent,
            "multiselect": Multiselect
        }
    }
</script>

<style lang="scss" scoped>

    #app {
        margin-top: 20px;
    }

    pre {
        margin-top: 20px;
    }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
