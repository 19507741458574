<template>
  <div class="page" style="padding: 0;">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Pievienot pultu</h1>
    </div>
    <div class="page__content" style="padding: 0;">
      <div class="page__content__input" style="padding-top: 3rem;">
        <div class="page__content__input__form" >
          <div class="page__content__input__form__item" style="padding:0;">
            <multiselect style="padding-bottom: 4rem;" v-model="RemoteControl" :options="RemoteControls" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="RemoteLabel" ></multiselect>
          </div>

          <div class="page__content__input__form__item" style="padding:0;">
            <multiselect style="padding-bottom: 4rem;" v-model="Judge" :options="Judges" :close-on-select="true"
                         :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="JudgeLabel" ></multiselect>
          </div>
          <div class="page__content__input__form__btn-item" >
            <button class="btn btn-primary page__content__input__form__btn-item--btn" v-on:click="addRemoteControl">Pievienot</button>
            <button class="page__content__input__form__btn-item--btn" @click="$modal.hide('addingRemoteModal')">
              ❌ Aizvert
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "SelectTeamModalComponent",
    data(){
      return {
        RemoteControl: {},
        RemoteControls: [],
        Judge: {},
        Judges: [],
      }
    },
    methods: {
      getData() {
        this.$axios.get(`/api/competition/remote-controls/adding-data`, {
        })
            .then(response => {
              this.RemoteControls = response.data.RemoteControls;
              this.Judges = response.data.Judges;
              console.log(response.data)
            })
            .catch(e => {
              this.errors.push(e)
            })
      },
      addRemoteControl(){
       /*   this.$modal.show('ApplyTeamModal');*/

          this.$modal.hide('addingRemoteModal')

        this.$socket.emit('add-remote-for-competition',{
          CmId: this.$route.params.cmId,
          RemoteId: this.RemoteControl.Id,
          JudgeId: this.Judge.Id,
        })
      },
      RemoteLabel (option) {
        if ((typeof option.RemoteAddress=='undefined')){return '-'}
        return `${option.RemoteAddress}`
      },
      JudgeLabel (option) {
        if ((typeof option.Name=='undefined') && (typeof option.Surname=='undefined')){return '-'}
        return `${option.Name} ${option.Surname}`
      },
    },
    mounted() {
      this.getData()
    },
    components: {
      "multiselect": Multiselect
    },
  };
</script>

<style lang="scss">
  .change-category-modal{
    padding: 5rem 10rem 10rem 10rem;
    &__participant-data{
      padding-bottom: 2rem;
      &--name-surname{
        text-align:center;
      }
    }
    &__set-category-container{
      &--select{
        padding-bottom: 4rem;
      }
      &--save-btn{
        float: right;
        width: 14rem;
        height: 5rem;
      }
    }
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>