<template>
    <div>
        <vue-good-table
            :columns="columns"
            :rows="AgeCategoriesData"
            @on-cell-click="onCellClick"
            :sort-options="{
                enabled: true
            }"
            :pagination-options="{
                enabled: true,
                perPage: 10,
            }"
            styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field=='Actions'">
                    <button class="btn btn-primary" v-on:click="editAgeCategory(props.row.Id)">Labot</button>
                    <button class="btn btn-danger" v-on:click="deleteAgeCategory(props.row.Id)">Delete</button>
                </div>
                <span v-else>
              {{props.formattedRow[props.column.field]}}
          </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    export default {
        name: "AgeCategoriesGridComponent",
        props: ["AgeCategoriesData"],
        data(){
            return {
                columns: [
                    {
                        label: 'Nosaukums',
                        field: 'AgeCategoryTitle',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Īsais nosaukums',
                        field: 'Short',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                      label: 'Gender',
                      field: 'Gender',
                      thClass: 'text-center',
                      tdClass: 'text-center',
                      filterOptions: {
                        enabled: true,
                      }
                    },
                    {
                        label: 'Minimalais vecums',
                        field: 'AgeMin',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Maksimalais vecums',
                        field: 'AgeMax',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },{
                        label: 'Raunžu saits',
                        field: 'Rounds',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Raunda īlgums ',
                        field: 'RoundTime',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Pārtraukuma īlgums',
                        field: 'RestTime',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Iepriekšsakšanas laiks',
                        field: 'PreliminaryTime',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Discipline',
                        field: 'DisciplineTitle',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Darbības',
                        field: 'Actions',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ],
            }
        },
        methods: {
            editAgeCategory(q) {
                this.$router.push('/categories/age/edit/' + q)
            },
            deleteAgeCategory(q) {
                this.$socket.emit('delete-age-category',{ AgeCategId: q} );
            },

        },
        components: {
        },
    };

</script>

<style lang="scss">
    .text-center{
        text-align:center;
    }
</style>