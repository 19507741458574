<template>
  <div style="padding-top: 3rem; position: relative; z-index: 1;">
    <vue-good-table
        :columns="columns"
        :rows="ResultsData"
        :search-options="{
          enabled: true,
          trigger: 'enter'
        }"
        :pagination-options="{
          enabled: true,
          perPage: 10,
        }"
        styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Place'">
          <div v-if="props.row.Place=='0'">
            -
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
        </div>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: 'TeamResultsTableComponent',
    props: ["ResultsData"],
    data(){
      return {
        columns: [
          {
            label: 'Vieta',
            field: 'Place',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Vards, Uzvards',
            field: 'NameSurname',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Kategorija',
            field: 'Category',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Cinitaju skaits kategorijā',
            field: 'UsersCountInCategory',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
      }
    },
    methods: {
    },
    mounted() {
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>