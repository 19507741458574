<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Pieteikuma izskatišana</h1>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data-container">
        <div class="page__content__data-container__header">
          <div class="page__content__data-container__header__info-container">
            <p class="page__content__data-container__header__info-container--item" style="font-size: 3rem;">Pieteikuma dati
            </p>
          </div>
        </div>
        <div class="page__content__data-container__sub-content">
          <div class="page__content__data-container__sub-content__primary-info-container">
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Fotografija
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__img-item">
                <img v-if="HaveImage" style="width: 40rem; height:32rem; " :src="'/photos/'+UserData.Photo" @error="HaveImage=!HaveImage"/>
                <div v-if="!HaveImage" style="height:32rem; padding-top: 5rem;">
                  <h1>Lietotājs neielādē fotogrāfiju!</h1>
                </div>
            </div>
          </div>
          <div class="page__content__data-container__sub-content__primary-info-container">
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Personiskie dati
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">Vards,
                Uzvards: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info ">
                {{UserData.Name}} {{UserData.Surname}} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">Dzimsanas
                datums: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info ">
                {{UserData.DateOfBirth}} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                E-pasts: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Email }} </p>
            </div>
          </div>
          <div class="page__content__data-container__sub-content__primary-info-container">
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Kontaktinformācija
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label
                  class="page__content__data-container__sub-content__primary-info-container__item--label ">Valsts: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Country }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Adrese: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Adress }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container--header ">
              Personīga informācija
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Clubs: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Club }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Work: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Work }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Hobijs: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{
                UserData.Hobby }} </p>
            </div>
            <div class="page__content__data-container__sub-content__primary-info-container__item">
              <label class="page__content__data-container__sub-content__primary-info-container__item--label ">
                Pieredze: </label>
              <p class="page__content__data-container__sub-content__primary-info-container__item--info "> {{ UserData.Xp
                }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "CreateUserClaimPage",
    data(){
      return {
        show: false,
        HaveImage: true,
        UserData: {
          Gender: {},
          UserType: {}
        },
        DateOfBirth: null,
      }
    },
    methods: {
      getData(){
          this.$axios.get(`/api/userdata`,{
              params: {
                  userId: this.$route.params.userId
              }
          })
              .then(response => {
                  this.UserData = response.data.UserData;
                  this.DateOfBirth = response.data.UserData.DateOfBirth,
                  console.log(this.UserData)
              })
              .catch(e => {
                  this.errors.push(e)
              })
      },
      confirmUser(Id){
        this.$socket.emit('confirm-user', {Id: Id});
      },
      unconfirmUser(Id){
        this.$socket.emit('unconfirm-user', {Id: Id});
      },

    },
    mounted() {
        this.getData()
        this.$socket.on('refresh-create-users-claims', response => {
            if (response===true){
                this.$router.push('/dashboard')
            }else{alert("Something goes wrong!")}
        });
    },
    beforeDestroy () {
        this.$socket.removeListener('refresh-create-users-claims');
    },
    components: {
        MenuComponent
    },
  }
</script>

<style lang="scss" scoped>

  .page__content {
    &__data-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      /*  transition: 0.5s;
        transform: translateX(-5%);*/
      background: #cad3ff;
      /*  background-color: #66A6FF;*/
      z-index: 0;
      padding: 7rem 0;
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #212329;
        color: white;
        &--avatar {
          width: 10rem;
        }
        &__info-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;
          width: 100%;
          &--item {
            text-align: center;
            font-weight: bold;
            padding: 2rem 0;
            /*padding: 3rem 3rem 3rem 3rem;*/
          }
        }
      }
      &__sub-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 7rem;
        &__primary-info-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: white;
          padding: 7rem 7rem 0 0;
          &--header {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background: #373739;
            padding: 3rem 0 2rem 0;
          }
          &__item {
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 3rem 1rem 2rem 5rem;
            font-size: 18px;
            background: #373739;
            &--label {
              padding-right: 2rem;
              font-weight: bold;
            }
            &--info {
            }
            &--btn {
              padding-right: 3rem;
              width: 15rem;
            }
          }
          &__img-item{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 3rem 5rem 2rem 5rem;
            font-size: 18px;
            background: #373739;
          }
        }
        &__user-claims {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: white;
          padding: 7rem 7rem 3rem 0;
          &--header {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            background: #373739;
            padding: 3rem 0 2rem 0;
          }
          &__item {
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 3rem 1rem 2rem 1rem;
            font-size: 18px;
            width: 100%;
            background: #373739;
            &--label {
              padding-right: 2rem;
              font-weight: bold;
            }
            &__table {
              display: flex;
              flex-direction: column;
              width: 100%;
              /*align-items:center;*/
              background: #373739;
              min-height: 7rem;
              max-height: 35rem;
              overflow-y: scroll;
              &__cell {
                display: flex;
                flex-direction: row;
                width: 100%;
                z-index: 0;
                background: #EAEAEA;
                min-height: 7rem;
                border-bottom: 1px solid #474a56;
                cursor: pointer;
                &--text {
                  width: 100%;
                  text-align: left;
                  padding-left: 3rem;
                  align-self: center;
                  color: #474a56;
                }
                &__btn-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  z-index: 1;
                  text-align: right;
                  &--accept-btn {
                    padding: 3rem 3rem;
                    background: #9dd95c;
                    &:hover {
                      opacity: 0.7;
                      background: #70bf1c;
                      /*  background: #474a56;*/
                    }
                  }
                  &--reject-btn {
                    padding: 3rem 3rem;
                    background: #fc757a;
                    &:hover {
                      opacity: 0.8;
                      background: #e13a41;
                      /*background: #474a56;*/
                    }
                  }
                }
                &:hover {
                  opacity: 0.8;
                  background: #979BF1;
                }
              }
            }
          }
        }
      }
    }
  }


  /*.page__content{
    &__claime-data {
      transition: 0.5s;
      transform: translateX(-5%);
      background-color: #eeecf6;
      z-index: 0;
      padding: 7rem 20rem;
      &__form{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding:3rem 2rem;
        color: #eeecf6;
        background: #273749;
        &__item {
          display: flex;
          flex-direction: row;
          justify-content: left;
          width: 50%;
          padding-bottom: 2rem;
          &--label {
            width: 14rem;
            font-size: 20px;
          }
          &--text {
            margin-left: 1rem;
            font-size: 18px;
          }
          &--btn {
            padding-right: 3rem;
            width: 15rem;
          }
        }
      }
      &__buttons-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        &--accept-btn {
          width: 50%;
          padding: 3rem 3rem;
          background: #b5ff45;
          &:hover {
            opacity: 0.9;
            background: #b5ff45;
            !*  background: #474a56;*!
          }
        }
        &--reject-btn {
          width: 50%;
          padding: 3rem 3rem;
          background: #ff5157;
          &:hover {
            opacity: 0.9;
            background: #ff5157;
            !*background: #474a56;*!
          }
        }
      }
    }
  }*/

</style>