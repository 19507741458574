<template>
  <div class="page">
    <div class="page__section">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Kategorijas</h1>
    </div>
  </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <div class="page__content__data__categories">
          <div class="page__content__data__categories__item" v-for="(Discipline, key, index) in Disciplines">
            <div class="page__content__data__categories__item--label">
              <a :href="'/competition/category/discipline/'+$route.params.cmId+'/'+Discipline.CmDisciplineId" style="color: black;">{{Discipline.DisciplineTitle}}</a>
            </div>
            <div class="page__content__data__categories__item__content">
              <table class="table table-bordered page__content__data__categories__item__content__table">
                <tbody style="width: 100%; background: #eeecf6;">
                  <tr class="page__content__data__categories__item__content__table--row" v-for="(AgeCategory, key, index) in Discipline" v-if="index>0">
                    <td class="page__content__data__categories__item__content__table__cell--title" v-if="index>2">
                      <a :href="'/competition/category/age/'+$route.params.cmId+'/'+Discipline.CmDisciplineId+'/'+AgeCategory.AgeCategoryId" >{{AgeCategory.AgeCategoryTitle}}</a></td>
                    <td class="page__content__data__categories__item__content__table__cell--data" v-for="(WeightCategory, key, index) in AgeCategory" v-if="index>2">
                      <a :href="'/competition/category/weight/'+$route.params.cmId+'/'+Discipline.CmDisciplineId+'/'+AgeCategory.AgeCategoryId+'/'+WeightCategory.WeightCategoryId">
                        {{WeightCategory.WeightCategoryTitle}}
                        <br> ({{WeightCategory.BattlesCount}} cīņas)
                        <br> ({{WeightCategory.FightersCount}} cīnītāji(-js))
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "categoryPage",
      data(){
      return {
        show: false,
        Disciplines: null,
        CompetitionTitle: "",
      }
    },
    methods: {
        getData(){
            this.$axios.get(`/api/competition/categories`, {
            params: {
                cmId: this.$route.params.cmId,
            }
        })
            .then(response => {
                this.Disciplines= response.data.Disciplines;
                this.CompetitionTitle = response.data.CompetitionTitle;
                console.log("Geted data for categories!")
                console.log(this.CompetitionTitle)
                console.log(this.Disciplines)
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    },
    mounted () {
        this.getData()
        this.$socket.on('refresh-categories-data', response => {
            this.getData();
        });
    },
    beforeDestroy () {
      this.$socket.removeListener('refresh-categories-data');
    },
    components: {
      MenuComponent
    }
  }
</script>

<style lang="scss" scoped>
  /*body {
    background: linear-gradient(to top left, #fefcea, #f1da36);
  }*/

</style>