<template>
  <div class="page__content__data-container__sub-content__options">

    <div class="page__content__data-container__sub-content__options__container" v-if="$store.state.authUser && $store.state.authUser.type === 1">
      <div class="page__content__data-container__sub-content__options__container--header "> Opcijas</div>
      <router-link :to="{ name: 'dashboardPage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Vadibas panelis </span>
        </div>
      </router-link>
      <router-link :to="{ name: 'editProfilePage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Redaktet profili </span>
        </div>
      </router-link>
    </div>

    <div class="page__content__data-container__sub-content__options__container" v-if="$store.state.authUser && $store.state.authUser.type === 3">
      <div class="page__content__data-container__sub-content__options__container--header "> Opcijas</div>
      <router-link :to="{ name: 'editProfilePage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Redaktet profili </span>
        </div>
      </router-link>
    </div>

    <div class="page__content__data-container__sub-content__options__container" v-if="$store.state.authUser && $store.state.authUser.type === 4">
      <div class="page__content__data-container__sub-content__options__container--header "> Opcijas</div>
      <router-link :to="{ name: 'createTeamPage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Izveidot komandu </span>
        </div>
      </router-link>
      <router-link :to="{ name: 'editProfilePage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Redaktet profili </span>
        </div>
      </router-link>
      <div class="page__content__data-container__sub-content__options__container__input-item">
        <span class="page__content__data-container__sub-content__options__container__input-item--label"> Iesildīšana iepriekš </span>
        <input class="page__content__data-container__sub-content__options__container__input-item--input" type="number" min="0" max="9" v-model="WarmUp" @change="setWarmUp()" > &emsp;
        <span class="page__content__data-container__sub-content__options__container__input-item--label"> cīņam </span>
      </div>
    </div>

    <div class="page__content__data-container__sub-content__options__container" v-if="$store.state.authUser && $store.state.authUser.type === 5">
      <div class="page__content__data-container__sub-content__options__container--header "> Opcijas</div>
      <router-link :to="{ name: 'editProfilePage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Redaktet profili </span>
        </div>
      </router-link>
      <div class="page__content__data-container__sub-content__options__container__input-item">
        <span class="page__content__data-container__sub-content__options__container__input-item--label"> Iesildīšana iepriekš </span>
        <input class="page__content__data-container__sub-content__options__container__input-item--input" type="number" min="0" max="9" v-model="WarmUp" @change="setWarmUp()" > &emsp;
        <span class="page__content__data-container__sub-content__options__container__input-item--label"> cīņam </span>
      </div>
    </div>
    <div class="page__content__data-container__sub-content__options__container" v-if="$store.state.authUser && $store.state.authUser.type === 6">
      <div class="page__content__data-container__sub-content__options__container--header "> Opcijas</div>
      <router-link :to="{ name: 'editProfilePage'}">
        <div class="page__content__data-container__sub-content__options__container__item">
          <span class="page__content__data-container__sub-content__options__container__item--label"> Redaktet profili </span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserOptionsComponent",
    props: ["WarmUp"],
    data() {
      return {

      }
    },
    methods:{
      setWarmUp(){
        this.$axios.post(`/api/profile/edit-warm-up`,
        {
            userId: this.$store.state.authUser.id,
            warmup: this.WarmUp
        })
            .then(response => {})
            .catch(e => {
              this.errors.push(e)
            })
      }
    },
    mounted(){

    }
  }
</script>

<style lang="scss" scoped>
  .page__content__data-container__sub-content__options {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: white;
    padding: 3rem 5rem 0 2rem;

    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: white;
      height: 100%;
      background: #373739;

      &--header {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        padding: 3rem 0 2rem 0;
        background: #373739;
      }

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: left;
        font-size: 18px;
        padding: 3rem 1rem 2rem 5rem;
        background: #373739;

        &--label {
          padding-right: 2rem;
          font-weight: bold;
          color: white;
        }

        &:hover {
          background: #979BF1;
        }
      }

      &__input-item {
        display: flex;
        flex-direction: row;
        justify-content: left;
        font-size: 18px;
        padding: 3rem 1rem 2rem 5rem;
        background: #373739;

        &--label {
          padding-right: 2rem;
          font-weight: bold;
          color: white;
        }

        &--input {
          color: black;
        }
      }
    }
  }
</style>