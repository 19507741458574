<template>
  <div class="page" >
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title" v-if="TeamData">{{TeamData.Title}}</h1>
      <h1 class="page__section__name-container--title" v-if="UnkUsersTeamData">{{UnkUsersTeamData.Title}}</h1>
    </div>
    <div class="page__content__navbar-container" v-bind:class="{slide : show}">
      <MenuComponent></MenuComponent>
      <div @click="show = !show" class="page__content__navbar-container--open-btn">
        <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
      </div>
    </div>
    <apply-team-mobile class="mobile-apply-team" v-if="TeamData || UnkUsersTeamData" :TeamData="TeamData" :UnkUsersTeamData="UnkUsersTeamData"></apply-team-mobile>
    <apply-team-pc class="pc-apply-team" v-if="TeamData || UnkUsersTeamData" :TeamData="TeamData" :UnkUsersTeamData="UnkUsersTeamData"></apply-team-pc>
    <div class="page__content" v-else>
      <div class="page__content__input">
        <h1 class="info-text" >Izveleties vienu komandu</h1>
        <div class="page__content__input__form__btn-item">
          <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="ShowApplyTeamSelect">
            Izveleties komandu
          </button>
        </div>
      </div>
    </div>
    <modal name="TeamSelectModal" :height="400" :clickToClose="false" >
      <SelectTeamModalComponent></SelectTeamModalComponent>
    </modal>
    <modal name="TeamCreateModal" height="auto" :scrollable="true" :clickToClose="false" >
      <CreateTeamModalComponent></CreateTeamModalComponent>
    </modal>
  </div>
</template>

<script>
  import SelectTeamModalComponent from '../../components/admin/ParticipantTableComponents/SelectTeamModalComponent.vue';
  import CreateTeamModalComponent from '../../components/admin/ParticipantTableComponents/CreateTeamModalComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";
  import applyTeamComponentMobile from '../../components/public/MobileComponentsVersions/ApplyTeamComponent';
  import applyTeamComponentPc from '../../components/public/PcComponentsVersions/ApplyTeamComponent';

  export default {
    name: "ApplyTeamModalComponent",
    data(){
      return {
        show: false,
        TeamData: null,
        UnkUsersTeamData: null,
      }
    },
    methods: {
      ShowApplyTeamSelect(){
        this.$modal.show('TeamSelectModal');
      },
    },
    mounted() {
      this.$modal.show('TeamSelectModal');

      this.$bus.$on("SelectTeamData", (Team)=>{
        this.TeamData=Team;
        this.$modal.show('ApplyTeamModal');
        console.log("geted real team  data: ",Team)
      });

      this.$bus.$on("OpenCreateTeam", response =>{
        this.$modal.show('TeamCreateModal');
        console.log('show create team')
      });

      this.$bus.$on("NonexistentTeamData", (Team)=>{
        this.UnkUsersTeamData=Team;
        this.$modal.hide('TeamCreateModal');
        console.log("geted external team data: ",Team)
      });
    },
    created() {
    },
    beforeDestroy () {
    },
    components: {
      SelectTeamModalComponent, CreateTeamModalComponent, MenuComponent,
      'apply-team-mobile' : applyTeamComponentMobile, 'apply-team-pc' : applyTeamComponentPc
    },
  };
</script>

<style lang="scss">
  .info-text{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6rem 6rem 12rem;
  }
  .mobile-apply-team{
    display: none;
  }

  .pc-apply-team{
    display: block;
  }

  @media (max-width: 1024px) {
    .mobile-apply-team{
      display:block;
    }
    .pc-apply-team{
      display:none;
    }
  }

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>