<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="ParticipantsData"
      @on-cell-click="onCellClick"
      :pagination-options="{
        enabled: true,
        perPage: 10,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
      </template>
    </vue-good-table>
  </div>
</template>

<script>

  export default {
    name: "ExternalClaimsTableComponent",
    props: ["ParticipantsData"],
    data(){
      return {
        columns: [
          {
            label: 'Dalibnieks',
            field: 'NameSurname',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Svars',
            field: 'Weight',
            type: "Number",
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Komanda',
            field: 'TeamTitle',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Treneris',
            field: 'Coach',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: 'Kategorija',
            field: 'Category',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
            }
          }
        ],
      }
    },
    methods: {},
    mounted() {},
    components: {},
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }
</style>