<template>
  <div v-if="show" class="header">
    <router-link :to="{name: 'homePage'}" class="logo">COMPETITION MANAGER</router-link>
    <nav class="nav">
      <ul>
        <li class="active">
          <router-link v-if="!$store.state.authUser || ($store.state.authUser.type !== 1 && $store.state.authUser.type !== 3 && $store.state.authUser.type !== 4)" :to="{ name: 'competitionsPage'}">Sacensības</router-link>
          <router-link v-if="$store.state.authUser && $store.state.authUser.type === 3" :to="{ name: 'Judge', params: { id: $store.state.authUser.id }}">Judge</router-link>
          <router-link v-if="$store.state.authUser && $store.state.authUser.type === 4" :to="{ name: 'CoachCompetitionsPage', params: { id: $store.state.authUser.id }}">Coach</router-link>
          <router-link v-if="$store.state.authUser && $store.state.authUser.type === 1" :to="{ name: 'SecretaryCompetitionsPage', params: { id: $store.state.authUser.id }}">Secretary</router-link>
        </li>
        <li>
          <router-link v-if="!$store.state.authUser"  :to="{ name: 'Competitions'}">Streams</router-link>
          <router-link v-if="$store.state.authUser && ($store.state.authUser.type != 1 && $store.state.authUser.type != 2)"  :to="{ name: 'Competitions'}">Streams</router-link>
          <router-link v-if="$store.state.authUser && ($store.state.authUser.type === 1 || $store.state.authUser.type === 2)" :to="{ name: 'dashboardPage'}">Dashboard</router-link>
        </li>
      </ul>
    </nav>

    <div class="actions">
      <a class="sign-link" v-if="!$store.state.authUser" href="#" @click.prevent="openLogin()">Login</a>
      <a class="sign-link" v-if="$store.state.authUser" href="#" @click.prevent="logout()">Logout</a>
      <router-link class="sign-link" v-if="$store.state.authUser" :to="{ name: 'profilePage'}">Profile </router-link>
    </div>
    <modal name="login"  :height="'500px'">
      <LoginComponent ></LoginComponent>
    </modal>
  </div>
</template>

<script>
  import LoginComponent from './Login.vue'
  export default {
  name: 'header-component',
  data () {
    return {
      posts: [],
      errors: [],
      show: true,
    }
  },
  methods: {
    openLogin() {
      this.$modal.show('login');
    },
    logout (){
      this.$axios.post( '/api/logout')
        .then( response => {
          this.$store.commit('SET_USER', null);
          this.$router.push( { name:"homePage" } );
        })
        .catch( (error) => {
        });
    },
    clear(e){
      this.user = {
        email: '',
        psw: ''
      }
    }
  },
  mounted() {
    this.$bus.$on("header-off", ()=>{
      this.show=false;
    })
    this.$socket.on('config-notifications', response => {
      if (this.$store.state.authUser) {
        this.$socket.emit('notification-data', {UserId: this.$store.state.authUser.id})
      }
    });
    this.$socket.on('notification', response => {
      alert(response)
    });

  },
  beforeDestroy () {
    this.$socket.removeListener('config-notifications');
    this.$socket.removeListener('notification');
    this.show=true;
  },
  components: {
    LoginComponent
  }
}
</script>

<style lang="scss" scoped>
  /*.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #273749;
    &__logo{
      display: flex;
      padding: 1rem 3rem;
      &--title{
        display: flex;
        font-size: 3.2rem;
        font-weight: bold;
        color: white;
        padding: 0 3rem;
      }
    }
    &__navbar{
      display: flex;
      padding: 1rem 3rem;
      &--item{
        display: flex;
        font-size: 2.2rem;
        color: white;
        padding: 1rem 3rem;
      }
    }
  }*/

/*.header{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #273749;
  color: #ffffff;
  &__section{
    display: flex;
    align-items: center;
    &--item{
      padding: 1rem 1.5rem;
      font-size: 2rem;
      margin-left: 0.5rem;
      &:hover{
        background-color: #555555;
        border-radius: 0.1rem;
      }
    }
  }
}*/

  @media (max-width: 700px) {
    .header {
      flex-wrap: wrap;
    }
    .nav {
      order: 2;
      background: #333;
      width: 100%;
      font-size: 1rem;
    }
    .menu {
      top: 9rem;
    }
  }

  @media (max-width: 400px) {
    .logo{
      font-size: 0.5rem !important;
    }
  }

  .header {
    background: #273749;
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }

  .logo {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 10px;
  }
  .nav {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      padding: 20px;
      display: inline-block;
    }
    .active {
      a {
        font-weight: bold;
        color: white;
        background: #807b77;
      }
    }
  }

  .dropdown {
    ul {
      display: none;
    }

  }
  .dropdown:hover
  {
    ul {
      list-style: none;
      display: block;
      li a{
        text-decoration: none;
        padding: 20px;
      }
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .sign-link {
    color: #CDD0D0;
    font-size: 1rem;
    margin-right: 4rem;
    text-transform: uppercase;
  }

</style>
