var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.CompetitionInfo.Title))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__data" }, [
        _c("div", { staticClass: "page__content__data__primary-info" }, [
          _c(
            "div",
            { staticClass: "page__content__data__primary-info__item" },
            [
              _c(
                "h2",
                {
                  staticClass: "page__content__data__primary-info__item--label"
                },
                [_vm._v("Datums")]
              ),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass:
                    "page__content__data__primary-info__item--content"
                },
                [
                  _vm._v(
                    _vm._s(
                      this.$moment(_vm.CompetitionInfo.Date).format(
                        "YYYY-MM-DD"
                      )
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__data__primary-info__item" },
            [
              _c(
                "h2",
                {
                  staticClass: "page__content__data__primary-info__item--label"
                },
                [_vm._v("Pieteikšanās līdz")]
              ),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass:
                    "page__content__data__primary-info__item--content"
                },
                [
                  _vm._v(
                    _vm._s(
                      this.$moment(_vm.CompetitionInfo.AppUntil).format(
                        "YYYY-MM-DD"
                      )
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__data__primary-info__item" },
            [
              _c(
                "h2",
                {
                  staticClass: "page__content__data__primary-info__item--label"
                },
                [_vm._v("Galvenais tiesnesis")]
              ),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass:
                    "page__content__data__primary-info__item--content"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.CompetitionInfo.JudgeName +
                        " " +
                        _vm.CompetitionInfo.JudgeSurname
                    )
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__data__invite" }, [
          _c("div", {
            staticClass: "page__content__data__invite--content notice",
            domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.InviteMessage) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__data__invite--apply" },
            [
              _c(
                "router-link",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    to: {
                      name: "applyTeamPage",
                      params: { cmId: _vm.$route.params.cmId }
                    }
                  }
                },
                [_vm._v("Pieteikties sacensībām")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__data__info" }, [
          _c(
            "div",
            {
              staticClass: "page__content__data__info__item",
              staticStyle: { width: "100%" }
            },
            [
              _c(
                "h2",
                { staticClass: "page__content__data__info__item--label" },
                [_vm._v("Programma")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page__content__data__info__item--content" },
                [
                  _c("h3", {
                    domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.Program) }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__data__info__item",
              staticStyle: { width: "100%" }
            },
            [
              _c(
                "h2",
                { staticClass: "page__content__data__info__item--label" },
                [_vm._v("Noteikumi")]
              ),
              _vm._v(" "),
              _c("h3", {
                staticClass: "page__content__data__info__item--content",
                domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.Rules) }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__data__info__item" }, [
            _c(
              "h2",
              { staticClass: "page__content__data__info__item--label" },
              [_vm._v("Apbalvošana")]
            ),
            _vm._v(" "),
            _c("h3", {
              staticClass: "page__content__data__info__item--content",
              domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.Prizes) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__data__info__item" }, [
            _c(
              "h2",
              { staticClass: "page__content__data__info__item--label" },
              [_vm._v("Ambulance")]
            ),
            _vm._v(" "),
            _c("h3", {
              staticClass: "page__content__data__info__item--content",
              domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.Ambulance) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__data__info__item" }, [
            _c(
              "h2",
              { staticClass: "page__content__data__info__item--label" },
              [_vm._v("Sadarbiba")]
            ),
            _vm._v(" "),
            _c("h3", {
              staticClass: "page__content__data__info__item--content",
              domProps: { innerHTML: _vm._s(_vm.CompetitionInfo.Cooperation) }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78ed4856", { render: render, staticRenderFns: staticRenderFns })
  }
}