<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Pult address: {{RemoteAddress}}</h1>
                <h1 class="page__section__name-container--title">Pult key: {{RemoteKey}}</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
                <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="remote-buttons">
                <div class="remote-buttons__top">
                  <button class="btn btn-warning remote-buttons__top--btn" @click="addRemoteRequest()">Add remote</button>
                </div>
                <div class="remote-buttons__bottom">
                  <button class="btn btn-danger remote-buttons__bottom--btn" @click="addRedPoint()">Add red point</button>
                  <button class="btn btn-primary remote-buttons__bottom--btn" @click="addBluePoint()">Add blue point</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuComponent from "../../components/common/MenuComponent";

    export default {
        name: "competitionRemoteControlsPage",
        data() {
            return{
                show: false,
                RemoteAddress: `12:34:56:78`,
                RemoteKey: ``,
                Red: 1,
                Blue: 2,
                Time: '00:00:00'
            }
        },
        methods: {
          addRemoteRequest(){
              this.$socket.emit('add-remote-request',this.RemoteAddress)
          },
          addRedPoint(){
            this.$socket.emit('add-point-remote',{
              RemoteKey: this.RemoteKey,
              ColorId: this.Red,
              TimeStamp: this.$moment().format("hh:mm:ss")
            })
          },
          addBluePoint(){
            this.$socket.emit('add-point-remote',{
              RemoteKey: this.RemoteKey,
              ColorId: this.Blue,
              TimeStamp: this.$moment().format("hh:mm:ss")
            })
          },
        },
        mounted() {
          this.$socket.on('config-remote',response =>{
            console.log('config-remote')
            this.$socket.emit('connect-remote',{RemoteAddress: this.RemoteAddress})
          })
          this.$socket.on('rewrite-key-for-remote',response =>{
              console.log('rewrite key-for-remote')
              this.RemoteKey = response.RemoteKey;
              this.Time = response.Time;
              console.log('Time: ',this.Time)
              this.$socket.emit('remote-key-rewritten',{RemoteAddress: this.RemoteAddress, RemoteKey: this.RemoteKey, CmRemoteId: response.CmRemoteId})
          })

          this.$socket.on('clear-remote-key',response => {
            console.log('clear-remote-key',response)
            console.log(response.EventName)
            this.RemoteKey = '';
            if (response.EventName!=''){
              this.$socket.emit(response.EventName,response.resp)
            }
          })
        },
        beforeDestroy () {
          this.$socket.removeListener('config-remote');
          this.$socket.removeListener('rewrite-key-for-remote');
          this.$socket.removeListener('rewrite-key-for-remote');
          this.$socket.removeListener('clear-remote-key');
        },
        components:{
            MenuComponent,
        },
    }
</script>

<style lang="scss" scoped>

    body {
        font-size: 14px;
    }

    * {
        box-sizing: border-box;
    }


    .remote-buttons{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      &__top{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        &--btn{
          padding-left:1rem;
          margin-right: 1rem;
          width:10rem;
        }
      }
      &__bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        &--btn{
          padding-left:1rem;
          margin-right: 1rem;
          width:30rem;
          height: 25rem;
          font-size: 3rem;
        }
      }
    }


</style>