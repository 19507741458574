<template>
  <div class="page">
    <!--<div class="page__section" v-if="ApplyData">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container&#45;&#45;title">Sveršana {{ApplyData.WeighingNr}} Nr</h1>
      </div>
    </div>-->
    <div class="page__content">
      <h1 class="page__content--title" v-if="TeamData">Sveršana</h1>

      <h3 class="page__content--title" v-if="TeamData">Komanda: {{TeamData.TeamTitle}}, Nr {{TeamData.Nr}}</h3>
      <br>
      <div class="vgt-global-search vgt-clearfix">
        <div class="vgt-global-search__input vgt-pull-left">
          <span class="input__icon"><div class="magnifying-glass"></div></span><input type="text" placeholder="Search Table" class="vgt-input vgt-pull-left" v-model="string" >
        </div>
      </div>
      <div class="vue-good-table" style=" height: 26rem; overflow-y: scroll; margin-top: -6px;">
        <table class="table table-bordered" style="font-size: 16px;">
          <thead>
          <tr>
            <th>Nr</th>
            <th>Komandas nosaukums</th>
            <th>Darbības</th>
          </tr>
          </thead>
          <draggable class="draggble-item table-content" v-model="TeamsData" :element="'tbody'" :move="getMoveParams" @update="changeNr">
            <tr v-for="(data,index) in TeamsData" v-if="!Search" :key="data.Nr" >
              <td>{{data.Nr}}</td>
              <td>{{data.TeamTitle}}</td>
              <td style="width: 45%;">
                <button class="table-content__btn btn btn-warning" v-on:click="nextWeighingApply">
                  Izlaist
                </button>
                <button class="table-content__btn btn btn-primary" v-on:click="approveWeighingApply(data.QueueId)" v-if="!data.Approved">
                  Apstiprināt
                </button>
                <button class="table-content__btn btn btn-danger" v-on:click="disapproveWeighingApply(data.QueueId)" v-if="data.Approved">
                  Atcelt
                </button>
              </td>
            </tr>
            <tr v-for="(data,index) in SearchResults" v-if="((Search)&&(index!=0))" >
              <td>{{data.Nr}}</td>
              <td>{{data.TeamTitle}}</td>
              <td>
                <button class="page__content__table--btn btn btn-warning" v-on:click="nextWeighingApply">
                  Izlaist
                </button>
                <button class="table-content__btn btn btn-primary" v-on:click="approveWeighingApply(data.QueueId)" v-if="!data.Approved">
                  Apstiprināt
                </button>
                <button class="table-content__btn btn btn-danger" v-on:click="disapproveWeighingApply(data.QueueId)" v-if="data.Approved">
                  Atcelt
                </button>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
      <div class="control-block" v-if="TeamData">
     <!--   <button class="control-block__btn btn btn-warning"
                v-on:click="nextWeighigApply">
          Izlaist komandu
        </button>
        <button class="control-block__btn btn btn-primary"
                v-on:click="approveWeighingApply">
          Nakoša komanda
        </button>-->
        <button class="control-block__btn btn btn-secondary"
                @click="$modal.hide('WeighingModal')">❌ Aizvert
        </button>
      </div>
      <div v-else class="page__content__input__form">
        <div class="page__content__input__form__alert">
          <h1>Sveršana beidzas</h1>
          <button class="page__content__input__form__alert--btn btn btn-secondary"
                  @click="$modal.hide('WeighingModal')">
            ❌ Aizvert
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: "WeighingQueueModalComponent",
    data() {
      return {
        TeamData: null,
        TeamsData: [],
        Search: false,
        SearchResults: [],
        string: '',
        DraggElement: {},
        DropElement: {}
      }
    },
    methods: {
      getMoveParams: function(evt) {
        console.log('Drag: ',JSON.stringify(evt.draggedContext.element),' Drop: ',JSON.stringify(evt.draggedContext.element))
        this.DraggElement = {
              Id: evt.draggedContext.element.QueueId,
              Nr: evt.relatedContext.element.Nr
            };
        this.DropElement = {
          Id: evt.relatedContext.element.QueueId,
          Nr: evt.draggedContext.element.Nr
        };
      },
      changeNr: function(){
        this.$socket.emit('change-weighing-queue-nr', {
          DraggElement: this.DraggElement,
          DropElement: this.DropElement,
          CmId: this.$route.params.cmId,
        })
        console.log("Edit Drag: ", this.DraggElement, "Drop: ", this.DropElement);
      },
      approveWeighingApply(Id) {
        this.$socket.emit('approve-weighing-team', {
          QueueId: Id,
          CompetitionId: this.$route.params.cmId
        });
      },
      disapproveWeighingApply(Id) {
        this.$socket.emit('disapprove-weighing-team', {
          QueueId: Id,
          CompetitionId: this.$route.params.cmId
        });
      },
      nextWeighingApply() {
        this.$socket.emit('skip-weighing-team', this.TeamData)
      },

      getData: function () {
        this.$axios.get(`/api/weighing-queue`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.TeamData = response.data.TeamData;
            console.log(response.data)
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.$axios.get(`/api/weighing-queue/list`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
                .then(response => {
                  this.TeamsData = response.data;
                  console.log(response.data)
                })
                .catch(e => {
                  this.errors.push(e)
                })
      },
    },
    watch: {
      string: function (val) {//todo search
        let SearchData = this.TeamsData
        console.log(val)
        if (val != "") {
          this.Search=true;

          this.SearchResults=[{}];

          console.log("eto SearchData: ",SearchData)

          SearchData.forEach((data) => {
            console.log("fight data: ",data)
            let HaveFight=false;
            let Found=false;

            this.SearchResults.forEach((ResultData)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",data.QueueId, " & ", ResultData.QueueId)
              if(data.QueueId==ResultData.QueueId){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
                console.log('this.SearchResults: ',this.SearchResults)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(data).forEach((key) => {
                if(key === 'TeamTitle' || key === 'Nr') {
                  let Param = data[key];
                  console.log("Fight param ", Param)
                  Param = "" + Param
                  console.log("val:", val)
                  let Search = Param.search(val)
                  console.log("Search ", Search)
                  if (Search != -1) {
                    console.log("pushim: ", data)
                    return Found = true;
                  }
                }
                console.log("neto: ", data)
              })
              if (Found==true) {
                this.SearchResults.push(data)
                console.log(this.SearchResults)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.SearchResults)
        }
      }
    },
    mounted() {
      this.getData();
      this.$socket.on('next-on-weighing', response => {
        response != null ? this.TeamData = response : this.TeamData = null
      })
      this.$socket.on('success', response => {
        if (response === true) {
          alert("Pieteikums nomainits!")
        } else {
          if (response != '') {
            alert(response)
          } else {
            alert("Something goes wrong! Check all fields on filled right")
          }
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('next-on-weighing', response => {
        console.log("response ", response)
        this.getData();
      });
    },
    beforeDestroy() {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('next-on-weighing');
    },
    components: {
      draggable
    },
  }
</script>


<style lang="scss" scoped>

  .page {
    background: #eeecf6;
    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #eeecf6;
        z-index: 0;
        padding: 0 7rem;
        &--title{
          padding-left: 4rem;
        }
    }
  }

  .weighing-claim {
    width: 100%;

    &__input-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 0;
      padding: 0 4rem 7rem 4rem;

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;

        &--label {
          text-align: right;
        }

        &--input {
        }
      }

      &__alert {
        padding-top: 5rem;

        &--btn {
          font-size: 1.8rem;
          width: 9rem;
        }
      }
    }

    &__buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-top: 2rem;

      &--btn {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
    }
  }
  .table-content {
    cursor: pointer;
    &__btn {
      font-size: 1.3rem;
      width: 9rem;
    }
  }

  .control-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;
    padding: 3rem 4rem 7rem 14rem;
    &__btn {
      font-size: 1.3rem;
      width: 15rem;
    }
  }

  th { 
    position: sticky; top: 0;
    border: 1px solid #ddd;
    background: #eeecf6;
  }

  /*  #coachs{}
    .slide{
      transform: translateX(0%);
      transition: 0.5s;
      z-index: 0;
    }*/
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>