var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.TeamsRings
        ? _c(
            "div",
            { staticClass: "container", staticStyle: { padding: "2rem" } },
            [
              _vm._l(_vm.TeamsRings, function(TeamRings, key) {
                return _vm.ListShow
                  ? _c(
                      "div",
                      { staticClass: "list-group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "list-group-item list-group-item-action team-title"
                          },
                          [_c("h2", [_vm._v(_vm._s(key))])]
                        ),
                        _vm._v(" "),
                        _vm._l(TeamRings, function(Ring, index) {
                          return index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "list-group-item list-group-item-action ring-list-item",
                                  on: {
                                    click: function($event) {
                                      _vm.showRingFights(Ring.Id, TeamRings[0])
                                    }
                                  }
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      " " +
                                        _vm._s(Ring.Title) +
                                        " Nr. " +
                                        _vm._s(Ring.Nr) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.ListShow
                ? _c(
                    "div",
                    { staticClass: "fights-list" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fights-list--back-row",
                          staticStyle: { padding: "2rem" },
                          on: {
                            click: function($event) {
                              _vm.showList()
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/backarrow.png",
                              width: "20rem"
                            }
                          }),
                          _vm._v(" Atpakal pie sarakstam")
                        ]
                      ),
                      _vm._v(" "),
                      _c("battles", {
                        staticStyle: { padding: "2rem" },
                        attrs: { BattlesData: _vm.Battles }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.TeamsRings
        ? _c("div", { staticClass: "empty-message" }, [
            _c("h2", [_vm._v("Nav cīņas")])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Komandu ciņas")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e9ebc54", { render: render, staticRenderFns: staticRenderFns })
  }
}