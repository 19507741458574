<template>
  <div class="ring-battles">
    <div class="vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon"><div class="magnifying-glass"></div></span>
        <input type="text"
          placeholder="Search Table"
          class="vgt-input vgt-pull-left"
          v-model="string" @keydown.enter="searchValue(string)">
      </div>
    </div>
      <div class="ring-battles__data-container">
        <table class="table table-bordered ring-battles__data-container--table">
        <thead>
        <tr>
          <th>Battle Nr</th>
          <th>Limenis</th>
          <th>Vecuma kategorija</th>
          <th>Svara kategorija</th>
          <th>Komanda</th>
          <th class="side-color--red">Sarkanais stūris</th>
          <th class="side-color--red"></th>
          <th>Rezultats</th>
          <th class="side-color--blue"></th>
          <th class="side-color--blue">Zilais stūris</th>
          <th>Komanda</th>
          <th>Laiks līdz sakšanai</th>
          <th>Winner</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!Search" v-for="data in FightsData" v-bind:class="data.Color" @click="openFight(data.Id)">
          <td>{{data.BattleNr}}</td>
          <td><span v-if="data.Lvl>1">1/</span>{{data.Lvl}}</td>
          <td>{{data.AgeCategory}}</td>
          <td>{{data.WeightCategory}}</td>
          <td>{{data.RedTeam}}</td>
          <td>{{data.RedNameSurname}}</td>
          <td class="side-color--red"></td>
          <td class="results-color">{{data.Result}}</td>
          <td class="side-color--blue"></td>
          <td>{{data.BlueNameSurname}}</td>
          <td>{{data.BlueTeam}}</td>
          <td>{{data.LeftTime}}</td>
          <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
          <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
          <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
        </tr>
        <tr v-if="((Search)&&(index!=0))" v-for="(data, index) in Fights" v-bind:class="data.Color" @click="openFight(data.Id)">
          <td>{{data.BattleNr}}</td>
          <td><span v-if="data.Lvl>1">1/</span>{{data.Lvl}}</td>
          <td>{{data.AgeCategory}}</td>
          <td>{{data.WeightCategory}}</td>
          <td>{{data.RedTeam}}</td>
          <td>{{data.RedNameSurname}}</td>
          <td class="side-color--red"></td>
          <td class="results-color">{{data.Result}}</td>
          <td class="side-color--blue"></td>
          <td>{{data.BlueNameSurname}}</td>
          <td>{{data.BlueTeam}}</td>
          <td>{{data.LeftTime}}</td>
          <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
          <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
          <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'SecretaryFightsComponent',
    props: ["FightsData"],
    data() {
      return {
        StringData: "",
        Fights: [],
        string: "",
        Search: false,
      };
    },
    methods: {
      openFight(fightId) {
        let RouterData = this.$router.resolve({
          name: 'secretaryFight',
          params: {fightId: fightId, id: this.$route.params.id}
        })
        window.open(RouterData.href, '_blank');
      },
      searchValue: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;

          this.Fights=[{}];

          console.log("eto Battles Data: ",this.FightsData)

          let SearchFightsData = this.FightsData;

          console.log("eto Battles search Data: ",SearchFightsData)

          SearchFightsData.forEach((FightData) => {
            console.log("fight data: ",FightData)
            let HaveFight=false;
            let Found=false;

            this.Fights.forEach((Fight)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",FightData.Id, " & ", Fight.Id)
              if(FightData.Id==Fight.Id){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log("Fight param ",FightParam)
                FightParam=""+FightParam
                console.log("val:", val)
                let Search=FightParam.search(val)
                console.log("Search ",Search)
                if (Search!=-1) {
                  console.log("pushim: ", FightData)
                  return Found=true;
                }
                console.log("neto: ", FightData)
              })
              if (Found==true) {
                this.Fights.push(FightData)
                console.log(this.Fights)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.Fights)
        }
      }
    },
/*    watch: {
      string: function (val) {
        console.log(val)
        if (val != "") {
          this.Search = true;

          this.Fights = [{}];

          let SearchFightsData = this.FightsData;

          SearchFightsData.forEach((FightData) => {
            console.log(FightData)
            let HaveFight = false;
            let Found = false;
            this.Fights.forEach((Fight) => {
              if (FightData.Id === Fight.Id) {
                return HaveFight = true;
              }
            })
            if (HaveFight == false) {
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log(FightParam)
                FightParam = "" + FightParam
                console.log(val)
                let Search = FightParam.search(val)
                console.log(Search)
                if (Search != -1) {
                  return Found = true;
                }
              })
              if (Found == true) {
                this.Fights.push(FightData)
              }
            }
          })
        } else {
          this.Search = false;
          console.log(this.Fights)
        }
      }
    },*/
    mounted() {
      this.Fights = this.FightsData;
    },
    components: {},
  };
</script>

<style lang="scss">
  th {
    text-align: center;
  }

  thead {
    padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;
  }

  tbody {
    text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;
  }

/*
  .red-background {
    height: 9rem;
    background-color: #FB8585;

  }

  .green-background {
    height: 9rem;
    background-color: #88ff7b;
  }
*/

 /* .ring-battles{
    &__data-container{
      display:flex;
      flex-direction: row;
      overflow: auto;
      &--qrcode{
        padding: 0 4rem;
      }
      &--table{
        font-size: 16px;
      }
    }
  }*/

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>