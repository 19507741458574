<template>
  <div class="vue-good-table">
    <div class="vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon"><div class="magnifying-glass"></div></span><input type="text" placeholder="Search Table" class="vgt-input vgt-pull-left" v-model="string" @keydown.enter="searchValue(string)">
      </div>
    </div>
    <table class="table table-bordered" style="font-size: 16px;">
      <thead>
      <tr>
        <th>Nr</th>
        <th>Vecuma kategorija</th>
        <th>Svara kategorija</th>
        <th>Limenis</th>
        <th>Komanda</th>
        <th class="side-color--red">Sarkanais stūris</th>
        <th class="side-color--red"></th>
        <th>Rezultats</th>
        <th class="side-color--blue"></th>
        <th class="side-color--blue">Zilais stūris</th>
        <th>Komanda</th>
        <th>Winner</th>
        <th>Laiks līdz sakšanai</th>
        <th>Darbibas</th>
      </tr>
      </thead>
      <draggable class="draggble-item" v-model="BattlesData" :element="'tbody'" :move="getMoveParams" @update="ChangeBattleNr">
      <tr v-for="(data,index) in BattlesData" :key="data.Nr"  v-if="!Search" v-bind:class="data.Color">
        <td>{{data.Nr}}</td>
        <td>{{data.AgeCategory}}</td>
        <td>{{data.WeightCategory}}</td>
        <td>{{data.CompetitionPart}}</td>
        <td @click="showEdit(data, 'RedTeam')" v-if="!((data.Id==Selected.Id) && (Selected.Row==='RedTeam'))">{{data.RedTeam}} &emsp; <span v-if="data.RedCmUserId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id==Selected.Id) && (Selected.Row==='RedTeam') " >
          <div v-if="!updatable" >
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
        <td @click="showEdit(data, 'RedNameSurname')" v-if="!((data.Id==Selected.Id) && (Selected.Row=='RedNameSurname'))">{{ data.RedNameSurname ? data.RedNameSurname : data.RedComesFrom }} &emsp; <span v-if="data.RedId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id==Selected.Id) && (Selected.Row=='RedNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td class="side-color--red"></td>
        <td class="results-color" v-on:click="ShowSelectWinnerModal(data)">{{data.Result}}</td>
        <td class="side-color--blue"></td>
        <td @click="showEdit(data, 'BlueNameSurname')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname'))">{{data.BlueNameSurname ? data.BlueNameSurname : data.BlueComesFrom }}  &emsp; <span  v-if="data.BlueId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td @click="showEdit(data, 'BlueTeam')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='BlueTeam'))">{{data.BlueTeam}} &emsp; <span  v-if="data.BlueCmUserId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='BlueTeam') " >
          <div v-if="!updatable" >
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
        <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
        <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
        <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
        <td>{{data.LeftTime}}</td>
        <td><button class="btn btn-primary" v-on:click="openBattle(data.Id)">Atvērt ciņu</button></td>
      </tr>
      <tr v-for="(data,index) in Fights" v-if="((Search)&&(index!=0))" v-bind:class="data.Color">
        <td>{{data.Nr}}</td>
        <td>{{data.AgeCategory}}</td>
        <td>{{data.WeightCategory}}</td>
        <td><span v-if="data.CompetitionPart>1">1/</span>{{data.CompetitionPart}}</td>
        <td @click="showEdit(data, 'RedTeam')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='RedTeam'))">{{data.RedTeam}} &emsp; <span v-if="data.RedCmUserId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='RedTeam') " >
          <div v-if="!updatable" >
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
        <td @click="showEdit(data, 'RedNameSurname')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='RedNameSurname'))">{{data.RedNameSurname}} &emsp; <span v-if="data.RedId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='RedNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td class="side-color--red"></td>
        <td class="results-color" v-on:click="ShowSelectWinnerModal(data)">{{data.Result}}</td>
        <td class="side-color--blue"></td>
        <td @click="showEdit(data, 'BlueNameSurname')" v-if="!((data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname'))">{{data.BlueNameSurname}} &emsp; <span v-if="data.BlueId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.Id===Selected.Id) && (Selected.Row==='BlueNameSurname') ">
          <label>Mainit: </label>
          <input v-model="Selected.Value.Field" @keyup.enter="updateData(Selected)">
        </td>
        <td @click="showEdit(data, 'BlueTeam')" v-if="!((data.BlueId===Selected.Id) && (Selected.Row==='BlueTeam'))">{{data.BlueTeam}} &emsp; <span v-if="data.BlueCmUserId" class="glyphicon glyphicon-pencil"></span></td>
        <td v-if="(data.BlueId===Selected.Id) && (Selected.Row==='BlueTeam') " >
          <div v-if="!updatable" >
            <label>Mainit: </label>
            <multiselect v-model="Selected.Value" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
            <button class="glyphicon glyphicon-ok" @click="updateData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
          <div v-if="updatable">
            <label>Atjaunot: </label>
            <input v-model="Selected.Value.Title">
            <button class="glyphicon glyphicon-ok" @click="editData(Selected)"></button>
            <button class="glyphicon glyphicon-pencil" @click="updatable=!updatable"></button>
          </div>
        </td>
        <td v-if="data.WinnerId==data.RedCmUserId">{{data.RedNameSurname}}</td>
        <td v-else-if="data.WinnerId==data.BlueCmUserId">{{data.BlueNameSurname}}</td>
        <td v-else-if="(data.WinnerId!=data.RedCmUserId)||(data.WinnerId!=data.BlueCmUserId)">-</td>
        <td>{{data.LeftTime}}</td>
        <td><button class="btn btn-primary" v-on:click="openBattle(data.Id)">Atvērt ciņu</button></td>
      </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import Multiselect from 'vue-multiselect';
  import DatePickerComponent from 'vuejs-datepicker';

  export default {
    name: 'BattlesListTableComponent',
    props: ["BattlesData"],
    data(){
      return {
        Selected: {
          Id: 0,
          Row: "",
          UserId: 0,
          Value: {
            Id:  0,
            Title: '',
            Field: ''
          },
          type: ""
        },
        updatable: false,
        Teams: [],
        Color: '',
        string: "",
        Search: false,
        Fights: [],
        DraggElement: {Id: 0, Nr: 0},
        DropElement: {Id: 0,Nr: 0},
        NotificationFighters: [],
        Fighters: [],
        CmId: 0,
      }
    },
    methods: {
      openBattle(q){
        this.$router.push('/secretary/'+ this.$store.state.authUser.id +'/fight/' + q)
      },
      getMoveParams: function(evt) {
        console.log('(evt.draggedContext.element.RedCmUserId) && (evt.draggedContext.element.BlueCmUserId): ',(evt.draggedContext.element.RedCmUserId !=null) && (evt.draggedContext.element.BlueCmUserId !=null))
        console.log((evt.draggedContext.element.RedCmUserId),' && ',(evt.draggedContext.element.BlueCmUserId))
        if ((evt.draggedContext.element.RedCmUserId !=null) && (evt.draggedContext.element.BlueCmUserId !=null)) {
          if ((evt.draggedContext.element.CompetitionPart === evt.relatedContext.element.CompetitionPart) && (evt.draggedContext.element.WinnerId === null) && (evt.relatedContext.element.WinnerId === null)) {
            this.DraggElement = {
              Id: evt.draggedContext.element.Id,
              Nr: evt.relatedContext.element.Nr
            };
            this.DropElement = {
              Id: evt.relatedContext.element.Id,
              Nr: evt.draggedContext.element.Nr
            };
            this.NotificationFighters = []
            this.Fighters = []
            this.NotificationFighters = [
              evt.draggedContext.element.RedId,
              evt.draggedContext.element.BlueId,
              evt.relatedContext.element.RedId,
              evt.relatedContext.element.BlueId
            ]
            this.Fighters = [evt.draggedContext.element.RedCmUserId,
              evt.draggedContext.element.BlueCmUserId,
              evt.relatedContext.element.RedCmUserId,
              evt.relatedContext.element.BlueCmUserId
            ]
          }
          return ((evt.draggedContext.element.CompetitionPart === evt.relatedContext.element.CompetitionPart) && (evt.draggedContext.element.WinnerId === null) && (evt.relatedContext.element.WinnerId === null));
        }else{
          alert('This battle don\'t have two fighters!')
        }
      },
      ChangeBattleNr: function(){
        if(this.Fighters.length!=0) {
          this.$socket.emit('edit-battle-nr', {
            DraggElement: this.DraggElement,
            DropElement: this.DropElement,
            NotificationFighters: this.NotificationFighters,
            Fighters: this.Fighters,
            CmId: this.$route.params.cmId,
            ChangeUser: this.$store.state.authUser.id
          })
          console.log("Edit Drag: ", this.DraggElement, "Drop: ", this.DropElement);
        }
      },
      showEdit(data,row){
        console.log(row)
        if ((row=='RedTeam') && (data.RedCmUserId)) {
          this.Selected = {
            Id: data.Id,
            Row: row,
            UserId: data.RedCmUserId,
            Value: {
              Id:  data.RedTeamId,
              Title: data[row]
            },
          }
        } else if ((row=='BlueTeam') && (data.BlueCmUserId)) {
          this.Selected = {
            Id: data.Id,
            Row: row,
            UserId: data.BlueCmUserId,
            Value: {
              Id:  data.BlueTeamId,
              Title: data[row]
            },
          }
        } else if ((row=='RedNameSurname') && (data.RedId)) {
          console.log("red")
          this.Selected = {
            Id: data.Id,
            Row: row,
            Value: {
              UserId: data.RedId,
              Field: data[row]
            }
          }
        } else if ((row=='BlueNameSurname') && (data.BlueId)) {
          this.Selected = {
            Id: data.Id,
            Row: row,
            Value: {
              UserId: data.BlueId,
              Field: data[row]
            }
          }
        }
        console.log(data.Id)
      },
      editData(rowData){
        this.Selected = {
          Id: 0,
          Row: "",
          UserId: 0,
          Value: {
            Id:  0,
            Title: '',
            Field: ''
          },
          type: ""
        },
        console.log("Will edit: ", rowData)
        if ((rowData.Row == 'RedTeam') || (rowData.Row == 'BlueTeam')) {
          rowData.type='edit'
          this.$socket.emit('edit-team-title', rowData);
        }
      },
      updateData(rowData){
        this.Selected = {
          Id: 0,
          Row: "",
          UserId: 0,
          Value: {
            Id:  0,
            Title: '',
            Field: ''
          },
          type: ""
        },
        console.log("Will update: ", rowData)
        if ((rowData.Row == 'RedNameSurname') || (rowData.Row == 'BlueNameSurname')) {
          console.log('should work')
          this.$socket.emit('edit-name-surname', rowData);
        }
        if ((rowData.Row == 'RedTeam') || (rowData.Row == 'BlueTeam')) {
          rowData.type='update'
          this.$socket.emit('edit-team-title', rowData);
        }
      },
      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },
      ShowSelectWinnerModal (BattleData){
        console.log('BattleData: ',BattleData)
        this.$bus.$emit("SelectWinnerData", BattleData);
      },
      searchValue: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;

          this.Fights=[{}];

          console.log("eto Battles Data: ",this.BattlesData)

          let SearchFightsData = this.BattlesData;

          console.log("eto Battles search Data: ",SearchFightsData)

          SearchFightsData.forEach((FightData) => {
            console.log("fight data: ",FightData)
            let HaveFight=false;
            let Found=false;

            this.Fights.forEach((Fight)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",FightData.Id, " & ", Fight.Id)
              if(FightData.Id==Fight.Id){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log("Fight param ",FightParam)
                FightParam=""+FightParam
                console.log("val:", val)
                let Search=FightParam.search(val)
                console.log("Search ",Search)
                if (Search!=-1) {
                  console.log("pushim: ", FightData)
                  return Found=true;
                }
                console.log("neto: ", FightData)
              })
              if (Found==true) {
                this.Fights.push(FightData)
                console.log(this.Fights)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.Fights)
        }
      }
    },
   /* watch: {
      string: function (val) {
        console.log(val)
        if (val) {
          this.Search=true;

          this.Fights=[{}];

          console.log("eto Battles Data: ",this.BattlesData)

          let SearchFightsData = this.BattlesData;

          console.log("eto Battles search Data: ",SearchFightsData)

          SearchFightsData.forEach((FightData) => {
            console.log("fight data: ",FightData)
            let HaveFight=false;
            let Found=false;

            this.Fights.forEach((Fight)=>{
              console.log("Proverjaem fights chtobi ne pisatj klonov.. .",FightData.Id, " & ", Fight.Id)
              if(FightData.Id==Fight.Id){
                return HaveFight=true;
                console.log("HaveFight dolzono bitj true: ", HaveFight)
              }
              console.log("HaveFight dolzono bitj false: ", HaveFight)
            })
            if (HaveFight==false) {
              console.log("HaveFight dolzono bitj false proverjaem boj: ", HaveFight)
              Object.keys(FightData).forEach((key) => {
                let FightParam = FightData[key];
                console.log("Fight param ",FightParam)
                FightParam=""+FightParam
                console.log("val:", val)
                let Search=FightParam.search(val)
                console.log("Search ",Search)
                if (Search!=-1) {
                  console.log("pushim: ", FightData)
                    return Found=true;
                }
                console.log("neto: ", FightData)
              })
              if (Found==true) {
                this.Fights.push(FightData)
                console.log(this.Fights)
              }
            }
          })
        } else {console.log("savsem neto neto: ", this.Search)
          this.Search=false;
          console.log(this.Fights)
        }
      }
    },*/
    mounted() {
   /*   this.Fights=this.BattlesData;*/
      console.log('Battle')
      this.$axios.get(`/api/get-teams/short-data`, {
      })
          .then(response => {
            this.Teams = response.data.Teams;
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    components: {
      DatePickerComponent, "multiselect": Multiselect,draggable
    },
  };
</script>

<style lang="scss">
  th{text-align: center;}
  thead{padding: .75em 1.5em .75em .75em;
    background: linear-gradient(#F4F5F8, #F1F3F6);
    color: #606266;}
  tbody{text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #DCDFE6;
    color: #606266;
    background-color: #ffffff;}
/*  .red-background {
    height: 9rem;
    background-color: #FB8585;

  }
  .green-background {
    height: 9rem;
    background-color: #88ff7b;
  }*/
  .draggble-item:hover{
    cursor: pointer;
  }
/*  .side-color{
    width: 2rem;
    &--red {
      background-color: #ff848a;
    }
    &--blue {
      background-color: #989dff;
    }
  }
  .results-color{
    background: #fafafa;
  }*/
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>