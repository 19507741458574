var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input" }, [
        _c("h1", { staticClass: "page__content__input--title" }, [
          _vm._v("Sacensiba: " + _vm._s(_vm.CompetitionTitle))
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__input--btn" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-info",
                attrs: { to: { name: "weightCategoriesPage" } }
              },
              [_vm._v("Izveidot svara categoriju +")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Discipline")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Disciplines,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "Select discipline",
                  "track-by": "Id",
                  "custom-label": _vm.DisciplineLabel
                },
                model: {
                  value: _vm.Discipline,
                  callback: function($$v) {
                    _vm.Discipline = $$v
                  },
                  expression: "Discipline"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Vecuma kategorija")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Categories,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "open-direction": "below",
                  placeholder: "Select category",
                  "track-by": "Id",
                  "custom-label": _vm.AgeCategoryLabel
                },
                model: {
                  value: _vm.AgeCategory,
                  callback: function($$v) {
                    _vm.AgeCategory = $$v
                  },
                  expression: "AgeCategory"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label col-lg-2 col-sm-4"
                },
                [_vm._v("Svara kategorijas")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.WeightCategories,
                  multiple: true,
                  "track-by": "Id",
                  "custom-label": _vm.WeightCategoryLabel
                },
                model: {
                  value: _vm.WeightCategoryIds,
                  callback: function($$v) {
                    _vm.WeightCategoryIds = $$v
                  },
                  expression: "WeightCategoryIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _c(
              "button",
              {
                staticClass:
                  "page__content__input__form__item--btn btn btn-primary",
                on: { click: _vm.AddCategory }
              },
              [_vm._v("Saglabat")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Svara kategorijas pievienosana")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e9aa69a4", { render: render, staticRenderFns: staticRenderFns })
  }
}