var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page score-registration col-xs-12 clear-pads" },
    [
      _c("div", {
        staticClass: "col-xs-6 clear-pads red-corner",
        class: [
          _vm.redKickActive == true ? "red-corner-darker" : "red-corner-bg"
        ]
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "col-xs-6 clear-pads blue-corner",
        class: [
          _vm.blueKickActive == true ? "blue-corner-darker" : "blue-corner-bg"
        ]
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "red-corner trigger",
          on: {
            click: function($event) {
              _vm.kick(1, _vm.fight.red_user_id)
            },
            mousedown: function($event) {
              _vm.animateRed(true)
            },
            mouseup: function($event) {
              _vm.animateRed(false)
            }
          }
        },
        [_c("div", { staticClass: "col-xs-6 clear-pads" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "blue-corner trigger",
          on: {
            click: function($event) {
              _vm.kick(2, _vm.fight.blue_user_id)
            },
            mousedown: function($event) {
              _vm.animateBlue(true)
            },
            mouseup: function($event) {
              _vm.animateBlue(false)
            }
          }
        },
        [_c("div", { staticClass: "col-xs-6 clear-pads" })]
      ),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { name: "confirm-ready-status", height: "auto" } },
        [_c("confirm-ready-status")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7b913b82", { render: render, staticRenderFns: staticRenderFns })
  }
}