<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="DisciplinesData"
      @on-cell-click="onCellClick"
      :search-options="{
          enabled: true,
          trigger: 'enter'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 30,
      }"
      styleClass="vgt-table striped bordered">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field=='Actions'">
          <button class="btn btn-primary" v-on:click="editDiscipline(props.row.Id)">Labot</button>
          <button class="btn btn-danger" v-on:click="deleteDiscipline(props.row.Id)">Delete</button>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  /*import disciplinePage from '../pages/disciplinePage.vue';*/
  export default {
    name: "DisciplinesTableComponent",
    props: ["DisciplinesData"],
    data(){
      return {
        columns: [
          {
            label: 'Nosaukums',
            field: 'Title',
          },
          {
            label: 'Saisinats',
            field: 'Short',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Ciņu Stils',
            field:  'Style',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Apraksts',
            field: 'Description',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
              label: 'Darbibas',
              field: 'Actions',
              thClass: 'text-center',
              tdClass: 'text-center',
          },
        ],
        CmId: null,
      }
    },
    methods: {
        /*onCellClick(params) {
            if (params.column.field != "Actions") {
               /!* this.CmId = params.row.Id;
                this.$router.push('/admin/discipline/' + this.CmId)*!/
            }
        },*/
        editDiscipline(q) {
            this.$router.push('/disciplines/discipline/edit/' + q)
        },
        deleteDiscipline(q) {
            this.$socket.emit('delete-discipline', {discipId: q})

        },
    },
    components: {
    },
  };
</script>

<style lang="scss">
  .text-center{
    text-align:center;
  }

</style>