var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", staticStyle: { padding: "0" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content", staticStyle: { padding: "0" } }, [
      _c(
        "div",
        {
          staticClass: "page__content__input",
          staticStyle: { "padding-top": "3rem" }
        },
        [
          _c("div", { staticClass: "page__content__input__form" }, [
            _c("div", {
              staticClass: "page__content__input__form__item",
              staticStyle: { padding: "0" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "page__content__input__form__btn-item" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-bordered table-hover text-center table-battle-results",
                  attrs: { align: "center" }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "2" } }),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _vm._v("Red: " + _vm._s(_vm.BattleData.RedNameSurname))
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _vm._v(
                          "Blue: " + _vm._s(_vm.BattleData.BlueNameSurname)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Scores")]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.RedScore,
                              expression: "RedScore"
                            }
                          ],
                          domProps: { value: _vm.RedScore },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.RedScore = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.BlueScore,
                              expression: "BlueScore"
                            }
                          ],
                          domProps: { value: _vm.BlueScore },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.BlueScore = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Result")]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _c("p", [_vm._v(_vm._s(_vm.RedScore))])
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _c("p", [_vm._v(_vm._s(_vm.BlueScore))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Winner")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "red-radio-label",
                          attrs: { colspan: "2" }
                        },
                        [
                          _c("label", { staticClass: "radio-container" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.winner,
                                  expression: "winner"
                                }
                              ],
                              attrs: { type: "radio", value: "red" },
                              domProps: { checked: _vm._q(_vm.winner, "red") },
                              on: {
                                change: function($event) {
                                  _vm.winner = "red"
                                }
                              }
                            }),
                            _vm._v("Red\n                  "),
                            _c("span", { staticClass: "red-checkmark" })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "blue-radio-label",
                          attrs: { colspan: "2" }
                        },
                        [
                          _c("label", { staticClass: "radio-container" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.winner,
                                  expression: "winner"
                                }
                              ],
                              attrs: { type: "radio", value: "blue" },
                              domProps: { checked: _vm._q(_vm.winner, "blue") },
                              on: {
                                change: function($event) {
                                  _vm.winner = "blue"
                                }
                              }
                            }),
                            _vm._v("Blue\n                  "),
                            _c("span", { staticClass: "blue-checkmark" })
                          ])
                        ]
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.BattleData.WinnerId
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        width: "100%",
                        "font-size": "2rem",
                        display: "inline-block"
                      }
                    },
                    [
                      _vm._v(
                        "\n            Winner is: " +
                          _vm._s(
                            _vm.BattleData.WinnerId ===
                              _vm.BattleData.RedCmUserId
                              ? _vm.BattleData.RedNameSurname
                              : _vm.BattleData.BlueNameSurname
                          ) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "1rem" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        _vm.SendResult(_vm.winner, _vm.RedScore, _vm.BlueScore)
                      }
                    }
                  },
                  [_vm._v("\n              Saglabat\n            ")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "1rem" } }, [
                _c(
                  "button",
                  {
                    staticClass: "page__content__input__form__btn-item--btn",
                    on: {
                      click: function($event) {
                        _vm.$modal.hide("SelectWinnerModal")
                      }
                    }
                  },
                  [_vm._v("\n              ❌ Aizvert\n            ")]
                )
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Izveleties uzvaretaju")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "atcive" }, [
        _c(
          "th",
          { staticStyle: { "text-align": "center" }, attrs: { colspan: "6" } },
          [_vm._v("Battle Results")]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b441344", { render: render, staticRenderFns: staticRenderFns })
  }
}