var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", staticStyle: { padding: "0" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content", staticStyle: { padding: "0" } }, [
      _c(
        "div",
        {
          staticClass: "page__content__input",
          staticStyle: { "padding-top": "3rem" }
        },
        [
          _c("div", { staticClass: "page__content__input__form" }, [
            _c(
              "div",
              {
                staticClass: "page__content__input__form__item",
                staticStyle: { padding: "0" }
              },
              [
                _c("multiselect", {
                  staticStyle: { "padding-bottom": "4rem" },
                  attrs: {
                    options: _vm.RemoteControls,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "open-direction": "below",
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.RemoteLabel
                  },
                  model: {
                    value: _vm.RemoteControl,
                    callback: function($$v) {
                      _vm.RemoteControl = $$v
                    },
                    expression: "RemoteControl"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__input__form__item",
                staticStyle: { padding: "0" }
              },
              [
                _c("multiselect", {
                  staticStyle: { "padding-bottom": "4rem" },
                  attrs: {
                    options: _vm.Judges,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "open-direction": "below",
                    placeholder: "",
                    "track-by": "Id",
                    "custom-label": _vm.JudgeLabel
                  },
                  model: {
                    value: _vm.Judge,
                    callback: function($$v) {
                      _vm.Judge = $$v
                    },
                    expression: "Judge"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "page__content__input__form__btn-item" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary page__content__input__form__btn-item--btn",
                  on: { click: _vm.addRemoteControl }
                },
                [_vm._v("Pievienot")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "page__content__input__form__btn-item--btn",
                  on: {
                    click: function($event) {
                      _vm.$modal.hide("addingRemoteModal")
                    }
                  }
                },
                [_vm._v("\n            ❌ Aizvert\n          ")]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section__name-container" }, [
      _c("h1", { staticClass: "page__section__name-container--title" }, [
        _vm._v("Pievienot pultu")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f2c3a7c4", { render: render, staticRenderFns: staticRenderFns })
  }
}