<template>
  <div id="app">
    <HeaderComponent/>
    <router-view></router-view>
  </div>
</template>

<script>
  import HeaderComponent from './components/HeadComponents/Header.vue'
  import css from './assets/scss/main.scss'
  export default {
  name: 'App',
  mounted(){
    if (this.$store.state.authUser) {
      this.$socket.on('config-notifications', response => {
        this.$socket.emit('notification-data', {UserId: this.$store.state.authUser.id})
      });
    }
  },
  components: {
    HeaderComponent
  },
}
</script>

<style lang="sass">

</style>
