var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page__section__name-container" }, [
        _vm.TeamData
          ? _c("h1", { staticClass: "page__section__name-container--title" }, [
              _vm._v(_vm._s(_vm.TeamData.Title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.UnkUsersTeamData
          ? _c("h1", { staticClass: "page__section__name-container--title" }, [
              _vm._v(_vm._s(_vm.UnkUsersTeamData.Title))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.TeamData || _vm.UnkUsersTeamData
        ? _c("apply-team-mobile", {
            staticClass: "mobile-apply-team",
            attrs: {
              TeamData: _vm.TeamData,
              UnkUsersTeamData: _vm.UnkUsersTeamData
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.TeamData || _vm.UnkUsersTeamData
        ? _c("apply-team-pc", {
            staticClass: "pc-apply-team",
            attrs: {
              TeamData: _vm.TeamData,
              UnkUsersTeamData: _vm.UnkUsersTeamData
            }
          })
        : _c("div", { staticClass: "page__content" }, [
            _c("div", { staticClass: "page__content__input" }, [
              _c("h1", { staticClass: "info-text" }, [
                _vm._v("Izveleties vienu komandu")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page__content__input__form__btn-item" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "page__content__input__form__btn-item--btn btn btn-primary",
                      on: { click: _vm.ShowApplyTeamSelect }
                    },
                    [_vm._v("\n          Izveleties komandu\n        ")]
                  )
                ]
              )
            ])
          ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "TeamSelectModal", height: 400, clickToClose: false }
        },
        [_c("SelectTeamModalComponent")],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "TeamCreateModal",
            height: "auto",
            scrollable: true,
            clickToClose: false
          }
        },
        [_c("CreateTeamModalComponent")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77f8362e", { render: render, staticRenderFns: staticRenderFns })
  }
}