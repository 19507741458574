var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__data-container" }, [
        _c("div", { staticClass: "page__content__data-container__header" }, [
          _c(
            "div",
            {
              staticClass:
                "page__content__data-container__header__info-container"
            },
            [
              _c("img", {
                staticClass: "page__content__data-container__header--avatar",
                attrs: { src: "/static/avatar.png", width: "20rem" }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "page__content__data-container__header__info-container--item"
                },
                [
                  _vm._v("Fights: "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.UserData.Fights) + "\n          ")
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "page__content__data-container__header__info-container--item"
                },
                [
                  _vm._v(" Uzvaras: "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.UserData.Victories) + " ")
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "page__content__data-container__header__info-container--item"
                },
                [
                  _vm._v(" Nokauti: "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.UserData.Knockouts) + " ")
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__data-container__sub-content" },
          [
            _c(
              "div",
              {
                staticClass:
                  "page__content__data-container__sub-content__primary-info-container"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container--header "
                  },
                  [_vm._v("\n            Mani dati\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("Vards,\n              Uzvards: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.UserData.Name) +
                            " " +
                            _vm._s(_vm.UserData.Surname) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("Dzimsanas\n              datums: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.UserData.DateOfBirth) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              E-pasts: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Email) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Parole: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info ",
                        attrs: { to: { name: "editProfilePasswordPage" } }
                      },
                      [_vm._v(" Mainit parole\n            ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("user-options", { attrs: { WarmUp: _vm.UserData.WarmUp } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "page__content__data-container__sub-content__primary-info-container"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container--header "
                  },
                  [_vm._v("\n            Kontaktinformācija\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("Valsts: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Country) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Adrese: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Adress) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container--header "
                  },
                  [_vm._v("\n            Personīga informācija\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Clubs: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Club) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Work: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Work) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Hobijs: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Hobby) + " ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data-container__sub-content__primary-info-container__item"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--label "
                      },
                      [_vm._v("\n              Pieredze: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "page__content__data-container__sub-content__primary-info-container__item--info "
                      },
                      [_vm._v(" " + _vm._s(_vm.UserData.Xp) + " ")]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.$store.state.authUser.type == 4 ||
            _vm.$store.state.authUser.type == 5
              ? _c("div", { staticClass: "list-grid" }, [
                  _vm.$store.state.authUser.type === 4
                    ? _c("div", { staticClass: "list-grid--header" }, [
                        _vm._v("\n            Komandas\n          ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.authUser.type === 4
                    ? _c("div", { staticClass: "list-grid__item" }, [
                        _c(
                          "div",
                          { staticClass: "list-grid__item__table" },
                          [
                            _vm._l(_vm.Teams, function(Team, index) {
                              return _c(
                                "div",
                                { staticClass: "list-grid__item__table__cell" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell--text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                 " +
                                          _vm._s(Team.Title) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "list-grid__item__table__cell__btn-container--reject-btn",
                                          on: {
                                            click: function($event) {
                                              _vm.deleteTeam(Team.Id)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Dzest\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.Teams.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "list-grid__item__table__empty-table-msg"
                                  },
                                  [
                                    _c("div", {}, [
                                      _vm._v(
                                        "\n                  Komandas nav\n                "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-grid--header" }, [
                    _vm._v("\n            Pieteikumi sacensibam\n          ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-grid__item" }, [
                    _c(
                      "div",
                      { staticClass: "list-grid__item__table" },
                      [
                        _vm._l(_vm.ClaimsData, function(
                          CompetitionUserClaime,
                          index
                        ) {
                          return _c(
                            "div",
                            { staticClass: "list-grid__item__table__cell" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell--text"
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(CompetitionUserClaime.Name) +
                                        " " +
                                        _vm._s(CompetitionUserClaime.Surname) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        CompetitionUserClaime.CompetitionTitle
                                      ) +
                                      "   " +
                                      _vm._s(
                                        CompetitionUserClaime.CompetitionDate
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell__btn-container"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--reject-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.deleteCmUser(
                                            CompetitionUserClaime.CmUserId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Atteikt\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.ClaimsData.length === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "list-grid__item__table__empty-table-msg"
                              },
                              [
                                _c("div", {}, [
                                  _vm._v(
                                    "\n                  Pieteikumu nav\n                "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Mans profils")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-56207c15", { render: render, staticRenderFns: staticRenderFns })
  }
}