var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__data" },
          [
            _c(
              "H1",
              {
                staticClass: "page__content__data--header",
                staticStyle: { color: "black" }
              },
              [_vm._v(_vm._s(_vm.CompetitionTitle))]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
              [
                _vm._l(_vm.WeightCategoryFighters, function(
                  WeightCategory,
                  key,
                  index
                ) {
                  return index == 0
                    ? _c(
                        "li",
                        {
                          staticClass: "active",
                          on: {
                            click: function($event) {
                              _vm.getparticipantcount(key)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "tab",
                                href: "#" + index,
                                role: "tab"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s((_vm.Category = key)) +
                                  " kg (" +
                                  _vm._s(WeightCategory.Fighters.length) +
                                  ") "
                              ),
                              WeightCategory.TossUpStatus
                                ? _c("img", {
                                    attrs: {
                                      src: "/static/green-tick.png",
                                      alt: "",
                                      width: "40",
                                      height: "30"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !WeightCategory.TossUpStatus
                                ? _c("img", {
                                    attrs: {
                                      src: "/static/cross.png",
                                      alt: "",
                                      width: "40",
                                      height: "30"
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.WeightCategoryFighters, function(
                  WeightCategory,
                  key,
                  index
                ) {
                  return index != 0
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function($event) {
                              _vm.getparticipantcount(key)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "tab",
                                href: "#" + index,
                                role: "tab"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(key) +
                                  " kg (" +
                                  _vm._s(WeightCategory.Fighters.length) +
                                  ") "
                              ),
                              WeightCategory.TossUpStatus
                                ? _c("img", {
                                    attrs: {
                                      src: "/static/green-tick.png",
                                      alt: "",
                                      width: "40",
                                      height: "30"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !WeightCategory.TossUpStatus
                                ? _c("img", {
                                    attrs: {
                                      src: "/static/cross.png",
                                      alt: "",
                                      width: "40",
                                      height: "30"
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _vm._l(_vm.WeightCategoryFighters, function(
                  WeightCategory,
                  key,
                  index
                ) {
                  return index == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane active",
                          attrs: { id: index, role: "tabpanel" }
                        },
                        [
                          _c("participants-table", {
                            attrs: {
                              ParticipantsLozesanaiData:
                                WeightCategory.Fighters,
                              AlgorithmId: _vm.AlgorithmId
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.WeightCategoryFighters, function(
                  WeightCategory,
                  key,
                  index
                ) {
                  return index != 0
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane",
                          attrs: { id: index, role: "tabpanel" }
                        },
                        [
                          _c("participants-table", {
                            attrs: {
                              ParticipantsLozesanaiData:
                                WeightCategory.Fighters,
                              AlgorithmId: _vm.AlgorithmId
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { "text-align": "right" } }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { width: "25rem" },
                  on: {
                    click: function($event) {
                      _vm.generateBattles()
                    }
                  }
                },
                [_vm._v("Ģenerēt cīnas")]
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "ParticipantCategoryModal", width: 900, height: 400 }
        },
        [
          _c("ParticipantCategoryModalComponent", {
            attrs: { ParticipantData: _vm.ParticipantData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Lozešana")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3466b1b2", { render: render, staticRenderFns: staticRenderFns })
  }
}