var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__content" }, [
      _vm.TeamData
        ? _c("h1", { staticClass: "page__content--title" }, [
            _vm._v("Sveršana")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.TeamData
        ? _c("h3", { staticClass: "page__content--title" }, [
            _vm._v(
              "Komanda: " +
                _vm._s(_vm.TeamData.TeamTitle) +
                ", Nr " +
                _vm._s(_vm.TeamData.Nr)
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
        _c("div", { staticClass: "vgt-global-search__input vgt-pull-left" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.string,
                expression: "string"
              }
            ],
            staticClass: "vgt-input vgt-pull-left",
            attrs: { type: "text", placeholder: "Search Table" },
            domProps: { value: _vm.string },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.string = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vue-good-table",
          staticStyle: {
            height: "26rem",
            "overflow-y": "scroll",
            "margin-top": "-6px"
          }
        },
        [
          _c(
            "table",
            {
              staticClass: "table table-bordered",
              staticStyle: { "font-size": "16px" }
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  staticClass: "draggble-item table-content",
                  attrs: { element: "tbody", move: _vm.getMoveParams },
                  on: { update: _vm.changeNr },
                  model: {
                    value: _vm.TeamsData,
                    callback: function($$v) {
                      _vm.TeamsData = $$v
                    },
                    expression: "TeamsData"
                  }
                },
                [
                  _vm._l(_vm.TeamsData, function(data, index) {
                    return !_vm.Search
                      ? _c("tr", { key: data.Nr }, [
                          _c("td", [_vm._v(_vm._s(data.Nr))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(data.TeamTitle))]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { width: "45%" } }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "table-content__btn btn btn-warning",
                                on: { click: _vm.nextWeighingApply }
                              },
                              [
                                _vm._v(
                                  "\n                Izlaist\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !data.Approved
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "table-content__btn btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        _vm.approveWeighingApply(data.QueueId)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Apstiprināt\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            data.Approved
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "table-content__btn btn btn-danger",
                                    on: {
                                      click: function($event) {
                                        _vm.disapproveWeighingApply(
                                          data.QueueId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Atcelt\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.SearchResults, function(data, index) {
                    return _vm.Search && index != 0
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(data.Nr))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(data.TeamTitle))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "page__content__table--btn btn btn-warning",
                                on: { click: _vm.nextWeighingApply }
                              },
                              [
                                _vm._v(
                                  "\n                Izlaist\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !data.Approved
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "table-content__btn btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        _vm.approveWeighingApply(data.QueueId)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Apstiprināt\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            data.Approved
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "table-content__btn btn btn-danger",
                                    on: {
                                      click: function($event) {
                                        _vm.disapproveWeighingApply(
                                          data.QueueId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Atcelt\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.TeamData
        ? _c("div", { staticClass: "control-block" }, [
            _c(
              "button",
              {
                staticClass: "control-block__btn btn btn-secondary",
                on: {
                  click: function($event) {
                    _vm.$modal.hide("WeighingModal")
                  }
                }
              },
              [_vm._v("❌ Aizvert\n      ")]
            )
          ])
        : _c("div", { staticClass: "page__content__input__form" }, [
            _c("div", { staticClass: "page__content__input__form__alert" }, [
              _c("h1", [_vm._v("Sveršana beidzas")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "page__content__input__form__alert--btn btn btn-secondary",
                  on: {
                    click: function($event) {
                      _vm.$modal.hide("WeighingModal")
                    }
                  }
                },
                [_vm._v("\n          ❌ Aizvert\n        ")]
              )
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input__icon" }, [
      _c("div", { staticClass: "magnifying-glass" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komandas nosaukums")]),
        _vm._v(" "),
        _c("th", [_vm._v("Darbības")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79e3e720", { render: render, staticRenderFns: staticRenderFns })
  }
}