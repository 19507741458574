var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "graph" }, [
    _c(
      "div",
      { staticClass: "competition" },
      _vm._l(_vm.BattlesGraphData, function(CompetitionPart, CompIndex) {
        return _c(
          "div",
          { staticClass: "competition__part" },
          _vm._l(CompetitionPart, function(Battle, BattleIndex) {
            return _c(
              "div",
              { staticClass: "competition__part__battle-block" },
              [
                !(CompIndex === 0)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "competition__part__battle-block__connect--first"
                      },
                      [_vm._m(0, true)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "competition__part__battle-block__fighters" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "competition__part__battle-block__fighters_red"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "competition__part__battle-block__fighters_red--name"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(Battle.RedNameSurname) +
                                " (" +
                                _vm._s(Battle.RedCmNr) +
                                ")\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "competition__part__battle-block__fighters_red__result"
                          },
                          [
                            Battle.CompetitionPart == 1 &&
                            Battle.WinnerId == Battle.RedCmUserId &&
                            Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--first"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.RedResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : Battle.CompetitionPart == 1 &&
                                Battle.WinnerId != Battle.RedCmUserId &&
                                Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--second"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.RedResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : Battle.CompetitionPart != 1 &&
                                Battle.WinnerId == Battle.RedCmUserId &&
                                Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--winner"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.RedResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--dif"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.RedResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "competition__part__battle-block__fighters_blue"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "competition__part__battle-block__fighters_blue--name"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(Battle.BlueNameSurname) +
                                " (" +
                                _vm._s(Battle.BlueCmNr) +
                                ")\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "competition__part__battle-block__fighters_blue__result"
                          },
                          [
                            Battle.CompetitionPart == 1 &&
                            Battle.WinnerId == Battle.BlueCmUserId &&
                            Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--first"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.BlueResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : Battle.CompetitionPart == 1 &&
                                Battle.WinnerId != Battle.BlueCmUserId &&
                                Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--second"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.BlueResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : Battle.CompetitionPart != 1 &&
                                Battle.WinnerId == Battle.BlueCmUserId &&
                                Battle.WinnerId != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--winner"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.BlueResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "competition__part__battle-block__fighters_red__result--dif"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(Battle.BlueResult) +
                                        "\n              "
                                    )
                                  ]
                                )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                !(CompetitionPart.length === 1)
                  ? _c(
                      "div",
                      {
                        staticClass: "competition__part__battle-block__connect"
                      },
                      [
                        !(BattleIndex & 1)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "competition__part__battle-block__connect--end"
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "competition__part__battle-block__connect--top",
                                  class: {
                                    "top-padding":
                                      _vm.BattlesGraphData.length > 3 &&
                                      (Battle.CompetitionPart == 2 ||
                                        Battle.CompetitionPart == 4)
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        BattleIndex & 1
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "competition__part__battle-block_connect"
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "competition__part__battle-block__connect--bottom",
                                  class: {
                                    "bottom-padding":
                                      _vm.BattlesGraphData.length > 3 &&
                                      (Battle.CompetitionPart == 2 ||
                                        Battle.CompetitionPart == 4)
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            )
          })
        )
      })
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "competition__part__battle-block__connect" },
      [
        _c("div", {
          staticClass:
            "competition__part__battle-block__connect--middle_connection"
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fa88016c", { render: render, staticRenderFns: staticRenderFns })
  }
}