<template>
  <div class="page">
    <div class="page__section">
      <div class="page__section__name-container">
        <h1 class="page__section__name-container--title">Age Category</h1>
      </div>
      <div class="page__section__sort">
        <span class="page__section__sort--item" >
          <a :href="'/competition/category/discipline/'+$route.params.cmId+'/'+$route.params.discipId">{{ Disciplines[$route.params.discipId] }}</a>
        </span>
        <select class="page__section__sort--select" v-model="SelectedAgeCategory" v-on:change="getSelected">
          <option v-for="(AgeCategory, key) in AgeCategories" :value="AgeCategory" selected="selected">{{ key }}</option>
        </select>
      </div>
      <div class="page__section__team-sort">
        <a style="padding-left: 1rem; padding-right: 1rem; cursor: pointer;" @click="clearTeamSelect">Komanda:</a>
        <select class="page__section__team-sort--select" v-model="SelectedTeam" v-on:change="getSelected">
          <option v-for="team in Teams" :value="team.Id" selected="selected">{{ team.Title }}</option>
        </select>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
          <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__data">
        <div class="emptycategory" v-if="(HaveBattles==true) && (Object.keys(Fighters).length===0)">
          <h1 >Šeit pagaidam nav ne viena ciņa</h1>
        </div>

          <div v-for="(WeightCategory, index) in Fighters" @drop="dropFighter()">
              <a v-if="WeightCategory!=''" class="page__content__data--header" v-on:click="some()" :href="'/competition/category/weight/'+$route.params.cmId+'/'+$route.params.discipId+'/'+SelectedAgeCategory+'/'+WeightCategory[0].WeightCategoryId">{{ WeightCategory[0].WeightCategoryTitle }} Kg</a>

              <AdminNavigationInCategoryComponent v-if="$store.state.authUser && ($store.state.authUser.type === 1) && (WeightCategory!='')" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]" :ParticipantsLozesanai="FightersLozesanai[index]"></AdminNavigationInCategoryComponent>
              <PublicNavigationInCategoryComponent v-if="(!$store.state.authUser) && (WeightCategory!='')" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
              <PublicNavigationInCategoryComponent v-if="$store.state.authUser && !($store.state.authUser.type === 1) && (WeightCategory!='')" :FightersData="WeightCategory" :BattlesData="BattlesInWeightCategory[index]" :BattlesGraph="BattlesForGraph[index]"></PublicNavigationInCategoryComponent>
          </div>
      </div>
    </div>
    <modal name="ParticipantCategoryModal" :width="900" :height="400">
      <ParticipantCategoryModalComponent :ParticipantData="ParticipantData"></ParticipantCategoryModalComponent>
    </modal>
    <modal name="AddCategoriesModal" :scrollable="true" width="100%" :height="'auto'">
      <AddCategoryModalComponent></AddCategoryModalComponent>
    </modal>
  </div>
</template>

<script>

  import AdminNavigationInCategoryComponent from '../../components/admin/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';
  import AddCategoryModalComponent from '../../components/admin/ParticipantTableComponents/AddCategoryModalComponent.vue';
  import PublicNavigationInCategoryComponent from '../../components/public/BattlesInCategoriesComponents/NavigationInCategoryComponent.vue';
  import MenuComponent from "../../components/common/MenuComponent";
  import ParticipantCategoryModalComponent from '../../components/admin/ParticipantTableComponents/ParticipantCategoryModalComponent.vue';
  import { Drag, Drop } from 'vue-drag-drop';

  export default {
    name: 'ageCategoryPage',
    data () {
      return {
        show: false,
        CompetitionTitle: "",
        Fighters: {},
        FightersLozesanai: {},
        AgeCategories: null,
        SelectedAgeCategory: null,
        Disciplines: [],
        BattlesInWeightCategory: {},
        HaveBattles: false,
        BattlesForGraph: {},
        ParticipantData:{},
        SelectedTeam: null,
        Teams: [],
      }
    },
    methods: {
      clearTeamSelect: function(){
        this.SelectedTeam = null;
        this.getSelected();
      },
      dropFighter(data){
        console.log('droped')
      },
      getTeams: function(){
        this.$axios.get(`/api/competition/age-category/get-teams`, {
          params: {
            cmId: this.$route.params.cmId,
            AgeCategoryId: this.$route.params.ageCategId,
          }
        })
          .then(response => {
            this.Teams = response.data.Teams;
            console.log(this.Teams)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      getSelected: function(){
        this.$router.replace({ name: "ageCategoryPage", params: {cmId: this.$route.params.cmId, discipId: this.$route.params.discipId, ageCategId: this.SelectedAgeCategory}});
        this.$axios.get(`/api/age`, {
          params: {
            cmId: this.$route.params.cmId,
            disciplineId: this.$route.params.discipId,
            ageCategId: this.SelectedAgeCategory,
            TeamId: this.SelectedTeam,
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.Fighters = response.data.Fighters;
            this.FightersLozesanai = response.data.FightersLozesanai;
            this.SelectedAgeCategory=response.data.SelectedCategID;
            this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
            this.BattlesForGraph=response.data.BattlesForGraph;
            this.HaveBattles = false;
            let WeightCategoryLength=0;
            Object.keys(this.BattlesInWeightCategory).forEach((key)=>{
              let WeightCategory = this.BattlesInWeightCategory[key];
              WeightCategoryLength+=WeightCategory.length;
              console.log("WeightCategoryLength: ",WeightCategoryLength)
            })
            if (WeightCategoryLength==0){
              console.log("End WeightCategoryLength: ",WeightCategoryLength)
              this.HaveBattles=true;
            }
            console.log(response.data)
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.getTeams()
      },
      getData: function(){
        this.$axios.get(`/api/age`, {
          params: {
            cmId: this.$route.params.cmId,
            disciplineId: this.$route.params.discipId,
            ageCategId: this.$route.params.ageCategId,
            TeamId: this.SelectedTeam,
          }
        })
          .then(response => {
            this.CompetitionTitle = response.data.CompetitionTitle;
            this.Fighters = response.data.Fighters;
            this.FightersLozesanai = response.data.FightersLozesanai;
            this.AgeCategories=response.data.AgeCategories;
            this.SelectedAgeCategory=response.data.SelectedCategID;
            this.Disciplines=response.data.Disciplines;
            this.BattlesInWeightCategory=response.data.BattlesInWeightCategory;
            this.BattlesForGraph=response.data.BattlesForGraph;
            let WeightCategoryLength=0;
            Object.keys(this.BattlesInWeightCategory).forEach((key)=>{
              let WeightCategory = this.BattlesInWeightCategory[key];
              WeightCategoryLength+=WeightCategory.length;
              console.log("WeightCategoryLength: ",WeightCategoryLength)

            })
            if (WeightCategoryLength==0){
              console.log("End WeightCategoryLength: ",WeightCategoryLength)
              this.HaveBattles=true;
            }
            console.log(this.AgeCategories);
            console.log(this.Disciplines);
            console.log(response.data)
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.getTeams()
      }
    },
    mounted () {
      this.getData();
      this.$socket.on('refresh-users-data', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-cmusers-data', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-battles-list', response => {
        this.getSelected();
      });
      this.$socket.on('refresh-tossup-data', response => {
        this.getSelected();
      });
      this.$bus.$on("CmUserData", (data)=>{
        this.ParticipantData=data;
        console.log("geted data: ",data)
      });
    },
    beforeDestroy () {
        this.$socket.removeListener('refresh-users-data');
        this.$socket.removeListener('refresh-cmusers-data');
        this.$socket.removeListener('refresh-battles-list');
        this.$socket.removeListener('refresh-tossup-data');
    },
    components: {
      MenuComponent,
      AdminNavigationInCategoryComponent,
      PublicNavigationInCategoryComponent,
      ParticipantCategoryModalComponent,
      AddCategoryModalComponent,
      'drag': Drag, 'drop': Drop
    }
  }
</script>


<style lang="scss" scoped>
  .emptycategory{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
  }

</style>