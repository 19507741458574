<template>
  <div v-if="fight" class="fight-info" >
    <div class="fight-info__fighter fight-info__fighter--red">
      <h1 class="fight-info__fighter__full-name">{{ fight.red_name }} {{ fight.red_surname }}</h1>
      <h2 class="fight-info__fighter__club">Club / Nationality: {{ fight.red_club }}</h2>
      <img class="fight-info__fighter__glove" src="/static/red.png" align="right" height="200px"/>
    </div>
    <div v-if="fight" class="fight-info__fight-description">
      <div class="fight-info__fight-description__fight-main-info">
        <h4>Weight Category: {{ fight.weight_category }} KG</h4>
        <h4>Ring: {{ fight.ring }}</h4>
        <h4>Ring Nr: {{ fight.ring_nr }}</h4>
      </div>
      <div class="fight-info__fight-description__timer">
        <timer v-if="fight.roundDetails" :data="fight.roundDetails" :lvl="fight.lvl"></timer>
      </div>
    </div>
    <div v-if="fight" class="fight-info__fighter fight-info__fighter--blue">
      <h1 class="fight-info__fighter__full-name">{{ fight.blue_name }} {{ fight.blue_surname }}</h1>
      <h2 class="fight-info__fighter__club">Club / Nationality: {{ fight.blue_club }}</h2>
      <img class="fight-info__fighter__glove" src="/static/blue.png" align="left" height="200px"/>
    </div>
  </div>
</template>

<script>
  import Timer from './Timer.vue'
  export default {
    name: "FightInfo",
    props: ["fight"],
    components: {
      Timer
    }
  }
</script>

<style lang="scss" scoped>

</style>