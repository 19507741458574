<template>
  <div class="page">
    <div class="page__section">
    <div class="page__section__name-container">
      <h1 class="page__section__name-container--title">Pieteikuma labošana</h1>
    </div>
  </div>
    <div class="page__content">
      <div class="page__content__navbar-container" v-bind:class="{slide : show}">
        <MenuComponent></MenuComponent>
        <div @click="show = !show" class="page__content__navbar-container--open-btn">
          <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
        </div>
      </div>
      <div class="page__content__input">
        <br>
     <!--   <div class="page__content__input__form__item" >
          <label class="page__content__input__form__item&#45;&#45;label control-label " style="z-index:2;">Atrast dalibnieku</label>
          <multiselect v-model="ApplyData.User" :options="Users" :close-on-select="true"
                       :clear-on-select="false"
                       placeholder="" track-by="Id" :custom-label="NameSurname" ></multiselect>
          <button @click="clearUser()">X</button>
        </div>-->
        <div class="page__content__input__form">
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Vards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.User.Name" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvards<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" type="text" v-model="ApplyData.User.Surname" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item" >
            <label class="page__content__input__form__item--label control-label " style="left:-2rem; ">Dzimsanas Datums<sup>*</sup></label>
            <div class="page__content__input__form__item--input" style="z-index: 1;">
              <DatePicker calendar-class="calendar" :monday-first="true" v-model="ApplyData.User.DateOfBirth" placeholder="00-00-00" :bootstrap-styling="true" @closed="setCategories()"></DatePicker>
            </div>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index:0;">Dzimums<sup>*</sup></label>
            <multiselect v-model="ApplyData.User.Gender" :options="Genders" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="GenderLabel" @input="setCategories()">
            </multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Weight<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Weight" @change="setCategories()">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index:0;">Kategorijas<sup>*</sup></label>
            <multiselect v-model="ApplyData.Categories" :options="Categories" :close-on-select="true"
                         :multiple="true" :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="CategoryLabel" ></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Cinas<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Fights" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Uzvaras<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Victory" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Nokdauni<sup>*</sup></label>
            <input class="page__content__input__form__item--input form-control" v-model="ApplyData.Knockouts" >
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " style="z-index:0;">Team<sup>*</sup></label>
            <multiselect v-model="ApplyData.Team" :options="Teams" :close-on-select="true"
                          :clear-on-select="false" :open-direction="'below'"
                         placeholder="" track-by="Id" :custom-label="TeamLabel" ></multiselect>
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label " type="checkbox" style="left:-2rem;">Samaksāts</label>
            <input class="page__content__input__form__item--input form-control" style="width:5rem; left:-2rem;" type="checkbox" v-model="ApplyData.Payed">
          </div>
          <div class="page__content__input__form__item">
            <label class="page__content__input__form__item--label control-label ">Maksājuma komentārs<sup>*</sup></label>
            <textarea class="page__content__input__form__item--input form-control" rows="5" v-model="ApplyData.PaymentComments" ></textarea>
          </div>
          <div class="page__content__input__form__btn-item">
            <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="editApplyUser(ApplyData)">Saglabat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import DatePicker from 'vuejs-datepicker';
  import MenuComponent from "../../components/common/MenuComponent";
  export default {
    name: "applyParticipantPage",
    data(){
      return {
        show: false,
        /*Users: [],*/
        ApplyData: {
          User: {Name: "", Surname: "", Gender: {}},
          Categories: {},
          DateOfBirth: new Date(),
          Weight: 0,
          Fights: 0,
          Victory: 0,
          Knockouts: 0,
          Team: this.TeamData,
          Payed: false,
          PaymentComments: '',
          CmId: this.$route.params.cmId ,
        },
        Genders: [{Id: 1, Title: "Sieviete"},
          {Id: 2, Title: "Virietis"},
        ],
        Categories: [],
        Teams: [],
        AllCategories: [],
      }
    },
    methods: {
      editApplyUser(Data){
        let DataApply = Object.assign({}, Data);
        this.DateOfBirth = new Date( Data.User.DateOfBirth);

        let Now = new Date
        let DateOfBirth = new Date(Data.User.DateOfBirth);
        let Age = ((Now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        if ((typeof Data.User.Name!='undefined') && (typeof Data.User.Surname!='undefined')){
          if (Data.User.Name.length < 3) {
            alert("Vārds ievadīts ne pareizi!")
            return;
          } else if (Data.User.Surname.length < 2) {
            alert("Uzvārds ievadīts ne pareizi!")
            return;
          }
        }
        if ((typeof Data.User.Name=='undefined') || (typeof Data.User.Surname=='undefined')){
          alert("Dalibnieka dati neievadīti!")
          return;
        }
        if ((Age<=5) || (!DateOfBirth)) {
          alert("Dzimšanas datums neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (!Data.User.Gender) {
          alert("Dzimums nav ievadīts!")
          return;
        }
        if ((Data.Weight.length<2) || (!Data.Weight)){
          alert("Svars neievadīts vai ievadīts nekorekti!")
          return;
        }
        if (Data.Categories.length==0) {
          alert("Kategorija neievadīta!")
          return;
        }
        if (!Data.Team) {
          alert("Komanda nav ievadīta!")
          return;
        }
        console.log(Data)

        DataApply.Categories=[]
        Data.Categories.forEach((ApplyCategory)=>{
          console.log(`Category: `,ApplyCategory)
          DataApply.Categories.push(ApplyCategory.Id)
        })
        DataApply.CmId = this.$route.params.cmId;
        DataApply.CmUserId = this.$route.params.applyId;
        DataApply.User.DateOfBirth = this.DateOfBirth.getFullYear()+
          "-"+
          parseInt(this.DateOfBirth.getMonth()+1)+
          "-"+
          this.DateOfBirth.getDate();
        this.$socket.emit('edit-cmuser', DataApply);
      },
      setCategories(){
        if (!this.ApplyData.User){
          this.ApplyData.User={Gender:{}}
        }
        let now = new Date;
        let DateOfBirth = new Date(this.ApplyData.User.DateOfBirth);
        let Age=((now.getTime() - DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);
        console.log("DateOfBirth: ",this.ApplyData.User.DateOfBirth)
        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          let CheckWeight=true;
          if(item.CategorySide==1){
            CheckWeight = (item.WeightMax<=this.ApplyData.Weight);
          }else{
            CheckWeight = (item.WeightMax>=this.ApplyData.Weight);
          }
          if (CheckWeight && (item.AgeMin<=Age) && (item.AgeMax>=Age) && (item.Gender==this.ApplyData.User.Gender.Id)) {
            this.Categories.push(item);
          }
        })
      },
      getData(){
        this.$axios.get(`/api/participant/editdata`,{
            params:{
                cmId: this.$route.params.cmId,
                teamId: this.$route.params.teamId,
                userId: this.$route.params.userId
            }
        })
            .then(response => {
                this.Users = response.data.Users;
                this.Categories = response.data.Categories;
                this.AllCategories = response.data.Categories;
                this.Teams = response.data.Teams;
                this.ApplyData = response.data.ApplyData;
                console.log(response)
                console.log(this.ApplyData.User)
                console.log(this.Categories)
                console.log(this.Users)
            })
            .catch(e => {
                this.errors.push(e)
            })
      },
      clearUser(){
        this.ApplyData.User={Gender:{}}
        this.ApplyData.Weight=0;
        this.ApplyData.Category={};
        this.setCategories()
      },
      NameSurname (option) {
          let now = new Date;
          let UserBirthDate=option.DateOfBirth;
          UserBirthDate = new Date(UserBirthDate);
          let Age=((now.getTime() - UserBirthDate) / (24 * 3600 * 365.25 * 1000) | 0);
          if ((typeof option.Name=='undefined')||(typeof option.Surname=='undefined')){return '-'}
        return `${option.Name} - ${option.Surname} (Age: ${Age})`
      },
      CategoryLabel (option) {
        if ((typeof option.AgeCategory=='undefined')&&(typeof option.WeightMax=='undefined')){return '-'}
        let Side = '';

        if(option.CategorySide==1){
          Side = ' + ';
        }else{
          Side = ' - ';
        }
        return `${option.DisciplineTitle} ${option.AgeCategory} ${Side}${option.WeightMax} (Age: ${option.AgeMin} - ${option.AgeMax})`
      },
      TeamLabel (option) {
        if ((typeof option.Title=='undefined')){return '-'}
        return `${option.Title}`
      },
      GenderLabel (option){
        if (typeof option.Title=='undefined'){return '-'}
        return `${option.Title}`
      },
    },
    mounted() {
      this.getData();
      this.$socket.on('success', response => {
        console.log(response)
        if (response.message) {
          alert(response.message)
        }
        if (response.status===true){
          this.$router.push({name: 'competitionClaimsPage', params: {cmId: this.$route.params.cmId}})
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('refresh-categories-data', response => {
        this.getData();
      });
    },
    beforeDestroy () {
      this.$socket.removeListener('success');
      this.$socket.removeListener('refresh-teams-data');
      this.$socket.removeListener('refresh-categories-data');
    },
    /*watch: {
      User: function(val) {
        console.log(val)
        if (val==null) {
          this.CompetitionUsersData.UserId = 0
        }else {
          this.CompetitionUsersData.UserId = val.Id
          this.CompetitionUsersData.Name = val.Name
          this.CompetitionUsersData.Surname = val.Surname
          val.DateOfBirth = new Date(val.DateOfBirth);
          this.DateOfBirth = val.DateOfBirth.getFullYear() +
            "-" +
            parseInt(val.DateOfBirth.getMonth() + 1) +
            "-" +
            val.DateOfBirth.getDate();
          this.CompetitionUsersData.Gender = val.Gender
        }
      },
      Weight: function(val) {
        console.log(val)
        let now = new Date;
        this.DateOfBirth = new Date(this.DateOfBirth);
        let Age=((now.getTime() - this.DateOfBirth) / (24 * 3600 * 365.25 * 1000) | 0);

        console.log(Age)
        this.Categories=[];
        this.AllCategories.forEach((item) => {
          if ((item.WeightMax>=val) && (item.AgeMin<=Age) && (item.AgeMax>=Age)) {
            this.Categories.push(item);
          }
        })
        console.log(this.Categories)
        this.CompetitionUsersData.Weight=val;
      },

      Category: function(val) {
        this.CompetitionUsersData.Category=val.Id
      },
      Team: function(val) {
        this.CompetitionUsersData.TeamId=val.Id
      }
    },*/
    components: {
        MenuComponent,
      DatePicker, "multiselect": Multiselect
    },
  }
</script>


<style lang="scss" scoped>

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>