<template>
  <div class="">
    <ul class="nav nav-tabs" role="tablist">
      <li class="active">
        <a id="participants-tab" data-toggle="tab" :href="'#participants-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tab">
          Pieteiktie dalībnieki</a>
      </li>
      <li >
        <a id="battles-list-tab" data-toggle="tab" :href="'#battleslist-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tab">
          Cīņu saraksts</a>
      </li>
      <li >
        <a  id="battles-graph-tab" data-toggle="tab" :href="'#battlesgraph-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tab">
          Cīņu grafiks</a>
      </li>
      <li >
        <a  id="lozesana-tab" data-toggle="tab" :href="'#lozesana-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tab">
          Lozesana</a>
      </li>
      <li >
        <a v-on:click="ShowAddCategory()" style="cursor: pointer;">
          Pievienot Kategoriju</a>
      </li>
    </ul>
    <div class="tab-content" style="overflow:auto;">
      <div class="tab-pane active" :id="'participants-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tabpanel">
        <ParticipantsTableComponent :FighterData="FightersData"></ParticipantsTableComponent>
      </div>
      <div class="tab-pane" :id="'battleslist-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tabpanel">
        <BattlesListTableComponent :BattlesListData="BattlesData"></BattlesListTableComponent>
      </div>
      <div class="tab-pane" :id="'battlesgraph-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tabpanel">
        <BattlesGraphComponent :BattlesGraphData="BattlesGraph"></BattlesGraphComponent>
      </div>
      <div class="tab-pane" :id="'lozesana-'+FightersData[0].AgeCategId+FightersData[0].WeightCategoryId" role="tabpanel">
        <LozesanaTableComponent :ParticipantsLozesanaiData="ParticipantsLozesanai"></LozesanaTableComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import ParticipantsTableComponent from './ParticipantsTableComponent.vue';
  import BattlesListTableComponent from './BattlesListTableComponent.vue';
  import BattlesGraphComponent from './BattlesGraphComponent.vue';
  import LozesanaTableComponent from "./LozesanaTableComponent";

  export default {
    name: "NavigationInCategoryComponent",
      props: ["FightersData", "BattlesData", "BattlesGraph", "ParticipantsLozesanai"],
      data() {
        return {}
      },
    methods:{
      ShowAddCategory(){
        this.$modal.show('AddCategoriesModal');
      },
    },
    mounted (){

    },
    components: {
      LozesanaTableComponent,
      ParticipantsTableComponent, BattlesListTableComponent, BattlesGraphComponent
    }
  }
</script>

<style scoped>

</style>