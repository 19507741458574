var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.RingTitle))
        ])
      ]),
      _vm._v(" "),
      _vm.$store.state.authUser &&
      _vm.$store.state.authUser.type === 1 &&
      !_vm.EditMode
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              staticStyle: { "margin-left": "5rem", "margin-bottom": "3rem" },
              on: {
                click: function($event) {
                  _vm.EditMode = !_vm.EditMode
                }
              }
            },
            [_vm._v("Ieslēgt labošanu")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser &&
      _vm.$store.state.authUser.type === 1 &&
      _vm.EditMode
        ? _c(
            "button",
            {
              staticClass: "btn btn-danger",
              staticStyle: { "margin-left": "5rem", "margin-bottom": "3rem" },
              on: {
                click: function($event) {
                  _vm.EditMode = !_vm.EditMode
                }
              }
            },
            [_vm._v("Izslēgt labošanu")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
        ? _c(
            "button",
            {
              staticClass: "btn btn-warning",
              staticStyle: { "margin-left": "5rem", "margin-bottom": "3rem" },
              on: {
                click: function($event) {
                  _vm.rewriteBattleNrs()
                }
              }
            },
            [_vm._v("Pārrakstīt numurus")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          staticStyle: { "margin-left": "5rem", "margin-bottom": "3rem" },
          attrs: { type: "button" },
          on: { click: _vm.printBattles }
        },
        [_vm._v("\n        Izdrukāt sarakstu\n      ")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content__data", attrs: { id: "page-content" } },
        [
          _vm.$store.state.authUser &&
          _vm.$store.state.authUser.type === 1 &&
          _vm.EditMode
            ? _c("admin-ring-battles", { attrs: { BattlesData: _vm.Battles } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.state.authUser ||
          (_vm.$store.state.authUser &&
            !(_vm.$store.state.authUser.type === 1)) ||
          !_vm.EditMode
            ? _c("public-ring-battles", { attrs: { BattlesData: _vm.Battles } })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.authUser && _vm.$store.state.authUser.type === 1
            ? _c(
                "modal",
                {
                  attrs: {
                    name: "SelectWinnerModal",
                    clickToClose: false,
                    scrollable: true,
                    height: "auto"
                  }
                },
                [
                  _c("WinnerSelectComponent", {
                    attrs: { BattleData: _vm.BattleData }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-748e3917", { render: render, staticRenderFns: staticRenderFns })
  }
}