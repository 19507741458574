<template>
  <div>
    <h1>404</h1>
  </div>
</template>

<script>
  export default {
  name: 'PageNotFound',
  mounted () {
  }
}
</script>

<style  lang="scss" scoped>
  h1 {
    color: red;
    span {
      color: green;
    }
  }
</style>
