var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ring-battles" }, [
    _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
      _c("div", { staticClass: "vgt-global-search__input vgt-pull-left" }, [
        _vm._m(0),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.string,
              expression: "string"
            }
          ],
          staticClass: "vgt-input vgt-pull-left",
          attrs: { type: "text", placeholder: "Search Table" },
          domProps: { value: _vm.string },
          on: {
            keydown: function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.searchValue(_vm.string)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.string = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ring-battles__data-container" }, [
      _c(
        "table",
        {
          staticClass:
            "table table-bordered ring-battles__data-container--table"
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.FightsData, function(data) {
                return !_vm.Search
                  ? _c(
                      "tr",
                      {
                        class: data.Color,
                        on: {
                          click: function($event) {
                            _vm.openFight(data.RingId, data.Id)
                          }
                        }
                      },
                      [
                        _c("td", [_vm._v(_vm._s(data.BattleNr))]),
                        _vm._v(" "),
                        _c("td", [
                          data.Lvl > 1 ? _c("span", [_vm._v("1/")]) : _vm._e(),
                          _vm._v(_vm._s(data.Lvl))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.AgeCategory))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.WeightCategory))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.RedTeam))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.RedNameSurname))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "side-color--red" }),
                        _vm._v(" "),
                        _c("td", { staticClass: "results-color" }, [
                          _vm._v(_vm._s(data.Result))
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "side-color--blue" }),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.BlueNameSurname))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.BlueTeam))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.LeftTime))]),
                        _vm._v(" "),
                        data.WinnerId == data.RedCmUserId
                          ? _c("td", [_vm._v(_vm._s(data.RedNameSurname))])
                          : data.WinnerId == data.BlueCmUserId
                          ? _c("td", [_vm._v(_vm._s(data.BlueNameSurname))])
                          : data.WinnerId != data.RedCmUserId ||
                            data.WinnerId != data.BlueCmUserId
                          ? _c("td", [_vm._v("-")])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.Fights, function(data, index) {
                return _vm.Search && index != 0
                  ? _c(
                      "tr",
                      {
                        class: data.Color,
                        on: {
                          click: function($event) {
                            _vm.openFight(data.RingId, data.Id)
                          }
                        }
                      },
                      [
                        _c("td", [_vm._v(_vm._s(data.BattleNr))]),
                        _vm._v(" "),
                        _c("td", [
                          data.Lvl > 1 ? _c("span", [_vm._v("1/")]) : _vm._e(),
                          _vm._v(_vm._s(data.Lvl))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.AgeCategory))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.WeightCategory))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.RedTeam))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.RedNameSurname))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "side-color--red" }),
                        _vm._v(" "),
                        _c("td", { staticClass: "results-color" }, [
                          _vm._v(_vm._s(data.Result))
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "side-color--blue" }),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.BlueNameSurname))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.BlueTeam))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(data.LeftTime))]),
                        _vm._v(" "),
                        data.WinnerId == data.RedCmUserId
                          ? _c("td", [_vm._v(_vm._s(data.RedNameSurname))])
                          : data.WinnerId == data.BlueCmUserId
                          ? _c("td", [_vm._v(_vm._s(data.BlueNameSurname))])
                          : data.WinnerId != data.RedCmUserId ||
                            data.WinnerId != data.BlueCmUserId
                          ? _c("td", [_vm._v("-")])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input__icon" }, [
      _c("div", { staticClass: "magnifying-glass" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Battle Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Limenis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Vecuma kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Svara kategorija")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }, [
          _vm._v("Sarkanais stūris")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--red" }),
        _vm._v(" "),
        _c("th", [_vm._v("Rezultats")]),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }),
        _vm._v(" "),
        _c("th", { staticClass: "side-color--blue" }, [
          _vm._v("Zilais stūris")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", [_vm._v("Laiks līdz sakšanai")]),
        _vm._v(" "),
        _c("th", [_vm._v("Winner")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-307f664c", { render: render, staticRenderFns: staticRenderFns })
  }
}