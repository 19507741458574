var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page",
      staticStyle: { height: "50rem", "background-color": "#838383" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          {
            staticClass: "page__content__navbar-container",
            class: { slide: _vm.show }
          },
          [
            _c("MenuComponent"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__navbar-container--open-btn",
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-menu-hamburger",
                  staticStyle: { color: "black", "font-size": "2.3rem" }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__data" }, [
          _c("div", { staticClass: "page__content__data__interactive-menu" }, [
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateDataTab,
                  leaveactive: _vm.animateMainTab,
                  displayshow: _vm.showDataTab,
                  displayhide: _vm.showMainTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("SACENSĪBAS")]
                ),
                _vm._v(" "),
                _vm._m(1)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateDataTab,
                  leaveactive: _vm.animateMainTab,
                  displayshow: _vm.showDataTab,
                  displayhide: _vm.showMainTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("LIETOTAJI")]
                ),
                _vm._v(" "),
                _vm._m(2)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateDataTab,
                  leaveactive: _vm.animateMainTab,
                  displayshow: _vm.showDataTab,
                  displayhide: _vm.showMainTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("KOMANDAS")]
                ),
                _vm._v(" "),
                _vm._m(3)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateDataTab,
                  leaveactive: _vm.animateMainTab,
                  displayshow: _vm.showDataTab,
                  displayhide: _vm.showMainTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("DATI SACENSĪBAM")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item__content"
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.showMainDataTabs()
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass:
                            "page__content__data__interactive-menu__item__content--img",
                          attrs: { src: "/static/data.jpg" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateMainTab,
                  leaveactive: _vm.animateDataTab,
                  displayshow: _vm.showMainTab,
                  displayhide: _vm.showDataTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("ATPAKAĻ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item__content"
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.showMainDataTabs()
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass:
                            "page__content__data__interactive-menu__item__content--img",
                          attrs: { src: "/static/back.jpg" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateMainTab,
                  leaveactive: _vm.animateDataTab,
                  displayshow: _vm.showMainTab,
                  displayhide: _vm.showDataTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("DISCIPLINAS")]
                ),
                _vm._v(" "),
                _vm._m(4)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateMainTab,
                  leaveactive: _vm.animateDataTab,
                  displayshow: _vm.showMainTab,
                  displayhide: _vm.showDataTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("RINGI")]
                ),
                _vm._v(" "),
                _vm._m(5)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: _vm.animateMainTab,
                  leaveactive: _vm.animateDataTab,
                  displayshow: _vm.showMainTab,
                  displayhide: _vm.showDataTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("KATOGORIJAS")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item__content"
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.showDataCategoriesTabs()
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass:
                            "page__content__data__interactive-menu__item__content--img",
                          attrs: { src: "/static/categories.png" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: !_vm.animateDataTab,
                  leaveactive: _vm.animateCategoriesTab,
                  displayshow: !_vm.showDataTab,
                  displayhide: _vm.showCategoriesTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("ATPAKAĻ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item__content"
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.showDataCategoriesTabs()
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass:
                            "page__content__data__interactive-menu__item__content--img",
                          attrs: { src: "/static/back.jpg" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: !_vm.animateDataTab,
                  leaveactive: _vm.animateCategoriesTab,
                  displayshow: !_vm.showDataTab,
                  displayhide: _vm.showCategoriesTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("VECUMU KATOGORIJAS")]
                ),
                _vm._v(" "),
                _vm._m(6)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "page__content__data__interactive-menu__item",
                class: {
                  enteractive: !_vm.animateDataTab,
                  leaveactive: _vm.animateCategoriesTab,
                  displayshow: !_vm.showDataTab,
                  displayhide: _vm.showCategoriesTab
                }
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "page__content__data__interactive-menu__item--label"
                  },
                  [_vm._v("SVARU KATEGORIJAS")]
                ),
                _vm._v(" "),
                _vm._m(7)
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__data-container" }, [
            _vm._m(8),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page__content__data-container__sub-content" },
              [
                _c("div", { staticClass: "list-grid" }, [
                  _c("div", { staticClass: "list-grid--header" }, [
                    _vm._v(
                      "\n              Pieteikumi lietotaju izveidošanai\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-grid__item" }, [
                    _c(
                      "div",
                      { staticClass: "list-grid__item__table" },
                      [
                        _vm._l(_vm.CreateUsersClaims, function(
                          CreateUserClaim,
                          index
                        ) {
                          return _c(
                            "div",
                            { staticClass: "list-grid__item__table__cell" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell--text",
                                  on: {
                                    click: function($event) {
                                      _vm.openCreateUserClaim(
                                        CreateUserClaim.Id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(CreateUserClaim.Name) +
                                        " " +
                                        _vm._s(CreateUserClaim.Surname) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(CreateUserClaim.Email) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell__btn-container"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--accept-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.confirmUser(CreateUserClaim.Id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Pieteikt\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--reject-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.unconfirmUser(CreateUserClaim.Id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Atteikt\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.CreateUsersClaims.length === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "list-grid__item__table__empty-table-msg"
                              },
                              [
                                _c("div", {}, [
                                  _vm._v(
                                    "\n                    Pieteikumu nav\n                  "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "list-grid" }, [
                  _c("div", { staticClass: "list-grid--header" }, [
                    _vm._v(
                      "\n              Pieteikumi sacensibam\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-grid__item" }, [
                    _c(
                      "div",
                      { staticClass: "list-grid__item__table" },
                      [
                        _vm._l(_vm.CompetitionUsersClaims, function(
                          CompetitionUserClaim,
                          index
                        ) {
                          return _c(
                            "div",
                            { staticClass: "list-grid__item__table__cell" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell--text",
                                  on: {
                                    click: function($event) {
                                      _vm.openCompetitionUserClaim(
                                        CompetitionUserClaim.CompetitionId,
                                        CompetitionUserClaim.Id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(CompetitionUserClaim.Name) +
                                        " " +
                                        _vm._s(CompetitionUserClaim.Surname) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        CompetitionUserClaim.CompetitionTitle
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    pieteikts: " +
                                      _vm._s(CompetitionUserClaim.ApplyDate) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-grid__item__table__cell__btn-container"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--accept-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.confirmCmUser(
                                            CompetitionUserClaim.Id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Pieteikt\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-grid__item__table__cell__btn-container--reject-btn",
                                      on: {
                                        click: function($event) {
                                          _vm.unconfirmCmUser(
                                            CompetitionUserClaim.Id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Atteikt\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.CompetitionUsersClaims.length === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "list-grid__item__table__empty-table-msg"
                              },
                              [
                                _c("div", {}, [
                                  _vm._v(
                                    "\n                    Pieteikumu nav\n                  "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Vadības panelis")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/competitions" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/competition.jpg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/users" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/user.jpg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/teams" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/team.png" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/disciplines" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/disciplines.jpg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/rings" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/ring.svg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/categories/age" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/age.jpg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__content__data__interactive-menu__item__content" },
      [
        _c("a", { attrs: { href: "/categories/weight" } }, [
          _c("img", {
            staticClass:
              "page__content__data__interactive-menu__item__content--img",
            attrs: { src: "/static/weight.jpg" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content__data-container__header" }, [
      _c(
        "div",
        {
          staticClass: "page__content__data-container__header__info-container"
        },
        [
          _c(
            "p",
            {
              staticClass:
                "page__content__data-container__header__info-container--item"
            },
            [_vm._v(" Lietotaju pieteikumi ")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78a89a9a", { render: render, staticRenderFns: staticRenderFns })
  }
}