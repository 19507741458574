var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-good-table" }, [
    _c(
      "table",
      {
        staticClass: "table table-bordered",
        staticStyle: { "font-size": "16px" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.BattlesListData, function(data) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(data.Nr))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(data.lvl))]),
              _vm._v(" "),
              !(data.Id === _vm.Selected.Id && _vm.Selected.Row === "RedTeam")
                ? _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.showEdit(data, "RedTeam")
                        }
                      }
                    },
                    [
                      _vm._v("\n        " + _vm._s(data.RedTeam) + "   "),
                      _c("span", { staticClass: "glyphicon glyphicon-pencil" })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              data.Id === _vm.Selected.Id && _vm.Selected.Row === "RedTeam"
                ? _c("td", [
                    !_vm.updatable
                      ? _c(
                          "div",
                          [
                            _c("label", [_vm._v("Mainit: ")]),
                            _vm._v(" "),
                            _c("multiselect", {
                              attrs: {
                                options: _vm.Teams,
                                "close-on-select": true,
                                "clear-on-select": false,
                                "open-direction": "below",
                                placeholder: "",
                                "track-by": "Id",
                                "custom-label": _vm.TeamLabel
                              },
                              model: {
                                value: _vm.Selected.Value,
                                callback: function($$v) {
                                  _vm.$set(_vm.Selected, "Value", $$v)
                                },
                                expression: "Selected.Value"
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "glyphicon glyphicon-ok",
                              on: {
                                click: function($event) {
                                  _vm.updateData(_vm.Selected)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "glyphicon glyphicon-pencil",
                              on: {
                                click: function($event) {
                                  _vm.updatable = !_vm.updatable
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.updatable
                      ? _c("div", [
                          _c("label", [_vm._v("Atjaunot: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Title,
                                expression: "Selected.Value.Title"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Title",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "glyphicon glyphicon-ok",
                            on: {
                              click: function($event) {
                                _vm.editData(_vm.Selected)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "glyphicon glyphicon-pencil",
                            on: {
                              click: function($event) {
                                _vm.updatable = !_vm.updatable
                              }
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              !(
                data.Id === _vm.Selected.Id &&
                _vm.Selected.Row === "RedNameSurname"
              )
                ? _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.showEdit(data, "RedNameSurname")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(data.RedNameSurname) +
                          " (" +
                          _vm._s(data.RedCmUserId) +
                          ")   "
                      ),
                      _c("span", { staticClass: "glyphicon glyphicon-pencil" })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              data.Id === _vm.Selected.Id &&
              _vm.Selected.Row === "RedNameSurname"
                ? _c("td", [
                    _c("label", [_vm._v("Mainit: ")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Selected.Value.Field,
                          expression: "Selected.Value.Field"
                        }
                      ],
                      domProps: { value: _vm.Selected.Value.Field },
                      on: {
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.updateData(_vm.Selected)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.Selected.Value,
                            "Field",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(data.Result))]),
              _vm._v(" "),
              !(
                data.Id === _vm.Selected.Id &&
                _vm.Selected.Row === "BlueNameSurname"
              )
                ? _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.showEdit(data, "BlueNameSurname")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(data.BlueNameSurname) +
                          " (" +
                          _vm._s(data.BlueCmUserId) +
                          ")   "
                      ),
                      _c("span", { staticClass: "glyphicon glyphicon-pencil" })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              data.Id === _vm.Selected.Id &&
              _vm.Selected.Row === "BlueNameSurname"
                ? _c("td", [
                    _c("label", [_vm._v("Mainit: ")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Selected.Value.Field,
                          expression: "Selected.Value.Field"
                        }
                      ],
                      domProps: { value: _vm.Selected.Value.Field },
                      on: {
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.updateData(_vm.Selected)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.Selected.Value,
                            "Field",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              !(data.Id === _vm.Selected.Id && _vm.Selected.Row === "BlueTeam")
                ? _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.showEdit(data, "BlueTeam")
                        }
                      }
                    },
                    [
                      _vm._v("\n        " + _vm._s(data.BlueTeam) + "   "),
                      _c("span", { staticClass: "glyphicon glyphicon-pencil" })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              data.Id === _vm.Selected.Id && _vm.Selected.Row === "BlueTeam"
                ? _c("td", [
                    !_vm.updatable
                      ? _c(
                          "div",
                          [
                            _c("label", [_vm._v("Mainit: ")]),
                            _vm._v(" "),
                            _c("multiselect", {
                              attrs: {
                                options: _vm.Teams,
                                "close-on-select": true,
                                "clear-on-select": false,
                                "open-direction": "below",
                                placeholder: "",
                                "track-by": "Id",
                                "custom-label": _vm.TeamLabel
                              },
                              model: {
                                value: _vm.Selected.Value,
                                callback: function($$v) {
                                  _vm.$set(_vm.Selected, "Value", $$v)
                                },
                                expression: "Selected.Value"
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "glyphicon glyphicon-ok",
                              on: {
                                click: function($event) {
                                  _vm.updateData(_vm.Selected)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "glyphicon glyphicon-pencil",
                              on: {
                                click: function($event) {
                                  _vm.updatable = !_vm.updatable
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.updatable
                      ? _c("div", [
                          _c("label", [_vm._v("Atjaunot: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Selected.Value.Title,
                                expression: "Selected.Value.Title"
                              }
                            ],
                            domProps: { value: _vm.Selected.Value.Title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Selected.Value,
                                  "Title",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "glyphicon glyphicon-ok",
                            on: {
                              click: function($event) {
                                _vm.editData(_vm.Selected)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "glyphicon glyphicon-pencil",
                            on: {
                              click: function($event) {
                                _vm.updatable = !_vm.updatable
                              }
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          })
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Limenis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")]),
        _vm._v(" "),
        _c("th", [_vm._v("Sarkanais sturis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Resultats")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zils Sturis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komanda")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b9bc5710", { render: render, staticRenderFns: staticRenderFns })
  }
}