var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input__form" }, [
        _c("h1", [
          _vm._v(
            "Lietotajs: " +
              _vm._s(_vm.UserData.Name) +
              " " +
              _vm._s(_vm.UserData.Surname)
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.UserData.Name,
                expression: "UserData.Name"
              }
            ],
            staticClass: "page__content__input__form__item--input form-control",
            domProps: { value: _vm.UserData.Name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.UserData, "Name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.UserData.Surname,
                expression: "UserData.Surname"
              }
            ],
            staticClass: "page__content__input__form__item--input form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.UserData.Surname },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.UserData, "Surname", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item--input",
              staticStyle: { left: "-2rem" }
            },
            [
              _c("DatePicker", {
                attrs: { "monday-first": true, "bootstrap-styling": true },
                model: {
                  value: _vm.DateOfBirth,
                  callback: function($$v) {
                    _vm.DateOfBirth = $$v
                  },
                  expression: "DateOfBirth"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__input__form__item" },
          [
            _vm._m(4),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.Genders,
                "close-on-select": true,
                "clear-on-select": false,
                "open-direction": "below",
                placeholder: "",
                "track-by": "Id",
                "custom-label": _vm.GenderLabel
              },
              model: {
                value: _vm.Gender,
                callback: function($$v) {
                  _vm.Gender = $$v
                },
                expression: "Gender"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.UserData.Email,
                expression: "UserData.Email"
              }
            ],
            staticClass: "page__content__input__form__item--input form-control",
            domProps: { value: _vm.UserData.Email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.UserData, "Email", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content__input__form__item" },
          [
            _vm._m(6),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.UserTypes,
                "close-on-select": true,
                "clear-on-select": false,
                "open-direction": "below",
                placeholder: "",
                "track-by": "Id",
                "custom-label": _vm.UserTypeLabel
              },
              model: {
                value: _vm.UserType,
                callback: function($$v) {
                  _vm.UserType = $$v
                },
                expression: "UserType"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("input", {
            staticClass:
              "page__content__input__form__item--input control-label",
            attrs: { type: "file", name: "image" },
            on: { change: _vm.onFileSelected }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(8),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.UserData.Parole,
                expression: "UserData.Parole"
              }
            ],
            staticClass: "page__content__input__form__item--input form-control",
            attrs: { type: "password" },
            domProps: { value: _vm.UserData.Parole },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.UserData, "Parole", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form__item" }, [
          _vm._m(9),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.UserData.RepeatParole,
                expression: "UserData.RepeatParole"
              }
            ],
            staticClass: "page__content__input__form__item--input form-control",
            attrs: { type: "password" },
            domProps: { value: _vm.UserData.RepeatParole },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.UserData, "RepeatParole", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "page__content__input__form__item--btn btn btn-primary",
            on: { click: _vm.createUser }
          },
          [_vm._v("Saglabat")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Registracija")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Vards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Uzvards"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "page__content__input__form__item--label control-label ",
        staticStyle: { left: "-2rem" }
      },
      [_vm._v("Dzimsanas\n          Datums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Gender"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Email"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Lietotaju\n          tips"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Ieladejiet jusu fotografiju ar pase"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Parole"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Atkārtot\n          paroli"), _c("sup", [_vm._v("*")])]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5dd9585a", { render: render, staticRenderFns: staticRenderFns })
  }
}