var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__input" }, [
        _c("h1", { staticClass: "page__content__input--title" }, [
          _vm._v("Sacensibu labošana: ")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "page__content__input__form" }, [
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.CompetitionData.Title,
                  expression: "CompetitionData.Title"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.CompetitionData.Title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.CompetitionData, "Title", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.CompetitionData.Subtitle,
                  expression: "CompetitionData.Subtitle"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.CompetitionData.Subtitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.CompetitionData, "Subtitle", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.CompetitionData.JudgeId,
                    expression: "CompetitionData.JudgeId"
                  }
                ],
                staticClass:
                  "page__content__input__form__item--input form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.CompetitionData,
                      "JudgeId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.MainJudges, function(Judge) {
                return _c("option", { domProps: { value: Judge.Id } }, [
                  _vm._v(_vm._s(Judge.Name) + " " + _vm._s(Judge.Surname))
                ])
              })
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("datetime", {
                attrs: {
                  "input-class":
                    "page__content__input__form__item--input form-control",
                  type: "date",
                  format: "yyyy-MM-dd"
                },
                model: {
                  value: _vm.StartDate,
                  callback: function($$v) {
                    _vm.StartDate = $$v
                  },
                  expression: "StartDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(5),
              _vm._v(" "),
              _c("datetime", {
                attrs: {
                  "input-class":
                    "page__content__input__form__item--input form-control",
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm"
                },
                model: {
                  value: _vm.AppUntil,
                  callback: function($$v) {
                    _vm.AppUntil = $$v
                  },
                  expression: "AppUntil"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(6),
              _vm._v(" "),
              _vm.MceVisible.b
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.b = !_vm.MceVisible.b
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "input-notice",
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Invitation)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.b
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Invitation,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Invitation", $$v)
                          },
                          expression: "CompetitionData.Invitation"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.b = !_vm.MceVisible.b
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(7),
              _vm._v(" "),
              _vm.MceVisible.c
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.c = !_vm.MceVisible.c
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Program)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.c
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Program,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Program", $$v)
                          },
                          expression: "CompetitionData.Program"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.c = !_vm.MceVisible.c
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(8),
              _vm._v(" "),
              _vm.MceVisible.d
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.d = !_vm.MceVisible.d
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Rules)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.d
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Rules,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Rules", $$v)
                          },
                          expression: "CompetitionData.Rules"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.d = !_vm.MceVisible.d
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(9),
              _vm._v(" "),
              _vm.MceVisible.e
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.e = !_vm.MceVisible.e
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Ambulance)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.e
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Ambulance,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Ambulance", $$v)
                          },
                          expression: "CompetitionData.Ambulance"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.e = !_vm.MceVisible.e
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(10),
              _vm._v(" "),
              _vm.MceVisible.f
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.f = !_vm.MceVisible.f
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Prizes)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.f
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Prizes,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Prizes", $$v)
                          },
                          expression: "CompetitionData.Prizes"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.f = !_vm.MceVisible.f
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(11),
              _vm._v(" "),
              _vm.MceVisible.g
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.g = !_vm.MceVisible.g
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Equipment)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.g
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Equipment,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Equipment", $$v)
                          },
                          expression: "CompetitionData.Equipment"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.g = !_vm.MceVisible.g
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__input__form__item",
              staticStyle: { overflow: "auto" }
            },
            [
              _vm._m(12),
              _vm._v(" "),
              _vm.MceVisible.a
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "min-height": "10rem",
                        border: "0.3rem solid greenyellow"
                      },
                      on: {
                        click: function($event) {
                          _vm.MceVisible.a = !_vm.MceVisible.a
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.CompetitionData.Cooperation)
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.MceVisible.a
                ? _c(
                    "div",
                    [
                      _c("tinymce", {
                        attrs: {
                          plugins: _vm.McePlagins,
                          other: _vm.MceOtherOptions
                        },
                        model: {
                          value: _vm.CompetitionData.Cooperation,
                          callback: function($$v) {
                            _vm.$set(_vm.CompetitionData, "Cooperation", $$v)
                          },
                          expression: "CompetitionData.Cooperation"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info control-label",
                          on: {
                            click: function($event) {
                              _vm.MceVisible.a = !_vm.MceVisible.a
                            }
                          }
                        },
                        [_vm._v("Change view")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__item" }, [
            _c(
              "label",
              {
                staticClass:
                  "page__content__input__form__item--label control-label "
              },
              [_vm._v("Cena")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.CompetitionData.Price,
                  expression: "CompetitionData.Price"
                }
              ],
              staticClass:
                "page__content__input__form__item--input form-control",
              domProps: { value: _vm.CompetitionData.Price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.CompetitionData, "Price", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _vm._m(13),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Disciplines,
                  multiple: true,
                  "track-by": "Id",
                  "custom-label": _vm.DisciplineLabel
                },
                model: {
                  value: _vm.CompetitionData.DisciplineIds,
                  callback: function($$v) {
                    _vm.$set(_vm.CompetitionData, "DisciplineIds", $$v)
                  },
                  expression: "CompetitionData.DisciplineIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content__input__form__item" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "page__content__input__form__item--label control-label "
                },
                [_vm._v("Ciņas generacijas algoritms")]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.Algorithms,
                  "track-by": "Id",
                  "custom-label": _vm.AlgorithmLabel
                },
                model: {
                  value: _vm.CompetitionData.Algorithm,
                  callback: function($$v) {
                    _vm.$set(_vm.CompetitionData, "Algorithm", $$v)
                  },
                  expression: "CompetitionData.Algorithm"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page__content__input__form__btn-item" }, [
            _c(
              "button",
              {
                staticClass:
                  "page__content__input__form__btn-item--btn btn btn-primary",
                on: { click: _vm.editCompetitionData }
              },
              [_vm._v("Saglabat")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v("Labošana")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Nosaukums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Apakšvirsraksts"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Tiesnesis"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Datums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Pieteikšanās līdz"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Uzaicinājums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Programa"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Noteikumi"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Ambulance"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Apbalvošana"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Ekipejums"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Sadarbība"), _c("sup", [_vm._v("*")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "page__content__input__form__item--label control-label " },
      [_vm._v("Discipline ids"), _c("sup", [_vm._v("*")])]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-03a1d42a", { render: render, staticRenderFns: staticRenderFns })
  }
}