<template>
    <div class="page">
        <div class="page__section">
            <div class="page__section__name-container">
                <h1 class="page__section__name-container--title">Stilu Izveidošana</h1>
            </div>
        </div>
        <div class="page__content">
            <div class="page__content__navbar-container" v-bind:class="{slide : show}">
               <MenuComponent></MenuComponent>
                <div @click="show = !show" class="page__content__navbar-container--open-btn">
                    <span class="glyphicon glyphicon-menu-hamburger" style="color: black; font-size: 2.3rem"></span>
                </div>
            </div>
            <div class="page__content__input">
                <h1 class="page__content__input--title">Izveidošanas dati</h1>
                <br>
                <div class="page__content__input__form">
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Nosaukums<sup>*</sup></label>
                        <input class="page__content__input__form__item--input form-control"  v-model="StyleData.Title">
                    </div>
                    <div class="page__content__input__form__item">
                        <label class="page__content__input__form__item--label control-label ">Apraksts<sup>*</sup></label>
                        <textarea  class="page__content__input__form__item--input form-control" rows="10" v-model="StyleData.Description">
                        </textarea>
                    </div>
                    <div class="page__content__input__form__btn-item">
                        <button class="page__content__input__form__btn-item--btn btn btn-primary" v-on:click="createStyle">Izveidot</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import MenuComponent from "../../components/common/MenuComponent";

    export default {
        name: "createStylePage",
        data(){
            return {
                show: false,
                StyleData: {
                    Title: "",
                    Description: "",
                },
            }
        },
        methods: {
            createStyle(){
                console.log('create-style')
                this.$socket.emit('create-style', this.StyleData);
            },
        },
        mounted() {
            this.$socket.on('success', response => {
                if (response===true){
                    this.$router.push('/styles')
                }else{alert("Check all fields on filled right")}
            });
        },
        beforeDestroy () {
            this.$socket.removeListener('success');
        },
        components: {
            MenuComponent,
        }
    }
</script>

<style lang="scss" scoped>

    #app {
        margin-top: 20px;
    }

    pre {
        margin-top: 20px;
    }
</style>

