<template>
  <div class="container">
    <router-link class="list-group-item list-group-item-action"
       v-for="(competition, index) in competitions" :to="{ name: 'Competition', params: { id: competition.id}}" :key="competition.id">
      {{ index + 1 }}) &ensp;{{competition.title}}

      <span>Date: {{competition.date | date}}</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Competitions',
    data () {
      return {
        competitions: {}
      }
    },
    mounted () {
      this.$axios.get(`/api/stream/competitions`)
        .then(response => {
        this.competitions = response.data;
    })
    .catch(e => {
        this.errors.push(e)
    })
      this.$socket.emit('join', {test: 322})
      //console.log(this.$store.state.authUser)
    }
  }
</script>

<style  lang="scss" scoped>
  h1 {
    color: red;
  }
</style>
