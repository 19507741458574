var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page__section" }, [
      _c("div", { staticClass: "page__section__name-container" }, [
        _c("h1", { staticClass: "page__section__name-container--title" }, [
          _vm._v(_vm._s(_vm.CompetitionTitle) + " ")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c(
        "div",
        {
          staticClass: "page__content__navbar-container",
          class: { slide: _vm.show }
        },
        [
          _c("MenuComponent"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page__content__navbar-container--open-btn",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-menu-hamburger",
                staticStyle: { color: "black", "font-size": "2.3rem" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content__data" }, [
        _c(
          "h1",
          {
            staticClass: "page__content__data--header",
            staticStyle: { color: "black", "font-size": "3rem" }
          },
          [_vm._v("Cīņas shēma " + _vm._s(_vm.CategoryTitle))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "right", margin: "0 5rem 0" } },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                staticStyle: { width: "25rem" },
                on: {
                  click: function($event) {
                    _vm.generateBattles()
                  }
                }
              },
              [_vm._v("Generēt cīņas")]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "graph" }, [
          _c(
            "div",
            { staticClass: "competition" },
            _vm._l(_vm.BattlesForGraph, function(CompetitionPart, CompIndex) {
              return _c(
                "div",
                { staticClass: "competition__part" },
                _vm._l(CompetitionPart, function(Battle, BattleIndex) {
                  return _c(
                    "div",
                    { staticClass: "competition__part__battle-block" },
                    [
                      !(CompIndex === 0)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "competition__part__battle-block__connect--first"
                            },
                            [_vm._m(0, true)]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "competition__part__battle-block__fighters"
                        },
                        [
                          _c(
                            "drop",
                            {
                              staticClass:
                                "competition__part__battle-block__fighters_red",
                              on: {
                                drop: function($event) {
                                  _vm.dropFighter({
                                    Id: Battle.RedCmUserId,
                                    Nr: Battle.RedCmNr
                                  })
                                }
                              }
                            },
                            [
                              Battle.RedNameSurname && Battle.RedCmNr
                                ? _c(
                                    "drag",
                                    {
                                      staticClass:
                                        "competition__part__battle-block__fighters_red--name",
                                      on: {
                                        drag: function($event) {
                                          _vm.dragFighter({
                                            Id: Battle.RedCmUserId,
                                            Nr: Battle.RedCmNr
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "20rem",
                                            margin: "1rem 1rem"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.RedNameSurname) +
                                              " (" +
                                              _vm._s(Battle.RedCmNr) +
                                              ")\n                      "
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#413636" }
                                            },
                                            [_vm._v(_vm._s(Battle.RedTeam))]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "competition__part__battle-block__fighters_red--name"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "20rem",
                                            margin: "1rem 1rem"
                                          }
                                        },
                                        [_vm._v(_vm._s(Battle.RedComesFromNr))]
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "competition__part__battle-block__fighters_red__result"
                                },
                                [
                                  Battle.CompetitionPart == 1 &&
                                  Battle.WinnerId == Battle.RedCmUserId &&
                                  Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--first"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.RedResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : Battle.CompetitionPart == 1 &&
                                      Battle.WinnerId != Battle.RedCmUserId &&
                                      Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--second"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.RedResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : Battle.CompetitionPart != 1 &&
                                      Battle.WinnerId == Battle.RedCmUserId &&
                                      Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--winner"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.RedResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--dif"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.RedResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "drop",
                            {
                              staticClass:
                                "competition__part__battle-block__fighters_blue",
                              on: {
                                drop: function($event) {
                                  _vm.dropFighter({
                                    Id: Battle.BlueCmUserId,
                                    Nr: Battle.BlueCmNr
                                  })
                                }
                              }
                            },
                            [
                              Battle.BlueNameSurname && Battle.BlueCmNr
                                ? _c(
                                    "drag",
                                    {
                                      staticClass:
                                        "competition__part__battle-block__fighters_blue--name",
                                      on: {
                                        drag: function($event) {
                                          _vm.dragFighter({
                                            Id: Battle.BlueCmUserId,
                                            Nr: Battle.BlueCmNr
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "20rem",
                                            margin: "1rem 1rem"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.BlueNameSurname) +
                                              " (" +
                                              _vm._s(Battle.BlueCmNr) +
                                              ")\n                      "
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#413636" }
                                            },
                                            [_vm._v(_vm._s(Battle.BlueTeam))]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "competition__part__battle-block__fighters_red--name"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "20rem",
                                            margin: "1rem 1rem"
                                          }
                                        },
                                        [_vm._v(_vm._s(Battle.BlueComesFromNr))]
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "competition__part__battle-block__fighters_blue__result"
                                },
                                [
                                  Battle.CompetitionPart == 1 &&
                                  Battle.WinnerId == Battle.BlueCmUserId &&
                                  Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--first"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.BlueResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : Battle.CompetitionPart == 1 &&
                                      Battle.WinnerId != Battle.BlueCmUserId &&
                                      Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--second"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.BlueResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : Battle.CompetitionPart != 1 &&
                                      Battle.WinnerId == Battle.BlueCmUserId &&
                                      Battle.WinnerId != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--winner"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.BlueResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "competition__part__battle-block__fighters_red__result--dif"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(Battle.BlueResult) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !(CompetitionPart.length === 1)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "competition__part__battle-block__connect"
                            },
                            [
                              !(BattleIndex & 1)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "competition__part__battle-block__connect--end"
                                    },
                                    [
                                      !(
                                        _vm.BattlesForGraph.length === 3 &&
                                        CompIndex === 1
                                      )
                                        ? _c("div", {
                                            staticClass:
                                              "competition__part__battle-block__connect--top",
                                            class: {
                                              "top-padding":
                                                _vm.BattlesForGraph.length >
                                                  3 &&
                                                Battle.CompetitionPart == 2
                                            }
                                          })
                                        : _c("div", {
                                            staticClass:
                                              "competition__part__battle-block__connect--top",
                                            class: {
                                              "four-parts-connect":
                                                _vm.BattlesForGraph.length ===
                                                  3 && CompIndex === 1
                                            }
                                          })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              BattleIndex & 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "competition__part__battle-block_connect"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "competition__part__battle-block__connect--bottom",
                                        class: {
                                          "bottom-padding":
                                            _vm.BattlesForGraph.length > 3 &&
                                            (Battle.CompetitionPart == 2 ||
                                              Battle.CompetitionPart == 4)
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                })
              )
            })
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "competition__part__battle-block__connect" },
      [
        _c("div", {
          staticClass:
            "competition__part__battle-block__connect--middle_connection"
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b9532b4", { render: render, staticRenderFns: staticRenderFns })
  }
}