<template>
  <div class="page adding-remote-container">
    <div class="page__content__input">
      <h1 class="page__content__input--title" style="padding: 0;">Pultu pieslegšana</h1>
      <div class="list-grid adding-remote-list">
        <div class="list-grid__item">
          <div class="list-grid__item__table">
            <div class="list-grid__item__table__cell" v-for="FoundRemote in FoundRemotes">
              <div class="list-grid__item__table__cell--text" style="font-size: 3rem !important;">
                {{FoundRemote.Nr}} - {{FoundRemote.RemoteAddress}}
              </div>
              <div class="list-grid__item__table__cell__btn-container">
                <div v-if="checkSaved(FoundRemote.RemoteAddress)" class="list-grid__item__table__cell__btn-container--accept-btn" @click="addRemoteController(FoundRemote.RemoteAddress)">
                  Saglabat
                </div>
              </div>
            </div>
            <div class="list-grid__item__table__empty-table-msg" v-if="FoundRemotes.length===0">
              <div class="">
                Pultu nav
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page__content__input__form__btn-item" >
        <button class="page__content__input__form__btn-item--btn btn btn-secondary"
                @click="$modal.hide('addRemoteModal')">❌ Aizvert
        </button>
      </div>
     <!-- <div v-else class="page__content__input__form">
        <div class="page__content__input__form__alert">
          <h1>Sveršana beidzas</h1>
          <button class="page__content__input__form__alert&#45;&#45;btn btn btn-secondary"
                  @click="$modal.hide('WeighingModal')">
            ❌ Aizvert
          </button>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>

  export default {
    name: "addRemoteModalComponent",
    props: ['RemotesAddresses'],
    data() {
      return {
        RemoteNr: 0,
        FoundRemotes: [],
        SavedRemotes: this.RemotesAddresses,
      }
    },
    methods: {
      addRemoteController(RemoteAddress){
        this.$socket.emit(`add-remote-controller`,{RemoteAddress: RemoteAddress})
      },
      checkSaved(RemoteAddress){
        console.log(this.RemotesAddresses)
        console.log(!this.SavedRemotes.some((remote) => remote==RemoteAddress))
        return !this.SavedRemotes.some((remote) => remote==RemoteAddress)
      },
    /*  approveWeighingApply() {
        this.$socket.emit('next-weighing-team', this.TeamData);
      },
      nextWeighigApply() {
        this.$socket.emit('skip-weighing-team', this.TeamData)
      },*/

  /*    getData: function () {
        this.$axios.get(`/api/weighing-queue`, {
          params: {
            cmId: this.$route.params.cmId,
          }
        })
          .then(response => {
            this.TeamData = response.data.TeamData;
            console.log(response.data)
          })
          .catch(e => {
            this.errors.push(e)
          })
      },*/
    },
    mounted() {
      this.$socket.on('found-remote',response=>{
        this.RemoteNr++
        console.log(response)
        if(!this.FoundRemotes.some((item)=>item.RemoteAddress==response)) {
          console.log('response: ',response)
          this.FoundRemotes.push({Nr: this.RemoteNr, RemoteAddress: response})
        }
      })
      this.$socket.on('success',response=>{
        if (response.Status===true){
          console.log(this.SavedRemotes)
          console.log('pushed: ',response.RemoteAddress)
          this.SavedRemotes.push(response.RemoteAddress)
        }
      })

     /* this.getData();
      this.$socket.on('next-on-weighing', response => {
        response != null ? this.TeamData = response : this.TeamData = null
      })
      this.$socket.on('success', response => {
        if (response === true) {
          alert("Pieteikums nomainits!")
        } else {
          if (response != '') {
            alert(response)
          } else {
            alert("Something goes wrong! Check all fields on filled right")
          }
        }
      });
      this.$socket.on('refresh-teams-data', response => {
        this.getData();
      });
      this.$socket.on('next-on-weighing', response => {
        console.log("response ", response)
        this.getData();
      });*/
    },
    beforeDestroy() {
      this.$socket.removeListener('success');
      this.$socket.removeListener('found-remote');
    },
    components: {

    },
  }
</script>


<style lang="scss" scoped>

  .page {
    background: #eeecf6;
  }

  .adding-remote-list{
    width:100%;
    padding: 4rem 0 0 0;
  }

  .adding-remote-container{
    padding: 0;
  }

  /*.weighing-claim {
    width: 100%;

    &__input-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 0;
      padding: 0 4rem 7rem 4rem;

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-bottom: 2rem;

        &--label {
          text-align: right;
        }

        &--input {
        }
      }

      &__alert {
        padding-top: 5rem;

        &--btn {
          font-size: 1.8rem;
        }
      }
    }

    &__buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-top: 2rem;

      &--btn {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
    }
  }*/

  /*  #coachs{}
    .slide{
      transform: translateX(0%);
      transition: 0.5s;
      z-index: 0;
    }*/
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>