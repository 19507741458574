var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12 clear-pads judge-statuses text-center bold" },
    [
      _c("div", { staticClass: "col-md-12 clear-pads" }, [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button" },
            on: { click: _vm.hideModal }
          },
          [_c("span", [_vm._v("×")])]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.judges, function(judge) {
        return _c("div", { staticClass: "col-md-4 item" }, [
          _c("div", { staticClass: "col-md-12 clear-pads judge" }, [
            _vm._v(_vm._s(judge.name) + " " + _vm._s(judge.surname))
          ]),
          _vm._v(" "),
          judge.status === 0
            ? _c("div", { staticClass: "col-md-12 clear-pads status red" }, [
                _vm._v("Refused")
              ])
            : _vm._e(),
          _vm._v(" "),
          judge.status === 1
            ? _c("div", { staticClass: "col-md-12 clear-pads status green" }, [
                _vm._v("Ready")
              ])
            : _vm._e(),
          _vm._v(" "),
          judge.status === null
            ? _c("div", { staticClass: "col-md-12 clear-pads status gray" }, [
                _vm._v("?")
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d880321", { render: render, staticRenderFns: staticRenderFns })
  }
}